import { Box, Typography, useTheme } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import { routes } from "../../../../../routes";
import { useHandleRouteClick } from "../../../../../routes/hooks";
import CategoryResults from "./CategoryResults";
import RecognationOverview from "./RecognationOverview";
import { ResultsData, TextData } from "./ResultsData";

export default function ResultsOverview() {
  const theme = useTheme();
  const params = useParams();
  const handleRouteClick = useHandleRouteClick();
  const { state } = useLocation();

  const handleBackNavigation = () => {
    if (state?.results) {
      handleRouteClick(routes.customAI.path);
    } else {
      handleRouteClick(routes.aiSetting.path.replace(":id", String(params?.id)));
    }
  };

  const overviewData = { men: 67, women: 35, children: 12, cars: 5, buses: 8, cats: 17 };

  return (
    <Box
      px={3}
      mt={2}
      sx={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
      }}
    >
      <Box display="flex" gap={1}>
        <Typography variant="h1" color={theme.palette.text.titleLabel}>
          Results Overview
        </Typography>
      </Box>
      <Box>
        <BackButton onClick={handleBackNavigation} sx={{ mr: -1 }} />
      </Box>
      <Box sx={{ height: "100vh", overflow: "scroll", pb: 5 }}>
        {state?.results && (
          <Box
            sx={{
              background: theme.palette.background.darkCardBackground,
              border: theme.palette.additionalColors.databasecardBorder,
              borderRadius: 2,
              my: 4,
              p: 3,
            }}
          >
            <CategoryResults overviewData={overviewData} />
          </Box>
        )}
        <RecognationOverview results={state?.datasource === "image" ? ResultsData : TextData} />
      </Box>
    </Box>
  );
}
