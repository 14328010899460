import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, Grid, Typography, useTheme } from "@mui/material";
import CustomIconButton from "../../../../components/CustomIconButton";
import { DetailOptions } from "../AIContainer";

interface DialogProps {
  open: boolean;
  handleClose: () => void;
  details: DetailOptions;
}

export default function ModelDetail({ open, details, handleClose }: DialogProps) {
  const theme = useTheme();

  const renderModelInfo = (label: string, name: string) => (
    <Box display="flex" flexDirection="column" textAlign="left">
      <Typography variant="subtitle2" color={theme.palette.text.tableHeader}>
        {label}
      </Typography>
      <Typography variant="body4" color={theme.palette.text.titleLabel}>
        {name}
      </Typography>
    </Box>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.background.default,
          borderRadius: "10px",
          width: "100%",
          maxWidth: "32%",
          textAlign: "center",
          p: 2,
        },
      }}
    >
      <DialogContent sx={{ p: 1 }}>
        <Box display={"flex"} width="100%" mb={3}>
          <Typography color={theme.palette.text.titleLabel} sx={{ m: "auto", mr: 0 }}>
            {details?.name}
          </Typography>
          <CustomIconButton variant="contained" onClick={handleClose} sx={{ ml: "auto" }}>
            <CloseIcon sx={{ color: theme.palette.error.main }} />
          </CustomIconButton>
        </Box>
        <Grid container spacing={2} mt={5} width="100%" m="auto">
          <Grid item xs={6}>
            {renderModelInfo("Basic AI Model", "Entity Recognition")}
          </Grid>
          <Grid item xs={6}>
            {renderModelInfo("Date type", "Image")}
          </Grid>
          <Grid item xs={6} mt={2}>
            {renderModelInfo("Customization method", "Data Labeling")}
          </Grid>
          <Grid item xs={6} mt={2}>
            {renderModelInfo("User", "Dan Ruby")}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
