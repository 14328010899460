import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Typography, useTheme } from "@mui/material";
import { FAQProps } from "../faq/FAQItem";
import Articles from "./Articles";
import SpecificArticle from "./SpecificArticle";

interface SpecificTopicProps {
  topicData?: FAQProps;
  isArticle: boolean;
  openSpecificArticle(title: string): void;
}

export default function SpecificTopic({ topicData, isArticle, openSpecificArticle }: SpecificTopicProps) {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {!isArticle && (
        <>
          <Box display="flex" gap={3} mt={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: theme.palette.primary.light,
                p: "0.6em",
                borderRadius: "50%",
                alignSelf: "end",
              }}
              pt={1}
            >
              <SettingsIcon sx={{ fontSize: "2em", color: theme.palette.background.default }} />
            </Box>

            <Box display="flex" flexDirection="column" width="100%">
              <Typography variant="subtitle1" sx={{ color: theme.palette.primary.light, mb: 1, fontSize: "2.25rem", fontWeight: 700 }}>
                {topicData?.title}
              </Typography>
              <Typography variant="body1" sx={{ color: theme.palette.text.captionColor }}>
                Articles that will help users with the process of training AI models, including model selection, hyperparameter tuning, and best
                practices.
              </Typography>
            </Box>
            <Box display="flex" width="25%">
              <Typography variant="body2" sx={{ color: theme.palette.primary.inactiveIcon, ml: "auto" }}>
                117 Articles
              </Typography>
            </Box>
          </Box>
          <Articles openSpecificArticle={openSpecificArticle} />
        </>
      )}
      {isArticle && <SpecificArticle />}
    </Box>
  );
}
