import { FormControl, Typography } from "@mui/material";
import CustomInputField from "../../../../components/CustomInputField";

interface FieldProps {
  name: string;
  label: string;
  placeholder: string;
  defaultValue: string;
  handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

export default function PaymentDetailField({ name, label, placeholder, defaultValue, handleInputChange }: FieldProps) {
  return (
    <FormControl fullWidth sx={{ mt: 2 }}>
      <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
        {label}
      </Typography>
      <CustomInputField
        required
        name={name}
        defaultValue={defaultValue}
        size="small"
        placeholder={placeholder}
        onChange={handleInputChange}
        autoComplete="off"
      />
    </FormControl>
  );
}
