import CloseIcon from "@mui/icons-material/Close";
import { Checkbox, Grid, Popover, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CustomButton from "../../../../../../../components/CustomButton";
import CustomCloseIconButton from "../../../../../../../components/CustomCloseIconButton";
import MultiChipInputField from "../../../../../../../components/MultiChipInputField";

export interface TagsData {
  tags: string[];
  checked: boolean;
}
interface EditTagsDialogProps {
  anchor: HTMLDivElement | null;
  id: string;
  open: boolean;
  defaultTags: string[];
  getData: (data: TagsData) => void;
  handleClose: () => void;
}

const EditTagsDialog = ({ anchor, id, open, defaultTags, getData, handleClose }: EditTagsDialogProps) => {
  const theme = useTheme();
  const [tags, setTags] = useState<string[]>([]);
  const [checked, setChecked] = useState<boolean>(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleKeywordsChange = (values: string[]) => {
    setTags(values);
  };
  const handleUpdate = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    getData({ tags: tags, checked: checked });
    handleClose();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        marginLeft: "25px",
      }}
      slotProps={{
        paper: {
          sx: {
            background: theme.palette.background.gradientLight1,
            boxShadow: theme.palette.dashboardContainer.editTagsDialogBoxShadow,
            border: theme.palette.dashboardContainer.editTagsDialogBorder,
            borderRadius: "10px",
            width: "35%",
            p: 2,
            textAlign: "center",
          },
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
            Edit Tags:{" "}
          </Typography>
          <CustomCloseIconButton onClick={handleClose} sx={{ marginLeft: { xs: "0", md: "80px" } }}>
            <CloseIcon sx={{ width: "16px", height: "16px" }} />
          </CustomCloseIconButton>
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
          <Typography variant="caption1" sx={{ lineHeight: "120%", color: theme.palette.text.light }} textAlign="left">
            Write your words separated by Space
          </Typography>
          <MultiChipInputField placeholder="Text" separator={" "} onKeywordsChange={handleKeywordsChange} defaultTags={defaultTags} />
        </Grid>
        <Grid item xs={12} display="flex" alignItems="center">
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
            sx={{
              p: 0,
              fontSize: "14px",
              fontWeight: "400",
              "& .MuiSvgIcon-root": {
                fill: checked ? theme.palette.checkbox.default : theme.palette.checkbox.darkBorder,
              },
              "& .MuiButtonBase-root.MuiCheckbox-root": {
                pl: "5px",
              },
              "&:hover .MuiSvgIcon-root": {
                fill: checked ? theme.palette.checkbox.default : "linear-gradient(135deg, rgba(38, 46, 63, 0.70) 0%, rgba(29, 35, 48, 0.70) 100%)",
                stroke: checked ? "none" : theme.palette.checkbox.darkBorder,
              },
              "&:active .MuiSvgIcon-root": {
                fill: checked ? "#245785" : theme.palette.checkbox.darkBorder,
              },
            }}
          />
          <Typography variant="caption" ml={1} color={theme.palette.text.titleLabel}>
            Apply new tags configuration to similar cases
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" gap={1.5} justifyContent="flex-end">
          <CustomButton
            variant="contained"
            sx={{
              ...theme.typography.h6,
              border: `0.5px solid ${theme.palette.additionalColors.border1Light}`,
              color: theme.palette.primary.main,
              background: theme.palette.background.tertiaryDarkGradient,
              boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
              "&:hover": {
                background: theme.palette.background.tertiaryGradient,
                boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
              },
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              handleClose();
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            sx={{
              ...theme.typography.bigButton,
              background: theme.palette.background.secondaryBgColor,
              color: theme.palette.text.secondaryText,
              width: "123px",
            }}
            onClick={handleUpdate}
          >
            Update Data
          </CustomButton>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default EditTagsDialog;
