import { Box, Checkbox, Link, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";

import { useState } from "react";
import CustomFormButton from "../../components/CustomFormButton";
import FormikInputField from "../../components/FormikInputField";

const initialValues = {
  userName: "",
  organisation: "",
  email: "",
};

const formField = {
  userName: {
    name: "user_name",
    placeholder: "First & last name",
    requiredErrorMsg: "Username is required",
  },
  email: {
    name: "user_email",
    placeholder: "Email Address",
    requiredErrorMsg: "Email Address is required",
  },
};

const validationSchema = Yup.object().shape({
  [formField.userName.name]: Yup.string().required(`${formField.userName.requiredErrorMsg}`),
  [formField.email.name]: Yup.string().email("Please enter a valid email").required(`${formField.email.requiredErrorMsg}`),
});

export interface UserDetailsFormProps {
  handleSubmit: (values: any) => any;
  handleInputChange: (params: { fieldName: string; value: string }) => void;
  isLoading: boolean;
}

const UserDetailsForm: React.FC<UserDetailsFormProps> = ({ handleSubmit, handleInputChange, isLoading }) => {
  const theme = useTheme();

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ zIndex: 1, width: "100%" }}
      // py={{ xs: 2, sm: 1, lg: 0, xl: 3 }}
      // px={{ xs: 6, sm: 1, lg: 0, xl: 3 }}
    >
      <Typography
        variant="h1"
        sx={{
          fontFeatureSettings: "'liga' off",
          color: (theme) => theme.palette.text.titleLabel,
        }}
      >
        Sign Up
      </Typography>
      <Typography mt={2} mb={3} variant="body6" textAlign="center" color={(theme) => theme.palette.text.titleLabel}>
        Get started with free version. Access to 1 Data Source & 1 AI Model. No credit card required{" "}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
        }}
      >
        {(formikProps) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formikProps.handleSubmit();
            }}
            style={{ width: "100%" }}
          >
            <FormikInputField
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formikProps.handleChange(e);
                handleInputChange({
                  fieldName: formField.userName.name,
                  value: e.target.value,
                });
              }}
              style={{ width: "100%" }}
              name={formField.userName.name}
              placeholder={formField.userName.placeholder}
            />
            <FormikInputField
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formikProps.handleChange(e);
                handleInputChange({
                  fieldName: formField.email.name,
                  value: e.target.value,
                });
              }}
              style={{ width: "100%" }}
              name={formField.email.name}
              placeholder={formField.email.placeholder}
            />
            <Box display="flex" gap={0.5} mt={1}>
              <Checkbox
                onChange={handleChange}
                sx={{
                  p: 0,
                  mt: -0.5,
                  "&.MuiCheckbox-root": {
                    color: theme.palette.checkbox.darkBorder,
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: "1.2rem",
                    fill: checked ? theme.palette.checkbox.default : theme.palette.checkbox.darkBorder,
                  },
                  "& .MuiButtonBase-root.MuiCheckbox-root": {
                    pl: "5px",
                  },
                  "&:active .MuiSvgIcon-root": {
                    fill: checked ? "#245785" : theme.palette.checkbox.darkBorder,
                  },
                }}
              />
              <Typography variant="body4">
                I agree to{" "}
                <Link underline="none" sx={{ color: theme.palette.text.default }}>
                  Terms and Conditions
                </Link>
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <CustomFormButton
                sx={{
                  marginTop: "3rem",
                  color: theme.palette.background.main,
                  px: 10,
                }}
                isLoading={isLoading}
              >
                Sign up
              </CustomFormButton>
              <Typography variant="body6" mt={3} color={theme.palette.text.titleLabel}>
                Already have an account?
                <NavLink
                  to="/login"
                  style={{
                    textDecoration: "none",
                    fontWeight: theme.typography.fontWeightMedium,
                    color: theme.palette.primary.main,
                    marginLeft: "4px",
                  }}
                >
                  Sign In
                </NavLink>
              </Typography>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default UserDetailsForm;
