import { Box, Typography, useTheme } from "@mui/material";
import Chart from "react-apexcharts";

interface EntityType {
  [key: string]: number;
}

type TextPalette = {
  extremeStatus: string;
  highStatus: string;
  moderateStatus: string;
  neutralStatus: string;
  lowStatus: string;
};

type ChartColors = string[];

interface ChartOptions {
  option: {
    chart: {
      type: "donut";
    };
    labels: string[];
    legend: {
      show: boolean;
      position: "bottom";
      fontSize: string;
      fontWeight: number;
      lineHeight: string;
      labels: {
        colors: string | undefined;
        useSeriesColors: boolean;
      };
      markers: {
        width: number;
        height: number;
        radius: number;
        strokeWidth: number;
        fillColors: string[];
      };
    };
    dataLabels: {
      enabled: boolean;
    };
    fill: {
      colors: ChartColors;
    };
    states: {
      hover: { filter: { type: string; value: number } };
      active: { filter: { type: string; value: number } };
    };
    stroke: {
      width: number;
    };
    plotOptions: {
      pie: {
        expandOnClick: boolean;
        donut: {
          labels: {
            show: boolean;
            name: { show: boolean };
            total: {
              show: boolean;
              showAlways: boolean;
              formatter(w: any): string;
            };
            value: {
              fontWeight: number;
              fontSize: string;
              lineHeight: string;
              color: string | undefined;
            };
          };
        };
      };
    };
  };
  series: number[];
}

export default function RelevantEntities() {
  const entitiesData: EntityType = {
    extreme: 800,
    high: 2500,
    moderate: 1200,
    neutral: 7000,
    low: 500,
  };

  const theme = useTheme();
  const option: ChartOptions = {
    option: {
      chart: {
        type: "donut",
      },
      labels: Object.keys(entitiesData),
      legend: {
        show: false,
        position: "bottom",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "100%",
        labels: {
          colors: theme.palette.text.titleLabel,
          useSeriesColors: false,
        },
        markers: {
          width: 12,
          height: 12,
          radius: 1,
          strokeWidth: 0,
          fillColors: ["linear-gradient(180deg, #2EA3FF 0%, #1C5D91 100%)", "#F17742", "#05539A"],
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: [
          theme.palette.text.extremeStatus,
          theme.palette.text.highStatus,
          theme.palette.text.moderateStatus,
          theme.palette.text.neutralStatus,
          theme.palette.text.lowStatus,
        ] as ChartColors,
      },
      states: {
        hover: { filter: { type: "none", value: 0 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: {
        width: 0,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: { show: false },
              total: {
                show: true,
                showAlways: true,
                formatter: function (w: any) {
                  const totals = w.globals.seriesTotals;
                  const result = totals.reduce((a: any, b: any) => a + b);
                  return result;
                },
              },
              value: {
                fontWeight: 600,
                fontSize: "32px",
                lineHeight: "100%",
                color: theme.palette.text.titleLabel,
              },
            },
          },
        },
      },
    },
    series: Object.values(entitiesData),
  };

  return (
    <Box>
      <Typography variant="h4" mb={2.5} color={theme.palette.text.titleLabel}>
        Number of relevant entities
      </Typography>
      <Chart type="donut" options={option.option} series={option.series} height={200} />
      <Box mt={2.5}>
        {Object.keys(entitiesData).map((entity: string) => {
          const statusColor: any = `${entity}Status`;
          return (
            <Box display="flex" justifyContent="space-between" key={`entity-${entity}`} mb={0.3}>
              <Typography variant="body5" color={theme.palette.text.titleLabel} sx={{ display: "flex", gap: 0.7, alignItems: "center", pb: 0.3 }}>
                <Box
                  sx={{
                    height: "12px",
                    width: "12px",
                    background: theme.palette.text[statusColor as keyof TextPalette],
                    borderRadius: "2px",
                  }}
                ></Box>
                {entity}
              </Typography>
              <Typography variant="body5" color={theme.palette.text.titleLabel}>
                {entitiesData[entity]} {`(${100 - 10 || 0}%)`}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
