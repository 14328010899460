import { Box, Grid, Typography, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { BiSolidSearch } from "react-icons/bi";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { useHandleRouteClick } from "../../../routes/hooks";

export interface NotificationCardProps {
  label: string;
  text: string;
  timestamp: string;
  category: "alert" | "system" | "query";
  read: boolean;
}

export default function NotificationCard({ label, text, category, timestamp, read }: NotificationCardProps) {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const renderNotificationIcon = (cat: "alert" | "system" | "query") => {
    switch (cat) {
      case "alert":
        return (
          <Box
            sx={{
              width: "86px",
              height: "86px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              backgroundColor: theme.palette.error.text,
            }}
          >
            <BsFillExclamationTriangleFill size={60} color={theme.palette.additionalColors.dark2} />
          </Box>
        );
      case "system":
        return (
          <Box
            sx={{
              width: "86px",
              height: "86px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              backgroundColor: theme.palette.action.selected,
            }}
          >
            <FaUserAlt size={60} color={theme.palette.additionalColors.dark2} />
          </Box>
        );
      case "query":
        return (
          <Box
            sx={{
              width: "86px",
              height: "86px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              backgroundColor: theme.palette.additionalColors.light,
            }}
          >
            <BiSolidSearch size={60} color={theme.palette.additionalColors.dark2} />
          </Box>
        );
      default:
        return (
          <Box
            sx={{
              width: "86px",
              height: "86px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              backgroundColor: theme.palette.additionalColors.light,
            }}
          >
            <BsFillExclamationTriangleFill size={60} color={theme.palette.additionalColors.dark2} />
          </Box>
        );
    }
  };

  const handlenotificationClick = () => {};

  return (
    <Grid
      item
      xs={12}
      lg={12}
      sx={{
        backgroundColor: read ? theme.palette.dashboardContainer.areaCameraCardBackgroundColor : theme.palette.dashboardContainer.sessionCardActive,
        borderRadius: "10px",
        p: 2,
        cursor: "pointer",
        border: `1px solid ${theme.palette.dashboardContainer.borderColor}`,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }} onClick={handlenotificationClick}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "86px",
              height: "86px",
              borderRadius: "4px",
              margin: { xs: "auto", sm: 0 },
            }}
          >
            <Box>{renderNotificationIcon(category)}</Box>
          </Box>
          <Box display={"flex"} gap={1.5} justifyContent={"center"} flexDirection={"column"} ml={2}>
            <Typography
              variant="subtitle1"
              sx={(theme) => ({ ...theme.typography.h3, fontSize: "23px", color: read ? theme.palette.text.activeInputField : "" })}
            >
              {label}
            </Typography>
            <Typography
              variant="caption"
              sx={(theme) => ({
                ...theme.typography.h4,
                maxWidth: "850px",
                lineHeight: "120%",
                color: theme.palette.text.titleLabel,
              })}
            >
              {text}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography
            sx={{
              text: "scondary",
              float: "right",
              cursor: "pointer",
              fontSize: { xs: "0.7em", sm: "0.8em" },
              marginLeft: "auto",
              whiteSpace: "nowrap",
            }}
          >
            {timestamp}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}
