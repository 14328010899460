import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, InputAdornment, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import CustomButton from "../../../../../../../components/CustomButton";
import CustomInputField from "../../../../../../../components/CustomInputField";
import CustomStatus from "../../../../../../../components/CustomStatus";
import CustomTranscript, { Transcript } from "../../../../../../../components/CustomTranscript";
import { routes } from "../../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../../routes/hooks";
import RedFlaggedCard from "../../../../Overview/RiskSummary/component/RedFlaggedCard";
import RiskFactorCard from "../../../../Overview/RiskSummary/component/RiskFactorCard";
import SentimentAnalysisCard from "../../../../Overview/RiskSummary/component/SentimentAnalysisCard";
import AudioPlayer from "./AudioPlayer";

export default function SpecificAudio() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const [enableView, setView] = useState(false);
  const [textTranscript, setTextTranscript] = useState<Transcript[]>([]);

  const backHandler = () => {
    if (!enableView) {
      handleRouteClick(`${routes.analytics.path}?tab=analysis&page=feed`, { activeTab: "audio" });
    } else {
      setView(false);
    }
  };

  const handleAllConversation = () => {
    setView(true);
  };

  const sentimentData = [
    { key: "positive", value: 77 },
    { key: "neutral", value: 5 },
    { key: "negative", value: 18 },
  ];

  const redFlaggedData = [
    { key: "Terrorism", value: 590 },
    { key: "Weapon", value: 467 },
    { key: "Kill", value: 239 },
    { key: "Violence", value: 127 },
  ];

  const riskFactorData = [
    {
      title: "Behavioral Risk Factor",
      status: "high",
      subData: [
        {
          label: "Unusual online activities",
          count: 14,
        },
        {
          label: "Unusual online activities",
          count: 24,
        },
        {
          label: "Unusual online activities",
          count: 0,
        },
        {
          label: "Unusual online activities",
          count: 56,
        },
      ],
    },
    {
      title: "Behavioral Risk Factor",
      status: "high",
      subData: [
        {
          label: "Unusual online activities",
          count: 14,
        },
        {
          label: "Unusual online activities",
          count: 24,
        },
        {
          label: "Unusual online activities",
          count: 0,
        },
        {
          label: "Unusual online activities",
          count: 56,
        },
      ],
    },
    {
      title: "Content Risk Factor",
      status: "neutral",
      subData: [
        {
          label: "Unusual online activities",
          count: 14,
        },
        {
          label: "Unusual online activities",
          count: 24,
        },
        {
          label: "Unusual online activities",
          count: 0,
        },
        {
          label: "Unusual online activities",
          count: 56,
        },
      ],
    },
  ];

  const audioTranscript: Transcript[] = [
    {
      time: "00:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "01:00",
      text: "The1 balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "02:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "03:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "04:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "05:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "06:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "07:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "08:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "09:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "10:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "11:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "12:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
  ];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filtered = audioTranscript.filter((item) => {
      return Object.values(item).some((value) => {
        return String(value).toLowerCase().includes(e.target.value.toLowerCase());
      });
    });
    if (filtered) {
      setTextTranscript(filtered);
    }
  };

  useEffect(() => {
    setTextTranscript(audioTranscript);
  }, []);

  return (
    <Grid container sx={{ display: "flex", flexFlow: "column", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          padding: "5px",
        }}
      >
        <Typography variant="h3" fontWeight="bold" sx={{ marginLeft: "10px", color: theme.palette.text.titleLabel }} display={"flex"}>
          Audio Analysis
        </Typography>
        <CustomButton
          onClick={() => console.log("update")}
          varient="text"
          sx={{
            ...theme.typography.bigButton,
            background: theme.palette.background.secondaryBgColor,
            color: theme.palette.text.secondaryText,
            width: "123px",
            height: "36px",
            ml: "auto",
            boxShadow: "none",
            border: "none",
          }}
        >
          Update Model
        </CustomButton>
      </Box>
      <Box>
        <CustomButton
          onClick={backHandler}
          startIcon={<KeyboardArrowLeftIcon sx={{ mt: "-3px", mr: -1 }} />}
          varient="text"
          sx={{
            ...theme.typography.bigButton,
            lineHeight: "110%",
            cursor: "pointer",
            marginTop: "5px",
            display: "flex",
            justifyContent: "left",
            background: "transparent",
            boxShadow: "none",
            border: "none",
            "&:hover": {
              background: "transparent",
            },
            pl: 1,
            mt: -2,
          }}
        >
          Back
        </CustomButton>
      </Box>
      <Grid container overflow={{ xs: "auto", xl: "hidden" }} height="100%" mt={2}>
        <Grid item xs={12} xl={7} pt={0}>
          <Box
            sx={{
              background: theme.palette.background.default,
              boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
              borderRadius: { md: "18px" },
              px: 2,
              pt: 2,
              pb: 3,
              height: "100%",
            }}
          >
            <Typography variant="h3" fontSize={"22px"} color={theme.palette.text.tableHeader} pb={1}>
              Audio name 93458
            </Typography>

            <Box
              height={{
                xs: "45vh",
                md: "55vh",
                xl: "100%",
              }}
              sx={{ [theme.breakpoints.up("lg")]: { display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" } }}
            >
              <AudioPlayer url={"https://download.samplelib.com/mp3/sample-3s.mp3"} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} xl={5} height="100%" pl={{ xl: 3 }}>
          <Box
            sx={{
              background: { xl: theme.palette.background.darkCardBackground },
              border: { xl: theme.palette.additionalColors.databasecardBorder },
              borderRadius: { xl: 2 },
              height: { xl: "100%" },
              overflow: { xl: "hidden" },
              p: 2,
            }}
          >
            <Box
              sx={{
                height: { xl: "100%" },
                overflow: { xl: enableView ? "hidden" : "scroll" },
              }}
            >
              {!enableView && (
                <Box mt={{ xs: 2, xl: 0 }}>
                  <Typography variant="h3" fontSize={"22px"} color={theme.palette.text.titleLabel}>
                    Audio Summary
                  </Typography>
                  <Grid container spacing={2} mt={0.1} display="flex">
                    <Grid item xs={12} sm={6} display="flex">
                      <SentimentAnalysisCard sentimentData={sentimentData} />
                    </Grid>
                    <Grid item xs={12} sm={6} display="flex">
                      <RedFlaggedCard redFlaggedData={redFlaggedData} enableViewAll={false} enableBorder={true} />
                    </Grid>
                  </Grid>
                </Box>
              )}

              {!enableView && (
                <Box mt={3}>
                  <Box display="flex" gap={3} mb={-2}>
                    <Typography variant="h3" fontSize={"22px"} color={theme.palette.text.titleLabel}>
                      Risk Summary
                    </Typography>
                    <CustomStatus label="high" status="high" sx={{ width: "88px", height: "24px" }} />
                  </Box>
                  <Box mt={2} width="100%">
                    <Grid container mt={2} spacing={2}>
                      {riskFactorData.map((riskData, idx) => {
                        const count = riskFactorData.length === idx + 1 && idx % 2 === 0 ? 12 : 6;
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={4}
                            xl={count}
                            key={`statics-${idx}`}
                            display="flex"
                            justifyContent="space-between"
                            gap={1}
                            width="100%"
                          >
                            <RiskFactorCard riskFactorData={riskData} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Box>
              )}

              <Box mt={3} width="100%" height={enableView ? "100%" : "auto"}>
                <Box display="flex" gap={3}>
                  <Typography variant="h3" fontSize={"22px"} color={theme.palette.text.titleLabel}>
                    {enableView ? "Audio-to-Text Convertion" : "Audio Text Convertion"}
                  </Typography>

                  {!enableView && (
                    <Typography
                      ml="auto"
                      variant="bigCaption"
                      color={theme.palette.text.default}
                      display={"flex"}
                      sx={{
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={handleAllConversation}
                    >
                      View All
                    </Typography>
                  )}
                </Box>
                {enableView && (
                  <CustomInputField
                    placeholder="Search"
                    size="small"
                    variant="outlined"
                    autoComplete="off"
                    onChange={handleSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ mt: 2 }}
                  />
                )}

                <Box
                  sx={{
                    width: "100%",
                    height: "87%",
                    overflow: "Hidden",
                    background: theme.palette.background.darkCardBackground,
                    border: theme.palette.additionalColors.databasecardBorder,
                    p: 3,
                    mt: 2,
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: enableView ? { xs: "50vh", xl: "100%" } : "auto",
                      overflow: "scroll",
                    }}
                  >
                    <CustomTranscript transcript={enableView ? textTranscript : textTranscript.slice(0, 4)} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
