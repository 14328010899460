import { createSlice } from "@reduxjs/toolkit";

export interface CartCliceState {
  isOpen: boolean;
}

const initialCartState: CartCliceState = {
  isOpen: false,
};

const drawerSlice = createSlice({
  name: "drawerSlice",
  initialState: initialCartState,
  reducers: {
    toggleDrawer: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const drawerAction = drawerSlice.actions;
export default drawerSlice;
