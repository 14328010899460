import { Box, Switch } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { toggleTheme } from "../store/ui/themeSlice";
import { ThemeVariantsProps } from "../theme";
import UiLoading from "./UiLoading";

export interface BaseLayoutProps {
  children?: React.ReactNode;
}

export default function BaseLayout({ children }: BaseLayoutProps) {
  const dispatch = useAppDispatch();
  const { isLoading, isAuthenticated } = useAppSelector((state) => state.auth);
  const { themeMode } = useAppSelector((state) => state.theme);
  const isDarkTheme = themeMode === ThemeVariantsProps.dark;

  return (
    <>
      {isLoading && <UiLoading height="100vh" />}
      <Box>
        {!isAuthenticated && (
          <Switch
            name="theme"
            sx={{ position: "absolute", right: "10px", top: "10px", zIndex: 1 }}
            onChange={(e) => {
              dispatch(toggleTheme());
            }}
            checked={isDarkTheme}
          />
        )}
        {children}
      </Box>
    </>
  );
}
