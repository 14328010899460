import { Box, Typography, useTheme } from "@mui/material";
import CustomTable from "../../../../../components/CustomTable";
import RadioFilterButton from "../../../../../components/RadioFilterButton";
import { LEVEL_OPT, TableData } from "../constant";

export default function TopConnections() {
  const theme = useTheme();

  const header = [
    { id: "name", label: "Entity Name", searchEnable: false, minWidth: 150 },
    { id: "type", label: "Entity Type", searchEnable: false, minWidth: 100 },
    { id: "subtype", label: "Entity SubType", searchEnable: false, minWidth: 300 },
    { id: "region", label: "Region", searchEnable: false, minWidth: 100 },
    { id: "score", label: "Risk Score", searchEnable: false, type: "button", minWidth: 100 },
  ];
  return (
    <>
      <Box display="flex" mb={2}>
        <Typography variant="h4" color={theme.palette.text.titleLabel}>
          Top-5 connections
        </Typography>
        <RadioFilterButton
          label="Level"
          options={LEVEL_OPT}
          onChange={() => console.log("change")}
          defaultValue={LEVEL_OPT[0].id}
          sx={{ ml: "auto" }}
        />
      </Box>
      <CustomTable header={header} tableData={TableData.slice(0, 5)} rows={5} pagination={false} search={false} />
    </>
  );
}
