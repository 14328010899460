import { Box, Typography, useTheme } from "@mui/material";
import BackButton from "../../../../components/BackButton";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";

export default function Header() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const handleBackNavigation = () => {
    handleRouteClick(routes.home.path);
  };

  return (
    <Box px={1.5}>
      <Typography variant="h1" color={theme.palette.text.titleLabel}>
        Custom case
      </Typography>
      <BackButton onClick={handleBackNavigation} />
    </Box>
  );
}
