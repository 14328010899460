import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

export interface StaticsProps {
  label: string;
  count: string;
  icon: React.ReactNode;
}
export default function CustomStatics({ label, count, icon }: StaticsProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.darkCardBackground,
        display: "flex",
        p: 2,
        border: theme.palette.additionalColors.databasecardBorder,
        borderRadius: "8px",
      }}
    >
      <Box>
        <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
          {label}
        </Typography>
        <Typography variant="h3" mt={0.5} color={theme.palette.text.titleLabel}>
          {count}
        </Typography>
      </Box>
      <Box sx={{ background: theme.palette.primary.tertiaryGradient, p: 1 }} pt={1} ml="auto">
        {icon}
      </Box>
    </Box>
  );
}
