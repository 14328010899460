import SubTabLayout from "../../../layouts/SubTabLayout";
import QueriesHistory from "./QueriesHistorySection";
import QueryReports from "./QueryReportsSection";

const tabs = [
  {
    id: "queries-history",
    title: "History of queries",
    disabled: false,
    component: <QueriesHistory />,
  },
  {
    id: "reports",
    title: "Reports",
    disabled: false,
    component: <QueryReports />,
  },
];

export default function HistoryContent() {
  return <SubTabLayout tabs={tabs} sx={{ margin: "auto" }} />;
}
