import axiosInstance from "./index";

export const postAPI = (
  url: string,
  data?: Record<string, any>,
  config?: Record<string, any>
): Promise<any> => {
  let formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  return axiosInstance.post(`${url}`, formData, config);
};

export const getAPI = (url: string, ...rest: any[]): Promise<any> => {
  return axiosInstance.get(`${url}`, ...rest);
};

export const deleteAPI = (url: string, ...rest: any[]): Promise<any> => {
  return axiosInstance.delete(`${url}`, ...rest);
};

export const patchAPI = (
  url: string,
  data?: Record<string, any>,
  config?: Record<string, any>
): Promise<any> => {
  let formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  return axiosInstance.patch(`${url}`, formData, config);
};
