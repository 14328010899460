import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CustomButton from "../../../components/CustomButton";
import CustomIconButton from "../../../components/CustomIconButton";
import SubmitLoadingDeleteButton from "../../../components/SubmitLoadingDeleteButton";

export interface DeleteQueryDialogProps {
  open: boolean;
  handleToggle: () => void;
  queryId: string | number;
}

export default function DeleteQueryDialog({ open, handleToggle, queryId }: DeleteQueryDialogProps) {
  const theme = useTheme();

  const handleDelete = () => {
    console.log(queryId);
  };

  return (
    <Dialog
      onClose={handleToggle}
      open={open}
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          py: 4,
          px: 3,
          minWidth: "50vw",
          minHeight: "50vh",
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        <CustomIconButton onClick={handleToggle} sx={{ color: theme.palette.error.main }}>
          <CloseIcon />
        </CustomIconButton>
      </Box>
      <DialogContent sx={{ margin: 0, padding: 0, mt: 4, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Box sx={{ my: 2, mx: "auto" }}>
          <img height="140" src="/assets/cross_dialog_icon.png" />
        </Box>
        <Typography variant="h1" textAlign="center" my={2}>
          Confirm Delete
        </Typography>
        <Typography variant="caption" textAlign="center">
          Are you sure you want to delete the query? This action cannot be undone.
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mb: 2,
            mt: 4,
            justifyContent: "center",
          }}
        >
          <CustomButton sx={{ ...theme.typography.bigButton }} onClick={handleToggle}>
            Cancel
          </CustomButton>
          <SubmitLoadingDeleteButton isLoading={false} color="error">
            Delete
          </SubmitLoadingDeleteButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
