import { Switch, useTheme } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import React from "react";

interface CustomIconProps {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  [x: string]: any;
}
export default function CustomSwitch({ children, sx, ...props }: CustomIconProps) {
  const theme = useTheme();
  return (
    <Switch
      sx={{
        "& .MuiSwitch-switchBase": {
          // background: theme.palette.toggle.baseDisabledLight,
          // p: "5px",
          // top: "5px",
          "&.Mui-checked": {
            "& + .MuiSwitch-track": {
              background: theme.palette.toggle.baseDisabled,
              borderRadius: "20px",
            },
          },
        },
        "& .MuiSwitch-track": {
          background: theme.palette.toggle.baseDisabled,
          borderRadius: "20px",
        },
        "& .MuiSwitch-thumb": {
          color: theme.palette.additionalColors.light,
        },
        ...sx,
      }}
      {...props}
    />
  );
}
