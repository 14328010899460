import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

interface SubPoints {
  points: {
    id: number;
    name: string;
    subPoints: {
      id: number;
      name: string;
    }[];
  }[];
  getActivePoint(id: number, activeTab: string): void;
}

export default function SubPoints({ points, getActivePoint }: SubPoints) {
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState("1.1");

  const handleTab = (id: number, activeTab: string) => {
    getActivePoint(id, activeTab);
    setActiveTab(activeTab);
  };

  let hrStyle = {
    width: "1px",
    float: "left",
    mr: 1,
  };

  return (
    <Box>
      <Box ml={1}>
        {points?.map((p, idx) => {
          const tabline = `1.${idx + 1}`;
          let activeLine = activeTab !== tabline ? theme.palette.additionalColors.databasecardBorder : `1px solid ${theme.palette.text.default}`;
          return (
            <React.Fragment key={`tab-${idx}`}>
              <Box
                sx={{
                  border: activeLine,
                  ...hrStyle,
                }}
                style={{ height: "32px" }}
              ></Box>
              <Typography
                variant="body1"
                color={activeTab !== tabline ? theme.palette.text.tableHeader : theme.palette.text.titleLabel}
                onClick={() => handleTab(p?.id, tabline)}
                sx={{ mb: 1.5, cursor: "pointer" }}
              >
                {tabline} {p?.name}
              </Typography>
              {p?.subPoints?.map((a, i) => {
                const line = `1.${idx + 1}.${i + 1}`;
                return (
                  <Box display="flex" gap={2} key={`subtab-${idx}`}>
                    <Box
                      sx={{
                        border:
                          activeTab !== line ? `1px ridge ${theme.palette.additionalColors.lightBorder}` : `1px ridge ${theme.palette.text.default}`,
                        ...hrStyle,
                      }}
                      style={{ height: "32px" }}
                    ></Box>
                    <Typography
                      variant="body3"
                      color={activeTab !== line ? theme.palette.text.tableHeader : theme.palette.text.titleLabel}
                      onClick={() => handleTab(a?.id, line)}
                      sx={{ cursor: "pointer" }}
                    >
                      {line} {a?.name}
                    </Typography>
                  </Box>
                );
              })}
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
}
