import { Box, Typography, useTheme } from "@mui/material";
import CustomTable from "../../../../../components/CustomTable";
import { TableData } from "../constant";

export default function RiskyEntities() {
  const theme = useTheme();

  const header = [
    { id: "name", label: "Entity Name", searchEnable: false, minWidth: 150 },
    { id: "type", label: "Entity Type", searchEnable: false, minWidth: 100 },
    { id: "subtype", label: "Entity SubType", searchEnable: false, minWidth: 300 },
    { id: "region", label: "Region", searchEnable: false, minWidth: 100 },
    { id: "score", label: "Risk Score", searchEnable: false, type: "button", minWidth: 100 },
    { id: "factor1", label: "Factor 1", searchEnable: false, type: "button", minWidth: 100 },
  ];
  return (
    <Box>
      <Typography mb={3} variant="h4" color={theme.palette.text.titleLabel}>
        5 Most Risky Entities
      </Typography>

      <CustomTable header={header} tableData={TableData.slice(0, 5)} rows={5} pagination={false} search={false} />
    </Box>
  );
}
