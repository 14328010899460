import React from "react";

import { Box } from "@mui/material";
import UiLoading from "../layouts/UiLoading";
import { logout } from "../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../store/hooks";

export default function Logout() {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    dispatch(logout());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box>{isLoading && <UiLoading height="100vh" />}</Box>;
}
