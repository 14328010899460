import { Box, Grid, Typography, useTheme } from "@mui/material";
import CustomDivider from "../../../../../../components/CustomDivider";

interface dataOptions {
  name?: string;
  description?: string;
}

interface ConnectionCardOptions {
  icon?: React.ReactNode;
  title?: string;
  data?: dataOptions[];
}
interface ConnectionProps {
  cardData: ConnectionCardOptions;
}

export default function ConnectionCard({ cardData }: ConnectionProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.background.tertiaryGradientBgColor,
        border: theme.palette.additionalColors.databasecardBorder,
        p: 2,
        borderRadius: "8px",
        flexFlow: "row wrap",
        width: "100%",
      }}
    >
      <Box>
        <Box display="flex" mb={3}>
          <Box
            sx={{ background: theme.palette.primary.tertiaryGradient, p: 1, width: "48px", height: "48px", borderRadius: "60px", display: "flex" }}
            pt={1}
          >
            {cardData?.icon}
          </Box>
          <Typography variant="body1" fontSize="18px" mt={2} ml={2} color={theme.palette.text.titleLabel}>
            {cardData?.title}
          </Typography>
        </Box>
        {cardData?.data?.map((option: any, idx) => (
          <Box flexDirection="column" mb={1.5} key={`card-${idx}`}>
            <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
              {option?.name}
            </Typography>
            {option?.description && (
              <Typography variant="body5" color={theme.palette.text.titleLabel}>
                {option?.description}
              </Typography>
            )}
            {option?.description && <CustomDivider sx={{ margin: "5px auto" }} />}
          </Box>
        ))}
        <Grid item xs={12}></Grid>
      </Box>
    </Box>
  );
}
