import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Typography, useTheme } from "@mui/material";
import CustomDivider from "../../../../components/CustomDivider";
import { ARTICLES } from "../../../../utils/constants";

interface ArticleProps {
  openSpecificArticle(title: string): void;
}

export default function Articles({ openSpecificArticle }: ArticleProps) {
  const theme = useTheme();

  return (
    <Box width="100%">
      {ARTICLES.map((article) => (
        <Box
          key={`Topic-${article?.id}`}
          sx={{
            background: theme.palette.background.darkCardBackground,
            border: `2px solid ${theme.palette.additionalColors.lightBorder}`,
            borderRadius: 2,
            p: 2,
            my: 5,
          }}
        >
          <Typography variant="h2" sx={{ color: theme.palette.text.titleLabel }}>
            {article?.title}
          </Typography>
          <CustomDivider width="100%" />
          {article?.articles?.map((subTopics, idx) => (
            <Box
              sx={{ display: "flex", cursor: "pointer" }}
              key={`subTopics-${subTopics?.id}`}
              mt={idx === 0 ? 3 : 2}
              onClick={() => openSpecificArticle(subTopics?.name)}
            >
              <Typography variant="body1" sx={{ color: theme.palette.text.tableHeader }}>
                {subTopics?.name}
              </Typography>
              <ChevronRightIcon sx={{ color: theme.palette.text.default, ml: "auto" }} />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}
