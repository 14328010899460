import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Box, Typography, useTheme } from "@mui/material";
import CustomRadialBar from "../../../../../components/CustomRadial";
import CustomTextButton from "../../../../../components/CustomTextButton";

interface StatisticsProps {
  statisticsData: {
    value: number;
    label: string;
  }[];
}
export default function Statistics({ statisticsData }: StatisticsProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        p: 2,
        borderRadius: 2,
        flexFlow: "row wrap",
        display: "flex",
      }}
    >
      <Box display="flex" gap={1}>
        <Typography variant="h4">Statistics</Typography>
        <InfoOutlined sx={{ color: theme.palette.text.tableHeader, fontSize: "18px" }} />
      </Box>
      <Box width={"100%"} display="flex" flexDirection={"row"} justifyContent="center" mt={2}>
        {statisticsData.map((data, idx) => (
          <Box
            key={`Statistics-${idx}`}
            sx={{
              width: "80px",
              height: "100%",
            }}
          >
            <CustomRadialBar subTitle={data?.label} count={"80"} value={data?.value} />
          </Box>
        ))}
      </Box>
      <CustomTextButton sx={{ mt: 3 }}>Show more</CustomTextButton>
    </Box>
  );
}
