import Forward30Icon from "@mui/icons-material/Forward30";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Replay30Icon from "@mui/icons-material/Replay30";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import AudioSlider, { CustomAudioSlider } from "../../../../../../../components/AudioSlider";
import CustomIconButton from "../../../../../../../components/CustomIconButton";
import VideoAnalytics from "../../../../../../../components/CustomVideoAnalytics";
import Duration from "../../../../../../../components/Duration";
import VideoSeekingSlider, { CustomSliderThumb } from "../../../../../../../components/VideoSeeker";

interface VideoDetailPlayerProps {
  src: string;
  showPlayerControls?: boolean;
  isFullScreen?: boolean;
  refreshIntervalInSec?: number;
  thumbnail: string;
  values: number[];
}

export default function VideoDetailPlayer({
  src,
  showPlayerControls = false,
  isFullScreen = false,
  refreshIntervalInSec = 5,
  thumbnail,
}: VideoDetailPlayerProps) {
  const theme = useTheme();

  const [isRetrying, setIsRetrying] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);
  const [played, setPlayed] = useState<boolean>(false);

  const [muted, setMuted] = useState<boolean>(false);
  const [volume, setVolume] = useState(1);
  const playerRef = useRef<ReactPlayer>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  useEffect(() => {
    if (isRetrying) {
      const refreshTimer = setTimeout(() => {
        setIsRetrying(false);
      }, refreshIntervalInSec * 1000);

      return () => {
        clearTimeout(refreshTimer);
      };
    }
  }, [isRetrying, refreshIntervalInSec]);

  const handleVolumeChange = (value: any) => {
    setVolume(parseFloat(value));
    if (value <= 0) {
      setMuted(true);
    } else {
      setMuted(false);
    }
  };

  const handlePlay = () => {
    setPlaying(true);
    setPlayed(true);
  };

  const handlePause = () => {
    if (playerRef?.current) {
      setPlaying(false);
    }
  };

  const handleSeekBackChange = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      const newTime = currentTime - 10; // Adjust the number of seconds as needed
      playerRef.current.seekTo(newTime);
    }
  };
  const handleSeekForwardChange = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      const newTime = currentTime + 10; // Adjust the number of seconds as needed
      playerRef.current.seekTo(newTime);
    }
  };

  const handleVideoEnded = () => {
    if (playerRef.current) {
      setPlaying(false); // Start playing again when video ends
    }
  };

  const handleSliderChange = (state: any) => {
    if (playerRef.current) {
      setCurrentTime(state.playedSeconds);
    }
  };

  const handleSliderDragStart = () => {
    setIsDragging(true);
  };

  const handleSliderDragEnd = () => {
    setIsDragging(false);
  };

  const handleDurationChange = () => {
    if (playerRef.current) {
      setDuration(playerRef.current.getDuration());
    }
  };

  const handleSeek = (e: any) => {
    if (playerRef.current) {
      const newTime = parseFloat(e.target.value);
      playerRef.current.seekTo(newTime);
      setCurrentTime(newTime);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef && !isDragging && playerRef?.current) {
        setCurrentTime(playerRef?.current?.getCurrentTime());
      }
    }, 100);

    return () => clearInterval(interval);
  }, [isDragging]);

  return (
    <Box
      sx={{
        position: "relative",
        display: { xl: "flex" },
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        paddingBottom: "9px",
        borderRadius: 1,
      }}
    >
      {!played ? (
        <Box position="relative" height="90%" width="100%" onClick={handlePlay}>
          <img src={thumbnail} alt="thumbnail" height="100%" width="100%" style={{ borderRadius: 4 }} />
          <CustomIconButton
            sx={{
              borderRadius: "50%",
              position: "absolute",
              width: "50px",
              height: "50px",
              border: "none",
              left: "50%",
              top: "50%",
              background: `${theme.palette.text.titleLabel}4D`,
              "&:hover": {
                background: `${theme.palette.text.titleLabel}4D`,
              },
            }}
          >
            <PlayArrowIcon sx={{ fontSize: "40px" }} />
          </CustomIconButton>
        </Box>
      ) : (
        <ReactPlayer
          ref={playerRef}
          playing={playing}
          width="100%"
          height="90%"
          style={{ borderRadius: "4px" }}
          url={src}
          muted={muted}
          volume={volume}
          onPlay={handlePlay}
          onPause={handlePause}
          onEnded={handleVideoEnded}
          onDuration={handleDurationChange}
        />
      )}
      {(showPlayerControls || isFullScreen) && (
        <Box
          sx={{
            position: "relative",
            mx: "auto",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            mt: 1.5,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomIconButton
              onClick={handleSeekBackChange}
              sx={{
                color: theme.palette.primary.main,
                borderRadius: "2px",
                minWidth: "2rem",
                width: "2rem",
                height: "2rem",
                mx: theme.spacing(0.5),
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Replay30Icon />
            </CustomIconButton>
            <CustomIconButton
              onClick={!playing ? handlePlay : handlePause}
              sx={{
                color: theme.palette.primary.main,
                borderRadius: "2px",
                minWidth: "2rem",
                width: "2rem",
                height: "2rem",
                mx: theme.spacing(0.5),
              }}
            >
              {playing ? <PauseIcon /> : <PlayArrowIcon />}
            </CustomIconButton>
            <CustomIconButton
              onClick={handleSeekForwardChange}
              sx={{
                color: theme.palette.primary.main,
                borderRadius: "2px",
                minWidth: "2rem",
                width: "2rem",
                height: "2rem",
                mx: theme.spacing(0.5),
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Forward30Icon />
            </CustomIconButton>
            <>
              <Box
                sx={(theme) => ({
                  position: "relative",
                  background: theme.palette.background.default,
                  boxShadow: theme.palette.dashboardContainer.seekBarBoxShadow,
                  border: `1px solid ${theme.palette.additionalColors.lightBorder}`,
                  borderRadius: 1,
                  height: "2.2rem",
                  minWidth: "8rem",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                  px: 1,
                  mx: (theme) => theme.spacing(0.5),
                })}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    mr: "2rem",
                    right: "-24px",
                    zIndex: 0,
                    opacity: 0.4,
                  }}
                >
                  <Box>
                    <VideoAnalytics
                      data={[
                        2.3, 2.1, 4.0, 5.1, 4.0, 3.6, 3.2, 2.3, 1.4, 1.8, 1.5, 1.2, 1.5, 1.2, 1.2, 1.5, 1.2, 2.3, 2.1, 3.6, 3.2, 2.3, 3.6, 3.2, 2.3,
                        3.6, 3.2, 2.3, 1.2, 1.5, 1.2, 2.3, 2.1, 3.6, 3.2, 2.3, 3.6, 3.2, 2.3, 3.6, 3.2, 2.3, 3.6, 3.2, 2.3, 3.6, 3.2, 2.3, 3.6, 3.2,
                        2.3,
                      ]}
                    />
                  </Box>

                  <CustomSliderThumb
                    step={0.1}
                    sx={{
                      "& .MuiSlider-thumb": {
                        height: { xs: 28, xl: 30 },
                        background: "linear-gradient(180deg, rgb(228 46 1 / 78%) 0%, rgba(228, 46, 1, 0) 71.87%)",
                        borderRadius: "0.2px",
                        top: "35px",
                        "&:hover": {
                          boxShadow: "none",
                        },
                        "& .seeker-bar": {
                          backgroundColor: "currentColor",
                          marginLeft: "20px",
                          marginRight: 0,
                          display: "none",
                        },
                      },
                      left: "15px",
                      top: "-16px",
                      width: "97%",
                      position: "absolute",
                    }}
                    slots={{ thumb: VideoSeekingSlider }}
                    value={currentTime}
                    min={0}
                    max={playerRef?.current?.getDuration() || 0}
                    onChange={handleSeek}
                    onMouseDown={handleSliderDragStart}
                    onMouseUp={handleSliderDragEnd}
                  />
                </Box>
                <Typography fontWeight="bold" variant="h6" color="primary" mt={"4px"}>
                  {playerRef?.current?.getCurrentTime() && currentTime ? <Duration seconds={currentTime && currentTime} /> : "00:00"}
                </Typography>

                <Typography fontWeight="bold" variant="h6" color="primary" mt={"4px"} sx={{ marginLeft: "auto" }}>
                  {playerRef?.current?.getDuration() && duration ? <Duration seconds={duration && duration} /> : "00:00"}
                </Typography>
              </Box>
              <Box display="flex" width={{ xs: "20%", lg: "10%" }} flexDirection="row">
                {muted ? (
                  <VolumeOffIcon sx={{ color: theme.palette.text.default, mr: "5px", mt: 0.4, fontSize: "1em" }} />
                ) : (
                  <VolumeUpIcon sx={{ color: theme.palette.text.default, mr: "5px", mt: 0.4, fontSize: "1em" }} />
                )}
                <CustomAudioSlider
                  sx={{
                    "&.MuiSlider-root": {
                      padding: "12px 0",
                    },
                  }}
                  name="volume"
                  slots={{ thumb: AudioSlider }}
                  min={0}
                  max={5}
                  step={0.1}
                  value={volume}
                  onChange={(e, v) => handleVolumeChange(v)}
                />
              </Box>
            </>
          </Box>
        </Box>
      )}
    </Box>
  );
}
