import { Button, ButtonProps } from "@mui/material";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import React from "react";

interface CustomButtonProps extends ButtonProps {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  [x: string]: any;
}

export default function CustomButton({ children, sx, ...props }: CustomButtonProps) {
  const theme = useTheme();

  return (
    <Button
      {...props}
      sx={{
        border: `0.5px solid ${theme.palette.additionalColors.border}`,
        color: theme.palette.primary.main,
        background: theme.palette.dashboardContainer.customButtonBackgroundColor,
        boxShadow: theme.palette.dashboardContainer.customButtonBoxShadow,
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: theme.palette.background.secondaryGradient,
          boxShadow: theme.palette.dashboardContainer.customButtonBoxShadow,
          // border: "none",
        },
        "&:active": {
          backgroundColor: theme.palette.background.secondaryGradient,
          boxShadow: "0px 0px 1px rgba(48, 62, 90, 0.55), 0px 0px 2px #12161D",
        },
        "&.Mui-disabled": {
          background: theme.palette.action.disabled,
          color: theme.palette.background.dark,
          border: "none",
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
}
