import { createSlice } from "@reduxjs/toolkit";
import TokenService from "../../axios/tokenservice";

import {
  changePassword,
  forgotPassword,
  forgotPasswordComplete,
  forgotPasswordTokenCheck,
  getMyDetail,
  login,
  logout,
  register,
  resendVerificationEmail,
  updateUserDetail,
  verifyEmail,
} from "./authThunk";

export interface AuthInitialState {
  isLoading: boolean;
  user: object | null;
  userDetail: object;
  isAuthenticated: boolean;
  isPasswordResetTokenVerified: boolean;
  isPasswordResetDone: boolean;
  staffs: object[];
  groups: object[];
  organization: object | null;
}

const authInitialState: AuthInitialState = {
  isLoading: false,
  user: TokenService.getUser(),
  userDetail: JSON.parse(String(localStorage.getItem("userDetail"))),
  isAuthenticated: Boolean(TokenService.getLocalAccessToken()),
  isPasswordResetTokenVerified: false,
  isPasswordResetDone: false,
  staffs: [],
  groups: [],
  organization: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // For login
    builder
      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.data;
        state.isAuthenticated = true;
        // Set localstorage
        TokenService.setUser(action.payload.data);
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For email verification
    builder
      .addCase(verifyEmail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For registration
    builder
      .addCase(register.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.organization = action.payload.data;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For forgotPassword
    builder
      .addCase(forgotPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For changePassword
    builder
      .addCase(changePassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For forgotPassword token check
    builder
      .addCase(forgotPasswordTokenCheck.pending, (state, action) => {
        state.isLoading = true;
        state.isPasswordResetTokenVerified = false;
      })
      .addCase(forgotPasswordTokenCheck.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isPasswordResetTokenVerified = true;
      })
      .addCase(forgotPasswordTokenCheck.rejected, (state, action) => {
        state.isLoading = false;
        state.isPasswordResetTokenVerified = false;
      });

    // For forgotPassword complete
    builder
      .addCase(forgotPasswordComplete.pending, (state, action) => {
        state.isLoading = true;
        state.isPasswordResetDone = false;
      })
      .addCase(forgotPasswordComplete.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isPasswordResetDone = true;
      })
      .addCase(forgotPasswordComplete.rejected, (state, action) => {
        state.isLoading = false;
        state.isPasswordResetDone = false;
      });

    // For resend verification email
    builder
      .addCase(resendVerificationEmail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(resendVerificationEmail.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(resendVerificationEmail.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For logout
    builder
      .addCase(logout.pending, (state, action) => {
        state.isLoading = true;
        state.user = null;
        state.organization = null;
        state.staffs = [];
        state.isAuthenticated = false;
        // Set localstorage
        TokenService.removeUser();
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        const theme = localStorage.getItem("isDarkTheme");
        localStorage.clear();
        if (theme) {
          localStorage.setItem("isDarkTheme", theme);
        }
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For User details
    builder
      .addCase(getMyDetail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMyDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetail = action.payload.data;
        localStorage.setItem("userDetail", JSON.stringify(action.payload.data));
      })
      .addCase(getMyDetail.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For Updating User details
    builder
      .addCase(updateUserDetail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateUserDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetail = action.payload.data;
        localStorage.setItem("userDetail", JSON.stringify(action.payload.data));
      })
      .addCase(updateUserDetail.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const authActions = authSlice.actions;

export default authSlice;
