import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useAppSelector } from "../../store/hooks";

import CustomFormButton from "../../components/CustomFormButton";
import FormikInputField from "../../components/FormikInputField";
import SignUpBaseLayout from "../../layouts/SignUpBaseLayout";

const initialValues = {
  password: "",
};

const formField = {
  password: {
    name: "password",
    placeholder: "Enter your password",
    requiredErrorMsg: "Password is required",
  },
};

const validationSchema = Yup.object().shape({
  [formField.password.name]: Yup.string()
    .min(8, "Password should be of minimum 8 characters length")
    .required(`${formField.password.requiredErrorMsg}`),
});

export default function Invitation() {
  const theme = useTheme();
  const [showpassword, setShowPassword] = React.useState<boolean>(false);
  const { isLoading } = useAppSelector((state) => state.auth);

  const handleClickShowPassword = () => {
    setShowPassword(!showpassword);
  };
  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  const handleSubmit = (value: string) => {
    console.log("password is : ", value);
  };

  return (
    <SignUpBaseLayout isLoading={isLoading}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ zIndex: 1, width: "100%" }}>
        <Typography
          variant="h1"
          textAlign="center"
          sx={{
            fontFeatureSettings: "'liga' off",
            color: (theme) => theme.palette.text.titleLabel,
          }}
        >
          You’re invited to{" "}
          <Typography variant="h1" display="inline" color={theme.palette.text.default}>
            David’s
          </Typography>{" "}
          workspace
        </Typography>
        <Typography mt={2.5} mb={1} variant="body6" color={(theme) => theme.palette.text.titleLabel}>
          Please, create your Password below.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log("bb", values);

            handleSubmit(values.password);
          }}
        >
          {(formikProps) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                console.log("aa", e);

                formikProps.handleSubmit();
              }}
              style={{ width: "100%" }}
            >
              <Box sx={{ width: "100%", mt: 3 }}>
                <FormikInputField
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formikProps.handleChange(e);
                  }}
                  name={formField.password.name}
                  placeholder={formField.password.placeholder}
                  type={showpassword ? "text" : "password"}
                  style={{ width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showpassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box display="flex" flexDirection="column" alignItems="center">
                  <CustomFormButton
                    // isLoading={isLoading}
                    sx={{
                      marginTop: 5,
                      width: { xs: "50%", sm: "55%", md: "60%", lg: "46%", xl: "13vw" },
                      color: theme.palette.background.main,
                    }}
                  >
                    Sign In
                  </CustomFormButton>
                  <Typography variant="body6" color={(theme) => theme.palette.text.titleLabel} mt={4}>
                    Have an issue?
                    <NavLink
                      to="/login"
                      style={{
                        ...theme.typography.body6,
                        color: theme.palette.primary.main,
                        textDecoration: "none",
                        marginLeft: "6px",
                      }}
                    >
                      Contact Us
                    </NavLink>
                  </Typography>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </SignUpBaseLayout>
  );
}
