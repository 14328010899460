import { Box, useTheme } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import CheckboxFilterButton, { MultiSelectValueType } from "../../../components/CheckboxFilterButton";
import { queryTypeFilterOptions } from "../../../utils/constants";
import AIModelsSection from "./AIModelsSection";
import SolutionsSection from "./SolutionsSection";

export interface TabType {
  title: string;
  id: number | string;
  component: React.ReactNode;
}

const tabs: TabType[] = [
  {
    title: "Solutions",
    id: "solutions",
    component: <SolutionsSection />,
  },
  {
    title: "AI Models",
    id: "ai_models",
    component: <AIModelsSection />,
  },
];

export default function MarketplaceContent() {
  const theme = useTheme();
  const { state } = useLocation();

  const [activeTabId, setActiveTabId] = React.useState<string | number>(state?.activeTab || tabs[0].id);

  const renderTabContent = (tabId: string | number) => {
    return (
      <Box
        sx={{
          borderTop: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          background: theme.palette.dashboardContainer.containerBackground,
          py: 2,
        }}
      >
        {tabs.find((tab) => tab.id === tabId)?.component}
      </Box>
    );
  };

  const handleChangeCategory = (value: MultiSelectValueType[]) => {
    console.log(value);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, height: "100%", overflow: "hidden" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            borderRadius: "6px",
            border: `1px solid ${theme.palette.primary.dark}`,
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            gap: 2,
            width: "fit-content",
          }}
        >
          {tabs.map((tab) => {
            return (
              <Box
                onClick={() => setActiveTabId(tab.id)}
                sx={{
                  cursor: "pointer",
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  px: 3,
                  py: 1,
                  borderRadius: "4px",
                  m: 0.2,
                  background: activeTabId === tab.id ? theme.palette.primary.main : "transparent",
                }}
                key={tab.id}
              >
                {tab.title}
              </Box>
            );
          })}
        </Box>
        <CheckboxFilterButton
          label="Category"
          onChange={(value) => handleChangeCategory(value)}
          options={queryTypeFilterOptions}
          showSearch={false}
        />
      </Box>
      <Box sx={{ height: "100%", overflow: "scroll" }}>{renderTabContent(activeTabId)}</Box>
    </Box>
  );
}
