import TabLayout from "../../layouts/TabLayout";
import HistoryContent from "./components/HistoryContent";

const tabs = [
  {
    id: "history",
    title: "History",
    disabled: false,
    component: <HistoryContent />,
  },
];

export default function History() {
  return <TabLayout tabs={tabs} />;
}
