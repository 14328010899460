import { Navigate } from "react-router-dom";

import DashboardBaseLayout from "../layouts/DashboardBaseLayout/index";
import NoSidebarDashboardBaseLayout from "../layouts/NoSidebarDashboardBaseLayout";
import { useAppSelector } from "../store/hooks";
import { routes } from "./index";

export interface PrivateRouteProps {
  showSideDrawer?: boolean;
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ showSideDrawer = true, children }) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  if (!isAuthenticated) return <Navigate to={routes.login.path} />;
  if (!showSideDrawer) return <NoSidebarDashboardBaseLayout>{children}</NoSidebarDashboardBaseLayout>;
  return <DashboardBaseLayout>{children}</DashboardBaseLayout>;
};

export default PrivateRoute;
