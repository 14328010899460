import { Box, useTheme } from "@mui/material";
import ConnectionSection from "../Components/Connections";
import Title from "../Components/Title";

export default function Connectionswrapper() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
      }}
      p={2}
    >
      <Title title="Connections" time="Last updated today at 16:54 (IST)" refresh={true} />
      <ConnectionSection />
    </Box>
  );
}
