import { Close } from "@mui/icons-material";
import { Box, Chip, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import BorderedCard from "../../../components/BorderedCard";
import CustomDivider from "../../../components/CustomDivider";
import CustomTextButton from "../../../components/CustomTextButton";
import AddNewKeywordDialog from "./AddNewKeywordDialog";

const allKeywords = [
  { id: 1, label: "missile attack" },
  { id: 2, label: "weapons" },
  { id: 3, label: "weapons" },
  { id: 4, label: "weapons" },
  { id: 5, label: "weapons" },
  { id: 6, label: "weapons" },
  { id: 7, label: "weapons" },
  { id: 8, label: "weapons" },
  { id: 9, label: "weapons" },
  { id: 10, label: "weapons" },
];

export interface KeywordData {
  id: number | string;
  label: string;
}

export default function RedFlaggedWordsSettings() {
  const theme = useTheme();
  const [editable, setEditable] = React.useState<boolean>(false);
  const [keywords, setKeywords] = React.useState<KeywordData[]>(allKeywords);
  const [openAddDialog, setOpenAddDialog] = React.useState(false);

  const toggleAddDialog = () => {
    setOpenAddDialog((prev) => !prev);
  };

  const handleEditSave = () => {
    if (editable) {
      //   handleProfileUpdate(updatedProfile);
    }
    setEditable(!editable);
  };

  const handleDeleteKeywords = (keywordId: string | number) => {
    setKeywords((prev) => {
      return prev.filter((kw) => kw.id !== keywordId);
    });
  };

  const handleAddKeyword = (value: string) => {
    setKeywords((prev) => {
      const newValues: KeywordData[] = value
        .split(",")
        .filter((v) => v.length >= 1)
        .map((v) => ({ id: String(v), label: v }));
      return [...prev, ...newValues];
    });
  };

  return (
    <BorderedCard>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h4">Red Flagged Words</Typography>
          <Typography variant="caption" color={theme.palette.primary.inactiveIcon}>
            This is the base of words that are identified by the system as “red flagged”. You can edit this list by deleting the words or adding ones.
          </Typography>
        </Box>
        {editable ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <CustomTextButton onClick={handleEditSave} color="error">
              Cancel
            </CustomTextButton>
            <CustomTextButton onClick={toggleAddDialog}>Add New</CustomTextButton>
            <CustomTextButton onClick={handleEditSave}>Save</CustomTextButton>
            <AddNewKeywordDialog open={openAddDialog} handleToggle={toggleAddDialog} handleSubmit={(text) => handleAddKeyword(text)} />
          </Box>
        ) : (
          <CustomTextButton onClick={handleEditSave}>Edit</CustomTextButton>
        )}
      </Box>
      <CustomDivider />
      <>
        <Box
          sx={{
            boxShadow: theme.palette.dashboardContainer.cardInsetBoxShadow,
            borderRadius: 1,
            // border: `0.1px solid ${theme.palette.additionalColors.border}`,
            background: theme.palette.background.paper,
            p: 2,
            minHeight: "127px",
            mt: 3,
          }}
        >
          <Stack direction="row" flexWrap="wrap" gap={1.5}>
            {keywords.length > 0 ? (
              keywords.map((word, index) => (
                <Chip
                  deleteIcon={editable ? <Close /> : <></>}
                  color="error"
                  key={index}
                  label={word.label}
                  onDelete={() => handleDeleteKeywords(word.id)}
                  sx={{
                    borderRadius: 1,
                    background: theme.palette.error.text,
                  }}
                />
              ))
            ) : (
              <Typography variant="caption1" textAlign="center">
                No Keywords
              </Typography>
            )}
          </Stack>
        </Box>
      </>
    </BorderedCard>
  );
}
