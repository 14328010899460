import ComputerIcon from "@mui/icons-material/Computer";
import MonitorIcon from "@mui/icons-material/Monitor";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import { Box, Typography, useTheme } from "@mui/material";

import BorderedCard from "../../../components/BorderedCard";
import SessionCard, { SessionDetail } from "./SessionCard";

const userSessions: SessionDetail[] = [
  {
    device_name: "MacBook Air",
    browser_version: "14.992.234.234 Zdolbuniv, Chrome",
    location: "Ukraine",
    browser_name: "SkylarkWeb Platform 1.0 (162783), Chrome",
    device_type: "Desktop",
    jti: "jkshsjkfd",
    is_active: true,
  },
  {
    device_name: "MacBook Air",
    browser_version: "14.992.234.234 Zdolbuniv, Chrome",
    location: "Ukraine",
    browser_name: "SkylarkWeb Platform 1.0 (162783), Chrome",
    device_type: "Desktop",
    jti: "jkshsjkfd",
    is_active: true,
  },
  {
    device_name: "MacBook Air",
    browser_version: "14.992.234.234 Zdolbuniv, Chrome",
    location: "Ukraine",
    browser_name: "SkylarkWeb Platform 1.0 (162783), Chrome",
    device_type: "Mobile",
    jti: "jkshsjkfd",
    is_active: true,
  },
  {
    device_name: "MacBook Air",
    browser_version: "14.992.234.234 Zdolbuniv, Chrome",
    location: "Ukraine",
    browser_name: "SkylarkWeb Platform 1.0 (162783), Chrome",
    device_type: "Desktop",
    jti: "jkshsjkfd",
    is_active: false,
  },
  {
    device_name: "MacBook Air",
    browser_version: "14.992.234.234 Zdolbuniv, Chrome",
    location: "Ukraine",
    browser_name: "SkylarkWeb Platform 1.0 (162783), Chrome",
    device_type: "Mobile",
    jti: "jkshsjkfd",
    is_active: false,
  },
];

export default function Sessions() {
  const theme = useTheme();
  const isLoading = false;

  return (
    <BorderedCard>
      <Typography variant="h4">Admin Sessions</Typography>
      {userSessions.length > 0 ? (
        <>
          <Box>
            <Typography variant="caption1" color={theme.palette.primary.main} sx={{ float: "right", mt: { xs: 1 } }}>
              Active Devices:
              {userSessions.filter((session) => session.is_active).length}
            </Typography>
            {userSessions.filter((session) => session.is_active).length > 0 && (
              <Box sx={{ width: "100%" }}>
                {userSessions
                  .filter((session) => session.is_active)
                  .map((session, index) => {
                    let deviceIcon = session?.device_type === "Mobile" ? <StayCurrentPortraitIcon /> : <MonitorIcon />;
                    return <SessionCard key={`active-${index}`} session={session} deviceIcon={deviceIcon} active={true} />;
                  })}
              </Box>
            )}
          </Box>
          <Box minHeight="2rem">
            <Typography variant="caption1" color={theme.palette.text.main} sx={{ float: "right", mt: { xs: 3 } }}>
              Recent Devices: {userSessions.filter((session) => !session.is_active).length}
            </Typography>
            {userSessions.filter((session) => !session.is_active).length > 0 && (
              <Box sx={{ width: "100%" }}>
                {userSessions
                  .filter((session) => !session.is_active)
                  .map((session, index) => {
                    let deviceIcon =
                      session?.device_type === "Desktop" ? (
                        <ComputerIcon />
                      ) : session?.device_type === "Mobile" ? (
                        <StayCurrentPortraitIcon />
                      ) : (
                        <MonitorIcon />
                      );
                    return <SessionCard key={`recent-${index}`} session={session} deviceIcon={deviceIcon} active={false} />;
                  })}
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Typography variant="caption">{isLoading ? "Loading..." : "No Sessions"}</Typography>
        </Box>
      )}
    </BorderedCard>
  );
}
