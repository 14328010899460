import SubTabLayout from "../../../layouts/SubTabLayout";
import AccountSection from "./AccountSection";
import BillingSection from "./BillingSection";
import PlanSection from "./PlanSection";
import SystemSection from "./SystemSection";
import WorkspaceSection from "./WorkspaceSection";

const tabs = [
  {
    id: "account",
    title: "Account",
    disabled: false,
    component: <AccountSection />,
  },
  {
    id: "system",
    title: "System",
    disabled: false,
    component: <SystemSection />,
  },
  {
    id: "workspace",
    title: "Workspace",
    disabled: false,
    component: <WorkspaceSection />,
  },
  {
    id: "billing",
    title: "Billing",
    disabled: false,
    component: <BillingSection />,
  },
  {
    id: "plan",
    title: "Plan",
    disabled: false,
    component: <PlanSection />,
  },
];

export default function SettingsContent() {
  return <SubTabLayout tabs={tabs} sx={{ margin: "auto" }} />;
}
