import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, InputAdornment, useTheme } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import CustomInputField from "../../../../components/CustomInputField";
import { DetailOptions } from "../AIContainer";
import CustomAICard from "./CustomAICard";

interface ListOption {
  details: DetailOptions[];
}

export default function CustomAIList({ details }: ListOption) {
  const theme = useTheme();
  const [filteredData, setFilterData] = useState<DetailOptions[]>([]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const filtered = details.filter((item) => {
      return Object.values(item).some((value) => {
        return String(value).toLowerCase().includes(e.target.value);
      });
    });
    if (filtered.length > 0) {
      setFilterData(filtered);
    } else {
      setFilterData(details);
    }
  };

  useEffect(() => {
    setFilterData(details);
  }, [details]);

  return (
    <Box>
      <CustomInputField
        placeholder=" Search"
        size="small"
        variant="outlined"
        autoComplete="off"
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box
        sx={{
          borderTop: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          background: theme.palette.dashboardContainer.containerBackground,
        }}
        mt={2}
      >
        <Grid container spacing={4} p={3}>
          {filteredData?.map((cardData, idx) => {
            return (
              <Grid item xs={12} sm={6} xl={4} key={`AI-model-${idx}`} display="flex">
                <CustomAICard cardData={cardData} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}
