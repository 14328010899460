import PersonIcon from "@mui/icons-material/Person";
import { Box, Typography, useTheme } from "@mui/material";

interface ChatboatProps {
  isConnecting: boolean;
  isOnline: boolean;
}
export default function Profile({ isConnecting, isOnline }: ChatboatProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        background: theme.palette.background.gradientLight1,
        boxShadow: theme.palette.dashboardContainer.themeButtonBoxShadow,
        px: 4,
        py: 1,
        mt: 2,
        borderRadius: 2,
      }}
    >
      {isConnecting && (
        <Box
          sx={{
            display: "flex",
            width: "2.5em",
            height: "2.5em",
            background: theme.palette.dashboardContainer.customRadioDropdownBackground,
            border: `2px solid ${theme.palette.additionalColors.lightBorder}`,
            borderRadius: "50%",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PersonIcon sx={{ color: theme.palette.background.dark, fontSize: "1.5em" }} />
        </Box>
      )}
      {!isConnecting && (
        <Box position="relative">
          <img
            src="/assets/user.png"
            alt="user"
            style={{
              width: "2.5em",
              height: "2.5em",
              borderRadius: "50%",
            }}
          />
          {isOnline && (
            <Box
              sx={{
                width: "10px",
                height: "10px",
                background: theme.palette.primary.active,
                borderRadius: "50%",
                position: "absolute",
                left: "35px",
                bottom: "5px",
              }}
            />
          )}
        </Box>
      )}
      {isConnecting && (
        <Typography variant="h6" color={theme.palette.text.default} fontSize="18px" mt={2}>
          Connecting...
        </Typography>
      )}
      {!isConnecting && (
        <Box sx={{ display: "flex", flexDirection: "column", mt: 1.2, gap: 0.5 }}>
          <Typography variant="h4" color={theme.palette.text.titleLabel}>
            Malek Smith
          </Typography>
          <Typography variant="body5" color={theme.palette.primary.inactiveIcon}>
            Skylark Labs Support Manager
          </Typography>
        </Box>
      )}
    </Box>
  );
}
