import { Box, Typography } from "@mui/material";
import AccountDetails from "./AccountDetails";
import AccountManagement from "./AccountManagement";
import Sessions from "./Sessions";

export default function AccountSection() {
  return (
    <Box>
      <Typography variant="h2" mb={3}>
        Account
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <AccountDetails />
        <Sessions />
        <AccountManagement />
      </Box>
    </Box>
  );
}
