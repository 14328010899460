import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    subtitle3: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    body6: React.CSSProperties;
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
    link5: React.CSSProperties;
    hugeButton: React.CSSProperties;
    bigButton: React.CSSProperties;
    regularButton: React.CSSProperties;
    smallButton: React.CSSProperties;
    controllerButton: React.CSSProperties;
    bigCaption: React.CSSProperties;
    semiBold: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    subtitle3?: React.CSSProperties;
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    body5?: React.CSSProperties;
    body6?: React.CSSProperties;
    caption1?: React.CSSProperties;
    caption2?: React.CSSProperties;
    link5?: React.CSSProperties;
    hugeButton?: React.CSSProperties;
    bigButton?: React.CSSProperties;
    regularButton?: React.CSSProperties;
    smallButton?: React.CSSProperties;
    controllerButton?: React.CSSProperties;
    bigCaption: React.CSSProperties;
    semiBold?: React.CSSProperties;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    body3: true;
    body4: true;
    body5: true;
    body6: true;
    caption1: true;
    caption2: true;
    link5: true;
    bigCaption: true;
    semiBold: true;
  }
}

export const typography: TypographyOptions = {
  h1: {
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  h2: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "110%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  h3: {
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  h4: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  h5: {
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  h6: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "100%",
    letterSpacing: 0,
  },
  //subtitles
  subtitle1: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    letterSpacing: 0,
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "100%",
    letterSpacing: 0,
  },
  subtitle3: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "100%",
    letterSpacing: 0,
  },
  //Body
  body1: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "100%",
    letterSpacing: 0,
  },
  body2: {
    fontWeight: 300,
    fontSize: "20px",
    lineHeight: "100%",
    letterSpacing: 0,
  },
  body3: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "100%",
    letterSpacing: 0,
  },
  body4: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "120%",
    letterSpacing: 0,
  },
  body5: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "100%",
    letterSpacing: 0,
  },
  body6: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "120%",
    letterSpacing: 0,
  },
  //caption
  caption: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "100%",
    letterSpacing: 0,
  },
  caption1: {
    fontWeight: 200,
    fontSize: "14px",
    lineHeight: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  caption2: {
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: "100%",
    letterSpacing: 0,
  },
  //custom defined
  link5: {
    fontWeight: 600,
    fontSize: "14px",
    letterSpacing: 0,
  },
  bigCaption: {
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: 0,
  },

  //Bold
  semiBold: {
    fontWeight: 600,
    fontSize: "22px",
    letterSpacing: 0,
  },
  //Buttons
  hugeButton: {
    fontWeight: 700,
    fontSize: "22px",
    textTransform: "capitalize",
    color: "#1D2330",
  },
  bigButton: {
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: 0,
  },
  regularButton: {
    fontWeight: 500,
    fontSize: "14px",
    letterSpacing: 0,
  },
  smallButton: {
    fontWeight: 500,
    fontSize: "12px",
    letterSpacing: 0,
  },
  controllerButton: {
    fontFamily: "Inter",
    fontWeight: 900,
    fontSize: "8px",
    letterSpacing: 0,
  },
  button: {
    textTransform: "none",
  },
  fontFamily: "League Spartan",
};
