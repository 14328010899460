import { Box, Typography, useTheme } from "@mui/material";
import TestResults from "./TestResults";

export default function TestingContainer() {
  const theme = useTheme();
  const testingData = [
    {
      name: "Test 3",
      createdAt: "03.04.2023 9:38 AM",
      trainingScore: { value: 80 },
      statisticsData: [
        { label: "men", value: 67 },
        { label: "women", value: 35 },
        { label: "children", value: 12 },
      ],
      precisionData: {
        categories: ["0.00", "0.25", "0.50", "0.75", "1.00"],
        series: [
          {
            name: "Alerts count bar graph",
            data: [1.0, 0.25, 0.5, 0.75, 1.0],
          },
        ],
      },
      reCallData: {
        categories: ["0.00", "0.25", "0.50", "0.75", "1.00"],
        series: [
          {
            name: "Alerts count bar graph",
            data: [1.0, 0.25, 0.5, 0.75, 1.0],
          },
        ],
      },
    },
    {
      name: "Test 2",
      createdAt: "03.04.2023 9:38 AM",
      trainingScore: { value: 80 },
      statisticsData: [
        { label: "men", value: 67 },
        { label: "women", value: 35 },
        { label: "children", value: 12 },
      ],
      precisionData: {
        categories: ["0.00", "0.25", "0.50", "0.75", "1.00"],
        series: [
          {
            name: "Alerts count bar graph",
            data: [1.0, 0.25, 0.5, 0.75, 1.0],
          },
        ],
      },
      reCallData: {
        categories: ["0.00", "0.25", "0.50", "0.75", "1.00"],
        series: [
          {
            name: "Alerts count bar graph",
            data: [1.0, 0.25, 0.5, 0.75, 1.0],
          },
        ],
      },
    },
    {
      name: "Test 1",
      createdAt: "03.04.2023 9:38 AM",
      trainingScore: { value: 80 },
      statisticsData: [
        { label: "men", value: 67 },
        { label: "women", value: 35 },
        { label: "children", value: 12 },
      ],
      precisionData: {
        categories: ["0.00", "0.25", "0.50", "0.75", "1.00"],
        series: [
          {
            name: "Alerts count bar graph",
            data: [1.0, 0.25, 0.5, 0.75, 1.0],
          },
        ],
      },
      reCallData: {
        categories: ["0.00", "0.25", "0.50", "0.75", "1.00"],
        series: [
          {
            name: "Alerts count bar graph",
            data: [1.0, 0.25, 0.5, 0.75, 1.0],
          },
        ],
      },
    },
  ];

  return (
    <Box>
      {testingData.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            background: theme.palette.dashboardContainer.backround,
            borderRadius: 2,
            border: theme.palette.additionalColors.databasecardBorder,
            p: 10,
          }}
        >
          <Typography variant="body4" color={theme.palette.text.tableHeader}>
            The Testing history will be displayed here
          </Typography>
        </Box>
      ) : (
        <TestResults testingData={testingData} />
      )}
    </Box>
  );
}
