import { createSlice } from "@reduxjs/toolkit";

export interface AnalysisInitialState {
  isLoading: boolean;
  feedFilter: any;
  connectionFilter: any;
  mapFilter: any;
}

const analysisInitialState: AnalysisInitialState = {
  isLoading: false,
  feedFilter: {},
  connectionFilter: {},
  mapFilter: {},
};

const analysisSlice = createSlice({
  name: "analysis",
  initialState: analysisInitialState,
  reducers: {
    addFeedFilter(state, action) {
      state.feedFilter = action.payload;
    },
    addConnectionFilter(state, action) {
      state.connectionFilter = action.payload;
    },
    addMapFilter(state, action) {
      state.mapFilter = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const analysisActions = analysisSlice.actions;

export default analysisSlice;
