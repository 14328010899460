import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import RadioFilterButton from "../../../../../../components/RadioFilterButton";

interface DateRangeFilter {
  selectedValue: string;
  getDateRange(value: string): void;
  isReset: boolean;
}

export default function DateRange({ selectedValue, getDateRange, isReset }: DateRangeFilter) {
  const theme = useTheme();

  const periodOptions = [
    {
      id: "last-24-hours",
      text: "Last 24 hours",
    },
    {
      id: "last-week",
      text: "Last Week",
    },
    {
      id: "last-month",
      text: "Last Month",
    },
    {
      id: "last-6-month",
      text: "Last 6 Month",
    },
    {
      id: "last-year",
      text: "Last Year",
    },
  ];

  const [value] = useState<string>(selectedValue || "");

  const handleChange = (value: string) => {
    getDateRange(value);
  };

  return (
    <Box>
      <Typography variant="h4" color={theme.palette.text.tableHeader} mb={1}>
        Date Range
      </Typography>
      <RadioFilterButton
        onChange={(value) => handleChange(value)}
        options={periodOptions}
        defaultValue={periodOptions[0].id}
        sx={{
          width: "100%",
          padding: 0,
          color: theme.palette.text.titleLabel,
          background: theme.palette.background.gradientLight2,
          boxShadow: theme.palette.dashboardContainer.customFilterOptionShadow,
        }}
        endIcon={<ExpandMoreIcon sx={{ color: theme.palette.primary.main, height: "20px", width: "20px", mt: 1 }} />}
        textColor={theme.palette.text.titleLabel}
        isReset={isReset}
        selectionData={value}
      />
    </Box>
  );
}
