import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { useTheme } from "@mui/material";
import { getMyDetail } from "../../store/auth/authThunk";
import { useAppDispatch } from "../../store/hooks";
import SideBar from "./components/Sidebar";

export interface DashboardBaseLayoutProps {
  children?: React.ReactNode;
}

export default function DashboardBaseLayout({ children }: DashboardBaseLayoutProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const userDetail = JSON.parse(String(localStorage.getItem("userDetail")));
  if (!userDetail || userDetail === null) dispatch(getMyDetail());

  return (
    <Box sx={{ display: "flex" }}>
      <SideBar />
      <Box
        component="main"
        sx={{
          maxHeight: "96vh",
          overflowY: "auto",
          my: 1.5,
          mr: 1.5,
          p: 2,
          pb: "4rem",
          flexGrow: 1,
          borderRadius: "29px 29px 29px 29px",
          overflow: "hidden",
          background: theme.palette.dashboardContainer.backround,
          boxShadow: theme.palette.dashboardContainer.boxShadow,
          border: `2px solid ${theme.palette.dashboardContainer.borderColor}`,
          borderLeft: "none",
          position: "relative",
          [theme.breakpoints.down("md")]: {
            background: "none",
            boxShadow: "none",
            border: "none",
            maxWidth: "100%",
            p: 0,
            pb: "4rem",
            marginTop: "70px",
            marginLeft: "10px",
          },
        }}
      >
        {/* <Box
          sx={{
            position: "absolute",
            width: "11px",
            borderRadius: "50%",
            borderLeft: `2px solid ${theme.palette.additionalColors.border1}`,
            zIndex: "1",
            rotate: "38deg",
            top: "-2px",
            left: "3px",
            height: "32px",
            boxShadow: `10px 10px 0 ${theme.palette.dashboardContainer.firstThemeColor}`,
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          }}
        ></Box> */}
        <Container maxWidth={false} disableGutters sx={{ margin: "0", height: "100%" }}>
          {children}
        </Container>
      </Box>
    </Box>
  );
}
