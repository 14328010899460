import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import Chart from "react-apexcharts";
import { LuArrowDownUp } from "react-icons/lu";
import RadioFilterButton from "../../../../../../components/RadioFilterButton";

export interface SubData {
  name: string;
  data: number[];
}

type CustomColor = string;

export interface ChartDataOptions {
  options: {
    tooltip: {
      enabled: boolean;
      alwaysShow: boolean;
      theme: string;
      marker: {
        show: boolean;
      };
      x: {
        show: boolean;
      };
    };
    plotOptions: {
      bar: {
        dataLabels: {
          position: string;
        };
      };
    };
    dataLabels: {
      enabled: boolean;
    };
    xaxis: {
      categories: string[];
      position: string;
      labels: {
        show: boolean;
        offsetY: number;
        style?: {
          colors: CustomColor;
          fontWeight?: number;
          fontSize?: string;
        };
      };
      axisBorder: {
        show: boolean;
      };
      axisTicks: {
        show: boolean;
      };
    };
    fill: {
      type: string;
      gradient: {
        type: string;
        shadeIntensity: number;
        opacityFrom: number;
        opacityTo: number;
        colorStops: {
          offset: number;
          color: CustomColor;
          opacity: number;
        }[];
      };
    };
    yaxis: {
      labels: {
        show: boolean;
      };
    };
    chart: {
      toolbar: {
        show: boolean;
      };
    };
    grid: {
      show: boolean;
    };
    states: {
      hover: { filter: { type: string; value: number } };
      active: { filter: { type: string; value: number } };
    };
  };
  series: SubData[];
}

const QuentityResults = () => {
  const [filter, setFilter] = useState("Last 24 Hours");

  const theme = useTheme();
  const chartData: ChartDataOptions = {
    options: {
      tooltip: {
        enabled: true,
        alwaysShow: true,
        theme: "dark",
        marker: {
          show: false,
        },
        x: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
        ],
        position: "bottom",
        labels: {
          show: true,
          offsetY: 0,
          style: {
            colors: theme.palette.text.titleLabel as CustomColor,
            fontWeight: 400,
            fontSize: "14px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          colorStops: [
            {
              offset: 0,
              color: theme.palette.background.barGraphLight as CustomColor,
              opacity: 1,
            },
            {
              offset: 100,
              color: theme.palette.background.barGraphDark as CustomColor,
              opacity: 1,
            },
          ],
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      states: {
        hover: { filter: { type: "none", value: 0 } },
        active: { filter: { type: "none", value: 0 } },
      },
    },
    series: [
      {
        name: "publications",
        data: [123, 30, 60, 10, 8, 123, 30, 60, 10, 8, 123, 30, 60, 10, 8, 123, 30, 60, 10, 8, 123, 30, 60, 10],
      },
    ],
  };

  const periodOptions = [
    {
      id: "last-24-hours",
      text: "Last 24 hours",
    },
    {
      id: "last-week",
      text: "Last Week",
    },
    {
      id: "last-month",
      text: "Last Month",
    },
    {
      id: "last-6-month",
      text: "Last 6 Month",
    },
    {
      id: "last-year",
      text: "Last Year",
    },
  ];

  const handleInputChange = (e: any) => {
    setFilter(e?.target?.value);
  };

  return (
    <Box sx={{ border: theme.palette.additionalColors.databasecardBorder, mt: 5, p: 2, borderRadius: 2 }}>
      <Box display="flex">
        <Box display="block">
          <Typography variant="subtitle1">Quantity of Results</Typography>
          <Typography variant="h3" mt={1}>
            649
          </Typography>
        </Box>
        <Box ml="auto">
          <RadioFilterButton
            label="Period"
            onChange={(value) => {}}
            options={periodOptions}
            startIcon={<LuArrowDownUp color={theme.palette.primary.main} size={16} />}
            defaultValue={periodOptions[0].id}
            sx={{ width: "200px" }}
          />
        </Box>
      </Box>
      <Box textAlign="center">
        <Chart options={chartData.options} series={chartData.series} type="bar" width="100%" height={180} />
      </Box>
    </Box>
  );
};

export default QuentityResults;
