import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { SOLUTIONS } from "../../../utils/constants";
import AddNewCustomCaseCard from "./AddNewCaseCard";
import SolutionCard from "./SolutionCard";

export default function HomeContent() {
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="h3" textAlign="center" my={3}>
        Get started with different solutions
      </Typography>
      <Container>
        <Grid
          container
          mt={0}
          pb={0}
          spacing={4}
          justifyContent="center"
          textAlign="center"
          sx={{
            borderTop: "1px solid",
            borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
            background: theme.palette.dashboardContainer.containerBackground,
          }}
        >
          <Grid item xs={12} md={6} lg={4} minHeight="16rem" width="100%">
            <AddNewCustomCaseCard />
          </Grid>
          {SOLUTIONS.map((sol) => {
            return (
              <Grid key={sol.id} item xs={12} md={6} lg={4} minHeight="16rem" width="100%">
                <SolutionCard data={sol} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
}
