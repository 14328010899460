import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CustomIconButton from "../../../../../../components/CustomIconButton";

interface InfoDetails {
  [x: string]: any;
}
export default function EntityInfo({ infoData }: InfoDetails) {
  const theme = useTheme();

  const imgSize = useMediaQuery("(min-width:1028px)") ? 138 : 75;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <img src={infoData?.media_image} style={{ width: imgSize, height: imgSize, borderRadius: "8px" }} />
        </Grid>
        <Grid item xs={6}>
          <Box width={{ xs: "100%", lg: "80%", xl: "50%" }}>
            <Box display="flex" gap={2} mb={1.5}>
              <CustomIconButton
                sx={{
                  width: "48px",
                  height: "48px",
                  borderRadius: "50%",
                  border: "none",
                  background: theme.palette.primary.tertiaryGradient,
                  boxShadow: "none",
                  pointerEvents: "none",
                }}
              >
                <PersonIcon sx={{ color: theme.palette.text.tableHeader, textAlign: "center" }} />
              </CustomIconButton>
              <Typography variant="h2" color={theme.palette.text.titleLabel} mt={1.6}>
                {infoData?.name}
              </Typography>
            </Box>
            <Typography variant="body3" color={theme.palette.text.titleLabel}>
              {infoData?.description}
            </Typography>
            <Box display="flex" my={3} gap={1}>
              <TwitterIcon sx={{ color: theme.palette.primary.main }} />
              <LinkedInIcon sx={{ color: theme.palette.primary.main }} />
              <FacebookIcon sx={{ color: theme.palette.primary.main }} />
            </Box>
            <Box display="flex" gap={2}>
              {infoData?.tag.map((data: string) => (
                <Box
                  key={data}
                  sx={{
                    backgroundColor: theme.palette.primary.tertiary,
                    color: theme.palette.error.btnText,
                    px: 0.6,
                    borderRadius: "4px",
                  }}
                >
                  <Typography variant="body5" color="#F5F9FF" sx={{ lineHeight: "18px" }}>
                    {data}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ borderRadius: 2, float: "right" }}>
            <img src="/assets/overviewmap.png" />
            <Box display="flex" mt={1}>
              <PlaceIcon sx={{ color: theme.palette.primary.main }} />
              <Typography variant="subtitle3" sx={{ color: theme.palette.primary.main, mt: 1 }}>
                {infoData?.location}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
