import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, InputAdornment, Pagination, useTheme } from "@mui/material";
import React from "react";
import CheckboxFilterButton, { MultiSelectValueType } from "../../../components/CheckboxFilterButton";
import CustomInputField from "../../../components/CustomInputField";
import QueryCard from "../../../components/QueryCard";
import { FAVORITE_QUERIES, queryTypeFilterOptions, solutionFilterOptions } from "../../../utils/constants";

export default function FavoriteQueriesContent() {
  const theme = useTheme();
  const [page, setPage] = React.useState(1);

  const handleChangeQueryType = (value: MultiSelectValueType[]) => {
    console.log(value);
  };

  const handleChangeSolution = (value: MultiSelectValueType[]) => {
    console.log(value);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <CustomInputField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
            placeholder="Search"
            size="small"
            variant="outlined"
            autoComplete="off"
            sx={{ width: { lg: "40%", md: "100%" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end" gap={2}>
          <CheckboxFilterButton
            label="Query Type"
            onChange={(value) => handleChangeQueryType(value)}
            options={queryTypeFilterOptions}
            showSearch={false}
          />
          <CheckboxFilterButton
            label="Solution"
            onChange={(value) => handleChangeSolution(value)}
            options={solutionFilterOptions}
            showSearch={false}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          my: 2,
          py: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderTop: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          background: theme.palette.dashboardContainer.containerBackground,
        }}
      >
        {FAVORITE_QUERIES.map((item) => {
          return <QueryCard data={item} key={item.id} isFavorite />;
        })}
      </Box>
      <Box>
        {
          <Box
            sx={{
              my: 2,
              mr: 1,
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            {FAVORITE_QUERIES.length / 8 >= 1 && (
              <Pagination
                onChange={() => {}}
                page={page}
                count={Math.ceil(FAVORITE_QUERIES.length / 8)}
                size="small"
                sx={(theme) => ({
                  ...theme.palette.pagination,
                })}
              />
            )}
          </Box>
        }
      </Box>
    </Box>
  );
}
