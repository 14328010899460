import { Box, useMediaQuery } from "@mui/material";
import ContentUILoader from "../../../../../../components/ContentUILoader";
import CustomNoResults from "../../../../../../components/CustomNoResult";
import VideoCard from "./VideoCard";

const itemData = [
  {
    id: 1,
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    id: 2,
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    id: 3,
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    rows: 2,
    cols: 1,
  },
  {
    id: 4,
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    id: 5,
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    id: 6,
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    rows: 2,
    cols: 1,
  },
  {
    id: 7,
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    id: 8,
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    id: 9,
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    id: 10,
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    id: 11,
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    id: 12,
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
];

interface VideosProps {
  drawerOpen: boolean;
  isRefresh: boolean;
}

export default function Videos({ drawerOpen, isRefresh }: VideosProps) {
  const matches = useMediaQuery("(min-width:1200px)") ? 2 : 4;

  let mergedColumnIds: number[] = [];

  for (let i = matches; i <= itemData.length; i += 10) {
    mergedColumnIds.push(i);
  }

  for (let i = 5; i <= itemData.length; i += 10) {
    mergedColumnIds.push(i);
  }

  return (
    <>
      {isRefresh ? (
        <ContentUILoader type="video" count={5} drawerOpen={drawerOpen} />
      ) : (
        <>
          {itemData.length > 0 ? (
            <Box sx={{ width: 1, mt: 3 }}>
              <Box display="grid" gridTemplateColumns="repeat(12, 4fr)" width="100%" gap={2}>
                {itemData.map((data, idx) => {
                  return (
                    <Box
                      key={`image-${idx}`}
                      gridColumn={{
                        xs: "span 12",
                        sm: drawerOpen ? "span 12" : "span 6",
                        lg: drawerOpen ? "span 6" : "span 4",
                        xl: drawerOpen ? "span 4" : "span 3",
                      }}
                    >
                      <VideoCard cardData={data} index={idx} />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ) : (
            <CustomNoResults />
          )}
        </>
      )}
    </>
  );
}
