import ReplayIcon from "@mui/icons-material/Replay";
import { Box, FormControlLabel, Typography, useTheme } from "@mui/material";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomSwitch from "../../../components/CustomSwitch";
import UiLoading from "../../../layouts/UiLoading";

export interface titleProps {
  title: string;
  time: string;
  refresh: Boolean;
  results?: boolean;
  resultLabel?: string;
  isRefresh?: boolean;
  handleRefresh?(): void;
}

export default function Title({ title, time, refresh, results = false, resultLabel, isRefresh = false, handleRefresh }: titleProps) {
  const theme = useTheme();
  return (
    <Box display="flex" justifyContent="space-between" mb={0.5}>
      {isRefresh && <UiLoading height="100vh" />}
      <Typography>{title}</Typography>
      {results && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: theme.palette.action.disabled,
            borderRadius: 6,
            px: 2,
            ml: "10%",
          }}
        >
          <Typography variant="h4" color={theme.palette.text.titleLabel}>
            {resultLabel}
          </Typography>
        </Box>
      )}
      <Box display="flex" gap={2}>
        <Typography variant="subtitle2" color={theme.palette.additionalColors.light}>
          {time}
          <CustomIconButton sx={{ ml: 1, width: "30px", height: "30px", color: theme.palette.error.btnText }} onClick={handleRefresh}>
            <ReplayIcon sx={{ color: theme.palette.text.default, transform: "rotate(-80deg)" }} />
          </CustomIconButton>
        </Typography>
        {refresh && (
          <FormControlLabel
            control={<CustomSwitch defaultChecked />}
            label="Auto Refresh"
            labelPlacement="start"
            sx={{ "& .MuiFormControlLabel-label": { ...theme.typography.subtitle2 } }}
          />
        )}
      </Box>
    </Box>
  );
}
