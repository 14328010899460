import TabLayout from "../../layouts/TabLayout";
import SettingsContent from "./component/SettingsContent";

const tabs = [
    {
        id: "settings",
        title: "Settings",
        disabled: false,
        component: <SettingsContent />,
    },
];

export default function Settings() {
    return <TabLayout tabs={tabs} />;
}
