import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TableRowsIcon from "@mui/icons-material/TableRows";
import WindowIcon from "@mui/icons-material/Window";
import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { LuArrowDownUp } from "react-icons/lu";
import { useLocation } from "react-router-dom";
import CustomButton from "../../../../../components/CustomButton";
import CustomIconButton from "../../../../../components/CustomIconButton";
import CustomTab from "../../../../../components/CustomTab";
import RadioFilterButton from "../../../../../components/RadioFilterButton";
import { routes } from "../../../../../routes";
import { useHandleRouteClick } from "../../../../../routes/hooks";

export interface TabRecord {
  id: string | number;
  title: string;
  disabled?: boolean;
  component: React.ReactNode;
}

export interface TabLayoutProps {
  tabs: TabRecord[];
  getActiveTab: (tab: string) => void;
  getView: (view: boolean) => void;
  handleDrawerOpen(): void;
  openDrawer: boolean;
}

export default function FilterSection({ tabs, getActiveTab, getView, handleDrawerOpen, openDrawer }: TabLayoutProps) {
  const theme = useTheme();
  const { state } = useLocation();
  const handleRouteClick = useHandleRouteClick();

  const [activeTab, setActiveTab] = useState(state?.activeTab || tabs[0].id);
  const [sortedValue, setsortedValue] = useState("relevance");
  const [subtype, setSubType] = useState("all posts");
  const [listView, setListView] = useState(true);

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
    getActiveTab(tab);
  };

  const handleRiskFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setsortedValue(e.target.value);
  };

  const handleTypeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubType(e.target.value);
  };

  const handleView = () => {
    setListView((prev) => !prev);
  };

  const handleFilter = () => {
    handleDrawerOpen();
  };

  useEffect(() => {
    getView(listView);
  }, [listView]);

  useEffect(() => {
    if (activeTab !== state?.activeTab) {
      handleRouteClick(`${routes.analytics.path}?tab=analysis&page=feed`);
    }
  }, [state?.activeTab, activeTab]);

  const subType = [
    { id: "all-posts", text: "All Posts" },
    { id: "top-sources", text: "Top Sources" },
    { id: "most-mentioned", text: "Most Mentioned" },
    { id: "most-used-hashtags", text: "Most Used Hashtags" },
    { id: "most popular", text: "Most Popular" },
  ];

  const relevance = [
    { id: "relevance", text: "Relevance" },
    { id: "autenticity", text: "Autenticity" },
    { id: "latest", text: "Latest" },
    { id: "oldest", text: "Oldest" },
    { id: "risk score: high first", text: "Risk Score: High First" },
    { id: "risk score: low first", text: "Risk Score: Low First" },
  ];

  return (
    <Box gap="12px" display={"flex"} mt={2} justifyContent="space-between" sx={{ [theme.breakpoints.down("xl")]: { flexDirection: "column" } }}>
      <Box
        display="flex"
        gap={2}
        width={"100%"}
        sx={{
          [theme.breakpoints.down("lg")]: {
            flexDirection: openDrawer ? "column" : "row",
          },
        }}
      >
        {!openDrawer && (
          <CustomButton
            endIcon={<ChevronRightIcon />}
            sx={{
              [theme.breakpoints.down("lg")]: {
                display: "none",
              },
            }}
            onClick={handleFilter}
          >
            Filters
          </CustomButton>
        )}
        {!openDrawer && (
          <CustomIconButton
            sx={{
              width: "36px",
              height: "36px",
              color: theme.palette.text.default,
              [theme.breakpoints.up("lg")]: {
                display: "none",
              },
            }}
            onClick={handleFilter}
          >
            <FilterAltIcon />
          </CustomIconButton>
        )}
        <CustomTab
          tabs={tabs}
          handleActiveTab={handleActiveTab}
          sx={{ border: `1px solid ${theme.palette.primary.tertiary}`, textAlign: "center", p: "0 2px", alignItems: "center", borderRadius: "6px" }}
          width="110px"
          activeTab={activeTab}
        />
        <Box ml="auto">
          <RadioFilterButton
            label="Category"
            onChange={(value) => {}}
            options={subType}
            startIcon={<LuArrowDownUp color={theme.palette.primary.main} size={16} />}
            defaultValue={subType[0].id}
            sx={{ width: "180px" }}
          />
        </Box>
      </Box>

      <Box display="flex" gap="20px" justifyContent="space-between">
        <RadioFilterButton
          onChange={(value) => {}}
          options={relevance}
          startIcon={<LuArrowDownUp color={theme.palette.primary.main} size={16} />}
          defaultValue={relevance[0].id}
          sx={{ width: "140px" }}
        />
        <CustomIconButton sx={{ width: "36px", height: "36px", ml: "auto", color: theme.palette.text.default }} onClick={handleView}>
          {listView ? <TableRowsIcon /> : <WindowIcon />}
        </CustomIconButton>
      </Box>
    </Box>
  );
}
