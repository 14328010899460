import List from "@mui/material/List";

import { useTheme } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Support from "../../../pages/support";
import { DrawerMenuItemsInterface } from "../../../routes";
import SidebarMenuItem from "./SidebarMenuItem";

export interface SidebarMenuProps {
  sidebarMenuItems: DrawerMenuItemsInterface[];
}

export default function SidebarMenu({ sidebarMenuItems }: SidebarMenuProps) {
  const theme = useTheme();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);
  const [isSupport, setIsSupport] = useState(false);

  const getActiveTab = (path: string) => {
    setActiveTab(path);
    if (path === "/support") {
      setIsSupport((prev) => !prev);
    } else {
      setIsSupport(false);
    }
  };

  const handleDrawerClose = () => {
    setIsSupport(false);
    setActiveTab(location?.pathname);
  };

  return (
    <List sx={{ width: "100%", borderRight: `2px solid ${theme.palette.sidebarMenu.itemBorderRightColor}` }}>
      {sidebarMenuItems.map((routeDetails, index) => (
        <SidebarMenuItem
          routeDetails={routeDetails}
          key={index}
          activeTab={routeDetails?.path === activeTab ? true : false}
          getActiveTab={getActiveTab}
        />
      ))}
      {isSupport && <Support handleDrawer={handleDrawerClose} open={isSupport} />}
    </List>
  );
}
