import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CustomDivider from "../../../../../../components/CustomDivider";
import HashtagDialog from "./HashtagModal";

interface HashtagOptions {
  key: string;
  value: number;
}

interface HashtagProps {
  hashtagData: HashtagOptions[];
}

export default function HashtagCard({ hashtagData }: HashtagProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const newData = hashtagData.slice(0, 5);
  return (
    <Box sx={{ border: theme.palette.additionalColors.databasecardBorder, p: 2, width: "100%", borderRadius: 2 }}>
      <Box display="flex" mb={1}>
        <Typography variant="h4" fontSize={"18px"}>
          Hashtags
        </Typography>
        <InfoOutlinedIcon sx={{ color: theme.palette.text.tableHeader, ml: 1, fontSize: "16px" }} />
        {hashtagData.length > 0 && (
          <Typography
            ml="auto"
            variant="link5"
            color={theme.palette.text.default}
            display={"flex"}
            sx={{
              cursor: "pointer",
              fontSize: "16px",
            }}
            onClick={handleOpen}
          >
            View All
          </Typography>
        )}
      </Box>
      {hashtagData.length > 0 ? (
        <>
          <CustomDivider />
          {newData.map((data: any, idx) => {
            return (
              <Box display="flex" gap={10} mt={2} key={`sentiment-${idx}`}>
                <Typography variant="h6" sx={{ textTransform: "capitalize", fontSize: "14px", color: theme.palette.text.default }}>
                  {data?.key}
                </Typography>
                <Typography variant="h6" sx={{ textTransform: "capitalize", fontSize: "14px", color: theme.palette.text.titleLabel }} ml="auto">
                  {data?.value}
                </Typography>
              </Box>
            );
          })}
        </>
      ) : (
        <Typography variant="caption" color={theme.palette.action.disabled}>
          No hashtags detected
        </Typography>
      )}
      <HashtagDialog open={open} handleClose={handleOpen} hashtagData={hashtagData} />
    </Box>
  );
}
