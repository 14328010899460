import { Box, CardMedia, SxProps, Typography, useTheme } from "@mui/material";

interface ChatBoxProps {
  type: string;
  message: string;
  sx?: SxProps;
}
export default function ChatBox({ type, message }: ChatBoxProps) {
  const theme = useTheme();

  const profileStyle = {
    width: "36px",
    height: "36px",
    alignItems: type === "outgoing" ? "start" : "end",
    alignSelf: "end",
  };

  return (
    <Box display="flex" sx={{ justifyContent: type === "outgoing" ? "end" : "start", gap: 1, my: 2 }}>
      {type === "incoming" && <CardMedia component="img" image="/assets/user.png" alt="profile" sx={profileStyle} />}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          background: `${theme.palette.primary.tertiary}4D`,
          borderRadius: type === "outgoing" ? "20px 20px 0px 20px" : "20px 20px 20px 0",
          width: "70%",
          p: 2,
        }}
      >
        <Typography variant="body4" color={theme.palette.text.titleLabel}>
          {message}
        </Typography>
        <Typography variant="body5" color={theme.palette.text.captionColor} sx={{ ml: type === "outgoing" ? "auto" : "0" }}>
          12:30
        </Typography>
      </Box>
      {type === "outgoing" && <CardMedia component="img" image={"/assets/profile.png"} alt="profile" sx={profileStyle} />}
    </Box>
  );
}
