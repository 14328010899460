import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "../../routes";

import SignUpBaseLayout from "../../layouts/SignUpBaseLayout";
import { forgotPassword } from "../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import EmailForm from "./EmailForm";
import EmailVerification from "./EmailVerification";

export default function ForgotPassword() {
  const dispatch = useAppDispatch();
  const [isEmailSent, setIsEmailSent] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const { isAuthenticated, isLoading } = useAppSelector((state) => state.auth);

  const handleSubmit = () => {
    dispatch(forgotPassword({ email })).then((res) => {
      if (res?.payload?.status === 200) {
        setIsEmailSent(true);
        toast.success("Verification email sent successfully");
      }
    });
  };

  const handleInputChange = (email: string) => {
    setEmail(email);
  };

  if (isAuthenticated) {
    return <Navigate to={routes.home.path} />;
  }

  return (
    <SignUpBaseLayout isLoading={isLoading}>
      {isEmailSent ? (
        <EmailVerification email={email} />
      ) : (
        <EmailForm handleSubmit={handleSubmit} handleInputChange={handleInputChange} isLoading={isLoading} />
      )}
    </SignUpBaseLayout>
  );
}
