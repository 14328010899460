import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Checkbox, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { HiMiniCursorArrowRipple } from "react-icons/hi2";
import CustomButton from "../../../../../../components/CustomButton";
import CustomDivider from "../../../../../../components/CustomDivider";
import CustomStatus from "../../../../../../components/CustomStatus";
import { useHandleRouteClick } from "../../../../../../routes/hooks";
import RiskFactorDialog, { RiskData, RiskFactorProps } from "./RiskFactorDialog";

export interface RiskLayoutProps {
  riskFactorData: RiskFactorProps;
  checkBox?: boolean;
  id?: number;
  getData?: (data: RiskData) => void;
  editing?: boolean;
}
export default function RiskFactorCard({ riskFactorData, checkBox = false, id, getData, editing = false }: RiskLayoutProps) {
  const handleRouteClick = useHandleRouteClick();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => setOpen(anchorEl ? true : false), [anchorEl]);

  const path = riskFactorData?.title.split(" ")[0].toLowerCase();

  const handleClick = (path: string) => {
    handleRouteClick(`/riskFactor/${path}`);
  };

  const configureHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const parentBox = event.currentTarget.parentElement?.parentElement?.parentElement;
    if (parentBox) {
      setAnchorEl(parentBox);
    }
  };

  const closeDialogHandler = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <Box
      sx={
        editing
          ? {
              borderRadius: "8px",
              border: "2px solid transparent",
              flexFlow: "row wrap",
              width: "100%",
              backgroundImage:
                anchorEl?.id === `${id}`
                  ? theme.palette.additionalColors.cardActiveBorderImage
                  : theme.palette.additionalColors.cardDefaultBorderImage,
              backgroundOrigin: "border-box",
              backgroundClip: "content-box, border-box",
              cursor: "pointer",
              position: "relative",
            }
          : {
              background: theme.palette.background.darkCardBackground,
              border: theme.palette.additionalColors.databasecardBorder,
              borderRadius: "8px",
              width: "100%",
              cursor: "pointer",
            }
      }
      onClick={() => handleClick(path)}
    >
      <Box p={2} id={`${id}`}>
        <Box
          mb={2}
          sx={{
            display: "flex",
          }}
        >
          <Typography>{riskFactorData?.title}</Typography>
          <InfoOutlinedIcon sx={{ color: theme.palette.text.tableHeader, ml: 1, fontSize: "16px", mt: 0.5 }} />
          <Box ml="auto">
            <CustomStatus label={riskFactorData?.status} status={riskFactorData?.status} sx={{ width: "88px", height: "28px" }} />
          </Box>
        </Box>
        <CustomDivider width="100%" />
        <Box height={editing ? "11rem" : "none"}>
          {riskFactorData.subData.map((data, idx) => (
            <Box key={`subData-${idx}`} display="flex" alignItems="flex-start" mt={2}>
              <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
                {data?.label}
              </Typography>
              {!checkBox ? (
                <CustomButton
                  variant="outlined"
                  sx={{
                    ...theme.typography.smallButton,
                    ml: "auto",
                    color: theme.palette.text.titleLabel,
                    border: `1px solid ${theme.palette.text.titleLabel}`,
                    boxShadow: "none",
                    height: "20px",
                    minWidth: "40px",
                    width: "40px",
                    lineHeight: "15px",
                    background: theme.palette.dashboardContainer.lightBgButton,
                  }}
                >
                  {data?.count || "-"}
                </CustomButton>
              ) : (
                <>
                  {data?.count ? (
                    <Checkbox
                      checked={true}
                      sx={{
                        ml: "auto",
                        mt: "-10px",
                        "&.MuiButtonBase-root.MuiCheckbox-root": {
                          p: 0,
                        },
                      }}
                    />
                  ) : (
                    <Box ml="auto" mr={1}>
                      -
                    </Box>
                  )}
                </>
              )}
              {editing && (
                <>
                  <CustomButton
                    variant="contained"
                    id={`${idx}`}
                    sx={{
                      ...theme.typography.h6,
                      border: `0.5px solid ${theme.palette.additionalColors.border1Light}`,
                      color: theme.palette.primary.main,
                      background: theme.palette.background.tertiaryDarkGradient,
                      boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
                      "&:hover": {
                        background: theme.palette.background.tertiaryGradient,
                        boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
                      },
                      position: "absolute",
                      bottom: 14,
                      right: 10,
                    }}
                    onClick={configureHandler}
                  >
                    Configure Results
                  </CustomButton>
                  {anchorEl?.id === `${id}` && (
                    <HiMiniCursorArrowRipple size={30} color={theme.palette.text.titleLabel} style={{ position: "absolute", bottom: 2, right: 8 }} />
                  )}
                  <RiskFactorDialog
                    anchor={anchorEl}
                    id={id}
                    riskFactorData={riskFactorData}
                    open={open}
                    getData={getData}
                    handleClose={closeDialogHandler}
                  />
                </>
              )}
            </Box>
          ))}{" "}
        </Box>
      </Box>
    </Box>
  );
}
