export const TableData = [
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    connections: 345,
    score: "high",
    factor1: "low",
    factor2: "neutral",
    factor3: "low",
    factor4: "moderate",
    factor5: "low",
    factor6: "low",
    description: "Description - how Entite connected with this entity",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    connections: 345,
    score: "high",
    factor1: "low",
    factor2: "neutral",
    factor3: "low",
    factor4: "moderate",
    factor5: "low",
    factor6: "low",
    description: "Description - how Entite connected with this entity",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    connections: 345,
    score: "high",
    factor1: "low",
    factor2: "low",
    factor3: "low",
    factor4: "moderate",
    factor5: "low",
    factor6: "neutral",
    description: "Description - how Entite connected with this entity",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    connections: 345,
    score: "high",
    factor1: "low",
    factor2: "neutral",
    factor3: "low",
    factor4: "moderate",
    factor5: "low",
    factor6: "low",
    description: "Description - how Entite connected with this entity",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    connections: 345,
    score: "high",
    factor1: "low",
    factor2: "low",
    factor3: "low",
    factor4: "moderate",
    factor5: "low",
    factor6: "neutral",
    description: "Description - how Entite connected with this entity",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    connections: 345,
    score: "high",
    factor1: "neutral",
    factor2: "low",
    factor3: "low",
    factor4: "moderate",
    factor5: "low",
    factor6: "low",
    description: "Description - how Entite connected with this entity",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    connections: 345,
    score: "high",
    factor1: "neutral",
    factor2: "low",
    factor3: "low",
    factor4: "moderate",
    factor5: "low",
    factor6: "low",
    description: "Description - how Entite connected with this entity",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    connections: 345,
    score: "high",
    factor1: "neutral",
    factor2: "low",
    factor3: "low",
    factor4: "moderate",
    factor5: "low",
    factor6: "low",
    description: "Description - how Entite connected with this entity",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    connections: 345,
    score: "high",
    factor1: "neutral",
    factor2: "low",
    factor3: "low",
    factor4: "moderate",
    factor5: "low",
    factor6: "low",
    description: "Description - how Entite connected with this entity",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    connections: 345,
    score: "high",
    factor1: "neutral",
    factor2: "low",
    factor3: "low",
    factor4: "moderate",
    factor5: "low",
    factor6: "low",
    description: "Description - how Entite connected with this entity",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    connections: 345,
    score: "high",
    factor1: "neutral",
    factor2: "low",
    factor3: "low",
    factor4: "moderate",
    factor5: "low",
    factor6: "low",
    description: "Description - how Entite connected with this entity",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    connections: 345,
    score: "low",
    factor1: "neutral",
    factor2: "low",
    factor3: "low",
    factor4: "moderate",
    factor5: "low",
    factor6: "low",
    description: "Description - how Entite connected with this entity",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    connections: 345,
    score: "high",
    factor1: "neutral",
    factor2: "low",
    factor3: "low",
    factor4: "moderate",
    factor5: "low",
    factor6: "low",
    description: "Description - how Entite connected with this entity",
  },
];

export const LEVEL_OPT = [
  {
    id: "level1",
    text: "Level 1",
  },
  {
    id: "level2",
    text: "Level 2",
  },
  {
    id: "level3",
    text: "Level 3",
  },
  {
    id: "level4",
    text: "Level 4",
  },
  {
    id: "level5",
    text: "Level 5",
  },
];
