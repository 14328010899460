import SaveAsIcon from "@mui/icons-material/SaveAs";
import { Box, Typography, useTheme } from "@mui/material";
import BackButton from "../../../../components/BackButton";
import CustomDivider from "../../../../components/CustomDivider";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";

export default function Header() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const handleBackNavigation = () => {
    handleRouteClick(routes.customAI.path);
  };

  return (
    <Box>
      <Box px={1.5}>
        <Box display="flex" gap={1}>
          <Typography variant="h1" color={theme.palette.text.titleLabel}>
            New Custom AI Model
          </Typography>
          <SaveAsIcon sx={{ color: theme.palette.primary.main }} />
        </Box>
        <BackButton onClick={handleBackNavigation} />
      </Box>
      <CustomDivider width="100%" />
    </Box>
  );
}
