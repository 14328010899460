import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Box, Typography, useTheme } from "@mui/material";
import CustomRadialBar from "../../../../../components/CustomRadial";
import CustomTextButton from "../../../../../components/CustomTextButton";

interface TrainingScoreProps {
  value: number;
}
export default function TrainingScore({ value }: TrainingScoreProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        p: 2,
        borderRadius: 2,
        flexFlow: "row wrap",
      }}
    >
      <Box display="flex" gap={1} mb={2}>
        <Typography variant="h4">Training Score</Typography>
        <InfoOutlined sx={{ color: theme.palette.text.tableHeader, fontSize: "18px" }} />
      </Box>

      <CustomRadialBar subTitle="" count={"80"} value={80} />
      <CustomTextButton sx={{ mt: 4, textAlign: "left" }}>Show more</CustomTextButton>
    </Box>
  );
}
