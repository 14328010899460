import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, useTheme } from "@mui/material";
import CustomIconButton from "../../../components/CustomIconButton";
import { NOTIFICATIONS } from "../../../utils/constants";
import NotificationPopupCard from "./NotificationPopupCard";

export interface NotificationsPopupProps {
  handleClose: () => void;
}

export default function NotificationsPopup({ handleClose }: NotificationsPopupProps) {
  const theme = useTheme();

  return (
    <Box minWidth="24rem" maxHeight="24rem" pb={2}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body1">New Notifications (18)</Typography>
        <CustomIconButton onClick={handleClose} sx={{ color: theme.palette.error.main }}>
          <CloseIcon />
        </CustomIconButton>
      </Box>
      <Box sx={{ mt: 2, maxHeight: "22rem", overflowY: "scroll" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {NOTIFICATIONS.map(({ category, id, label, read, text, timestamp }) => (
            <NotificationPopupCard
              read={read}
              key={id}
              category={category}
              label={label}
              text={text}
              timestamp={timestamp.toTimeString().slice(0, 5)}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
