import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box, Grid, useTheme } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../components/CustomButton";
import CustomFilterDrawer from "../../../../components/CustomFilterDrawer";
import CustomIconButton from "../../../../components/CustomIconButton";
import CustomTab from "../../../../components/CustomTab";
import { analysisActions } from "../../../../store/analysis/analysisSlice";
import Entities from "./Components/Entities";
import UserDemographics from "./Components/UserDemographics";

export default function MapSection() {
  const dispatch = useDispatch();

  const { mapFilter } = useSelector((state: any) => state.analysis);

  const tabs = [
    {
      id: "entities",
      title: "Entities",
      disabled: false,
      component: <Entities imgSrc={"/assets/map.png"} />,
    },
    {
      id: "demographics",
      title: "User Demographics",
      disabled: false,
      component: <UserDemographics imgSrc={"/assets/map.png"} />,
    },
  ];
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleFilterApply = (data: any) => {
    dispatch(analysisActions.addMapFilter(data));
  };

  const handleReset = (data: any) => {
    dispatch(analysisActions.addMapFilter(data));
  };

  return (
    <Box sx={{ borderTop: "1px solid", borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1` }}>
      <Grid container spacing={2} pt={2}>
        {drawerOpen && (
          <Grid item xs={5} lg={3}>
            <CustomFilterDrawer
              filterData={mapFilter}
              openDrawer={drawerOpen}
              closePlayCamerasDrawer={handleDrawerOpen}
              handleFilterApply={handleFilterApply}
              handleReset={handleReset}
            />
          </Grid>
        )}
        <Grid item xs={drawerOpen ? 7 : 12} lg={drawerOpen ? 9 : 12}>
          <Box display="flex" gap={2} width={"100%"} mt={2}>
            {!drawerOpen && (
              <CustomButton
                endIcon={<ChevronRightIcon />}
                sx={{
                  [theme.breakpoints.down("lg")]: {
                    display: "none",
                  },
                }}
                onClick={handleDrawerOpen}
              >
                Filters
              </CustomButton>
            )}
            {!drawerOpen && (
              <CustomIconButton
                sx={{
                  width: "36px",
                  height: "36px",
                  color: theme.palette.text.default,
                  [theme.breakpoints.up("lg")]: {
                    display: "none",
                  },
                }}
                onClick={handleDrawerOpen}
              >
                <FilterAltIcon />
              </CustomIconButton>
            )}
            <CustomTab
              tabs={tabs}
              handleActiveTab={handleActiveTab}
              sx={{
                border: `1px solid ${theme.palette.primary.tertiary}`,
                textAlign: "center",
                borderRadius: "6px",
                p: "0 2px",
                alignItems: "center",
              }}
              activeTab={activeTab}
            />
          </Box>
          {tabs.find((tab) => tab.id === activeTab)?.component}
        </Grid>
      </Grid>
    </Box>
  );
}
