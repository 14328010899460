import { Box, useTheme } from "@mui/material";
import AvailableSolutions from "./AvailableSolutions";
import CurrentPlanUpgrades from "./CurrentPlanUpgrades";

export default function SolutionsSection() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 6,
      }}
    >
      <CurrentPlanUpgrades />
      <AvailableSolutions />
    </Box>
  );
}
