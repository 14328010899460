import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import analysisSlice from "./analysis/analysisSlice";
import magicEditSlice from "./analysis/magicEditSlice";
import authSlice from "./auth/authSlice";
import drawerSlice from "./drawer/drawerSlice";
import themeSlice from "./ui/themeSlice";
import uiSlice from "./ui/uiSlice";

const reducers = combineReducers({
  auth: authSlice.reducer,
  analysis: analysisSlice.reducer,
  theme: themeSlice.reducer,
  ui: uiSlice.reducer,
  drawer: drawerSlice.reducer,
  magicEdit: magicEditSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "auth/logout/fulfilled") {
    state = {
      theme: undefined,
    };
  }
  return reducers(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (
    getDefaultMiddleware // To remove response headers should not be non-serializable error while returning promise in action creators.
  ) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
export const persistor = persistStore(store);
