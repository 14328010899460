import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { MultiSelectValueType } from "../../../../../../components/CheckboxFilterButton";
import MultiSelectAutocomplete from "../../../../../../components/MultiSelectAutocomplete";

interface KeywordsProps {
  selectedValue: string[];
  isReset: boolean;
  getKeywords(values: string[]): void;
}

export default function Keywords({ selectedValue, isReset, getKeywords }: KeywordsProps) {
  const theme = useTheme();

  const [values, setValues] = useState<MultiSelectValueType[]>([]);

  const filterList: MultiSelectValueType[] = [
    {
      id: "1",
      text: "Weapons",
    },
    {
      id: "2",
      text: "Missile attack1",
    },
    {
      id: "3",
      text: "Missile attack2",
    },
    {
      id: "4",
      text: "Missile attack3",
    },
    {
      id: "5",
      text: "Missile attack4",
    },
  ];

  const handleUsersChange = (value: MultiSelectValueType[]) => {
    const newValues = value.map((data) => data.text);
    getKeywords(newValues);
  };

  useEffect(() => {
    if (selectedValue) {
      const newValues = filterList.filter((data) => selectedValue.includes(data?.text));
      setValues(newValues);
    }
  }, [selectedValue]);

  return (
    <Box mt={2}>
      <Typography variant="h4" color={theme.palette.text.tableHeader} mb={1}>
        Keywords
      </Typography>

      <MultiSelectAutocomplete
        options={filterList}
        placeholder="Select Keywords"
        sx={{ background: theme.palette.background.darkCardBackground }}
        handleAutoChange={(value: MultiSelectValueType[]) => handleUsersChange(value)}
        isReset={isReset}
        selectionData={values}
      />
    </Box>
  );
}
