import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import CustomCloseIconButton from "../../../../../components/CustomCloseIconButton";
import CustomTextButton from "../../../../../components/CustomTextButton";
import PublicationCard from "../../Feed/components/Publications/PublicationCard";

interface EntityProps {
  openDrawer(): void;
}

const publicationData = [
  {
    id: 1,
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    description:
      "One time I jokingly posted a photo of myself in a helicopter saying I was going to shoot down the chinese spy balloon and reddit basement dwellers lost their minds saying i was going to get people killed",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: [],
    user: {
      profile: "",
      name: "Derek Johnson",
      userid: "@rattletrap1776",
    },
  },
  {
    id: 2,
    title: "Chinese spy balloon was able to transmit information back to Beijing",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.cnn.com",
    description:
      "The Chinese spy balloon that transited the US earlier this year was able to capture imagery and collect some signals intelligence from US military sites, a source familiar with the matter tells CNN.The balloon was able to trans...",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: [],
  },
  {
    id: 3,
    title: "Chinese spy balloon was able to transmit information back to Beijing",
    created_at: "13.04.2023 14:07",
    media_image: "https://upload.wikimedia.org/wikipedia/commons/6/66/CNN_International_logo.svg",
    source_link: "www.cnn.com",
    description:
      "The Chinese spy balloon that transited the US earlier this year was able to capture imagery and collect some signals intelligence from US military sites, a source familiar with the matter tells CNN.The balloon was able to trans...",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: ["https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg"],
  },
  {
    id: 4,
    title: "Chinese spy balloon was able to transmit information back to Beijing",
    created_at: "13.04.2023 14:07",
    media_image: "https://upload.wikimedia.org/wikipedia/commons/6/66/CNN_International_logo.svg",
    source_link: "www.cnn.com",
    description:
      "The Chinese spy balloon that transited the US earlier this year was able to capture imagery and collect some signals intelligence from US military sites, a source familiar with the matter tells CNN.The balloon was able to trans...",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: [
      "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
      "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
      "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    ],
  },
  {
    id: 5,
    title: "Chinese spy balloon was able to transmit information back to Beijing",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.cnn.com",
    description:
      "The Chinese spy balloon that transited the US earlier this year was able to capture imagery and collect some signals intelligence from US military sites, a source familiar with the matter tells CNN.The balloon was able to trans...",
    relevance: "97%",
    authenticity: "97%",
    status: "low",
    image: [],
  },
  {
    id: 6,
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    description:
      "One time I jokingly posted a photo of myself in a helicopter saying I was going to shoot down the chinese spy balloon and reddit basement dwellers lost their minds saying i was going to get people killed",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: [],
    user: {
      profile: "",
      name: "Derek Johnson",
      userid: "@rattletrap1776",
    },
  },
  {
    id: 7,
    title: "Chinese spy balloon was able to transmit information back to Beijing",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.cnn.com",
    description:
      "The Chinese spy balloon that transited the US earlier this year was able to capture imagery and collect some signals intelligence from US military sites, a source familiar with the matter tells CNN.The balloon was able to trans...",
    relevance: "97%",
    authenticity: "97%",
    status: "high",
    image: [],
  },
];

export default function Publications({ openDrawer }: EntityProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "430px",
        height: "100%",
        position: "absolute",
        overflow: "hidden",
        right: "16px",
        top: "216px",
        zIndex: 10000,
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        borderRadius: 2,
        p: 2,
      }}
    >
      <Box display="flex" gap={5}>
        <Typography variant="h3" fontSize="22px">
          Location Name
        </Typography>
        <CustomCloseIconButton onClick={openDrawer} sx={{ ml: "auto" }}>
          <CloseIcon sx={{ width: "16px", height: "16px" }} />
        </CustomCloseIconButton>
      </Box>
      <Typography variant="body5">Description</Typography>
      <Box display="flex" gap={2} mt={3}>
        <Typography variant="h4">Publications (319)</Typography>
        <CustomTextButton sx={{ ml: "auto" }}>View All</CustomTextButton>
      </Box>
      <Box
        mt={2}
        sx={{
          height: "100%",
          overflow: "auto",
        }}
      >
        {publicationData.map((data: any, idx) => (
          <Grid key={`image-${idx}`} item xs={12} display="flex" mb={2}>
            <PublicationCard cardData={data} view={"grid"} />
          </Grid>
        ))}
      </Box>
    </Box>
  );
}
