import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import Xarrow, { Xwrapper } from "react-xarrows";
import { MultiSelectValueType } from "../../../../components/CheckboxFilterButton";
import ActiveDots from "./ActiveDots";
import CustomStack from "./CustomStack";
import { QueryFormData } from "./QuerySection";

interface PipelineProps {
  canvasData?: QueryFormData;
  models?: MultiSelectValueType[];
  sourceType?: string;
  uploadedData?: any;
}

export interface ArrowsPoint {
  label?: string;
  id: string | undefined;
  active: boolean | undefined;
}

export default function PipelineCreation({ canvasData, models, sourceType, uploadedData }: PipelineProps) {
  const [activeSourceData, setActiveSourceData] = useState<ArrowsPoint>();
  const [activeModels, setActiveModels] = useState<any>([]);

  const theme = useTheme();

  const sourceActiveData = () => {
    setActiveSourceData((prev) => ({ id: sourceType, active: !prev?.active }));
  };

  const modelsActiveData = (selected: string) => {
    if (activeModels && activeModels.length > 0) {
      const modelsData = activeModels?.map((model: any) => {
        if (selected === model?.id) {
          model.active = !model.active;
        }
        return model;
      });
      setActiveModels(modelsData);
    }
  };

  useEffect(() => {
    if (sourceType !== undefined) {
      setActiveSourceData((prev) => ({ id: sourceType, active: false }));
    }
    const modelsData = activeModels?.map((model: any) => {
      model.active = false;
      return model;
    });
    setActiveModels(modelsData);
  }, [sourceType]);

  useEffect(() => {
    setActiveModels(models);
  }, [models]);

  let boxStyle: React.CSSProperties = {
    width: "100%",
    background: `linear-gradient(to right, ${theme.palette.action.disabled} 50%, transparent 0%) top repeat-x, linear-gradient(${theme.palette.action.disabled} 50%, transparent 0%) right repeat-y,linear-gradient(to right, ${theme.palette.action.disabled} 50%, transparent 0%) bottom repeat-x, linear-gradient(${theme.palette.action.disabled} 50%, transparent 0%) left repeat-y`,
    backgroundSize: "10px 1px, 1px 10px",
    borderRadius: 2,
    height: "390px",
    padding: 2,
    marginTop: 2,
  };

  return (
    <Box sx={{ boxShadow: theme.palette.dashboardContainer.customCardInsetBoxShadow, borderRadius: "16px 16px 0 0", mt: 1 }}>
      <Box
        sx={{
          background: theme.palette.text.secondaryText,
          borderRadius: "16px 16px 0 0",
          p: 3,
          boxShadow: "3px 2px 12px 0px #161926 inset, -3px -2px 3px 0px #282F47 inset",
        }}
      >
        <Typography>Canvas</Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          height: "100%",
          mt: 1,
        }}
      >
        <Xwrapper>
          <Grid item xs={6}>
            <Box pl={3} pb={2}>
              <Box display="flex" gap={1}>
                <TopicOutlinedIcon sx={{ fontSize: "18px" }} />
                <Typography variant="h4">Data Sources</Typography>
              </Box>
              <Box sx={boxStyle}>
                {sourceType === undefined || sourceType === "" ? (
                  <Box textAlign="center" width="50%" mx="auto" mt={5}>
                    <Typography variant="caption" color={theme.palette.text.tableHeader}>
                      The Data Sources you selected will be displayed here
                    </Typography>
                  </Box>
                ) : (
                  <Box display="inline-flex" width="auto">
                    <CustomStack
                      label={sourceType === "image" ? "images" : "files"}
                      count={uploadedData.length || 0}
                      icon={<TopicOutlinedIcon sx={{ color: theme.palette.text.titleLabel, fontSize: "18px", mt: "-2px" }} />}
                      id={activeSourceData?.id}
                      handleClick={sourceActiveData}
                      sx={{
                        background: activeSourceData?.active ? theme.palette.background.dark : theme.palette.action.disabled,
                        border: activeSourceData?.active ? `1px solid ${theme.palette.error.main}` : "none",
                      }}
                    />
                    {activeSourceData?.active && <ActiveDots />}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} position="relative">
            <Box pr={3} pb={2}>
              <Box display="flex" gap={1}>
                <DiamondOutlinedIcon sx={{ fontSize: "18px" }} />
                <Typography variant="h4">AI Models</Typography>
              </Box>
              <Box sx={boxStyle}>
                {activeModels?.length === 0 ? (
                  <Box textAlign="center" width="50%" mx="auto" mt={5}>
                    <Typography variant="caption" color={theme.palette.text.tableHeader}>
                      The AI Models you selected will be displayed here
                    </Typography>
                  </Box>
                ) : (
                  <>
                    {models?.map((model: any) => {
                      return (
                        <React.Fragment key={model?.id}>
                          <Box display="flex">
                            {model?.active && <ActiveDots />}
                            <CustomStack
                              label={model?.text}
                              icon={<DiamondOutlinedIcon sx={{ color: theme.palette.text.titleLabel, fontSize: "18px", mt: "-2px" }} />}
                              id={`${model?.id}`}
                              handleClick={() => modelsActiveData(model?.id)}
                              sx={{
                                background: model?.active ? theme.palette.background.dark : theme.palette.action.disabled,
                                border: model?.active ? `1px solid ${theme.palette.text.tableHeader}` : "none",
                              }}
                            />
                          </Box>
                          {model?.active && activeSourceData?.active && (
                            <Xarrow
                              start={`${activeSourceData?.id}`}
                              end={`${model?.id}`}
                              headShape={"circle"}
                              headSize={1}
                              arrowHeadProps={{ fill: "transparent", strokeWidth: "0.1", stroke: "CornflowerBlue" }}
                              curveness={0.4}
                              strokeWidth={6}
                              path={"smooth"}
                              zIndex={1000}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Xwrapper>
      </Grid>
    </Box>
  );
}
