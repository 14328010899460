import HomeIcon from "@mui/icons-material/Home";
import { Box, Container, IconButton, Typography } from "@mui/material";

export default function NotFound() {
  return (
    <>
      <Box
        style={{
          margin: "auto",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Container maxWidth="md">
          <IconButton color="inherit" href="/" style={{ marginBottom: "20px" }}>
            <HomeIcon />
          </IconButton>
          <Box sx={{ textAlign: "center" }}>
            <Typography>Page Not Found</Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}
