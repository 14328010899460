import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Grid, Paper, Typography, useTheme } from "@mui/material";
import { TfiExport } from "react-icons/tfi";
import { useParams } from "react-router-dom";
import CustomButton from "../../../../../../components/CustomButton";
import CustomTable from "../../../../../../components/CustomTable";
import { routes } from "../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../routes/hooks";

const tableData = [
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    factor: "Neutral",
    description: "Unusual online activities, Frequent account creation or changes",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    factor: "High",
    description: "Unusual online activities, Frequent account creation or changes",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    factor: "High",
    description: "Unusual online activities, Frequent account creation or changes",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "UK",
    factor: "High",
    description: "Unusual online activities, Frequent account creation or changes",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    factor: "High",
    description: "Unusual online activities, Frequent account creation or changes",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    factor: "Neutral",
    description: "Unusual online activities, Frequent account creation or changes",
  },
  {
    name: "Entity name11",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    factor: "Low",
    description: "Unusual online activities, Frequent account creation or changes",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    factor: "Extreme",
    description: "Unusual online activities, Frequent account creation or changes",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    factor: "High",
    description: "Unusual online activities, Frequent account creation or changes",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    factor: "High",
    description: "Unusual online activities, Frequent account creation or changes",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    factor: "High",
    description: "Unusual online activities, Frequent account creation or changes",
  },
  {
    name: "Entity name",
    type: "Organization",
    subtype: "Non-Goverment Organization",
    region: "Asia",
    factor: "High",
    description: "Unusual online activities, Frequent account creation or changes",
  },
];

const header = [
  { id: "name", label: "Entity Name", searchEnable: true, minWidth: 100 },
  { id: "type", label: "Entity Type", searchEnable: true, minWidth: 100 },
  { id: "subtype", label: "Entity SubType", searchEnable: true, minWidth: 100 },
  { id: "region", label: "Region", searchEnable: true, minWidth: 100 },
  { id: "factor", label: "Behavioral Risk Factor", searchEnable: true, type: "button", minWidth: 100 },
  { id: "description", label: "Description", searchEnable: true, minWidth: 100 },
];

export default function RiskFactorDetails() {
  const theme = useTheme();
  const params = useParams();
  const handleRouteClick = useHandleRouteClick();

  const backHandler = () => {
    handleRouteClick(routes.analytics.path);
  };

  return (
    <Grid container sx={{ position: "relative" }}>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "left",
              padding: "5px",
            }}
          >
            <Typography variant="h3" fontWeight="bold" sx={{ marginLeft: "10px", color: theme.palette.text.titleLabel }} display={"flex"}>
              {params.riskfactor} Risk Factor
            </Typography>

            <CustomButton
              onClick={backHandler}
              startIcon={<KeyboardArrowLeftIcon sx={{ mt: "-3px", mr: -1 }} />}
              varient="text"
              sx={{
                ...theme.typography.bigButton,
                lineHeight: "110%",
                cursor: "pointer",
                marginTop: "5px",
                display: "flex",
                justifyContent: "left",
                background: "transparent",
                border: "none",
                boxShadow: "none",
                "&:hover": {
                  background: "transparent",
                },
                pl: 0,
              }}
            >
              Back to Overview
            </CustomButton>
          </Box>
          <Box display="flex" gap={3} px={1} height="40px">
            <CustomButton
              startIcon={<AutoFixHighIcon />}
              sx={{
                ...theme.typography.bigButton,
                background: theme.palette.background.secondaryBgColor,
                color: theme.palette.text.secondaryText,
                width: "123px",
              }}
            >
              Magic Edit
            </CustomButton>
            <CustomButton
              variant="contained"
              sx={{
                ...theme.typography.h6,
                border: `0.5px solid ${theme.palette.additionalColors.border1Light}`,
                display: "flex",
                gap: 1,
                alignItems: "center",
                color: theme.palette.primary.main,
                background: theme.palette.background.tertiaryDarkGradient,
                boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
                "&:hover": {
                  background: theme.palette.background.tertiaryGradient,
                  boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
                },
              }}
              startIcon={<TfiExport style={{ width: "12px", height: "12px", marginBottom: "5px" }} />}
            >
              Export Report
            </CustomButton>
          </Box>
        </Box>

        <Paper
          sx={{
            background: theme.palette.background.default,
            boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
            borderRadius: "18px",
            padding: "20px 40px",
            height: "85vh",
            overflowY: "scroll",
          }}
        >
          <Box textAlign="center" mb={2}>
            <Typography variant="h5" color={theme.palette.text.titleLabel}>
              {tableData.length} items
            </Typography>
          </Box>
          <CustomTable header={header} tableData={tableData} rows={10} pagination={true} search={true} />
        </Paper>
      </Grid>
    </Grid>
  );
}
