import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Grid, SxProps, Theme, Typography, useTheme } from "@mui/material";
import React from "react";

interface StackProps {
  label: string;
  count?: number;
  icon?: React.ReactNode;
  id?: string;
  handleClick?(): void;
  sx?: SxProps<Theme>;
}

export default function CustomStack({ label, count, icon, id, handleClick, sx }: StackProps) {
  const theme = useTheme();

  return (
    <Grid container mb={2} onClick={handleClick}>
      <Box sx={{ display: "flex", gap: 0.5, width: "auto", background: theme.palette.action.disabled, borderRadius: "30px", p: 1.2, ...sx }} id={id}>
        {icon}
        {count && (
          <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
            {count}
          </Typography>
        )}
        <Typography variant="subtitle1" color={theme.palette.text.titleLabel} sx={{ textTransform: "capitalize", mt: count === 0 ? "3px" : 0 }}>
          {label}
        </Typography>
        <MoreVertIcon sx={{ color: theme.palette.text.titleLabel, fontSize: "18px", mt: "-2px" }} />
      </Box>
    </Grid>
  );
}
