import { Box } from "@mui/material";
import CurrentPlanDetails from "./CurrentPlanDetails";
import OtherPlans from "./OtherPlans";

export default function Plan() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <CurrentPlanDetails />
      <OtherPlans />
    </Box>
  );
}
