export const BASE_PROTOCOL = "https";
export const BASE_HOST = `server.skylarklabs.ai`;
export const AUTH_SERVICE_BASE_URL: string = `${BASE_PROTOCOL}://${BASE_HOST}`;

export const TESTING: boolean = true;

export const SOLUTIONS = [
  {
    id: "news_analysis",
    title: "News Analysis",
    thumbnail: "/solutions/news_analysis.png",
    description:
      "Social media analysis enables organizations, businesses, and individuals to make data-driven decisions, improve marketing strategies, enhance customer engagement, identify opportunities or risks, and gain a deeper understanding of their target audience.",
  },
  {
    id: "social-media-analysis",
    title: "Social Media Analysis",
    thumbnail: "/solutions/social_media_analysis.png",
    description:
      "Social media analysis enables organizations, businesses, and individuals to make data-driven decisions, improve marketing strategies, enhance customer engagement, identify opportunities or risks, and gain a deeper understanding of their target audience.",
  },
  {
    id: "supply-chain-analysis",
    title: "Supply Chain Analysis",
    thumbnail: "/solutions/supply_chain_analysis.png",
    description:
      "Social media analysis enables organizations, businesses, and individuals to make data-driven decisions, improve marketing strategies, enhance customer engagement, identify opportunities or risks, and gain a deeper understanding of their target audience.",
  },
  {
    id: "macroeconomy-analysis",
    title: "Macroeconomy Analysis",
    thumbnail: "/solutions/macro_economy_analysis.png",
    description:
      "Social media analysis enables organizations, businesses, and individuals to make data-driven decisions, improve marketing strategies, enhance customer engagement, identify opportunities or risks, and gain a deeper understanding of their target audience.",
  },
  {
    id: "competitive-analysis",
    title: "Competitive Analysis",
    thumbnail: "/solutions/competitive_analysis.png",
    description:
      "Social media analysis enables organizations, businesses, and individuals to make data-driven decisions, improve marketing strategies, enhance customer engagement, identify opportunities or risks, and gain a deeper understanding of their target audience.",
  },
];

const SOLUTION_INCLUDES = [
  {
    dataSources: [
      { title: "Twitter", thumbnail: "/assets/solution.png", added: false },
      { title: "Instagram", thumbnail: "/assets/solution.png", added: false },
      { title: "Facebook", thumbnail: "/assets/solution.png", added: false },
      { title: "Threads", thumbnail: "/assets/solution.png", added: false },
      { title: "Weibo", thumbnail: "/assets/solution.png", added: false },
      { title: "Weibo", thumbnail: "/assets/solution.png", added: false },
      { title: "Weibo", thumbnail: "/assets/solution.png", added: false },
    ],
  },
  {
    aiModels: [
      { title: "Entity Recognition", thumbnail: "/assets/solution.png", added: true },
      { title: "Entity Recognition", thumbnail: "/assets/solution.png", added: true },
      { title: "Entity Recognition", thumbnail: "/assets/solution.png", added: true },
      { title: "Entity Recognition", thumbnail: "/assets/solution.png", added: false },
      { title: "Entity Recognition", thumbnail: "/assets/solution.png", added: false },
      { title: "Entity Recognition", thumbnail: "/assets/solution.png", added: false },
      { title: "Entity Recognition", thumbnail: "/assets/solution.png", added: false },
    ],
  },
];

export const AVAILABLE_SOLUTIONS = [
  {
    id: 1,
    title: "Macroeconomy Analysis",
    subtitle: "Company Research",
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
    solutions: [...SOLUTION_INCLUDES],
  },
  {
    id: 2,
    title: "Competitive Analysis",
    subtitle: "Company Research",
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
    solutions: [...SOLUTION_INCLUDES],
  },
  {
    id: 3,
    title: "Social Media Analysis",
    subtitle: "Company Research",
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
    solutions: [...SOLUTION_INCLUDES],
  },
  {
    id: 4,
    title: "Social Media Analysis",
    subtitle: "Company Research",
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
    solutions: [...SOLUTION_INCLUDES],
  },
  {
    id: 5,
    title: "Social Media Analysis",
    subtitle: "Company Research",
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
    solutions: [...SOLUTION_INCLUDES],
  },
  {
    id: 6,
    title: "Social Media Analysis",
    subtitle: "Company Research",
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
    solutions: [...SOLUTION_INCLUDES],
  },
  {
    id: 7,
    title: "Social Media Analysis",
    subtitle: "Company Research",
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
    solutions: [...SOLUTION_INCLUDES],
  },
  {
    id: 8,
    title: "Social Media Analysis",
    subtitle: "Company Research",
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
    solutions: [...SOLUTION_INCLUDES],
  },
  {
    id: 9,
    title: "Social Media Analysis",
    subtitle: "Company Research",
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
    solutions: [...SOLUTION_INCLUDES],
  },
];

export const AVAILABLE_AI_MODELS = [
  {
    id: 1,
    title: "Macroeconomy Analysis",
    solutions: [
      {
        id: 1,
        title: "Text Summarization",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 2,
        title: "Text Abstraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 3,
        title: "Text Translation",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 4,
        title: "Entity Recognition",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 5,
        title: "Event Extraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
    ],
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
  },
  {
    id: 2,
    title: "Competitive Analysis",
    solutions: [
      {
        id: 1,
        title: "Text Summarization",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 2,
        title: "Text Abstraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 3,
        title: "Text Translation",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 4,
        title: "Entity Recognition",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 5,
        title: "Event Extraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
    ],
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
  },
  {
    id: 3,
    title: "Social Media Analysis",
    solutions: [
      {
        id: 1,
        title: "Text Summarization",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 2,
        title: "Text Abstraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 3,
        title: "Text Translation",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 4,
        title: "Entity Recognition",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 5,
        title: "Event Extraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
    ],
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
  },
  {
    id: 4,
    title: "Social Media Analysis",
    solutions: [
      {
        id: 1,
        title: "Text Summarization",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 2,
        title: "Text Abstraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 3,
        title: "Text Translation",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 4,
        title: "Entity Recognition",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 5,
        title: "Event Extraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
    ],
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
  },
  {
    id: 5,
    title: "Social Media Analysis",
    solutions: [
      {
        id: 1,
        title: "Text Summarization",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 2,
        title: "Text Abstraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 3,
        title: "Text Translation",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 4,
        title: "Entity Recognition",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 5,
        title: "Event Extraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
    ],
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
  },
  {
    id: 6,
    title: "Social Media Analysis",
    solutions: [
      {
        id: 1,
        title: "Text Summarization",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 2,
        title: "Text Abstraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 3,
        title: "Text Translation",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 4,
        title: "Entity Recognition",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 5,
        title: "Event Extraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
    ],
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
  },
  {
    id: 7,
    title: "Social Media Analysis",
    solutions: [
      {
        id: 1,
        title: "Text Summarization",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 2,
        title: "Text Abstraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 3,
        title: "Text Translation",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 4,
        title: "Entity Recognition",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 5,
        title: "Event Extraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
    ],
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
  },
  {
    id: 8,
    title: "Social Media Analysis",
    solutions: [
      {
        id: 1,
        title: "Text Summarization",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 2,
        title: "Text Abstraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 3,
        title: "Text Translation",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 4,
        title: "Entity Recognition",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 5,
        title: "Event Extraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
    ],
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
  },
  {
    id: 9,
    title: "Social Media Analysis",
    solutions: [
      {
        id: 1,
        title: "Text Summarization",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 2,
        title: "Text Abstraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 3,
        title: "Text Translation",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 4,
        title: "Entity Recognition",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
      {
        id: 5,
        title: "Event Extraction",
        thumbnail: "/solutions/solution.png",
        description:
          "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
      },
    ],
    thumbnail: "/solutions/solution.png",
    price: "$200",
    description:
      "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
  },
];

export const SOCIAL_MEDIA_DATA_SOURCES = [
  { id: "twitter", text: "Twitter", active: false },
  { id: "facebook", text: "Facebook", active: false },
  { id: "instagram", text: "Instagram", active: false },
  { id: "threads", text: "Threads", active: false },
];

export const AI_MODELS = [
  {
    id: "face-recognition",
    name: "Face recognition",
    description:
      "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
  },
  {
    id: "facebook",
    name: "Translation",
    description:
      "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
  },
  {
    id: "instagram",
    name: "Abstraction",
    description:
      "Identifies and extracts named entities or specific entities from unstructured text data. It focuses on recognizing and classifying entities such as names of people, organizations, locations, dates, numerical values, or other significant elements mentioned in the text.",
  },
];

export const RISK_FACTORS = [
  { id: "financial-risk-factors", name: "Financial Risk Factors" },
  { id: "privacy-and-security-risk-factors", name: "Privacy and Security Risk Factors" },
  { id: "network-risk-factors", name: "Network Risk Factors" },
  { id: "communication-risk-factors", name: "Communication Risk Factors" },
  { id: "content-risk-factors", name: "Content Risk Factors" },
];

export const FAVORITE_QUERIES = [
  {
    id: 1,
    name: "Chineese Balloon",
    isLiveMonitoring: true,
    dataSource: "Twitter, News, Documents, Reddit",
    aiModels: "Text Abstraction, Sentiment Analysis, Fake News Detection",
    resultsCount: 234,
    daetime: new Date(),
  },
  {
    id: 2,
    name: "Chineese Balloon",
    isLiveMonitoring: true,
    dataSource: "Twitter, News, Documents, Reddit",
    aiModels: "Text Abstraction, Sentiment Analysis, Fake News Detection",
    resultsCount: 234,
    daetime: new Date(),
  },
  {
    id: 3,
    name: "Chineese Balloon",
    isLiveMonitoring: true,
    dataSource: "Twitter, News, Documents, Reddit",
    aiModels: "Text Abstraction, Sentiment Analysis, Fake News Detection",
    resultsCount: 234,
    daetime: new Date(),
  },
  {
    id: 4,
    name: "Chineese Balloon",
    isLiveMonitoring: true,
    dataSource: "Twitter, News, Documents, Reddit",
    aiModels: "Text Abstraction, Sentiment Analysis, Fake News Detection",
    resultsCount: 234,
    daetime: new Date(),
  },
  {
    id: 5,
    name: "Chineese Balloon",
    isLiveMonitoring: true,
    dataSource: "Twitter, News, Documents, Reddit",
    aiModels: "Text Abstraction, Sentiment Analysis, Fake News Detection",
    resultsCount: 234,
    daetime: new Date(),
  },
  {
    id: 6,
    name: "Chineese Balloon",
    isLiveMonitoring: true,
    dataSource: "Twitter, News, Documents, Reddit",
    aiModels: "Text Abstraction, Sentiment Analysis, Fake News Detection",
    resultsCount: 234,
    daetime: new Date(),
  },
  {
    id: 7,
    name: "Chineese Balloon",
    isLiveMonitoring: true,
    dataSource: "Twitter, News, Documents, Reddit",
    aiModels: "Text Abstraction, Sentiment Analysis, Fake News Detection",
    resultsCount: 234,
    daetime: new Date(),
  },
  {
    id: 8,
    name: "Chineese Balloon",
    isLiveMonitoring: true,
    dataSource: "Twitter, News, Documents, Reddit",
    aiModels: "Text Abstraction, Sentiment Analysis, Fake News Detection",
    resultsCount: 234,
    daetime: new Date(),
  },
  {
    id: 9,
    name: "Chineese Balloon",
    isLiveMonitoring: true,
    dataSource: "Twitter, News, Documents, Reddit",
    aiModels: "Text Abstraction, Sentiment Analysis, Fake News Detection",
    resultsCount: 234,
    daetime: new Date(),
  },
  {
    id: 10,
    name: "Chineese Balloon",
    isLiveMonitoring: true,
    dataSource: "Twitter, News, Documents, Reddit",
    aiModels: "Text Abstraction, Sentiment Analysis, Fake News Detection",
    resultsCount: 234,
    daetime: new Date(),
  },
];

export const queryTypeFilterOptions = [
  {
    id: "all",
    text: "All",
  },
  {
    id: "person",
    text: "Person",
  },
  {
    id: "organization",
    text: "Organization",
  },
  {
    id: "own-data-source",
    text: "Own data source",
  },
];

export const notificationCategoryFilterOptions = [
  {
    id: "all",
    text: "All Categories",
  },
  {
    id: "alert",
    text: "Alert",
  },
  {
    id: "system",
    text: "System & User",
  },
  {
    id: "query",
    text: "Query & Processing",
  },
];

export const solutionFilterOptions = [
  {
    id: "all",
    text: "All",
  },
  {
    id: "custom-case",
    text: "Custom case",
  },
  {
    id: "social-media-analysis",
    text: "Social media analysis",
  },
  {
    id: "company-analysis",
    text: "Company analysis",
  },
];

export const AI_Models_Option = [
  {
    id: "face-recognition",
    text: "Face Recognition",
    active: false,
  },
  {
    id: "entity-recognition",
    text: "Entity Recognition",
    active: false,
  },
  {
    id: "text-summary",
    text: "Text Summary",
    active: false,
  },
  {
    id: "image-recognition",
    text: "Image Recognition",
    active: false,
  },
  {
    id: "Video-to-Text Conversion",
    text: "Video-to-Text Conversion",
    active: false,
  },
  {
    id: "Sentiment Analysis",
    text: "Sentiment Analysis",
    active: false,
  },
];

export const DATA_SOURCES = [
  { id: "text", text: "Text", active: false },
  { id: "image", text: "Image", active: false },
  { id: "audio", text: "Audio", active: false },
  { id: "video", text: "Video", active: false },
];

export const CATEGORIES = [
  { id: "everything", text: "Everything", active: false },
  { id: "person", text: "Person", active: false },
  { id: "organization", text: "Organization", active: false },
  { id: "event", text: "Event", active: false },
];

export const SEARCH_AREA = [
  { id: "india", text: "India", active: false },
  { id: "usa", text: "USA", active: false },
  { id: "canada", text: "Canada", active: false },
  { id: "japan", text: "Japan", active: false },
];

export const FILE_FORMAT = [
  { id: "csv", text: "CSV", type: "text", active: false },
  { id: "xlsx", text: "XLSX", type: "text", active: false },
  { id: "jpeg", text: "JPEG", type: "image", active: false },
  { id: "png", text: "PNG", type: "image", active: false },
  { id: "gif", text: "GIF", type: "image", active: false },
  { id: "svg", text: "SVG", type: "image", active: false },
  { id: "mp3", text: "MP3", type: "audio", active: false },
  { id: "ogg", text: "OGG", type: "audio", active: false },
  { id: "mp4", text: "MP4", type: "video", active: false },
  { id: "webm", text: "WebM", type: "video", active: false },
];

export const CUSTOM_AI_MODEL = [
  {
    id: 1,
    name: "Car Recognition",
    createdAt: "17.05.23",
    status: "processing",
  },
  {
    id: 2,
    name: "Object Recognition",
    createdAt: "17.05.23",
    status: "trained",
  },
  { id: 3, name: "Color Recognition", createdAt: "17.05.23", status: "trained" },
  {
    id: 4,
    name: "Entity Recognition",
    createdAt: "17.05.23",
    status: "trained",
  },
];

export const DATABASE_SOURCES = [
  { id: "Azure", name: "Azure", url: "https://azure.microsoft.com" },
  { id: "Big_Query", name: "Big_Query", url: "https://azure.microsoft.com" },
  { id: "MySQL", name: "MySQL", url: "https://azure.microsoft.com" },
  { id: "MicrosoftSQL", name: "MicrosoftSQL", url: "https://azure.microsoft.com" },
];

export const NOTIFICATIONS = [
  {
    id: 1,
    label: "Alert",
    text: "New Red flagged words detected (45)",
    timestamp: new Date(),
    category: "alert" as const,
    read: false,
  },
  {
    id: 2,
    label: "Live Monitoring",
    text: "New Results on your request – Chinese balloon (126)",
    timestamp: new Date(),
    category: "query" as const,
    read: false,
  },
  {
    id: 3,
    label: "System Notification",
    text: "New User has joined workspace by your invitation",
    timestamp: new Date(),
    category: "system" as const,
    read: false,
  },
  {
    id: 4,
    label: "Alert",
    text: "User P. Laurens shared an activity with you. ",
    timestamp: new Date(),
    category: "alert" as const,
    read: true,
  },
  {
    id: 5,
    label: "System Notification",
    text: "New User has joined workspace by your invitation",
    timestamp: new Date(),
    category: "system" as const,
    read: true,
  },
  {
    id: 6,
    label: "System Notification",
    text: "New User has joined workspace by your invitation",
    timestamp: new Date(),
    category: "system" as const,
    read: true,
  },
];

export const RegionFilterOption = [
  {
    id: "region1",
    text: "Region 1",
  },
  {
    id: "region2",
    text: "Region 2",
  },
  {
    id: "region3",
    text: "Region 3",
  },
];

export const RiskFactor = [
  {
    id: "factor1",
    text: "Factor 1",
  },
  {
    id: "factor2",
    text: "Factor 2",
  },
  {
    id: "factor3",
    text: "Factor 3",
  },
];

export const EntitySubType = [
  {
    id: "all",
    text: "All",
  },
  {
    id: "government-sector",
    text: "Government sector",
  },
  {
    id: "business-sector",
    text: "Business sector",
  },
  { id: "influencers", text: "Influencers" },
  { id: "media-persons", text: "Media persons" },
  { id: "civilians", text: "Civilians" },
];

export const Persons = [
  "All",
  "Simon Ateba",
  "Joseph Biden",
  "Xi Jinping",
  "Marcus Aminoff",
  "Lydia Siphron",
  "Alena Rosser",
  "Tatiana Saris",
  "Makenna Geidt",
  "Makenna Calzoni",
  "Leo Lubin",
  "Jordyn Korsgaard",
  "Tiana Lipshutz",
  "Zaire Bator1",
  "Zaire Bator2",
  "Zaire Bator3",
  "Zaire Bator4",
  "Zaire Bator5",
  "Zaire Bator6",
  "Zaire Bator7",
  "Zaire Bator8",
  "Zaire Bator9",
  "Zaire Bator10",
  "Zaire Bator11",
];

// Entities
export const RISK_SCORE = [
  { id: "extreme", text: "Extreme" },
  { id: "high", text: "High" },
  { id: "moderate", text: "Moderate" },
  { id: "neutral", text: "Neutral" },
  { id: "low", text: "Low" },
];
export const ENTITIES_SUBTYPE = [
  { id: "city", text: "City" },
  { id: "country", text: "Country" },
  { id: "region", text: "Region" },
  { id: "location", text: "Location" },
];

export const FAQS = [
  {
    id: 1,
    title: "Getting Started",
    description: "Articles that will assist users with initial setup, registration, and account activation.",
    icon: "extension",
  },
  {
    id: 2,
    title: "Platform Navigation",
    description: "Articles providing guidance on how to navigate the platform's interface.",
    icon: "navigation",
  },
  {
    id: 3,
    title: "About AI",
    description: "Articles that will help users with the process of training AI models.",
    icon: "about",
  },
  {
    id: 4,
    title: "Performance Optimization",
    description: "Articles providing tips and techniques to optimize AI model performance.",
    icon: "optimization",
  },
  {
    id: 5,
    title: "Model Management",
    description: "Articles explaining how to manage multiple versions of AI models",
    icon: "model",
  },
  {
    id: 6,
    title: "Performance monitoring",
    description: "Articles explaining how to manage multiple versions of AI models",
    icon: "monitor",
  },
];

export const SpecificFAQ = [
  {
    id: 1,
    question: "What the Low Code system is?",
    ans: "If you can keep your head when all about you Are losing theirs and blaming it on you, If you can trust yourself when all men doubt you, But make allowance for their doubting too; If you can wait and not be tired by waiting, Or being lied about, don’t deal in lies",
  },
  {
    id: 2,
    question: "What the Low Code system is?",
    ans: "If you can keep your head when all about you Are losing theirs and blaming it on you, If you can trust yourself when all men doubt you, But make allowance for their doubting too; If you can wait and not be tired by waiting, Or being lied about, don’t deal in lies",
  },
  {
    id: 3,
    question: "What the Low Code system is?",
    ans: "If you can keep your head when all about you Are losing theirs and blaming it on you, If you can trust yourself when all men doubt you, But make allowance for their doubting too; If you can wait and not be tired by waiting, Or being lied about, don’t deal in lies",
  },
  {
    id: 4,
    question: "What the Low Code system is?",
    ans: "If you can keep your head when all about you Are losing theirs and blaming it on you, If you can trust yourself when all men doubt you, But make allowance for their doubting too; If you can wait and not be tired by waiting, Or being lied about, don’t deal in lies",
  },
  {
    id: 5,
    question: "What the Low Code system is?",
    ans: "If you can keep your head when all about you Are losing theirs and blaming it on you, If you can trust yourself when all men doubt you, But make allowance for their doubting too; If you can wait and not be tired by waiting, Or being lied about, don’t deal in lies",
  },
  {
    id: 6,
    question: "What the Low Code system is?",
    ans: "If you can keep your head when all about you Are losing theirs and blaming it on you, If you can trust yourself when all men doubt you, But make allowance for their doubting too; If you can wait and not be tired by waiting, Or being lied about, don’t deal in lies",
  },
];

export const ARTICLES = [
  {
    id: 1,
    title: "Aerial Systems",
    articles: [
      { id: 1, name: "Missile Reconnaissance and Tracking System " },
      { id: 2, name: "Drones Reconnaissance and Observation System" },
      { id: 3, name: "Satellite Change Detection Systems" },
      { id: 4, name: "Airborne Systems" },
      { id: 5, name: "Aerial Systems Hardware. Hardware Specifications and Maintenance" },
    ],
  },
  {
    id: 2,
    title: "Aerial Systems",
    articles: [
      { id: 1, name: "Missile Reconnaissance and Tracking System " },
      { id: 2, name: "Drones Reconnaissance and Observation System" },
      { id: 3, name: "Satellite Change Detection Systems" },
      { id: 4, name: "Airborne Systems" },
      { id: 5, name: "Aerial Systems Hardware. Hardware Specifications and Maintenance" },
    ],
  },
];

export const SPECIFIC_ARTICLE = {
  title: "Satellite Change Detection System",
  summary:
    "With a wide range of sensors and advanced imaging technologies, satellites provide valuable data for various applications, including environmental monitoring, disaster management, agriculture, and more.",
  topics: [
    {
      id: 1,
      title: "Description",
      description:
        "Satellites have revolutionized our ability to observe and monitor the Earth's surface from space. With a wide range of sensors and advanced imaging technologies, satellites provide valuable data for various applications, including environmental monitoring, disaster management, urban planning, agriculture, and more. Among the many applications of satellite imagery, one of the most critical is the use of Satellite Change Detection Systems.",
      image: "/assets/article.png",
      content:
        "These systems use artificial intelligence and machine learning algorithms to analyze historical and current satellite images, identifying changes and anomalies on the Earth's surface over time. In this article, we explore the significance of Satellite Change Detection Systems and their numerous applications across different domains. \n\n\n Satellite Change Detection Systems employ a combination of computer vision, image processing, and pattern recognition techniques to compare satellite images captured at different times. The primary objective is to identify and highlight changes that have occurred on the Earth's surface between the two points in time. These changes can range from natural events like forest cover changes, floods, and volcanic eruptions to human-induced alterations such as urban expansion, deforestation, and infrastructure development.",
    },
    {
      id: 2,
      title: "Product Capabilities",
      description:
        "Satellites have revolutionized our ability to observe and monitor the Earth's surface from space. With a wide range of sensors and advanced imaging technologies, satellites provide valuable data for various applications, including environmental monitoring, disaster management, urban planning, agriculture, and more. Among the many applications of satellite imagery, one of the most critical is the use of Satellite Change Detection Systems.",
      image: "/assets/article.png",
      content:
        "These systems use artificial intelligence and machine learning algorithms to analyze historical and current satellite images, identifying changes and anomalies on the Earth's surface over time. In this article, we explore the significance of Satellite Change Detection Systems and their numerous applications across different domains. \n\n\n Satellite Change Detection Systems employ a combination of computer vision, image processing, and pattern recognition techniques to compare satellite images captured at different times. The primary objective is to identify and highlight changes that have occurred on the Earth's surface between the two points in time. These changes can range from natural events like forest cover changes, floods, and volcanic eruptions to human-induced alterations such as urban expansion, deforestation, and infrastructure development.",
      subTopics: [
        {
          id: 1.1,
          title: "Vehicles Detection",
          description:
            "Satellites have revolutionized our ability to observe and monitor the Earth's surface from space. With a wide range of sensors and advanced imaging technologies, satellites provide valuable data for various applications, including environmental monitoring, disaster management, urban planning, agriculture, and more. Among the many applications of satellite imagery, one of the most critical is the use of Satellite Change Detection Systems.",
          image: "/assets/article.png",
          content:
            "These systems use artificial intelligence and machine learning algorithms to analyze historical and current satellite images, identifying changes and anomalies on the Earth's surface over time. In this article, we explore the significance of Satellite Change Detection Systems and their numerous applications across different domains. \n\n\n Satellite Change Detection Systems employ a combination of computer vision, image processing, and pattern recognition techniques to compare satellite images captured at different times. The primary objective is to identify and highlight changes that have occurred on the Earth's surface between the two points in time. These changes can range from natural events like forest cover changes, floods, and volcanic eruptions to human-induced alterations such as urban expansion, deforestation, and infrastructure development.",
        },
        {
          id: 1.2,
          title: "Traffic Monitoring",
          description:
            "Satellites have revolutionized our ability to observe and monitor the Earth's surface from space. With a wide range of sensors and advanced imaging technologies, satellites provide valuable data for various applications, including environmental monitoring, disaster management, urban planning, agriculture, and more. Among the many applications of satellite imagery, one of the most critical is the use of Satellite Change Detection Systems.",
          image: "/assets/article.png",
          content:
            "These systems use artificial intelligence and machine learning algorithms to analyze historical and current satellite images, identifying changes and anomalies on the Earth's surface over time. In this article, we explore the significance of Satellite Change Detection Systems and their numerous applications across different domains. \n\n\n Satellite Change Detection Systems employ a combination of computer vision, image processing, and pattern recognition techniques to compare satellite images captured at different times. The primary objective is to identify and highlight changes that have occurred on the Earth's surface between the two points in time. These changes can range from natural events like forest cover changes, floods, and volcanic eruptions to human-induced alterations such as urban expansion, deforestation, and infrastructure development.",
        },
      ],
    },
    {
      id: 3,
      title: "Environment Monitoring",
      description:
        "Satellites have revolutionized our ability to observe and monitor the Earth's surface from space. With a wide range of sensors and advanced imaging technologies, satellites provide valuable data for various applications, including environmental monitoring, disaster management, urban planning, agriculture, and more. Among the many applications of satellite imagery, one of the most critical is the use of Satellite Change Detection Systems.",
      image: "/assets/article.png",
      content:
        "These systems use artificial intelligence and machine learning algorithms to analyze historical and current satellite images, identifying changes and anomalies on the Earth's surface over time. In this article, we explore the significance of Satellite Change Detection Systems and their numerous applications across different domains. <br />Satellite Change Detection Systems employ a combination of computer vision, image processing, and pattern recognition techniques to compare satellite images captured at different times. The primary objective is to identify and highlight changes that have occurred on the Earth's surface between the two points in time. These changes can range from natural events like forest cover changes, floods, and volcanic eruptions to human-induced alterations such as urban expansion, deforestation, and infrastructure development.",
    },
    {
      id: 4,
      title: "Infrastructure Monitoring",
      description:
        "Satellites have revolutionized our ability to observe and monitor the Earth's surface from space. With a wide range of sensors and advanced imaging technologies, satellites provide valuable data for various applications, including environmental monitoring, disaster management, urban planning, agriculture, and more. Among the many applications of satellite imagery, one of the most critical is the use of Satellite Change Detection Systems.",
      image: "/assets/article.png",
      content:
        "These systems use artificial intelligence and machine learning algorithms to analyze historical and current satellite images, identifying changes and anomalies on the Earth's surface over time. In this article, we explore the significance of Satellite Change Detection Systems and their numerous applications across different domains. \n\n\n Satellite Change Detection Systems employ a combination of computer vision, image processing, and pattern recognition techniques to compare satellite images captured at different times. The primary objective is to identify and highlight changes that have occurred on the Earth's surface between the two points in time. These changes can range from natural events like forest cover changes, floods, and volcanic eruptions to human-induced alterations such as urban expansion, deforestation, and infrastructure development.",
    },
  ],
  relatedArticles: [
    { id: 1, title: "Missile Reconnaissance and Tracking System" },
    { id: 2, title: "Drones Reconnaissance and Observation System" },
    { id: 3, title: "Satellite Change Detection Systems" },
    { id: 4, title: "Aerial Systems Hardware. Hardware Specifications and Maintenance" },
  ],
};
