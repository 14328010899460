import { Box, Typography } from "@mui/material";
import EmailSettings from "./EmailSettings";
import NotificationSettings from "./NotificationSettings";
import RedFlaggedWordsSettings from "./RedFlaggedWordsSettings";

export default function SystemSection() {
  return (
    <Box>
      <Typography variant="h2" mb={3}>
        System
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <RedFlaggedWordsSettings />
        <NotificationSettings />
        <EmailSettings />
      </Box>
    </Box>
  );
}
