import { Box, Grid, Typography, useTheme } from "@mui/material";

export default function ModelDetails() {
  const theme = useTheme();

  const renderModelInfo = (label: string, name: string) => (
    <Box display="flex" flexDirection="column" textAlign="left">
      <Typography variant="subtitle2" color={theme.palette.text.tableHeader}>
        {label}
      </Typography>
      <Typography variant="caption" color={theme.palette.text.titleLabel}>
        {name}
      </Typography>
    </Box>
  );

  return (
    <Grid container spacing={2} width="100%">
      <Grid item xs={6} md={3} lg={2}>
        {renderModelInfo("Basic AI Model", "Entity Recognition")}
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        {renderModelInfo("Date type", "Image")}
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        {renderModelInfo("Customization method", "Data Labeling")}
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        {renderModelInfo("User", "Dan Ruby")}
      </Grid>
    </Grid>
  );
}
