import { Box, useTheme } from "@mui/material";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useRef, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import { getImageSize } from "react-image-size";
import { FeatureGroup, ImageOverlay, MapContainer, Marker, Popup, ZoomControl } from "react-leaflet";
import CustomImageNode from "../../../../../components/CustomImageNode";
import PublicationInfo from "./PublicationInfo";
import Publications from "./Publications";
import ScoreLegend from "./ScoreLegend";

interface UserDemographicsProps {
  imgSrc: string;
  showLegend?: boolean;
  controls?: boolean;
  containerHeight?: string;
}

type TextPalette = {
  extremeStatus: string;
  highStatus: string;
  moderateStatus: string;
  neutralStatus: string;
  lowStatus: string;
};

export default function UserDemographics({ imgSrc, showLegend = true, controls = true, containerHeight }: UserDemographicsProps) {
  const theme = useTheme();

  const [width, setWidth] = useState<number>(500);
  const [height, setHeight] = useState<number>(500);
  const [bounds, setBounds] = useState<[number, number][] | null>(null);
  const [isPopped, setIsPopped] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);

  const popupElRef = useRef(null);

  useEffect(() => {
    getImageSize(imgSrc).then(({ width, height }) => {
      setHeight(height);
      setWidth(width);
      setBounds([
        [0, 0],
        [height, width],
      ]);
    });
  }, [imgSrc]);

  if (!bounds) {
    return <></>;
  }

  const handleOpenDetails = () => {
    setOpenDetails((prev) => !prev);
  };

  const pos: any = [
    { id: "A", count: 54, position: [377.24216496970394, 289.31578063964844], status: "extreme", legend: "person" },
    { id: "B", count: 52, position: [387.1408199230135, 339.7105255126953], status: "high", legend: "topic" }, //to jpn
    { id: "C", count: 554, position: [377.21884851012425, 311.15789794921875], status: "neutral", legend: "event" },
    { id: "D", count: 554, position: [350.6004489281331, 278.9210433959961], status: "moderate", legend: "location" }, //to rus
    { id: "E", count: 53, position: [335.8838657613802, 317.47369384765625], status: "low", legend: "organization" },
  ];

  return (
    <Box mt={2} width="100%" height={containerHeight || "55vh"}>
      <Box width="100%" height="100%" borderRadius={2} overflow="scroll" position="relative">
        <MapContainer
          attributionControl={false}
          center={[height / 2, width / 2]}
          boxZoom
          zoom={1}
          minZoom={-10}
          scrollWheelZoom={false}
          style={{ height: "100%", width: "100%", backgroundColor: "transparent" }}
          crs={L.CRS.Simple}
          zoomControl={false}
        >
          <ImageOverlay bounds={bounds} url={imgSrc} />
          {controls && <ZoomControl position="bottomright" />}
          <FeatureGroup>
            {pos?.map((mark: any, i: number) => {
              const statusColor: any = `${mark?.status}Status`;
              return (
                <Marker
                  key={i}
                  position={mark?.position}
                  icon={L.divIcon({
                    className: "custom icon",
                    html: ReactDOMServer.renderToString(
                      <CustomImageNode
                        color={theme.palette.text[statusColor as keyof TextPalette]}
                        legend={mark?.legend}
                        icon={false}
                        count={mark?.count}
                      />
                    ),
                  })}
                >
                  <Popup
                    closeButton={false}
                    ref={popupElRef}
                    eventHandlers={{
                      add: () => {
                        setIsPopped(true);
                      },
                      remove: () => {
                        setIsPopped(false);
                      },
                    }}
                    className="info-popup"
                  >
                    {isPopped ? (
                      <Box
                        sx={{
                          height: "auto",
                          width: "120px",
                          position: "relative",
                          padding: 0,
                          margin: 0,
                          borderRadius: "10px",
                        }}
                      >
                        <PublicationInfo handleOpenDetails={handleOpenDetails} />
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Popup>
                </Marker>
              );
            })}
          </FeatureGroup>
        </MapContainer>
      </Box>
      {showLegend && <ScoreLegend sx={{ float: "right", mt: 1.5 }} />}
      {openDetails && <Publications openDrawer={handleOpenDetails} />}
    </Box>
  );
}
