import { useTheme } from "@mui/material";
import React from "react";
import { BiEdit } from "react-icons/bi";
import { BsThreeDots } from "react-icons/bs";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomPopover from "../../../components/CustomPopover";
import CustomTextButton from "../../../components/CustomTextButton";

export interface MoreUserOptionsTooltipProps {
  userId: string | number;
}

export default function MoreUserOptionsTooltip({ userId }: MoreUserOptionsTooltipProps) {
  const theme = useTheme();
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleOpenMoreOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreOptionsAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOptions = () => {
    setMoreOptionsAnchorEl(null);
  };

  return (
    <>
      <CustomIconButton onClick={handleOpenMoreOptions} sx={{ color: theme.palette.text.default }}>
        <BsThreeDots />
      </CustomIconButton>
      <CustomPopover open={Boolean(moreOptionsAnchorEl)} anchorEl={moreOptionsAnchorEl} onClose={handleCloseMoreOptions}>
        <CustomTextButton onClick={() => {}} startIcon={<BiEdit size={16} />}>
          Edit
        </CustomTextButton>
      </CustomPopover>
    </>
  );
}
