import TabLayout from "../../layouts/TabLayout";
import FavoriteQueriesContent from "./component/FavoriteQueriesContent";

const tabs = [
  {
    id: "favorites",
    title: "Favorite Queries",
    disabled: false,
    component: <FavoriteQueriesContent />,
  },
];

export default function FavoriteQueries() {
  return <TabLayout tabs={tabs} />;
}
