import { Box, Typography, useTheme } from "@mui/material";

interface InfoProps {
  handleOpenDetails(): void;
}

export default function PublicationInfo({ handleOpenDetails }: InfoProps) {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" gap={1.2}>
      <Typography variant="subtitle2" color={theme.palette.text.titleLabel} mb={1}>
        City Name
      </Typography>
      <Box display="flex">
        <Typography variant="caption2" color={theme.palette.text.default} onClick={handleOpenDetails}>
          356 Publications:
        </Typography>
      </Box>
      <Typography variant="caption2" color={theme.palette.text.titleLabel} fontWeight={351}>
        12 in Twitter
      </Typography>
      <Typography variant="caption2" color={theme.palette.text.titleLabel} fontWeight={351}>
        8 in Instagram
      </Typography>
      <Typography variant="caption2" color={theme.palette.text.titleLabel} fontWeight={351}>
        4 in News
      </Typography>
    </Box>
  );
}
