import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";

import { NavLink } from "react-router-dom";
import CustomFormButton from "../../components/CustomFormButton";
import { resendVerificationEmail } from "../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
const maxSeconds: number = 60;

export interface EmailVerificationProps {
  email: string;
}

const EmailVerification: React.FC<EmailVerificationProps> = ({ email }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.auth);
  const [disabledResendButton, setDisabledResendButton] = React.useState<boolean>(true);
  const [seconds, setSeconds] = React.useState(maxSeconds);

  React.useEffect(() => {
    let interval: any = null;
    interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    if (seconds <= 0) {
      setDisabledResendButton(() => {
        clearInterval(interval);
        return false;
      });
    }
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ zIndex: 1, width: "100%" }}
      py={{ xs: 2, sm: 1, lg: 1, xl: 0 }}
      px={{ xs: 6, sm: 1, lg: 1, xl: 0 }}
    >
      <Typography
        variant="h1"
        sx={{
          fontFeatureSettings: "'liga' off",
          color: (theme) => theme.palette.text.titleLabel,
        }}
      >
        Email Verification
      </Typography>
      <Typography mt={2} mb={2} variant="body6" textAlign="center" color={(theme) => theme.palette.text.titleLabel}>
        We have sent email to{" "}
        <Typography display="inline" variant="body6" textAlign="center" color={(theme) => theme.palette.primary.main}>
          {email}
        </Typography>{" "}
        to confirm the validity of email address. After receiving the email follow the provided link to complete registration.
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <CustomFormButton
            disabled={disabledResendButton}
            onClick={() => {
              dispatch(resendVerificationEmail({ email })).then((res) => {
                if (res?.payload?.status === 200) {
                  toast.success("Sent the verification email successfully.");
                }
              });
            }}
            sx={{
              marginTop: "3.5rem",
              width: { xs: "60%", sm: "76%", md: "83%", lg: "66%" },
              color: theme.palette.background.main,
            }}
            isLoading={isLoading}
          >
            {disabledResendButton ? `Resend in ${seconds}` : "Resend"}
          </CustomFormButton>
          <Typography variant="body6" mt={3} color={theme.palette.text.titleLabel}>
            Have any issues?
            <NavLink
              to=""
              style={{
                textDecoration: "none",
                fontWeight: theme.typography.fontWeightMedium,
                color: theme.palette.text.default,
                marginLeft: "4px",
              }}
            >
              Contact us
            </NavLink>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailVerification;
