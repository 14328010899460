import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { BarChartProps } from "../../../../../components/CustomBarChart";
import CustomIconButton from "../../../../../components/CustomIconButton";
import CustomTextButton from "../../../../../components/CustomTextButton";
import Precision from "./Precision";
import Recall from "./Recall";
import Statistics from "./Statistics";
import TrainingScore from "./TrainingScore";

interface Results {
  name: string;
  createdAt: string;
  trainingScore: { value: number };
  statisticsData: { label: string; value: number }[];
  precisionData: BarChartProps;
  reCallData: BarChartProps;
}

interface TestingDataProps {
  testingData: Results[];
}

export default function TestResults({ testingData }: TestingDataProps) {
  const theme = useTheme();
  const [showExpand, setShowExpand] = useState<number>(1);
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const statisticsData = [
    { label: "men", value: 67 },
    { label: "women", value: 35 },
    { label: "children", value: 12 },
  ];

  const handleExpand = (idx: number) => {
    setShowExpand(idx);
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      {testingData.map((testData, idx) => {
        return (
          <Accordion
            key={idx}
            sx={{
              width: "100%",
              background: theme.palette.dashboardContainer.backround,
              border: theme.palette.additionalColors.databasecardBorder,
              p: 1,
              borderRadius: 2,
              mb: 2,
              "&.MuiPaper-root.MuiAccordion-root:before": {
                backgroundColor: "transparent",
              },
              "&.MuiPaper-root.MuiAccordion-root:last-of-type": {
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              },
            }}
            expanded={expanded === `panel${idx}`}
            onChange={handleChange(`panel${idx}`)}
          >
            <AccordionSummary
              aria-controls={`panel${idx}-content`}
              id={`panel${idx}-header`}
              sx={{
                pointerEvents: "none",
              }}
            >
              <Box display="flex" width="100%" gap={4}>
                <Typography variant="subtitle1" sx={{ background: theme.palette.primary.tertiaryGradient, py: 1, px: 2, borderRadius: 1 }}>
                  {testData.name}
                </Typography>
                <Typography variant="body4" color={theme.palette.text.tableHeader} pt={1}>
                  {testData.createdAt}
                </Typography>
                <Box sx={{ ml: "auto", gap: 4 }}>
                  <CustomTextButton
                    endIcon={
                      showExpand === idx ? <ExpandLessIcon sx={{ mt: "-3px", ml: "-4px" }} /> : <ExpandMoreIcon sx={{ mt: "-3px", ml: "-4px" }} />
                    }
                    sx={{
                      mr: 3,
                      pointerEvents: "auto",
                      "& .MuiSvgIcon-root": {
                        fontSize: "30px",
                      },
                    }}
                    onClick={() => handleExpand(idx)}
                  >
                    Details
                  </CustomTextButton>
                  <CustomIconButton sx={{ width: "26px", height: "26px" }}>
                    <MoreHorizIcon sx={{ fontSize: "18px", color: theme.palette.text.default }} />
                  </CustomIconButton>
                </Box>
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={3} display={"flex"}>
                <Grid item xs={12} sm={6} lg={3} sx={{ display: "flex" }}>
                  <TrainingScore value={testData.trainingScore.value} />
                </Grid>
                <Grid item xs={12} sm={6} lg={3} sx={{ display: "flex" }}>
                  <Statistics statisticsData={testData.statisticsData} />
                </Grid>
                <Grid item xs={12} sm={6} lg={3} sx={{ display: "flex" }}>
                  <Precision categories={testData.precisionData.categories} series={testData.precisionData.series} />
                </Grid>
                <Grid item xs={12} sm={6} lg={3} sx={{ display: "flex" }}>
                  <Recall categories={testData.precisionData.categories} series={testData.precisionData.series} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}
