import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import MessageIcon from "@mui/icons-material/Message";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import CustomDivider from "../../../../components/CustomDivider";
import FormikInputField from "../../../../components/FormikInputField";
import ChatBox from "./component/ChatBox";
import Profile from "./component/Profile";

interface ChatboatProps {
  isConnecting: boolean;
  discover?: boolean;
  handleLiveChat(): void;
}

export default function Chatboat({ isConnecting, discover = false, handleLiveChat }: ChatboatProps) {
  const theme = useTheme();
  const [isOnline, setIsOnline] = useState(true);
  const [message, setMessage] = useState("");

  const handleSendMessage = (message: any) => {
    console.log(message);
  };

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column">
      <Box display="flex">
        <Typography variant="semiBold" color={theme.palette.text.titleLabel}>
          Live Chat
        </Typography>
        <CloseIcon sx={{ color: theme.palette.error.icon, ml: "auto", cursor: "pointer" }} onClick={handleLiveChat} />
      </Box>
      <Profile isConnecting={isConnecting} isOnline={isOnline} />
      {isConnecting && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 3,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: discover ? 2 : { xs: 6, xl: 15 },
            textAlign: "center",
            alignSelf: "center",
            height: { xs: "100%", lg: "83%" },
          }}
        >
          <MessageIcon sx={{ fontSize: "2em", color: theme.palette.primary.baseDisabled }} />
          <Typography variant="body3" color={theme.palette.primary.baseDisabled}>
            Your chat history will be displayed here until chat is finished
          </Typography>
        </Box>
      )}
      {!isConnecting && (
        <Box
          sx={{
            width: "100%",
            height: "90%",
            overflow: "scroll",
            mt: 3,
          }}
        >
          <Typography variant="body5" color={theme.palette.action.disabled} display="table" margin="auto">
            *Support Manager Malek Smith is connected
          </Typography>
          <ChatBox
            type="outgoing"
            message="Greetings! I can't figure out how to purchase two identical license keys for multiple devices. Buying a whole package of licenses is not
          suitable for me"
          />
          <ChatBox type="incoming" message="Good afternoon, my name is Lora, please tell me which licenses you are interested in?" />
          <ChatBox type="incoming" message="Good afternoon, my name is Lora, please tell me which licenses you are interested in?" />
          <ChatBox type="incoming" message="Good afternoon, my name is Lora, please tell me which licenses you are interested in?" />
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CustomDivider width="100%" />
        <Box display="flex" mt={1} alignItems="center">
          <Formik
            initialValues={{
              replyText: "",
            }}
            onSubmit={(values) => {
              const commentData = {
                replyText: values.replyText,
              };
              handleSendMessage(commentData);
              values.replyText = "";
            }}
          >
            {(props) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.handleSubmit();
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormikInputField
                  name="replyText"
                  component={message}
                  placeholder="Type a Message.."
                  size="small"
                  variant="standard"
                  value={props.values.replyText}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  autoComplete="off"
                  sx={{
                    width: "100%",
                    "& .MuiInput-root": {
                      ...theme.typography.body4,
                      color: theme.palette.primary.inactiveIcon,
                    },
                    "&.MuiFormControl-root.MuiTextField-root .MuiInputBase-root": { background: "transparent", boxShadow: "none" },
                    "& .MuiInput-root:before": {
                      borderBottom: "none",
                    },
                    "& .MuiInput-root:after": {
                      borderBottom: "none",
                    },
                    "& .MuiInputBase-root": {
                      ":hover": {
                        ":before": {
                          borderBottom: "none",
                        },
                      },
                    },
                  }}
                  inputProps={{
                    style: {
                      height: "15px",
                      borderRadius: "0px 6px 6px 0px",
                      background: "transparent",
                      color: theme.palette.text.titleLabel,
                    },
                  }}
                  error={props.touched.replyText && Boolean(props.errors.replyText)}
                  helperText={props.touched.replyText && props.errors.replyText ? props.errors.replyText : null}
                />
                <Box display="flex" gap={1.5} ml="auto">
                  <AttachFileIcon sx={{ color: theme.palette.text.default, fontSize: "0.8em", cursor: "pointer" }} />
                  <CustomDivider orientation="vertical" sx={{ height: "18px" }} />
                  <Button type="submit" sx={{ minWidth: 0, p: 0 }}>
                    <SendRoundedIcon sx={{ color: theme.palette.text.default, fontSize: "1.2em", cursor: "pointer", transform: "rotate(-45deg)" }} />
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}
