import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import RangeSlider, { CustomRangeSlider } from "../../../../../../components/CustomRangeSlider";

interface RiskScoreProps {
  selectedValue: string[];
  isReset: boolean;
  getRiskScore(values: string[]): void;
}

export default function RiskScore({ selectedValue, isReset, getRiskScore }: RiskScoreProps) {
  const theme = useTheme();
  const [value, setValue] = useState<number[]>([1, 2]);

  const marks = [
    {
      value: 0,
      label: "Low",
    },
    {
      value: 1,
      label: "Neutral",
    },
    {
      value: 2,
      label: "Moderate",
    },
    {
      value: 3,
      label: "High",
    },
    {
      value: 4,
      label: "Extreme",
    },
  ];

  function valuetext(value: number) {
    return `${value}`;
  }

  const handleChange = (event: any, newValue: any) => {
    const filterLabel = marks.filter((data) => newValue.includes(data.value)).map((lData) => lData?.label);
    getRiskScore(filterLabel);
    setValue(newValue);
  };

  useEffect(() => {
    if (isReset) {
      setValue([1, 2]);
      const filterLabel = marks.filter((data) => [1, 2].includes(data.value)).map((lData) => lData?.label);
      getRiskScore(filterLabel);
    }
  }, [isReset]);

  useEffect(() => {
    if (selectedValue && selectedValue.length > 0) {
      const newValue = marks?.filter((data) => selectedValue.includes(data?.label)).map((rData) => rData?.value);
      setValue(newValue);
    }
  }, [selectedValue]);

  return (
    <Box mt={2}>
      <Typography variant="h4" color={theme.palette.text.tableHeader} mb={1}>
        Risk score
      </Typography>

      <Box px={1}>
        <CustomRangeSlider
          sx={{
            "&.MuiSlider-root": {
              padding: "12px 0",
            },
            "& .MuiSlider-mark": { display: "none" },
            "& .MuiSlider-markLabel": {
              color: theme.palette.text.titleLabel,
              fontSize: "12px",
              fontWeight: 400,
            },
          }}
          slots={{ thumb: RangeSlider }}
          min={0}
          max={5}
          step={1}
          marks={marks}
          value={value}
          getAriaValueText={valuetext}
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
}
