import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { useTheme } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import React from "react";

export interface CustomFormButtonProps extends LoadingButtonProps {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  isLoading?: boolean;
  [x: string]: any;
}

const CustomFormButton: React.FC<CustomFormButtonProps> = ({ sx, children, isLoading = false, ...rest }) => {
  const theme = useTheme();

  return (
    <LoadingButton
      type="submit"
      variant="contained"
      size="large"
      endIcon={<></>}
      loading={isLoading}
      loadingPosition="end"
      sx={{
        ...theme.typography.hugeButton,
        lineHeight: "100%",
        border: `0.5px solid ${theme.palette.additionalColors.border}`,
        backgroundColor: theme.palette.primary.main,
        transform: "rotate(0.13deg)",
        boxShadow: theme.palette.dashboardContainer.primaryButtonBoxShadow,
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
        },
        "&:active": {
          backgroundColor: theme.palette.action.active,
          boxShadow: "inset 0px 4px 6px rgba(1, 19, 35, 0.4)",
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.action.disabled,
          color: theme.palette.background.default,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};

export default CustomFormButton;
