import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExtensionIcon from "@mui/icons-material/Extension";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import NearMeIcon from "@mui/icons-material/NearMe";
import SpeedSharpIcon from "@mui/icons-material/SpeedSharp";
import { Box, Typography, useTheme } from "@mui/material";
import CustomDivider from "../../../../components/CustomDivider";

export interface FAQProps {
  id: number;
  title: string;
  description: string;
  icon: string;
}

interface FAQItemProps {
  faqItem: FAQProps;
}

export default function FAQItem({ faqItem }: FAQItemProps) {
  const theme = useTheme();

  const FAQIcon = () => {
    switch (faqItem?.icon) {
      case "extension":
        return <ExtensionIcon sx={{ color: theme.palette.text.tableHeader }} />;
      case "navigation":
        return <NearMeIcon sx={{ color: theme.palette.text.tableHeader }} />;
      case "about":
        return <DashboardIcon sx={{ color: theme.palette.text.tableHeader }} />;
      case "optimization":
        return <FmdGoodIcon sx={{ color: theme.palette.text.tableHeader }} />;
      case "model":
        return <SpeedSharpIcon sx={{ color: theme.palette.text.tableHeader }} />;
      case "monitor":
        return <MonitorHeartIcon sx={{ color: theme.palette.text.tableHeader }} />;
      case "default":
        break;
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" gap={2} my={2}>
        {FAQIcon()}
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle1" sx={{ color: theme.palette.text.titleLabel, mb: 0.8 }}>
            {faqItem?.title}
          </Typography>
          <Typography variant="body4" sx={{ color: theme.palette.primary.inactiveIcon }}>
            {faqItem?.description}
          </Typography>
        </Box>
        <ChevronRightIcon sx={{ color: theme.palette.text.default, ml: "auto" }} />
      </Box>
      <CustomDivider width="100%" />
    </>
  );
}
