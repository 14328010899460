import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CustomCloseIconButton from "../../components/CustomCloseIconButton";
import Chatboat from "./component/Chatboat";
import ChatSection from "./component/chat/ChatSection";
import FAQSection from "./component/faq/FAQSection";

interface SupportLayoutProps {
  open?: boolean;
  handleDrawer?(): void;
}
export default function Support({ open, handleDrawer }: SupportLayoutProps) {
  const theme = useTheme();

  const [isConnecting, setIsConnecting] = useState(true);
  const [chat, setChat] = useState(false);

  const handleConnection = () => {
    handleLiveChat();
    setTimeout(() => {
      setIsConnecting(false);
    }, 2000);
  };

  const handleLiveChat = () => {
    setChat((prev) => !prev);
    setIsConnecting(true);
  };

  const SupportContent = () => (
    <>
      <Box
        mt={2}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: (theme) => theme.spacing(2),
        }}
      >
        <Typography variant="h2">How can we help?</Typography>
        <CustomCloseIconButton
          sx={{ marginLeft: { xs: "0", md: "80px" }, [theme.breakpoints.down("md")]: { display: "none" } }}
          onClick={handleDrawer}
        >
          <CloseIcon sx={{ width: "16px", height: "16px" }} />
        </CustomCloseIconButton>
      </Box>
      {!chat && (
        <Box>
          <Box
            sx={{
              background: theme.palette.background.darkCardBackground,
              border: `1px solid ${theme.palette.additionalColors.lightBorder}`,
              p: 2,
              borderRadius: 2,
              height: { xs: "100%", md: "60%", xl: "80%" },
            }}
          >
            <FAQSection />
          </Box>
          <Box
            sx={{
              background: theme.palette.background.darkCardBackground,
              border: `1px solid ${theme.palette.additionalColors.lightBorder}`,
              px: 2,
              pt: 3,
              pb: 2,
              borderRadius: 2,
              mt: 2,
            }}
          >
            <ChatSection handleConnection={handleConnection} isActiveChat={true} />
          </Box>
        </Box>
      )}
      {chat && (
        <Box
          sx={{
            background: theme.palette.background.darkCardBackground,
            border: `1px solid ${theme.palette.additionalColors.lightBorder}`,
            px: 2,
            pt: 3,
            pb: 2,
            borderRadius: 2,
            height: "100%",
            mt: 2,
          }}
        >
          <Chatboat isConnecting={isConnecting} handleLiveChat={handleLiveChat} />
        </Box>
      )}
    </>
  );

  return (
    <>
      <Drawer
        anchor="left"
        variant="persistent"
        open={open}
        onClose={handleDrawer}
        hideBackdrop={true}
        PaperProps={{
          sx: {
            width: { sm: "41%", lg: "32%" },
            zIndex: 1210,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            top: 0,
            left: (theme) => ({
              left: "90px",
              [theme.breakpoints.down("md")]: {
                left: "0",
              },
            }),
            marginLeft: (theme) => ({
              marginLeft: "4px",
              [theme.breakpoints.down("md")]: {
                marginLeft: "0",
              },
            }),
            borderRight: "none",
            paddingLeft: 2,
            paddingRight: 2,
            paddingBottom: 5,
            overflowY: "scroll",
            background: theme.palette.dashboardContainer.backround,
            boxShadow: `25px 0px 50px 0px ${theme.palette.text.darkColor}59`,
            [theme.breakpoints.down("md")]: { display: "none" },
          },
        }}
      >
        {SupportContent()}
      </Drawer>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          position: "fixed",
          top: 0,
          borderRight: "none",
          paddingLeft: 2,
          paddingRight: 4,
          pb: 5,
          overflowY: "scroll",
          [theme.breakpoints.up("md")]: { display: "none" },
        }}
      >
        {SupportContent()}
      </Box>
    </>
  );
}
