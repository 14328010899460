import { Box } from "@mui/system";

import { Paper, useTheme } from "@mui/material";
import { additionalDrawerMenuItems, drawerMenuItems } from "../../../routes";
import { useAppSelector } from "../../../store/hooks";
import { ThemeVariantsProps } from "../../../theme";
import DrawerMenu from "./DrawerMenu";
import SidebarLogo from "./SidebarLogo";
import SidebarMenu from "./SidebarMenu";

const drawerWidth = "80px";

const SideBar = () => {
  const theme = useTheme();
  const { themeMode } = useAppSelector((state) => state.theme);
  const isDarkTheme = themeMode === ThemeVariantsProps.dark;

  return (
    <>
      <Paper
        sx={{
          alignItems: "center",
          width: drawerWidth,
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflowY: "scroll",
          overflowX: "hidden",
          position: "sticky",
          minWidth: "6rem",
          top: 0,
          zIndex: 9999,
          boxShadow: "none",
          backgroundImage: isDarkTheme ? `url(/skylarklabs_full_circle.png)` : `url(/skylarklabs_full_circle_light.png)`,
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        <SidebarLogo isDarkTheme={isDarkTheme} />
        <SidebarMenu sidebarMenuItems={drawerMenuItems} />
        <Box flex={1} />
        <SidebarMenu sidebarMenuItems={additionalDrawerMenuItems} />
      </Paper>
      <Paper
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1210,
          boxShadow: "none",
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        }}
      >
        <DrawerMenu sidebarMenuItems={[...drawerMenuItems, ...additionalDrawerMenuItems]} />
      </Paper>
    </>
  );
};
export default SideBar;
