import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { BsDot, BsThreeDots } from "react-icons/bs";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomPopover from "../../../components/CustomPopover";
import CustomTextButton from "../../../components/CustomTextButton";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";

export interface WorkspaceProps {
  workspaceId: string | number;
  name: string;
  members: number;
  planName: string;
  image?: string;
}

export interface WorkspaceCardProps {
  data: WorkspaceProps;
}

export function WorkspaceCard({ data }: WorkspaceCardProps) {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleOpenMoreOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreOptionsAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOptions = () => {
    setMoreOptionsAnchorEl(null);
  };

  const handleRedirectToDetail = () => {
    handleRouteClick(routes.workspaceDetail.path.replace(":workspaceId", String(data.workspaceId)));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: 2,
        alignItems: "center",
        borderRadius: 1,
        p: 2,
        background: theme.palette.background.gradientLight1,
        boxShadow: theme.palette.dashboardContainer.workspaceCardBoxShadow,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleRedirectToDetail}
        >
          <img src={data.image || "/assets/workspace-icon.png"} alt="Workspace" style={{ height: "auto", maxHeight: "48px", maxWidth: "48px" }} />
        </Box>
        <Box>
          <Box onClick={handleRedirectToDetail} sx={{ cursor: "pointer" }}>
            <Typography variant="h4">{data.name}</Typography>
          </Box>
          <Box>
            <Typography variant="caption" color={theme.palette.primary.inactiveIcon}>
              {data.members} members <BsDot /> Plan: {data.planName}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <CustomIconButton onClick={handleOpenMoreOptions} sx={{ color: theme.palette.text.default }}>
          <BsThreeDots />
        </CustomIconButton>
        <CustomPopover open={Boolean(moreOptionsAnchorEl)} anchorEl={moreOptionsAnchorEl} onClose={handleCloseMoreOptions}>
          <CustomTextButton onClick={() => {}} startIcon={<CloseIcon color="error" />}>
            Delete
          </CustomTextButton>
        </CustomPopover>
      </Box>
    </Box>
  );
}
