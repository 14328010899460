import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, Typography, useTheme } from "@mui/material";
import CustomButton from "../../../../components/CustomButton";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";

export default function PaymentConfirmation() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const handleBack = () => {
    handleRouteClick(routes.home.path);
  };

  return (
    <Box width="100%" sx={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>
      <Box
        sx={{
          display: "inline-block",
          background: theme.palette.dashboardContainer.backround,
          borderRadius: 5,
          textAlign: "center",
          p: 8,
        }}
      >
        <img src="/assets/success.png" alt="success" />
        <Typography variant="h1" color={theme.palette.text.titleLabel} mt={4} textTransform="none">
          Payment is successful
        </Typography>
        <Typography variant="body3" color={theme.palette.text.captionColor} display="inline-block" pt={1}>
          Invoices will be stored in your profile
        </Typography>
        <Box sx={{ display: "flex", border: `1px solid ${theme.palette.additionalColors.lightBorder}`, my: 4, px: 1, py: 0.8, borderRadius: 2 }}>
          <InsertDriveFileIcon sx={{ color: theme.palette.text.tableHeader }} />
          <Typography variant="body4" color={theme.palette.text.captionColor} pt={0.7} pl={2}>
            Invoice_23456. csv
          </Typography>
          <FileDownloadOutlinedIcon
            sx={{ ml: "auto", color: theme.palette.text.default, cursor: "pointer" }}
            onClick={() => console.log("download")}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            background: theme.palette.dashboardContainer.confirmBackBgColor,
            borderRadius: 5.5,
            p: 4,
          }}
        >
          <Typography variant="h2" color={theme.palette.text.titleLabel}>
            Look what you`ve got
          </Typography>
          <Typography variant="body3" color={theme.palette.text.captionColor}>
            All Data Sources, Ability to upload own data sources
          </Typography>
          <CustomButton
            sx={{
              ...theme.typography.bigButton,
              background: theme.palette.text.default,
              color: theme.palette.text.secondaryText,
              width: "100%",
              "&:hover": {
                background: theme.palette.text.default,
                color: theme.palette.text.secondaryText,
              },
              mt: 3,
            }}
            onClick={handleBack}
          >
            Go to Home Page
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
}
