import { Box, Radio, Typography, useTheme } from "@mui/material";

interface CardProps {
  label: string;
  logo: string;
  handlePaymentMethod(paymentOpt: string): void;
}

export default function PaymentCard({ label, logo, handlePaymentMethod }: CardProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        background: theme.palette.dashboardContainer.backround,
        border: `1px solid ${theme.palette.additionalColors.lightBorder}`,
        borderRadius: 2,
        p: 2,
        alignItems: "center",
      }}
      onClick={() => handlePaymentMethod(label)}
    >
      <Radio
        checked={true}
        onChange={() => {
          handlePaymentMethod(label);
        }}
        value={label}
        name={label.toLowerCase()}
        sx={{
          "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
            color: "#1A1B1E",
          },
          "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
            color: "#2EA3FF",
          },
        }}
      />
      <img src={logo} alt={`${label}-logo`} style={{ width: "3rem", height: "1.875rem" }} />
      <Typography variant="body1" fontWeight={500} color={theme.palette.text.tableHeader}>
        {label}
      </Typography>
    </Box>
  );
}
