import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, CardMedia, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CustomVideoPlayer from "../../../../../../../components/CustomVideoPlayer";
import { routes } from "../../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../../routes/hooks";

interface Details {
  title: string;
  type: string;
  data: {
    src: string;
    title: string;
    thumbnail?: string;
  }[];
}

interface RelatedDetailsProps {
  relatedDetails: Details;
}

export default function RelatedDetails({ relatedDetails }: RelatedDetailsProps) {
  const theme = useTheme();
  const params = useParams();
  const handleRouteClick = useHandleRouteClick();

  const [currentIdx, setIndex] = useState(0);
  let showItem = 5;
  if (useMediaQuery(theme.breakpoints.up("lg"))) {
    showItem = 4;
  }
  if (useMediaQuery(theme.breakpoints.only("lg"))) {
    showItem = 6;
  }
  if (useMediaQuery(theme.breakpoints.down("md"))) {
    showItem = 4;
  }
  if (useMediaQuery(theme.breakpoints.down("sm"))) {
    showItem = 1;
  }

  const itemsToDisplay = showItem;
  const itemsToScroll = showItem;

  const arrowUpHandler = () => {
    if (currentIdx > 0) {
      setIndex((prev) => Math.max(0, prev - itemsToScroll));
    }
  };

  const arrowDownHandler = () => {
    if (currentIdx < relatedDetails.data.length - itemsToDisplay) {
      setIndex((prev) => prev + itemsToScroll);
    }
  };

  const handleNavigation = () => {
    let newPath = routes.relatedOverview.path.replace(":type", relatedDetails?.type).replace(":id", String(params?.id));
    handleRouteClick(newPath, { title: relatedDetails?.title, type: relatedDetails?.type });
  };

  const visibleItems = relatedDetails.data.slice(currentIdx, currentIdx + itemsToDisplay);

  const renderDetailsCard = (type: string) => {
    if (type === "scene") {
      {
        return visibleItems.map((detail: any, idx: number) => (
          <Grid item xs={12} sm={12 / showItem} lg={12 / showItem} key={`detail-${idx}`} pb={2}>
            <CustomVideoPlayer hlsStreamUrl={detail?.src} thumbnail={detail?.thumbnail} height={"162px"} />

            <Box display="flex" mt={-0.5}>
              <Typography variant="body4">{detail?.title}</Typography>
              <Typography variant="body4" color={theme.palette.primary.light} sx={{ ml: "auto" }}>
                0:00
              </Typography>
            </Box>
          </Grid>
        ));
      }
    } else {
      return visibleItems.map((cardData, idx) => (
        <Grid item xs={12} sm={12 / showItem} lg={12 / showItem} key={`card-${idx}`} pb={2}>
          <Box
            sx={{ position: "relative", height: "162px", cursor: "pointer", transition: "transform 0.5s ease-in-out" }}
            onClick={() => console.log("click")}
          >
            <CardMedia component="img" image={cardData?.src} alt="recognation" sx={{ borderRadius: 1, height: "150px" }} />
            <Typography variant="body4" color={theme.palette.primary.light} style={{ cursor: "pointer" }}>
              {cardData?.title}
            </Typography>
          </Box>
        </Grid>
      ));
    }
  };

  const label = relatedDetails?.type === "scene" ? "scenes" : relatedDetails?.type === "face" ? "faces" : "entities";

  return (
    <Box mt={3} width="100%" mb={3}>
      <Box display="flex" gap={3} mb={2}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography variant="semiBold" color={theme.palette.text.titleLabel}>
            {relatedDetails?.title}:
          </Typography>
          <Typography variant="semiBold" color={theme.palette.text.titleLabel}>
            {relatedDetails?.data?.length} {label}
          </Typography>
        </Box>
        <Typography
          ml="auto"
          variant="bigCaption"
          color={theme.palette.text.default}
          display={"flex"}
          sx={{
            cursor: "pointer",
            fontSize: "16px",
          }}
          onClick={handleNavigation}
        >
          View All
        </Typography>
      </Box>
      <Box
        sx={{
          background: theme.palette.background.darkCardBackground,
          border: theme.palette.additionalColors.databasecardBorder,
          borderRadius: 2,
          py: 2,
          display: "flex",
        }}
      >
        <KeyboardArrowLeftIcon
          onClick={arrowUpHandler}
          fontSize="large"
          cursor="pointer"
          sx={{
            float: "left",
            textAlign: "center",
            margin: "auto 0",
            color: currentIdx === 0 ? theme.palette.action.disabled : theme.palette.primary.main,
          }}
        />
        <Grid container spacing={1} width="100%" display="flex">
          {renderDetailsCard(relatedDetails?.type)}
        </Grid>
        <KeyboardArrowRightIcon
          onClick={arrowDownHandler}
          fontSize="large"
          cursor="pointer"
          sx={{
            float: "right",
            textAlign: "center",
            margin: "auto 0",
            color: currentIdx >= relatedDetails.data.length - itemsToDisplay ? theme.palette.action.disabled : theme.palette.primary.main,
          }}
        />
      </Box>
    </Box>
  );
}
