import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, CardMedia, Grid, Typography, useTheme } from "@mui/material";
import CustomButton from "../../../../components/CustomButton";
import CustomIconButton from "../../../../components/CustomIconButton";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";

interface PlanDetailsProps {
  plan: string;
  title: string;
}

export default function PlanDetails({ plan, title }: PlanDetailsProps) {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const handleNavigation = () => {
    handleRouteClick(routes.paymentDetails.path, { plan: plan, amt: 200, backPath: routes.upgradeInfo.path, isCart: false });
  };

  const planFeatures = [
    {
      title: "All Data Sources",
      description:
        "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
    },
    {
      title: "Own Data Sources",
      description:
        "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
    },
    {
      title: "Workspace for unlimited members",
      description:
        "If you can keep your head when all about you are losing theirs and blaming it on you, if you can trust yourself when all men doubt you.",
    },
  ];

  const CardImage = () => (
    <CardMedia
      component="img"
      image="/assets/marketplace_Info.png"
      alt="info"
      sx={{ borderRadius: 4, height: { xs: "20em", lg: "27em", xl: "20em" }, minHeight: "50%" }}
    />
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: theme.palette.background.paper,
        boxShadow: theme.palette.dashboardContainer.customCardInsetBoxShadow,
        borderRadius: 4.5,
        px: 2,
        mt: 2,
      }}
    >
      <Grid
        container
        sx={{
          [theme.breakpoints.down("lg")]: {
            height: "100%",
            overflow: "scroll",
          },
        }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          order={{ xs: 2, md: 1 }}
          sx={{
            px: 2,
            py: 3,
            [theme.breakpoints.only("lg")]: {
              px: 8,
              py: 6,
            },
          }}
        >
          <Box mb={4}>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                background: theme.palette.background.gradientLight1,
                p: 2,
                width: { xs: "100%", lg: "80%", xl: "100%" },
                borderRadius: 2,
                boxShadow: theme.palette.dashboardContainer.workspaceCardBoxShadow,
              }}
            >
              <CardMedia component="img" image="/assets/workspace-icon.png" alt="info" sx={{ width: "48px", height: "48px" }} />
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h4" sx={{ color: theme.palette.text.titleLabel }}>
                  Workspace name
                </Typography>
                <Box display="flex" gap={1}>
                  <Typography variant="body3" color={theme.palette.primary.inactiveIcon}>
                    1 member
                  </Typography>
                  <Typography variant="body3" color={theme.palette.primary.inactiveIcon} sx={{ mt: "-3px" }}>
                    .
                  </Typography>
                  <Typography variant="body3" color={theme.palette.primary.inactiveIcon}>
                    Plan: Free
                  </Typography>
                </Box>
              </Box>
              <CustomIconButton sx={{ ml: "auto", mt: 1.4 }}>
                <ExpandMoreIcon sx={{ color: theme.palette.text.default }} />
              </CustomIconButton>
            </Box>
          </Box>
          <Typography variant="h2" color={theme.palette.text.titleLabel}>
            Features of {title} plan:
          </Typography>
          <Box
            mt={2}
            sx={{
              [theme.breakpoints.up("lg")]: {
                pl: 8,
                display: "none",
              },
            }}
          >
            {CardImage()}
          </Box>
          <Box mt={4}>
            {planFeatures.map((detail, idx) => (
              <Box key={`planInfo-${idx}`} sx={{ mb: 5 }}>
                <Typography variant="h4" sx={{ color: theme.palette.text.titleLabel, pb: 1.5, pl: 0.8 }}>{`${idx + 1}. ${detail?.title}`}</Typography>
                <Typography variant="body4" sx={{ color: theme.palette.text.captionColor }}>
                  {detail?.description}
                </Typography>
              </Box>
            ))}
          </Box>
          <CustomButton
            sx={{
              ...theme.typography.bigButton,
              background: theme.palette.text.default,
              color: theme.palette.text.secondaryText,
              px: "6em",
              "&:hover": {
                background: theme.palette.text.default,
                color: theme.palette.text.secondaryText,
              },
            }}
            onClick={handleNavigation}
          >
            Upgrade
          </CustomButton>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          xl={8}
          order={{ xs: 2, md: 1 }}
          sx={{
            px: 2,
            py: 3,
            [theme.breakpoints.only("lg")]: {
              px: 8,
              py: 6,
            },
            [theme.breakpoints.up("lg")]: {
              pl: 8,
            },
            [theme.breakpoints.down("lg")]: {
              display: "none",
            },
          }}
        >
          {CardImage()}
        </Grid>
      </Grid>
    </Box>
  );
}
