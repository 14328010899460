import { Box } from "@mui/material";
import CustomRadialBar from "../../../../../components/CustomRadial";

interface ResultData {
  [x: string]: any;
}

export default function CategoryResults({ overviewData }: ResultData) {
  return (
    <Box display="flex" justifyContent="center">
      {Object.keys(overviewData).map((category, idx) => (
        <Box
          key={`results-${idx}`}
          sx={{
            width: "80px",
            height: "100%",
          }}
        >
          <CustomRadialBar value={overviewData[category]} count={`${overviewData[category]}`} subTitle={category} />
        </Box>
      ))}
    </Box>
  );
}
