import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Grid, Paper, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { TfiExport } from "react-icons/tfi";
import { useParams } from "react-router-dom";
import CustomButton from "../../../../../components/CustomButton";
import CustomTab from "../../../../../components/CustomTab";
import { routes } from "../../../../../routes";
import { useHandleRouteClick } from "../../../../../routes/hooks";
import Connections from "../../Overview/RiskSummary/component/Connections";
import EntityInfo from "./components/EntityInfo";
import Mentions from "./components/Mentions";
import OnlineActivity from "./components/OnlineActivity";
import Overview from "./components/Overview";

const tabs = [
  {
    id: "overview",
    title: "Overview",
    disabled: false,
    component: <Overview />,
  },
  {
    id: "connections",
    title: "Connections",
    disabled: false,
    component: <Connections />,
  },
  {
    id: "online-activity",
    title: "Online Activity",
    disabled: false,
    component: <OnlineActivity />,
  },
  {
    id: "mentions",
    title: "Mentions",
    disabled: false,
    component: <Mentions />,
  },
];

const infoData = {
  id: 1,
  created_at: "13.04.2023 14:07",
  name: "Anne Thompson",
  media_image: "/assets/entityuser.png",
  social_media_link: ["https://twitter.com/", "https://in.linkedin.com/", "https://www.facebook.com/"],
  description:
    "Anne Thompson is an American journalist, working for NBC News as Chief Environmental Affairs correspondent. She covers the Catholic Church and environmental and economic issues.",
  relevance: "97%",
  authenticity: "97%",
  tag: ["Influencer", "Non-official"],
  category: "social_media",
  location: "Atlanta, Georgia, USA",
};

export default function SpecificEntity() {
  const theme = useTheme();
  const params = useParams();
  const handleRouteClick = useHandleRouteClick();

  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const backHandler = () => {
    handleRouteClick(`${routes.analytics.path}?tab=analysis&page=entities`);
  };

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <Grid container sx={{ position: "relative" }}>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "left",
              padding: "5px",
            }}
          >
            <Typography variant="h3" fontWeight="bold" sx={{ marginLeft: "10px", color: theme.palette.text.titleLabel }} display={"flex"}>
              Entity Page
            </Typography>

            <CustomButton
              onClick={backHandler}
              startIcon={<KeyboardArrowLeftIcon sx={{ mt: "-3px", mr: -1 }} />}
              varient="text"
              sx={{
                ...theme.typography.bigButton,
                lineHeight: "110%",
                cursor: "pointer",
                marginTop: "5px",
                display: "flex",
                justifyContent: "left",
                background: "transparent",
                boxShadow: "none",
                border: "none",
                "&:hover": {
                  background: "transparent",
                },
                pl: 0,
              }}
            >
              Back
            </CustomButton>
          </Box>
          <Box display="flex" gap={3} px={1} height="40px">
            <CustomButton
              startIcon={<AutoFixHighIcon />}
              sx={{
                ...theme.typography.bigButton,
                background: theme.palette.background.secondaryBgColor,
                color: theme.palette.text.secondaryText,
                width: "123px",
              }}
            >
              Magic Edit
            </CustomButton>
            <CustomButton
              variant="contained"
              sx={{
                ...theme.typography.h6,
                border: `0.5px solid ${theme.palette.additionalColors.border1Light}`,
                display: "flex",
                gap: 1,
                alignItems: "center",
                color: theme.palette.primary.main,
                background: theme.palette.background.tertiaryDarkGradient,
                boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
                "&:hover": {
                  background: theme.palette.background.tertiaryGradient,
                  boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
                },
              }}
              startIcon={<TfiExport style={{ width: "12px", height: "12px", marginBottom: "5px" }} />}
            >
              Export Report
            </CustomButton>
          </Box>
        </Box>

        <Paper
          sx={{
            background: theme.palette.background.default,
            boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
            borderRadius: { md: "18px" },
            padding: { xs: "20px 10px", md: "35px" },
            height: "87vh",
            overflowY: "scroll",
          }}
        >
          <EntityInfo infoData={infoData} />

          <Box
            sx={{
              mt: 3,
              borderTop: "1px solid",
              borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
              background: theme.palette.dashboardContainer.containerBackground,
            }}
          >
            <Box sx={{ display: "flex", mt: 3 }}>
              <CustomTab
                tabs={tabs}
                handleActiveTab={handleActiveTab}
                sx={{ border: `1px solid ${theme.palette.primary.tertiary}`, textAlign: "center", p: "1px" }}
                activeTab={activeTab}
              />
            </Box>

            {tabs.find((tab) => tab.id === activeTab)?.component}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
