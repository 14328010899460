import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import CustomMultiselect from "../../../../../../components/CustomMultiselect";

interface Options {
  id: string | number;
  text: string;
}

interface FilterProps {
  title: string;
  options: Options[];
  placeholder: string;
  selectedValue: string[];
  isReset?: boolean;
  getValues(values: string[]): void;
}

export default function FilterMultiSelect({ title, options, placeholder, isReset, getValues, selectedValue }: FilterProps) {
  const theme = useTheme();

  const [selValue, setSelValue] = useState<Options[]>([]);

  const handleSelectModel = (value: Options[]) => {
    const newValues = value.map((data) => data.text);
    getValues(newValues);
  };

  useEffect(() => {
    if (selectedValue) {
      let newData: Options[] = options.filter((data) => selectedValue.includes(data.text));
      setSelValue(newData);
    }
  }, [selectedValue]);

  return (
    <Box mt={2}>
      <Typography variant="h4" color={theme.palette.text.tableHeader} mb={1}>
        {title}
      </Typography>
      <CustomMultiselect
        label="Data sources"
        onChange={(value) => handleSelectModel(value)}
        options={options}
        showSearch={false}
        placeholder={placeholder}
        placeholderColor={theme.palette.text.titleLabel}
        isReset={isReset}
        selectionData={selValue}
      />
    </Box>
  );
}
