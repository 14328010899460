import TabLayout from "../../layouts/TabLayout";
import AIContainer from "./components/AIContainer";

export default function CustomAI() {
  const tabs = [
    {
      title: "Custom AI",
      id: "custom-ai",
      disabled: false,
      component: <AIContainer />,
    },
  ];

  return <TabLayout tabs={tabs} />;
}
