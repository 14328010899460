import { Box } from "@mui/material";
import { CUSTOM_AI_MODEL } from "../../../utils/constants";
import NoAIModels from "./NoAIModels";
import CustomAIList from "./Settings/CustomAIList";

export interface DetailOptions {
  id: number;
  name: string;
  createdAt: string;
  status: string;
}

export default function AIContainer() {
  return (
    <Box>
      {CUSTOM_AI_MODEL.length > 0 ? (
        <>
          <CustomAIList details={CUSTOM_AI_MODEL} />
        </>
      ) : (
        <NoAIModels />
      )}
    </Box>
  );
}
