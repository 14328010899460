import { Box, Button, Typography } from "@mui/material";
import { WorkspaceCard, WorkspaceProps } from "./WorkspaceCard";

export const workspaces: WorkspaceProps[] = [
  { workspaceId: 1, members: 23, name: "Workspace Name 1", planName: "Professional", image: "" },
  { workspaceId: 2, members: 3, name: "Workspace Name 2", planName: "Free", image: "" },
  { workspaceId: 3, members: 56, name: "Workspace Name 3", planName: "Professional", image: "" },
  { workspaceId: 4, members: 34, name: "Workspace Name 4", planName: "Free", image: "" },
];

export default function WorkspaceSection() {
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} mb={3}>
        <Typography variant="h2">Workspace</Typography>
        <Box>
          <Button variant="contained">Add New Workspace</Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        {workspaces.map((workspace) => {
          return <WorkspaceCard data={workspace} />;
        })}
      </Box>
    </Box>
  );
}
