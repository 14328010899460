import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "../../routes";

import SignUpBaseLayout from "../../layouts/SignUpBaseLayout";
import { register } from "../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import EmailVerification from "./EmailVerification";
import PasswordForm from "./PasswordForm";
import UserDetailsForm from "./UserDetailsForm";

const formSteps = [UserDetailsForm, PasswordForm, EmailVerification];

const initialFormState = {
  name: "",
  user_email: "",
  user_name: "",
  user_password1: "",
  user_password2: "",
};

export default function Register() {
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [formData, setFormData] = React.useState(initialFormState);

  const { isAuthenticated, isLoading } = useAppSelector((state) => state.auth);

  const handleNextStep = () => {
    if (activeStep < formSteps.length && activeStep >= 0) {
      setActiveStep((prev) => prev + 1);
    }
  };

  // const handlePrevStep = () => {
  //   if (activeStep < formSteps.length && activeStep >= 0) {
  //     setActiveStep((prev) => prev - 1);
  //   }
  // };

  const handleUserDetailSubmit = () => {
    handleNextStep();
  };

  const handlePasswordSubmit = () => {
    dispatch(register(formData))
      .then((res) => {
        if (res?.payload?.status === 201) {
          toast.success("Successfully registered the user");
          handleNextStep();
        }
      })
      .catch((err) => {
        toast.error("Oops! Some issues while submitting.");
      });
  };

  const handleInputChange = ({ fieldName, value }: { fieldName: string; value: any }) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const renderStep = (formStep: number) => {
    switch (formStep) {
      case 0:
        return <UserDetailsForm handleSubmit={handleUserDetailSubmit} handleInputChange={handleInputChange} isLoading={isLoading} />;
      case 1:
        return <PasswordForm handleSubmit={handlePasswordSubmit} handleInputChange={handleInputChange} isLoading={isLoading} />;
      case 2:
        return <EmailVerification email={formData.user_email} />;
      default:
        break;
    }
  };

  if (isAuthenticated) {
    return <Navigate to={routes.home.path} />;
  }

  return <SignUpBaseLayout isLoading={isLoading}>{renderStep(activeStep)}</SignUpBaseLayout>;
}
