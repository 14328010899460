import { Avatar, Box, Button, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import CheckboxFilterButton from "../../../components/CheckboxFilterButton";
import CustomDataTable from "../../../components/CustomDataTable";
import StatusButton from "../../../components/StatusButton";
import { workspaces } from "../component/WorkspaceSection";
import MoreUserOptionsTooltip from "./MoreUserOptionsTooltip";

export default function Users() {
  const theme = useTheme();
  const { workspaceId } = useParams();

  const workspaceData = workspaces.find((w) => String(w.workspaceId) === workspaceId);

  const columns: GridColDef[] = [
    { field: "id", headerName: "", width: 90, sortable: false },
    {
      field: "photo",
      headerName: "Photo",
      width: 80,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return <Avatar src={params.value} />;
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 340,
      editable: false,
      sortable: false,
      //   flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 110,
      editable: false,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 220,
      editable: false,
      sortable: false,
      //   flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      sortable: false,
      minWidth: 220,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <StatusButton status={params.value} />;
      },
    },
    {
      field: "moreOptions",
      headerName: "",
      minWidth: 60,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <MoreUserOptionsTooltip userId={params.id} />
          </>
        );
      },
    },
  ];

  const rows = [
    { id: 1, name: "Cristian Gonzales", role: "Admin", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "active" },
    { id: 2, name: "Cristian Gonzales", role: "User", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "inactive" },
    { id: 3, name: "Cristian Gonzales", role: "User", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "pending" },
    { id: 4, name: "Cristian Gonzales", role: "User", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "active" },
    { id: 5, name: "Cristian Gonzales", role: "User", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "active" },
    { id: 6, name: "Cristian Gonzales", role: "User", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "active" },
    {
      id: 7,
      name: "Cristian Gonzales",
      role: "Admin",
      email: "c.gonzales@skylark.es",
      phoneNumber: "+1 347 567 2345",
      photo: "",
      status: "inactive",
    },
    { id: 8, name: "Cristian Gonzales", role: "User", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "inactive" },
    { id: 9, name: "Cristian Gonzales", role: "User", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "active" },
    { id: 10, name: "Cristian Gonzales", role: "User", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "active" },
    { id: 11, name: "Cristian Gonzales", role: "Admin", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "active" },
    { id: 12, name: "Cristian Gonzales", role: "User", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "active" },
    { id: 13, name: "Cristian Gonzales", role: "Admin", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "active" },
    { id: 14, name: "Cristian Gonzales", role: "Admin", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "active" },
    { id: 15, name: "Cristian Gonzales", role: "Admin", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "active" },
  ];

  return (
    <>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center", position: "absolute", right: 0, top: 0 }}>
        <CheckboxFilterButton label="Query Type" onChange={(value) => {}} options={[]} showSearch={false} />
        <Button variant="contained">Add New User</Button>
      </Box>

      <Box>
        <CustomDataTable
          rows={rows}
          columns={columns}
          columnVisibilityModel={{
            id: false,
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          checkboxSelection={false}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableVirtualization
          disableRowSelectionOnClick
          isCellEditable={() => false}
          rowSelection={false}
          disableEval
          pagination
        />
      </Box>
    </>
  );
}
