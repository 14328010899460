import { Box } from "@mui/material";
import TabLayout from "../../layouts/TabLayout";
import AnalysisContainer from "./AnalysisContainer";

const tabs = [
  {
    id: "analysis",
    title: "Analysis",
    disabled: false,
    component: <AnalysisContainer />,
  },
];

export default function Analytics() {
  return (
    <Box>
      <TabLayout tabs={tabs} subPage={true} primarySubPage="overview" />
    </Box>
  );
}
