import { Box, CardMedia } from "@mui/material";
import CustomAudioPlayer from "../../../../../../../components/CustomAudioPlayer";

interface PlayerProps {
  url: string;
}
export default function AudioPlayer({ url }: PlayerProps) {
  return (
    <>
      <Box position="relative" width="100%" height={{ xs: "90%", xl: "60%" }}>
        <CardMedia component="img" image="/assets/audiobg.png" alt="audio" sx={{ width: "100%", height: "100%", borderRadius: "4px" }} />
      </Box>
      <CustomAudioPlayer url={url} />
    </>
  );
}
