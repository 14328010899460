import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import SkylarkLabsCircle from "./SkylarkLabsCircle";
export interface SignUpBaseLayoutProps {
  children?: React.ReactNode;
  isLoading: boolean;
}

export default function SignUpBaseLayout({ children, isLoading }: SignUpBaseLayoutProps) {
  const theme = useTheme();
  return (
    <Grid
      container
      px={5}
      py={{ xs: 25, sm: 17, md: 15, lg: 20 }}
      sx={{
        height: "100vh",
        overflowY: "hidden",
        overflowX: "hidden",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <SkylarkLabsCircle />
      <Box
        sx={{ zIndex: 900, position: "absolute", top: { xs: 30, md: 30, lg: 40 }, left: { xs: 30, md: 30, lg: 40 }, display: "flex", gap: "13px" }}
      >
        <img src="/skylarklab_logo_black.png" alt="logo" style={{ width: "32px", height: "32px" }} />
        <Box>
          <Typography variant="h2" color={(theme) => theme.palette.text.titleLabel}>
            Low Code Platform
          </Typography>
          <Typography variant="h4" color={(theme) => theme.palette.additionalColors.light} sx={{ textTransform: "none" }}>
            by Skylark
          </Typography>
        </Box>
      </Box>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        xs={12}
        sm={9}
        md={6}
        lg={5.5}
        xl={4}
        py={{ xs: 2, sm: 6, lg: 7, xl: 7 }}
        px={{ xs: 6, sm: 6, lg: 6, xl: 6 }}
        sx={(theme) => ({
          background: theme.palette.dashboardContainer.authBackground,
          boxShadow: theme.palette.dashboardContainer.authBoxShadow,
          borderRadius: "20px",
          zIndex: 900,
          // maxHeight: { xs: "89%", sm: "61%", md: "78%", lg: "80%", xl: "70%" },
        })}
      >
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ zIndex: 1, width: "100%" }}>
          {children}
        </Box>
      </Grid>
    </Grid>
  );
}
