import { SxProps, Theme } from '@mui/material';

export const sxTableCell:SxProps<Theme> = {
  fontSize: "14px",
  color: (theme) => theme.palette.text.tableHeader,
  borderBottom: (theme) => `1px solid${theme.palette.dashboardContainer.tableBorder}`,
  textAlign: "left",
};

export const sxTableCellBody:SxProps<Theme> = {
  borderBottom: (theme) => `1px solid ${theme.palette.dashboardContainer.tableBorder}`,
  textAlign: "left",
  fontSize: "16px",
  fontWeight: 400,
};

export const sxTableCellCenter:SxProps <Theme>= {
  fontSize: "14px",
  color: "#5A80AA",
  borderBottom: (theme) => `1px solid ${theme.palette.dashboardContainer.tableBorder}`,
  textAlign: "center",
};

export const sxTableCellBodyCenter:SxProps = {
  borderBottom: "1px solid #41527D",
  textAlign: "center",
  fontSize: "16px",
};