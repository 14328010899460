import BusinessIcon from "@mui/icons-material/Business";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import SourceIcon from "@mui/icons-material/Source";
import { Grid, Typography, useTheme } from "@mui/material";
import ConnectionCard from "./ConnectionsCard";

export default function Connections() {
  const theme = useTheme();
  const PersonData = {
    icon: <PersonIcon sx={{ m: "auto" }} />,
    title: "123 Persons",
    data: [
      {
        name: "Person Name",
        description: "Description",
      },
      {
        name: "Person Name",
        description: "Description",
      },
      {
        name: "Person Name",
        description: "Description",
      },
      {
        name: "Person Name",
        description: "Description",
      },
    ],
  };

  const orgData = {
    icon: <BusinessIcon sx={{ m: "auto" }} />,
    title: "76 Organizations",
    data: [
      {
        name: "Organization Name",
        description: "Description",
      },
      {
        name: "Organization Name",
        description: "Description",
      },
      {
        name: "Organization Name",
        description: "Description",
      },
      {
        name: "Organization Name",
        description: "Description",
      },
    ],
  };

  const locationData = {
    icon: <LocationOnIcon sx={{ m: "auto" }} />,
    title: "123 Locations",
    data: [{ name: "USA" }, { name: "China" }, { name: "Canada" }, { name: "Japan" }, { name: "Ukraine" }],
  };

  const topicData = {
    icon: <SourceIcon sx={{ m: "auto" }} />,
    title: "123 Topics",
    data: [{ name: "Terrorism" }, { name: "Weapon" }, { name: "Kill" }, { name: "Violence" }, { name: "Hate" }, { name: "Hate1" }],
  };

  const eventsData = {
    icon: <CalendarTodayIcon sx={{ m: "auto" }} />,
    title: "76 Events",
    data: [
      { name: "The balloon entered American airspace" },
      { name: "The balloon entered Canadian airspace" },
      { name: "The balloon returned to U.S. airspace" },
      { name: "The balloon floated over Montana near Malmstrom" },
    ],
  };

  return (
    <Grid container mt={3} mb={5}>
      <Typography variant="h3" color={theme.palette.text.titleLabel} sx={{ fontSize: "22px" }}>
        Connections
      </Typography>
      <Typography
        ml="auto"
        variant="link5"
        color={theme.palette.text.default}
        display={"flex"}
        sx={{
          cursor: "pointer",
          fontSize: "16px",
        }}
      >
        View Connections
        <ChevronRightIcon sx={{ color: theme.palette.text.default, mt: "-6px" }} />
      </Typography>
      <Grid mt={"-10px"} container spacing={3}>
        <Grid item xs={6} sm={4} display="flex">
          <ConnectionCard cardData={PersonData} />
        </Grid>

        <Grid item xs={6} sm={4} display="flex">
          <ConnectionCard cardData={orgData} />
        </Grid>
        <Grid item xs={6} sm={4} display="flex">
          <ConnectionCard cardData={locationData} />
        </Grid>
        <Grid item xs={6} sm={6} display="flex">
          <ConnectionCard cardData={topicData} />
        </Grid>
        <Grid item xs={6} sm={6} display="flex">
          <ConnectionCard cardData={eventsData} />
        </Grid>
      </Grid>
    </Grid>
  );
}
