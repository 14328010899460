import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { LuArrowDownUp } from "react-icons/lu";
import CustomTab from "../../../../components/CustomTab";
import RadioFilterButton from "../../../../components/RadioFilterButton";
import EntitiesDetails from "./components/EntitiesDetails";

const tabs = [
  {
    id: "all",
    title: "All",
    disabled: false,
    component: <EntitiesDetails type="all" />,
  },
  {
    id: "persons",
    title: "Persons",
    disabled: false,
    component: <EntitiesDetails type="person" />,
  },
  {
    id: "organizations",
    title: "Organizations",
    disabled: false,
    component: <EntitiesDetails type="organization" />,
  },
  {
    id: "locations",
    title: "Locations",
    disabled: false,
    component: <EntitiesDetails type="location" />,
  },
  {
    id: "events",
    title: "Events",
    disabled: false,
    component: <EntitiesDetails type="event" />,
  },
  {
    id: "topics",
    title: "Topics",
    disabled: false,
    component: <EntitiesDetails type="topic" />,
  },
];
export default function EntitiesSection() {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [sortedValue, setsortedValue] = useState("risk score: extreme first");
  const [subtype, setSubType] = useState("all");

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleRiskFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setsortedValue(e.target.value);
  };

  const handleTypeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubType(e.target.value);
  };

  const riskScoreOptions = [
    { id: "risk-score-extreme-first", text: "Extreme First" },
    { id: "risk-score-low-first", text: "Low First" },
  ];
  const subType = [
    { id: "all", text: "All" },
    { id: "historical-figure", text: "Historical Figure" },
    { id: "social-activist", text: "Social Activist" },
    { id: "humanitarian", text: "Humanitarian" },
    { id: "influential-figure", text: "Influential Figure" },
    { id: "goverment-worker", text: "Goverment Worker" },
    { id: "civilians", text: "Civilians" },
    { id: "business figure", text: "Business Figure" },
  ];

  return (
    <Box>
      <Box
        display="flex"
        sx={{
          [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
            gap: 2,
          },
          borderTop: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          pt: 3,
        }}
      >
        <CustomTab
          tabs={tabs}
          handleActiveTab={handleActiveTab}
          sx={{
            border: `1px solid ${theme.palette.primary.tertiary}`,
            textAlign: "center",
            p: "0 2px",
            alignItems: "center",
            borderRadius: "6px",
            [theme.breakpoints.only("lg")]: {
              width: "65%",
            },
          }}
          activeTab={activeTab}
        />
        <Box ml={{ lg: "auto", gap: "10px", display: "flex" }}>
          {activeTab !== "all" && (
            <RadioFilterButton
              label="SubType"
              onChange={(value) => {}}
              options={subType}
              startIcon={<LuArrowDownUp color={theme.palette.primary.main} size={16} />}
              defaultValue={subType[0].id}
              sx={{ width: "140px" }}
            />
          )}
          <RadioFilterButton
            label="Risk Score"
            onChange={(value) => {}}
            options={riskScoreOptions}
            startIcon={<LuArrowDownUp color={theme.palette.primary.main} size={16} />}
            defaultValue={riskScoreOptions[0].id}
            sx={{ width: "200px" }}
          />
        </Box>
      </Box>
      {tabs.find((tab) => tab.id === activeTab)?.component}
    </Box>
  );
}
