import { Box, Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { NOTIFICATIONS } from "../../../utils/constants";
import { formatDateTimeIntoCurrentTimeZone } from "../../../utils/helper";
import NotificationCard from "./NotificationCard";

const notificationDates = [new Date(), new Date(), new Date()];

export default function NotificationsContainer({ unreadCount = 5 }) {
  const matches = useMediaQuery("(min-width:1281px)");

  return (
    <Box display="flex" flexDirection="column" gap={"24px"} width="100%">
      <Box display="flex" justifyContent={"space-between"}>
        <Typography sx={(theme) => ({ ...theme.typography.h5 })}>{unreadCount} New Notification</Typography>
        <Tooltip title="Mark All as Read">
          <Typography
            sx={(theme) => ({
              ...theme.typography.link5,
              cursor: "pointer",
              color: unreadCount > 0 ? theme.palette.primary.main : theme.palette.action.disabled,
            })}
            onClick={() => {
              if (unreadCount > 0) {
              }
            }}
          >
            Mark All As Read
          </Typography>
        </Tooltip>
      </Box>
      <Box>
        {notificationDates.length > 0 ? (
          notificationDates.map((date, index) => (
            <React.Fragment key={`notification-${index}`}>
              <Box key={`Date-${index}`} display={"flex"} alignItems={"center"} justifyContent={"center"} mb={2} mt={4}>
                <Typography variant="h5">{formatDateTimeIntoCurrentTimeZone(date)}</Typography>
              </Box>
              <Grid container spacing={3}>
                {NOTIFICATIONS.length > 0 ? (
                  NOTIFICATIONS.map((notif, index) => (
                    <Grid item xs={12} lg={matches ? 6 : 12} key={`date-${index}`}>
                      <NotificationCard
                        key={index}
                        label={notif.label}
                        text={notif.text}
                        category={notif.category}
                        timestamp={formatDateTimeIntoCurrentTimeZone(notif.timestamp)}
                        read={notif.read}
                      />
                    </Grid>
                  ))
                ) : (
                  <Box textAlign="center">
                    <Typography variant="caption">No Notifications Found</Typography>
                  </Box>
                )}
              </Grid>
            </React.Fragment>
          ))
        ) : (
          <Box textAlign="center">
            <Typography variant="caption">No Notifications Found</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
