import { Box, Grid } from "@mui/material";
import { FAQS } from "../../../../utils/constants";
import AllCollections from "./AllCollections";

interface TopicsProps {
  openSpecificTopic(id: number): void;
}

export default function Topics({ openSpecificTopic }: TopicsProps) {
  return (
    <Box overflow="auto" pb={12} mt={1} display="flex" justifyContent="center" px={{ xs: 2, md: 10, xl: 30 }}>
      <Grid container spacing={2}>
        {FAQS.map((faq) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            xl={3}
            key={`faq-${faq?.id}`}
            onClick={() => openSpecificTopic(faq?.id)}
            sx={{ display: "flex", cursor: "pointer" }}
          >
            <AllCollections faqItem={faq} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
