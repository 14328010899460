import CloseIcon from "@mui/icons-material/Close";
import { Box, FormControl, Typography, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomInputField from "../../../components/CustomInputField";
import SubmitLoadingButton from "../../../components/SubmitLoadingButton";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";

export interface AddNewCaseDialogProps {
  open: boolean;
  handleToggle: () => void;
}

export interface FormDataType {
  name: string;
  saveTheCase: boolean;
}

const initialFormData: FormDataType = {
  name: "",
  saveTheCase: false,
};

export default function AddNewCaseDialog({ open, handleToggle }: AddNewCaseDialogProps) {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();
  const [formData, setFormData] = React.useState(initialFormData);

  const handleInputChange = ({ fieldName, value }: { fieldName: string; value: any }) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleCreateCustomCase = () => {
    if (formData.name) {
      handleRouteClick(routes.createCustomCase.path.replace(":caseId", formData.name.replaceAll(" ", "-")));
    } else {
      toast.warning("Please fill the name.");
    }
  };

  return (
    <Dialog
      onClose={handleToggle}
      open={open}
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          py: 4,
          px: 3,
          minWidth: "50vw",
          minHeight: "50vh",
        },
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h1" m="auto">
          New custom case
        </Typography>
        <CustomIconButton onClick={handleToggle} sx={{ color: theme.palette.error.main }}>
          <CloseIcon />
        </CustomIconButton>
      </Box>
      <DialogContent sx={{ margin: "auto", display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Box>
          <FormControl fullWidth>
            <Typography variant="caption1">Name</Typography>
            <CustomInputField
              value={formData.name}
              required
              name="name"
              placeholder="Name"
              size="small"
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange({ fieldName: e.target.name, value: e.target.value })}
              autoComplete="off"
            />
          </FormControl>
          <CustomCheckbox label="Save the case" />
        </Box>
        <Box mx="auto" my={3}>
          <SubmitLoadingButton isLoading={false} sx={{ px: 5 }} onClick={handleCreateCustomCase}>
            Create
          </SubmitLoadingButton>
        </Box>
        <Typography variant="subtitle2" mt={2} textAlign="center">
          Have any isues?
          <NavLink
            to="/login"
            style={{
              color: theme.palette.primary.main,
              textDecoration: "none",
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            Contact Us
          </NavLink>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
