import { Grid } from "@mui/material";
import ContentUILoader from "../../../../../../components/ContentUILoader";
import PublicationCard from "./PublicationCard";

interface ListProps {
  [x: string]: any;
}

interface ListViewProps {
  publicationData: ListProps[];
  drawerOpen: boolean;
  isRefresh: boolean;
}
export default function GridView({ publicationData, drawerOpen, isRefresh }: ListViewProps) {
  return (
    <Grid container spacing={2}>
      {!isRefresh ? (
        <>
          {publicationData.map((data: any, idx) => (
            <Grid key={`image-${idx}`} item xs={12} sm={drawerOpen ? 12 : 6} lg={drawerOpen ? 6 : 4} display="flex">
              <PublicationCard cardData={data} view={"grid"} />
            </Grid>
          ))}
        </>
      ) : (
        <ContentUILoader type="publication" count={5} drawerOpen={drawerOpen} />
      )}
    </Grid>
  );
}
