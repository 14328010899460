import { Box, Paper, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import CardTitle from "../components/CardTitle";
import Header from "../components/Header";
import { routes } from "../routes";

export interface TabRecord {
  id: string | number;
  title: string;
  disabled?: boolean;
  component: React.ReactNode;
}

export interface TabLayoutProps {
  children?: React.ReactNode;
  tabs: TabRecord[];
  subPage?: boolean;
  primarySubPage?: string;
}

export default function TabLayout({ children, tabs, subPage, primarySubPage }: TabLayoutProps) {
  const theme = useTheme();
  const location = useLocation();

  const pathdata = Object.keys(routes).filter((routeKey) => routes[routeKey].path === location.pathname);
  const matchedPage = pathdata && routes[pathdata[0]];

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = React.useState<TabRecord>(tabs[0]);

  if (!searchParams.get("tab")) {
    setSearchParams({ tab: String(tabs[0].id) });
  }

  useEffect(() => {
    if (!searchParams.get("tab")) {
      if (subPage && primarySubPage) {
        setSearchParams({ tab: String(tabs[0].id), page: primarySubPage });
      } else {
        setSearchParams({ tab: String(tabs[0].id) });
      }
    }
  }, []);

  const handleChangeActiveTab = (tabId: string | number) => {
    setActiveTab(tabs.find((tab) => tab.id === tabId) || tabs[0]);
    setSearchParams({ tab: String(tabId) });
  };

  const renderTabContent = (tabId: string | number) => {
    const firstTab: boolean = tabs[0].id === tabId;
    return (
      <Paper
        sx={{
          background: theme.palette.background.default,
          boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
          borderRadius: firstTab ? "0 18px 18px 18px" : "18px",
          paddingTop: "0px",
          height: "100%",
          overflowY: "scroll",
          py: 2,
          px: 2,
        }}
      >
        {tabs.find((tab) => tab.id === tabId)?.component}
      </Paper>
    );
  };

  return (
    <>
      <Box display="flex" position="relative">
        {tabs.map((tab) => {
          return (
            <React.Fragment key={tab.id}>
              <Paper
                onClick={() => {
                  handleChangeActiveTab(tab.id);
                }}
                sx={{
                  cursor: tab.id === searchParams.get("tab") ? "default" : "pointer",
                  background: tab.id === searchParams.get("tab") ? theme.palette.background.default : "transparent",
                  boxShadow: tab.id === searchParams.get("tab") ? theme.palette.dashboardContainer.insetBoxShadow : "none",
                  borderRadius: "18px 18px 0px 0px",
                  px: "2rem",
                  py: "1.4rem",
                  width: "fit-content",
                  position: "relative",
                }}
              >
                <CardTitle isOpen={tab.id === searchParams.get("tab")} title={tab.title} />
                {tab.id === searchParams.get("tab") && (
                  <Box
                    sx={{
                      position: "absolute",
                      ml: "-2rem",
                      height: "1.5rem",
                      width: "96%",
                      top: "3.5rem",
                      background: theme.palette.background.default,
                    }}
                  ></Box>
                )}
              </Paper>
            </React.Fragment>
          );
        })}
        {matchedPage?.header && <Header sx={{ [theme.breakpoints.down("md")]: { display: "none" } }} showOptions={matchedPage?.header} />}
      </Box>
      <Box height="100%">{renderTabContent(activeTab.id)}</Box>
      {children}
    </>
  );
}
