import { Box, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import CustomDivider from "../../../../../../../components/CustomDivider";
interface TitleBox {
  title: string;
  description: string;
}

interface TitleBoxProps {
  titleData: TitleBox;
}
const TitleBox: FC<TitleBoxProps> = ({ titleData }) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: "100%", border: theme.palette.additionalColors.databasecardBorder, borderRadius: 2, p: 2 }}>
      <Typography variant="h4" color={theme.palette.text.titleLabel}>
        {titleData?.title}
      </Typography>
      <CustomDivider sx={{ margin: "12px auto" }} />
      <Typography variant="body3" color={theme.palette.text.titleLabel}>
        {titleData?.description}
      </Typography>
    </Box>
  );
};

export default TitleBox;
