import { BrowserRouter, Routes as CoreRoutes, Route } from "react-router-dom";

import NotFound from "../pages/NotFound";
import PrivateRoute from "./PrivateRoute";
import { RoutesInterface, routesArr } from "./index";

export default function Routes() {
  return (
    <BrowserRouter>
      <CoreRoutes>
        {routesArr.map((route: [any, RoutesInterface], index) => {
          if (route[1].isLoginRequired) {
            return (
              <Route
                key={index}
                path={route[1].path}
                element={<PrivateRoute showSideDrawer={route[1].showSideDrawer}>{route[1].page}</PrivateRoute>}
              />
            );
          }
          return <Route key={index} path={route[1].path} element={route[1].page} />;
        })}
        {/* fallback route to show the 404 page */}
        <Route path="*" element={<NotFound />} />
      </CoreRoutes>
    </BrowserRouter>
  );
}
