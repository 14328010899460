import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import CustomDivider from "../../../../../../components/CustomDivider";
import CustomRadialBar from "../../../../../../components/CustomRadial";
import CustomTextButton from "../../../../../../components/CustomTextButton";
import { routes } from "../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../routes/hooks";
import SentimentAnalysisCard from "./SentimentAnalysisCard";

interface ResultData {
  [x: string]: any;
}

export default function AISummary() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const sentimentData = [
    { key: "positive", value: 77 },
    { key: "neutral", value: 5 },
    { key: "negative", value: 18 },
  ];

  const faceRecognation: ResultData = { men: 67, women: 35, children: 12, unidentified: 5 };

  const entityRecognation: ResultData = { men: 67, women: 35, children: 12, unidentified: 5 };

  const handleNavigation = (type: string) => {
    const typeData = type.split(" ")[0].toLowerCase();
    handleRouteClick(routes.recognationOverview.path.replace(":recognation", typeData), { title: type });
  };

  const RecognationBox = (title: string, data: any) => (
    <Box
      sx={{
        width: "100%",
        flexFlow: "row wrap",
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        borderRadius: 2,
        p: 2,
      }}
    >
      <Box display="flex" mb={1}>
        <Typography variant="h4" fontSize={"18px"}>
          {title}
        </Typography>
        <InfoOutlinedIcon sx={{ color: theme.palette.text.tableHeader, ml: 1, fontSize: "16px" }} />
      </Box>
      <CustomDivider />
      <Box display="flex" justifyContent="center" mt={2}>
        {Object.keys(data).map((category, idx) => (
          <Box
            key={`results-${idx}`}
            sx={{
              width: "80px",
            }}
          >
            <CustomRadialBar value={data[category]} count={`${data[category]}`} subTitle={category} />
          </Box>
        ))}
      </Box>
      <CustomTextButton sx={{ mt: 3 }} onClick={() => handleNavigation(title)}>
        Show more
      </CustomTextButton>
    </Box>
  );

  return (
    <Grid container mt={4}>
      <Typography variant="h3" color={theme.palette.text.titleLabel} sx={{ fontSize: "22px" }}>
        AI Summary
      </Typography>
      <Grid mt={1} container spacing={2}>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <SentimentAnalysisCard sentimentData={sentimentData} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} display="flex">
          {RecognationBox("Face recognition", faceRecognation)}
        </Grid>
        <Grid item xs={12} sm={12} md={4} display="flex">
          {RecognationBox("Entity recognition", entityRecognation)}
        </Grid>
      </Grid>
    </Grid>
  );
}
