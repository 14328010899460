import { Box, Grid } from "@mui/material";
import AISummary from "./AISummary";
import Connections from "./Connections";
import FeedSummary from "./FeedSummary";
import QuentityResults from "./QuentityResults";
import RiskFactorCard from "./RiskFactorCard";
import RiskScore from "./RiskScore";
import UserDemographics from "./UserDemographics";

const riskFactorData = [
  {
    title: "Behavioral Risk Factor",
    status: "high",
    subData: [
      {
        label: "Unusual online activities",
        count: 14,
      },
      {
        label: "Unusual online activities",
        count: 24,
      },
      {
        label: "Unusual online activities",
        count: 0,
      },
      {
        label: "Unusual online activities",
        count: 56,
      },
    ],
  },
  {
    title: "Behavioral Risk Factor",
    status: "high",
    subData: [
      {
        label: "Unusual online activities",
        count: 14,
      },
      {
        label: "Unusual online activities",
        count: 24,
      },
      {
        label: "Unusual online activities",
        count: 0,
      },
      {
        label: "Unusual online activities",
        count: 56,
      },
    ],
  },
  {
    title: "Content Risk Factor",
    status: "neutral",
    subData: [
      {
        label: "Unusual online activities",
        count: 14,
      },
      {
        label: "Unusual online activities",
        count: 24,
      },
      {
        label: "Unusual online activities",
        count: 0,
      },
      {
        label: "Unusual online activities",
        count: 56,
      },
    ],
  },
  {
    title: "Communication Risk Factor",
    status: "moderate",
    subData: [
      {
        label: "Unusual online activities",
        count: 14,
      },
      {
        label: "Unusual online activities",
        count: 24,
      },
      {
        label: "Unusual online activities",
        count: 0,
      },
      {
        label: "Unusual online activities",
        count: 56,
      },
    ],
  },
  {
    title: "Behavioral Risk Factor",
    status: "high",
    subData: [
      {
        label: "Unusual online activities",
        count: 14,
      },
      {
        label: "Unusual online activities",
        count: 24,
      },
      {
        label: "Unusual online activities",
        count: 0,
      },
      {
        label: "Unusual online activities",
        count: 56,
      },
    ],
  },
  {
    title: "Behavioral Risk Factor",
    status: "extreme",
    subData: [
      {
        label: "Unusual online activities",
        count: 14,
      },
      {
        label: "Unusual online activities",
        count: 24,
      },
      {
        label: "Unusual online activities",
        count: 0,
      },
      {
        label: "Unusual online activities",
        count: 56,
      },
    ],
  },
];

export default function Entities() {
  return (
    <Box>
      <RiskScore />
      <Box mt={2}>
        <Grid container mt={2} spacing={2}>
          {riskFactorData.map((riskData, idx) => {
            return (
              <Grid item xs={12} sm={6} lg={4} key={`statics-${idx}`} display="flex" justifyContent="space-between" gap={1} width="100%">
                <RiskFactorCard riskFactorData={riskData} checkBox={true} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <QuentityResults />
      <FeedSummary />
      <AISummary />
      <UserDemographics />
      <Box mt={5}>
        <Connections />
      </Box>
    </Box>
  );
}
