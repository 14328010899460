import FavoriteIcon from "@mui/icons-material/Favorite";
import MessageIcon from "@mui/icons-material/Message";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import ThreePIcon from "@mui/icons-material/ThreeP";
import { Box, Grid, Link, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { HiMiniCursorArrowRipple } from "react-icons/hi2";
import CustomButton from "../../../../../../../components/CustomButton";
import CustomCarousel from "../../../../../../../components/CustomCarousel";
import CustomChip from "../../../../../../../components/CustomChip";
import CustomIconButton from "../../../../../../../components/CustomIconButton";
import EditTagsDialog, { TagsData } from "./EditTagsDialog";
import Interactions from "./Interaction";
import PublicationDetails from "./PublicationDetails";

interface Info {
  [x: string]: any;
}

interface InfoProps {
  infoData: Info;
  getData: (data: TagsData) => void;
  editing: boolean;
}

export default function PublicationInfo({ infoData, getData, editing }: InfoProps) {
  const theme = useTheme();
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [openInteractions, setOpenInteractions] = useState<boolean>(false);
  const [tab, setActiveTab] = useState<string>("");
  const [tags, setTags] = useState<string[]>(infoData?.tag);
  const [editingTags, setEditingTags] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  useEffect(() => setOpen(anchorEl ? true : false), [anchorEl]);

  useEffect(() => setId(open ? "simple-popover" : ""), [open]);

  const handleModalOpen = () => {
    setOpenDetails((prev) => !prev);
  };

  const handleInteractionOpen = () => {
    setActiveTab("likes");
    setOpenInteractions((prev) => !prev);
  };

  const handlecommentInteractionOpen = () => {
    setActiveTab("comments");
    setOpenInteractions((prev) => !prev);
  };

  const handleTagsBoxClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setEditingTags(true);
    setAnchorEl(event.currentTarget);
  };
  const getDataHandler = (data: TagsData) => {
    setTags(data.tags);
    getData(data);
  };

  const handleEditTagsClose = () => {
    setEditingTags(false);
    setOpen(false);
    setAnchorEl(null);
  };

  const likesData = [
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: true },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: true },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: true },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: true },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
  ];
  const commentsData = [
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: true },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { prodile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
  ];

  return (
    <Grid container spacing={5}>
      <Grid item xs={3}>
        <img src={infoData?.media_image} alt="media" style={{ width: "138px", height: "138px", borderRadius: "8px" }} />
        <br />
        <Link href={infoData?.source_link} underline="none" sx={{ ...theme.typography.body3 }}>
          {infoData?.source_link}
        </Link>
        <Typography variant="h1" mt={5}>
          CNN
        </Typography>
        {infoData?.category === "news" ? (
          <Typography variant="body5" color={theme.palette.text.titleLabel} mt={3}>
            {infoData?.description}
          </Typography>
        ) : (
          <Box>
            <Box mt={2}>
              <Box display="flex" gap={1}>
                <PersonIcon sx={{ fontSize: "18px", mt: -0.5, color: theme.palette.text.tableHeader }} />
                <Typography variant="subtitle2">15 k followers</Typography>
              </Box>
              <Box display="flex" gap={1} mt={1.5}>
                <ThreePIcon sx={{ fontSize: "18px", mt: -0.5, color: theme.palette.text.tableHeader }} />
                <Typography variant="subtitle2">6044 following</Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Link href={"#"} underline="none" sx={{ ...theme.typography.body4 }}>
                #NAFO #WeAreNAFO <br /> #Russia_Is_A_Terrorist_State
              </Link>
            </Box>
          </Box>
        )}
        <Box color={theme.palette.text.default} display="flex" mt={5}>
          <PlaceIcon sx={{ fontSize: "1em", mt: -1.8 }} />
          <Typography variant="body5" mt={-1}>
            Atlanta, Georgia, USA
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-start"
          mt={3}
          sx={
            editing
              ? {
                  height: " 80px",
                  border: "2px solid transparent",
                  borderRadius: "4px",
                  backgroundImage: editingTags
                    ? theme.palette.additionalColors.cardActiveBorderImage
                    : theme.palette.additionalColors.cardDefaultBorderImage,
                  backgroundOrigin: "border-box",
                  backgroundClip: "content-box, border-box",
                  boxShadow: theme.palette.dashboardContainer.cardInsetBoxShadow,
                  position: "relative",
                }
              : {}
          }
          onClick={handleTagsBoxClick}
        >
          {tags.map((data: string, index: number) => (
            <CustomChip
              key={index}
              label={data}
              sx={{
                color: theme.palette.error.btnText,
                height: "24px",
                border: "none",
                mx: "4px",
                my: "4px",
                borderRadius: "4px",
              }}
              onDelete={() => {}}
              variant="outlined"
              deleteIcon={<></>}
            />
          ))}
          {editingTags && (
            <HiMiniCursorArrowRipple size={30} color={theme.palette.text.titleLabel} style={{ position: "absolute", bottom: 0, right: 4 }} />
          )}
          <EditTagsDialog anchor={anchorEl} id={id} open={open} defaultTags={tags} getData={getDataHandler} handleClose={handleEditTagsClose} />
        </Box>
        <Box mt={5} display="flex" gap={1.5}>
          <img src="/assets/profile.png" alt="profile" width={"36px"} height={"36px"} />
          <Typography variant="subtitle1" color={theme.palette.text.titleLabel} mt={1.5}>
            Ann Thompson
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="body4" color={theme.palette.text.tableHeader}>
          {infoData?.created_at}
        </Typography>
        <Box mt={2}>
          <Typography variant="h2" color={theme.palette.text.titleLabel}>
            {infoData?.title}
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="body3" color={theme.palette.text.titleLabel}>
            {infoData?.summary}
          </Typography>
        </Box>
        <Box mt={2}>
          <CustomCarousel
            images={[
              {
                id: 1,
                img: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
              },
              { id: 2, img: "https://i0.wp.com/payloadspace.com/wp-content/uploads/2023/02/Screenshot_20230203_090400.png?fit=1301%2C987&ssl=1" },
              {
                id: 3,
                img: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
              },
              { id: 4, img: "https://i0.wp.com/payloadspace.com/wp-content/uploads/2023/02/Screenshot_20230203_090400.png?fit=1301%2C987&ssl=1" },
              {
                id: 5,
                img: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
              },
              { id: 6, img: "https://i0.wp.com/payloadspace.com/wp-content/uploads/2023/02/Screenshot_20230203_090400.png?fit=1301%2C987&ssl=1" },
            ]}
          />
        </Box>
        <Box display="flex" mt={3}>
          <Box display="flex" gap={2} sx={{ color: theme.palette.text.tableHeader }}>
            <CustomIconButton sx={{ width: "40px", height: "40px" }} onClick={handleInteractionOpen}>
              <FavoriteIcon sx={{ color: theme.palette.text.tableHeader }} />
            </CustomIconButton>
            <Typography variant="h6" mt={1.8}>
              123 Likes
            </Typography>
            <CustomIconButton sx={{ width: "40px", height: "40px" }} onClick={handlecommentInteractionOpen}>
              <MessageIcon sx={{ color: theme.palette.text.tableHeader }} />
            </CustomIconButton>
            <Typography variant="h6" mt={1.8}>
              16 Comments
            </Typography>
          </Box>
          <Box ml="auto" display="flex" gap={1.5}>
            <CustomButton sx={{ border: "none", boxShadow: "none", background: "transparent", ml: -2 }} onClick={handleModalOpen}>
              View Details
            </CustomButton>
            <CustomButton endIcon={<OpenInNewIcon />}>Open Source</CustomButton>
          </Box>
        </Box>
      </Grid>
      <PublicationDetails open={openDetails} handleClose={handleModalOpen} />
      <Interactions
        open={openInteractions}
        handleClose={tab === "likes" ? handleInteractionOpen : handlecommentInteractionOpen}
        interactionData={{ likesData: likesData, commentsData: commentsData }}
        activeTabOpt={tab}
      />
    </Grid>
  );
}
