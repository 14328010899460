import FlagIcon from "@mui/icons-material/Flag";
import HexagonIcon from "@mui/icons-material/Hexagon";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Grid, useTheme } from "@mui/material";
import CustomStatics from "../../../components/CustomStatics";
import RiskSummary from "../Components/Overview/RiskSummary";
import Title from "../Components/Title";

export default function Overviewrapper() {
  const theme = useTheme();
  const statisticData = [
    { label: "Sources", count: "100k", icon: <HexagonIcon sx={{ color: theme.palette.additionalColors.light }} /> },
    { label: "Results", count: "3578", icon: <InsertDriveFileIcon sx={{ color: theme.palette.additionalColors.light }} /> },
    { label: "Mentions", count: "8687", icon: <MarkChatUnreadIcon sx={{ color: theme.palette.additionalColors.light }} /> },
    { label: "Entities", count: "515", icon: <PersonIcon sx={{ color: theme.palette.additionalColors.light }} /> },
    { label: "Red Flags", count: "21", icon: <FlagIcon sx={{ color: theme.palette.additionalColors.light }} /> },
  ];
  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
      }}
      p={2}
    >
      <Title title="Overview" time="Last updated today at 16:54 (IST)" refresh={true} />
      <Grid
        container
        mt={2}
        spacing={2}
        sx={{ borderTop: "1px solid", borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1` }}
      >
        {statisticData.map((staticData: any, idx) => (
          <Grid item xs={12} sm={4} lg={2.4} key={`statics-${idx}`} display="flex" justifyContent="space-between" gap={1} width="100%">
            <CustomStatics label={staticData?.label} count={staticData?.count} icon={staticData?.icon} />
          </Grid>
        ))}
      </Grid>
      <Grid mt={4}>
        <RiskSummary />
      </Grid>
    </Box>
  );
}
