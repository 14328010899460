import DashboardIcon from "@mui/icons-material/Dashboard";
import ExtensionIcon from "@mui/icons-material/Extension";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import NearMeIcon from "@mui/icons-material/NearMe";
import SpeedSharpIcon from "@mui/icons-material/SpeedSharp";
import { Box, Typography, useTheme } from "@mui/material";

interface FAQItemProps {
  faqItem: {
    id: number;
    title: string;
    description: string;
    icon: string;
  };
}

export default function AllCollections({ faqItem }: FAQItemProps) {
  const theme = useTheme();

  const FAQIcon = () => {
    switch (faqItem?.icon) {
      case "extension":
        return <ExtensionIcon sx={{ color: theme.palette.text.tableHeader }} />;
      case "navigation":
        return <NearMeIcon sx={{ color: theme.palette.text.tableHeader }} />;
      case "about":
        return <DashboardIcon sx={{ color: theme.palette.text.tableHeader }} />;
      case "optimization":
        return <FmdGoodIcon sx={{ color: theme.palette.text.tableHeader }} />;
      case "model":
        return <SpeedSharpIcon sx={{ color: theme.palette.text.tableHeader }} />;
      case "monitor":
        return <MonitorHeartIcon sx={{ color: theme.palette.text.tableHeader }} />;
      case "default":
        break;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row wrap",
        width: "100%",
        background: theme.palette.background.tertiaryDarkGradient,
        border: `1px solid ${theme.palette.additionalColors.lightBorder}`,
        borderRadius: 2,
        p: 1.5,
      }}
    >
      <Box display="flex" gap={1.5}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: theme.palette.primary.tertiaryGradient,
            p: "0.5em",
            width: "2.75rem",
            height: "2.75rem",
            borderRadius: "50%",
          }}
          pt={1}
        >
          {FAQIcon()}
        </Box>

        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle1" sx={{ color: theme.palette.text.titleLabel, mb: 0.2 }}>
            {faqItem?.title}
          </Typography>
          <Typography variant="body4" sx={{ color: theme.palette.primary.inactiveIcon }}>
            {faqItem?.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
