import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { Box, Typography, useTheme } from "@mui/material";

interface InfoProps {
  handleOpenDetails(): void;
}

export default function NodeInfo({ handleOpenDetails }: InfoProps) {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" gap={1.2}>
      <Typography variant="subtitle2" color={theme.palette.text.titleLabel} mb={1}>
        Entity Name
      </Typography>
      <Typography variant="caption2" color={theme.palette.text.titleLabel} fontWeight={351}>
        Entity type: Person
      </Typography>
      <Typography variant="caption2" color={theme.palette.text.titleLabel} fontWeight={351}>
        Entity Sub type: Goverment worker
      </Typography>
      <Typography variant="caption2" color={theme.palette.text.titleLabel} fontWeight={351}>
        Location: USA
      </Typography>
      <Box display="flex">
        <ShareOutlinedIcon
          sx={{ color: theme.palette.text.default, transform: "rotate(-90deg)", fontSize: "18px", mt: "-3px", cursor: "pointer" }}
          onClick={handleOpenDetails}
        />
        <Typography variant="caption2" color={theme.palette.text.default}>
          356
        </Typography>
      </Box>
      <Box display="flex" gap={1} mt={0.5}>
        <Typography variant="subtitle3" color={theme.palette.text.titleLabel}>
          Risk Score:
        </Typography>
        <Typography variant="subtitle3" color={theme.palette.text.extremeStatus}>
          Extreme
        </Typography>
      </Box>
    </Box>
  );
}
