import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Popover,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import CustomButton from "../../../../../../components/CustomButton";
import CustomCloseIconButton from "../../../../../../components/CustomCloseIconButton";
import CustomStatus from "../../../../../../components/CustomStatus";

export interface SubData {
  label: string;
  count?: number;
}
export interface RiskFactorProps {
  title: string;
  status: string;
  subData: SubData[];
}
export interface RiskFactorData {
  [x: string]: boolean;
}
export interface RiskData {
  checked: boolean;
  status: string;
  riskFactor: RiskFactorData;
}
interface RelevanceDialogProps {
  anchor: HTMLElement | null;
  id?: number;
  riskFactorData: RiskFactorProps;
  open: boolean;
  getData?: (data: RiskData) => void;
  handleClose: () => void;
}

const RiskFactorDialog = ({ anchor, id, riskFactorData, open, getData, handleClose }: RelevanceDialogProps) => {
  const theme = useTheme();
  const [status, setStatus] = useState<string>("High");
  const [checked, setChecked] = useState<boolean>(true);
  const [riskFactor, setRiskFactor] = useState<RiskFactorData>({
    "Unusual online activities": true,
    "Involvement in hate speech, extremism, or illegal content": false,
    "Threats or incriminating posts": false,
    "Sharing of suspicious or illegal materials": false,
  });

  useEffect(() => {
    let capitalisedStatus = riskFactorData.status.replace(/^./, riskFactorData.status[0].toUpperCase());
    setStatus(capitalisedStatus);
    const initialRiskFactorData: { [x: string]: boolean } = {};

    for (const item of riskFactorData.subData) {
      initialRiskFactorData[item.label] = item?.count ? true : false;
    }
    setRiskFactor(initialRiskFactorData);
  }, [riskFactorData, open]);

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setStatus((event.target as HTMLInputElement).value);
  };
  const handleRiskFactorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setRiskFactor({
      ...riskFactor,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const saveHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    let data: RiskData = {
      checked: checked,
      status: status,
      riskFactor: riskFactor,
    };
    if (getData) {
      getData(data);
    }
    handleClose();
  };

  const closeHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleClose();
  };

  return (
    <Popover
      id={`${id}`}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        marginTop: "20px",
      }}
      slotProps={{
        paper: {
          sx: {
            background: theme.palette.background.gradientLight1,
            boxShadow: theme.palette.dashboardContainer.editTagsDialogBoxShadow,
            border: theme.palette.dashboardContainer.editTagsDialogBorder,
            borderRadius: "10px",
            width: "40%",
            p: 2,
            textAlign: "center",
          },
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
            Content risk factor:
          </Typography>
          <CustomCloseIconButton onClick={closeHandler} sx={{ marginLeft: { xs: "0", md: "80px" } }}>
            <CloseIcon sx={{ width: "16px", height: "16px" }} />
          </CustomCloseIconButton>
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ display: "flex", alignItems: "flex-start" }}>
            <FormLabel id="demo-controlled-radio-buttons-group" sx={{ ...theme.typography.caption2 }}>
              Risk score:
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={status}
              onChange={handleStatusChange}
              sx={{ display: "flex", flexDirection: "row", rowGap: 1, columnGap: 2 }}
            >
              <FormControlLabel
                value="Extreme"
                control={<Radio />}
                label={<CustomStatus label={"Extreme"} status={"extreme"} sx={{ width: "88px", height: "28px" }} />}
              />
              <FormControlLabel
                value="High"
                control={<Radio />}
                label={<CustomStatus label={"High"} status={"high"} sx={{ width: "88px", height: "28px" }} />}
              />
              <FormControlLabel
                value="Low"
                control={<Radio />}
                label={<CustomStatus label={"Low"} status={"low"} sx={{ width: "88px", height: "28px" }} />}
              />
              <FormControlLabel
                value="Neutral"
                control={<Radio />}
                label={<CustomStatus label={"Neutral"} status={"neutral"} sx={{ width: "88px", height: "28px" }} />}
              />
              <FormControlLabel
                value="Moderate"
                control={<Radio />}
                label={<CustomStatus label={"Moderate"} status={"moderate"} sx={{ width: "88px", height: "28px" }} />}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="column" alignItems="flex-start">
          <FormLabel component="legend" sx={{ ...theme.typography.caption2 }}>
            Specific Risk Factors:
          </FormLabel>
          <FormControl sx={{ width: "100%" }} component="fieldset" variant="standard">
            <FormGroup sx={{ width: "100%" }}>
              <FormControlLabel
                sx={{ display: "flex", justifyContent: "space-between", ml: 0 }}
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={riskFactor["Unusual online activities"]}
                    onChange={handleRiskFactorChange}
                    name="Unusual online activities"
                    sx={{ p: "6px" }}
                  />
                }
                label={
                  <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
                    Unusual online activities{" "}
                  </Typography>
                }
              />
              <FormControlLabel
                sx={{ display: "flex", justifyContent: "space-between", textAlign: "start", ml: 0 }}
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={riskFactor["Involvement in hate speech, extremism, or illegal content"]}
                    onChange={handleRiskFactorChange}
                    name="Involvement in hate speech, extremism, or illegal content"
                    sx={{ p: "6px" }}
                  />
                }
                label={
                  <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
                    Involvement in hate speech, extremism, or illegal content
                  </Typography>
                }
              />
              <FormControlLabel
                sx={{ display: "flex", justifyContent: "space-between", ml: 0 }}
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={riskFactor["Threats or incriminating posts"]}
                    onChange={handleRiskFactorChange}
                    name="Threats or incriminating posts"
                    sx={{ p: "6px" }}
                  />
                }
                label={
                  <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
                    Threats or incriminating posts
                  </Typography>
                }
              />
              <FormControlLabel
                sx={{ display: "flex", justifyContent: "space-between", ml: 0 }}
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={riskFactor["Sharing of suspicious or illegal materials"]}
                    onChange={handleRiskFactorChange}
                    name="Sharing of suspicious or illegal materials"
                    sx={{ p: "6px" }}
                  />
                }
                label={
                  <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
                    Sharing of suspicious or illegal materials
                  </Typography>
                }
              />
            </FormGroup>
          </FormControl>
          <Box mt={2}>
            <Checkbox
              checked={checked}
              onChange={handleChecked}
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                p: 0,
                fontSize: "14px",
                fontWeight: "400",
                "& .MuiSvgIcon-root": {
                  fill: checked ? theme.palette.checkbox.default : theme.palette.checkbox.darkBorder,
                },
                "& .MuiButtonBase-root.MuiCheckbox-root": {
                  pl: "5px",
                },
                "&:hover .MuiSvgIcon-root": {
                  fill: checked ? theme.palette.checkbox.default : "linear-gradient(135deg, rgba(38, 46, 63, 0.70) 0%, rgba(29, 35, 48, 0.70) 100%)",
                  stroke: checked ? "none" : theme.palette.checkbox.darkBorder,
                },
                "&:active .MuiSvgIcon-root": {
                  fill: checked ? "#245785" : theme.palette.checkbox.darkBorder,
                },
              }}
            />
            <Typography variant="caption" ml={1} color={theme.palette.text.titleLabel}>
              Apply new tags configuration to similar cases
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} display="flex" gap={1.5} mt={1} justifyContent="flex-end">
          <CustomButton
            variant="contained"
            sx={{
              ...theme.typography.h6,
              border: `0.5px solid ${theme.palette.additionalColors.border1Light}`,
              color: theme.palette.primary.main,
              background: theme.palette.background.tertiaryDarkGradient,
              boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
              "&:hover": {
                background: theme.palette.background.tertiaryGradient,
                boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
              },
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              handleClose();
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            sx={{
              ...theme.typography.bigButton,
              background: theme.palette.background.secondaryBgColor,
              color: theme.palette.text.secondaryText,
              width: "123px",
            }}
            onClick={saveHandler}
          >
            Save
          </CustomButton>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default RiskFactorDialog;
