import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import React from "react";

export interface SubmitLoadingButtonProps extends ButtonProps {
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  isLoading: boolean;
  [x: string]: any;
}

export default function SubmitLoadingButton({ sx, children, isLoading = false, ...rest }: SubmitLoadingButtonProps) {
  const theme = useTheme();
  return (
    <Button
      type="submit"
      disabled={isLoading}
      variant="contained"
      sx={{
        fontWeight: "bold",
        border: "none",
        borderRadius: "4px",
        backgroundColor: theme.palette.primary.main,
        transform: "rotate(0.13deg)",
        boxShadow: theme.palette.dashboardContainer.primaryButtonBoxShadow,
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
        },
        "&:active": {
          backgroundColor: theme.palette.action.active,
          boxShadow: "inset 0px 4px 6px rgba(1, 19, 35, 0.4)",
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.action.disabled,
          color: theme.palette.background.default,
        },
        ...sx,
      }}
      {...rest}
    >
      {children} {isLoading && <CircularProgress color="inherit" size={16} sx={{ ml: 1 }} />}
    </Button>
  );
}
