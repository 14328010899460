import CloseIcon from "@mui/icons-material/Close";
import { Box, FormControl, Typography, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomInputField from "../../../components/CustomInputField";
import SubmitLoadingButton from "../../../components/SubmitLoadingButton";

export interface AddNewKeywordDialogProps {
  open: boolean;
  handleToggle: () => void;
  handleSubmit: (text: string) => void;
}

export interface FormDataType {
  text: string;
}

const initialFormData: FormDataType = {
  text: "",
};

export default function AddNewKeywordDialog({ open, handleToggle, handleSubmit }: AddNewKeywordDialogProps) {
  const theme = useTheme();
  const [formData, setFormData] = React.useState(initialFormData);

  const handleInputChange = ({ fieldName, value }: { fieldName: string; value: any }) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <Dialog
      onClose={handleToggle}
      open={open}
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          py: 4,
          px: 3,
          minWidth: "50vw",
          minHeight: "50vh",
        },
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h1" m="auto">
          Adding red-flagged words
        </Typography>
        <CustomIconButton onClick={handleToggle} sx={{ color: theme.palette.error.main }}>
          <CloseIcon />
        </CustomIconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Box mt={2}>
          <FormControl fullWidth>
            <Typography variant="caption1">Write your words separated by Comma(,)</Typography>
            <CustomInputField
              multiline
              rows={5}
              value={formData.text}
              required
              name="text"
              placeholder="Text here..."
              size="small"
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange({ fieldName: e.target.name, value: e.target.value })}
              autoComplete="off"
            />
          </FormControl>
        </Box>
        <Box mx="auto" mt={3}>
          <SubmitLoadingButton
            isLoading={false}
            onClick={() => {
              handleSubmit(formData.text);
              handleToggle();
              setFormData(initialFormData);
            }}
          >
            Add & Save
          </SubmitLoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
