import { Box, FormControl, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { FiUpload } from "react-icons/fi";
import BorderedCard from "../../../components/BorderedCard";
import CustomButton from "../../../components/CustomButton";
import CustomDivider from "../../../components/CustomDivider";
import CustomInputField from "../../../components/CustomInputField";
import CustomTextButton from "../../../components/CustomTextButton";
import StaticValueDisplay from "../../../components/StaticValueDisplay";
import classes from "../../../styles/AccountDetails.module.css";
import ChangePasswordDialog from "./ChangePasswordDialog";

export default function AccountDetails() {
  const theme = useTheme();
  const [imgSrc, setImgSrc] = React.useState<string>();
  const [editable, setEditable] = React.useState<boolean>(false);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = React.useState<boolean>(false);
  const [profileData, setProfileData] = React.useState<Record<string, any>>({});
  const [updatedProfile, setUpdatedProfile] = React.useState<Record<string, any>>({});

  const toggleOpenChangePasswordDialog = () => {
    setOpenChangePasswordDialog((prev) => !prev);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const fileUrl = URL.createObjectURL(file);
    setImgSrc(fileUrl);
    setUpdatedProfile((prev) => ({
      ...prev,
      profile_picture: file,
    }));
  };

  const handleEditSave = () => {
    if (editable) {
      //   handleProfileUpdate(updatedProfile);
    }
    setEditable(!editable);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {};

  return (
    <BorderedCard title="">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4">Account Details</Typography>
        {editable ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <CustomTextButton onClick={handleEditSave} color="error">
              Cancel
            </CustomTextButton>
            <CustomTextButton onClick={handleEditSave}>Save</CustomTextButton>
          </Box>
        ) : (
          <CustomTextButton onClick={handleEditSave}>Edit</CustomTextButton>
        )}
      </Box>
      <CustomDivider />
      <>
        <Grid container mt={3}>
          <Grid
            item
            xs={12}
            sm={3}
            lg={2}
            sx={{
              margin: { xs: "auto", sm: "auto", md: 0 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              position: "relative",
            }}
          >
            <input
              style={{ display: "none" }}
              id="profilePicture"
              name="profilePicture"
              className="profilePicture"
              type="file"
              onChange={handleFileChange}
              disabled={!editable}
            />
            <label
              htmlFor="profilePicture"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                width="100%"
                height="100%"
                src={imgSrc || profileData?.profile_picture || "/assets/profile.png"}
                alt="profile"
                className={classes.profileimg}
                style={{ opacity: editable ? 0.4 : 1 }}
              />
              {editable && (
                <Box sx={{ position: "absolute" }}>
                  <FiUpload size={32} />
                </Box>
              )}
            </label>
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            lg={9}
            sx={{
              margin: { sm: "auto", md: "auto 0 auto auto" },
            }}
          >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={6} md={4}>
                {editable ? (
                  <FormControl fullWidth>
                    <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                      User Name
                    </Typography>
                    <CustomInputField
                      defaultValue={profileData.name || "NA"}
                      required
                      name="name"
                      size="small"
                      placeholder="User Name"
                      onChange={handleInputChange}
                      autoComplete="off"
                      disabled={!editable}
                    />
                  </FormControl>
                ) : (
                  <StaticValueDisplay label="User Name" value={profileData["name"]} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {editable ? (
                  <FormControl fullWidth>
                    <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                      Email Address
                    </Typography>
                    <CustomInputField
                      defaultValue={profileData.email || "NA"}
                      required
                      name="email"
                      size="small"
                      placeholder="Email Address"
                      onChange={handleInputChange}
                      autoComplete="off"
                      disabled={!editable}
                    />
                  </FormControl>
                ) : (
                  <StaticValueDisplay label="Email Address" value={profileData["email"]} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {editable ? (
                  <FormControl fullWidth>
                    <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                      Role
                    </Typography>
                    <CustomInputField
                      defaultValue={profileData.role || "NA"}
                      required
                      name="role"
                      size="small"
                      placeholder="Role"
                      onChange={handleInputChange}
                      autoComplete="off"
                      disabled={!editable}
                    />
                  </FormControl>
                ) : (
                  <StaticValueDisplay label="Role" value={profileData["role"]} readOnly={true} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {editable ? (
                  <FormControl fullWidth>
                    <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                      Phone Number
                    </Typography>
                    <CustomInputField
                      defaultValue={profileData.contact || "NA"}
                      required
                      name="contact"
                      size="small"
                      placeholder="Phone Number"
                      onChange={handleInputChange}
                      autoComplete="off"
                      disabled={!editable}
                    />
                  </FormControl>
                ) : (
                  <StaticValueDisplay label="Phone Number" value={profileData["contact"]} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {editable ? (
                  <FormControl fullWidth>
                    <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                      Country
                    </Typography>
                    <CustomInputField
                      defaultValue={profileData.country || "NA"}
                      required
                      name="country"
                      size="small"
                      placeholder="Country"
                      onChange={handleInputChange}
                      autoComplete="off"
                      disabled={!editable}
                    />
                  </FormControl>
                ) : (
                  <StaticValueDisplay label="Country" value={profileData["country"]} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {editable ? (
                  <FormControl fullWidth>
                    <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                      Timezone
                    </Typography>
                    <CustomInputField
                      defaultValue={profileData.timezone || "NA"}
                      required
                      name="timezone"
                      size="small"
                      placeholder="Timezone"
                      onChange={handleInputChange}
                      autoComplete="off"
                      disabled={!editable}
                    />
                  </FormControl>
                ) : (
                  <StaticValueDisplay label="Timezone" value={profileData["timezone"]} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <ChangePasswordDialog open={openChangePasswordDialog} handleToggle={toggleOpenChangePasswordDialog} />
          <CustomButton size="small" onClick={toggleOpenChangePasswordDialog}>
            Change Password
          </CustomButton>
          <Box mt={2}>
            <Typography variant="caption2">Enable the</Typography>
            &nbsp;
            <Typography variant="caption2" color={theme.palette.text.default}>
              Two-Factor Authentication
            </Typography>
            &nbsp;
            <Typography variant="caption2">to protect your account</Typography>
          </Box>
        </Grid>
      </>
    </BorderedCard>
  );
}
