import { Box, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import Header from "./components/CustomCase/Header";
import SettingSection from "./components/CustomCase/SettingSection";

export default function CustomCase() {
  const theme = useTheme();
  const { caseId } = useParams();

  return (
    <Box p={2}>
      <Header />
      <SettingSection />
    </Box>
  );
}
