import { Box, Typography, useTheme } from "@mui/material";
import CustomBarChart, { BarChartProps } from "../../../../../components/CustomBarChart";

export default function Recall({ categories, series }: BarChartProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        px: 2,
        pt: 2,
        borderRadius: 2,
        flexFlow: "row wrap",
      }}
    >
      <Box display="flex">
        <Typography variant="h4">Recall</Typography>
      </Box>
      <Box textAlign="center">
        <CustomBarChart
          categories={["0.00", "0.25", "0.50", "0.75", "1.00"]}
          series={[
            {
              name: "Alerts count bar graph",
              data: [1.0, 0.25, 0.5, 0.75, 1.0],
            },
          ]}
        />
      </Box>
    </Box>
  );
}
