import { Box, Grid, useTheme } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomFilterDrawer from "../../../../components/CustomFilterDrawer";
import { analysisActions } from "../../../../store/analysis/analysisSlice";
import Audio from "./components/Audios";
import FilterSection from "./components/FilterSection";
import Images from "./components/Images";
import Publication from "./components/Publications";
import Videos from "./components/Videos";

interface FeedSectionProps {
  isRefresh: boolean;
}

export default function FeedSection({ isRefresh }: FeedSectionProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const { feedFilter } = useSelector((state: any) => state.analysis);

  const [activeTab, setActiveTab] = useState(state?.activeTab || "publication");
  const [view, setView] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const tabs = [
    {
      id: "publication",
      title: "Publications",
      disabled: false,
      component: <Publication view={view} drawerOpen={drawerOpen} isRefresh={isRefresh} />,
    },
    {
      id: "image",
      title: "Images",
      disabled: false,
      component: <Images drawerOpen={drawerOpen} isRefresh={isRefresh} />,
    },
    {
      id: "video",
      title: "Videos",
      disabled: false,
      component: <Videos drawerOpen={drawerOpen} isRefresh={isRefresh} />,
    },
    {
      id: "audio",
      title: "Audios",
      disabled: false,
      component: <Audio drawerOpen={drawerOpen} isRefresh={isRefresh} />,
    },
  ];

  const getActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const getView = (viewTab: boolean) => {
    setView(viewTab);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleFilterApply = (data: any) => {
    dispatch(analysisActions.addFeedFilter(data));
  };

  const handleReset = (data: any) => {
    dispatch(analysisActions.addFeedFilter(data));
  };

  return (
    <Box sx={{ borderTop: "1px solid", borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1` }}>
      <Grid container spacing={2}>
        {drawerOpen && (
          <Grid item xs={5} lg={3}>
            <CustomFilterDrawer
              filterData={feedFilter}
              openDrawer={drawerOpen}
              closePlayCamerasDrawer={handleDrawerOpen}
              handleFilterApply={handleFilterApply}
              handleReset={handleReset}
            />
          </Grid>
        )}
        <Grid item xs={drawerOpen ? 7 : 12} lg={drawerOpen ? 9 : 12}>
          <FilterSection tabs={tabs} getActiveTab={getActiveTab} getView={getView} handleDrawerOpen={handleDrawerOpen} openDrawer={drawerOpen} />
          <Box>{tabs.find((tab) => tab.id === activeTab)?.component}</Box>
        </Grid>
      </Grid>
    </Box>
  );
}
