import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import CustomButton from "../../../components/CustomButton";
import SubmitLoadingDeleteButton from "../../../components/SubmitLoadingDeleteButton";
import SubTabLayout from "../../../layouts/SubTabLayout";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { workspaces } from "../component/WorkspaceSection";
import Billing from "./Billing";
import Plan from "./Plan";
import Users from "./Users";

const tabs = [
  {
    id: "users",
    title: "Users",
    disabled: false,
    component: <Users />,
  },
  {
    id: "billing",
    title: "Billing",
    disabled: false,
    component: <Billing />,
  },
  {
    id: "plan",
    title: "Plan",
    disabled: false,
    component: <Plan />,
  },
];

export default function WorkspaceDetail() {
  const theme = useTheme();
  const { workspaceId } = useParams();
  const handleRouteClick = useHandleRouteClick();

  const workspaceData = workspaces.find((w) => String(w.workspaceId) === workspaceId);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end" py={2} px={1}>
        <Stack direction="column" alignItems="flex-start" gap={1}>
          <Typography variant="h1">{workspaceData?.name}</Typography>
          <BackButton onClick={() => handleRouteClick(routes.settings.path)} />
        </Stack>
        <Box>
          <Stack direction="row" gap={2}>
            <CustomButton sx={{ py: 0.5, width: "8rem" }}> Edit Name</CustomButton>
            <SubmitLoadingDeleteButton isLoading={false} sx={{ py: 0.5, width: "8rem" }} color="error">
              Delete Account
            </SubmitLoadingDeleteButton>
          </Stack>
        </Box>
      </Stack>
      <Box
        sx={{
          borderRadius: 2,
          background: theme.palette.background.paper,
          boxShadow: theme.palette.dashboardContainer.customCardInsetBoxShadow,
          p: 2,
          minHeight: "70vh",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <SubTabLayout
            tabs={tabs}
            sx={{
              border: `1px solid ${theme.palette.primary.dark}`,
              borderRadius: "6px",
              boxShadow: "none",
            }}
          ></SubTabLayout>
        </Box>
      </Box>
    </Box>
  );
}
