import { Box, Grid, List, ListItem, Typography, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import BorderedCard from "../../../components/BorderedCard";
import CustomDivider from "../../../components/CustomDivider";
import QueryCardKeyValueText from "../../../components/QueryCardKeyValueText";
import SubmitLoadingDeleteButton from "../../../components/SubmitLoadingDeleteButton";

export default function CurrentPlanDetails() {
  const theme = useTheme();

  return (
    <BorderedCard
      sx={{ display: "flex", gap: 4, flexDirection: "column", borderRadius: "16px", background: theme.palette.background.gradientLight1 }}
    >
      <Box>
        <Typography variant="h2">You’re currently on the Professional plan.</Typography>
        <Typography variant="caption" color={theme.palette.primary.inactiveIcon}>
          You’re currently on the Professional plan. Upgrade your plan to upload your own data or customize the AI Models.
        </Typography>
        <CustomDivider />
        <Box mt={3}>
          <Typography variant="h4">Plan information</Typography>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} md={5}>
              <QueryCardKeyValueText keyName={"First payment date"} value={"Jule 17th, 2023"} />
              <QueryCardKeyValueText keyName={"Next payment date"} value={"Jule 17th, 2023"} />
              <QueryCardKeyValueText keyName={"Amount due"} value={"$300"} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="caption" color={theme.palette.primary.inactiveIcon}>
                Your subscription will automatically renew 17.07.2024. You will be charged 300$ per year for Professional Plan. You can upgrade or
                cancel your plan at any time.
              </Typography>
              <Typography variant="caption" color={theme.palette.primary.inactiveIcon} mt={2}>
                If you have any questions please contact us&nbsp;
                <NavLink
                  to="/login"
                  style={{
                    color: theme.palette.primary.main,
                    textDecoration: "none",
                    fontWeight: theme.typography.fontWeightMedium,
                  }}
                >
                  lowcode_support@uk
                </NavLink>
              </Typography>
            </Grid>
          </Grid>
          <SubmitLoadingDeleteButton isLoading={false} sx={{ py: 0.5, width: "12rem" }} color="error">
            Cancel Plan
          </SubmitLoadingDeleteButton>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4">Current Preferences</Typography>
        <CustomDivider />
        <Grid container spacing={4} mt={1}>
          <Grid item xs={12} md={4}>
            <BorderedCard sx={{ background: theme.palette.background.paper, height: "100%" }}>
              <Typography variant="subtitle1" color={theme.palette.primary.light}>
                Solutions Available
              </Typography>
              <CustomDivider />
              <List sx={{ listStyleType: "disc", pl: 2 }}>
                <ListItem sx={{ display: "list-item", px: 0 }}>
                  <Typography variant="caption">Social Media Analysis</Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item", px: 0 }}>
                  <Typography variant="caption">News Analysis</Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item", px: 0 }}>
                  <Typography variant="caption">Predictable Analysis</Typography>
                </ListItem>
              </List>
            </BorderedCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <BorderedCard sx={{ background: theme.palette.background.paper, height: "100%" }}>
              <Typography variant="subtitle1" color={theme.palette.primary.light}>
                AI Models available
              </Typography>
              <CustomDivider />
              <List sx={{ listStyleType: "disc", pl: 2 }}>
                <ListItem sx={{ display: "list-item", px: 0 }}>
                  <Typography variant="caption">Social Media Analysis</Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item", px: 0 }}>
                  <Typography variant="caption">News Analysis</Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item", px: 0 }}>
                  <Typography variant="caption">Predictable Analysis</Typography>
                </ListItem>
              </List>
            </BorderedCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <BorderedCard sx={{ background: theme.palette.background.paper, height: "100%" }}>
              <Typography variant="subtitle1" color={theme.palette.primary.light}>
                Data Sources Available
              </Typography>
              <CustomDivider />
              <List sx={{ listStyleType: "disc", pl: 2 }}>
                <ListItem sx={{ display: "list-item", px: 0 }}>
                  <Typography variant="caption">Twitter</Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item", px: 0 }}>
                  <Typography variant="caption">News Blogs</Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item", px: 0 }}>
                  <Typography variant="caption">News Articles</Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item", px: 0 }}>
                  <Typography variant="caption">Instagram</Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item", px: 0 }}>
                  <Typography variant="caption">Tik Tok</Typography>
                </ListItem>
              </List>
            </BorderedCard>
          </Grid>
        </Grid>
      </Box>
    </BorderedCard>
  );
}
