import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "../../routes";

import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SignUpBaseLayout from "../../layouts/SignUpBaseLayout";
import { useHandleRouteClick } from "../../routes/hooks";
import { verifyEmail } from "../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export default function VerifyEmail() {
  const dispatch = useAppDispatch();
  const params: Record<string, any> = useParams();
  const handleRouteClick = useHandleRouteClick();
  const [statusText, setStatusText] = React.useState("Please wait, verifying...");

  const { isAuthenticated, isLoading } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    dispatch(verifyEmail(params.token))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Successfully verified the email");
          handleRouteClick(routes.login.path);
          setStatusText("Verification is completed");
        } else {
          toast.error("Failed to verify the token.");
          setStatusText("Failed to verify.");
        }
      })
      .catch((err) => {
        toast.error("Oops! Failed to verify the email");
        setStatusText("Failed to verify.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAuthenticated) {
    return <Navigate to={routes.home.path} />;
  }

  return (
    <SignUpBaseLayout isLoading={isLoading}>
      <Box display="flex" justifyContent="center" textAlign="center">
        {isLoading && (
          <Box>
            <CircularProgress size={80} thickness={2} />
          </Box>
        )}
        <Typography variant="h3">{statusText}</Typography>
      </Box>
    </SignUpBaseLayout>
  );
}
