import { Box, Typography, useTheme } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";

import { DrawerMenuItemsInterface } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";

export interface SidebarMenuItemProps {
  routeDetails: DrawerMenuItemsInterface;
  activeTab: boolean;
  getActiveTab(path: string): void;
}

export default function SidebarMenuItem({ routeDetails, activeTab, getActiveTab }: SidebarMenuItemProps) {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const handleNavigation = (path: string) => {
    if (routeDetails.path !== "/support") {
      handleRouteClick(`${path}`);
    }
    getActiveTab(path);
  };

  return (
    <ListItemButton
      disableRipple
      disableTouchRipple
      onClick={() => handleNavigation(routeDetails?.path)}
      sx={{
        transition: "ease-in",
        backgroundColor: (theme) => (activeTab ? "#1e2331" : "transparent"),
        background: activeTab ? theme.palette.sidebarMenu.itemActiveBackground : theme.palette.sidebarMenu.itemBackground,
        border: activeTab ? `2px solid ${theme.palette.sidebarMenu.itemActiveBorderColor}` : "initial",
        borderRight: "2px solid transparent",
        justifyContent: "center",
        px: 0.5,
        my: 1,
        width: "100%",
        display: "flex",
        position: "relative",
        gap: 1,
        flexDirection: "column",
        alignItems: "center",
        color: (theme) => (activeTab ? theme.palette.primary.main : theme.palette.text.main),
        borderRadius: "32px",
        aspectRatio: "1",
        "&:hover": {
          backgroundColor: (theme) => (activeTab ? "initial" : "transparent"),
        },
        "&::before": {
          content: "''",
          position: "absolute",
          top: "-27px",
          right: "5px",
          height: "29px",
          width: "24px",
          borderRadius: "50%",
          background: "transparent",
          borderRight: `3px solid ${theme.palette.sidebarMenu.itemActiveTopCurveBorderColor}`,
          transform: "rotate(35deg)",
          boxShadow: (theme) => `10px 10px 0 ${theme.palette.sidebarMenu.itemActiveTopCurveBoxShadow}`,
          display: activeTab ? "block" : "none",
        },
        "&::after": {
          content: "''",
          position: "absolute",
          bottom: "-27px",
          right: "5px",
          height: "29px",
          width: "24px",
          borderRadius: "50%",
          background: "transparent",
          borderTop: `3px solid ${theme.palette.sidebarMenu.itemActiveBottomBorderColor}`,
          transform: "rotate(35deg)",
          boxShadow: (theme) => `0px -8px 0 ${theme.palette.sidebarMenu.itemActiveBottomBoxShadow}`,
          display: activeTab ? "block" : "none",
        },
        marginLeft: (theme) => (activeTab ? theme.spacing(1.1) : "none"),
      }}
    >
      <ListItemIcon
        sx={{
          marginLeft: (theme) => (activeTab ? `-${theme.spacing(2)}` : "none"),
          minWidth: 0,
          justifyContent: "center",
          color: activeTab ? theme.palette.primary.main : theme.palette.sidebarMenu.itemIconColor,
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        {routeDetails.icon}
      </ListItemIcon>
      <Typography
        sx={{
          marginLeft: (theme) => (activeTab ? `-${theme.spacing(2)}` : "none"),
        }}
        variant="caption"
        color={activeTab ? (theme) => theme.palette.primary.main : (theme) => theme.palette.sidebarMenu.itemTextColor}
      >
        {routeDetails.text}
      </Typography>
      {activeTab && (
        <Box
          sx={{
            width: "0.5rem",
            height: "100%",
            background: theme.palette.sidebarMenu.itemActiveFakeBorderColor,
            position: "absolute",
            right: "-1px",
          }}
        ></Box>
      )}
    </ListItemButton>
  );
}
