import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CustomInputField from "../../../../components/CustomInputField";
import CustomTextButton from "../../../../components/CustomTextButton";
import Classes from "../../../../components/Scrollbar.module.css";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";
import { FAQS, SpecificFAQ } from "../../../../utils/constants";
import FAQDetails from "./FAQDetails";
import FAQItem from "./FAQItem";

const handleSearch = () => {};

export default function FAQSection() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const [openFAQ, setOpenFAQ] = useState(false);
  const [title, setTitle] = useState("FAQ");

  const handleOpenFAQ = (title: string) => {
    setOpenFAQ((prev) => !prev);
    setTitle(title);
  };

  const handleBackFAQ = () => {
    setOpenFAQ(false);
    setTitle("FAQ");
  };

  const handleNavigation = () => {
    handleRouteClick(routes.discover.path);
  };

  return (
    <Box height="100%" overflow="hidden">
      <Box display="flex" mt={1}>
        {openFAQ && <ChevronLeftIcon sx={{ color: theme.palette.text.default, mt: "-5px", fontSize: "1.4em" }} onClick={handleBackFAQ} />}
        <Typography variant="semiBold" color={theme.palette.text.titleLabel}>
          {title}
        </Typography>
        <CustomTextButton sx={{ ml: "auto" }} onClick={handleNavigation}>
          Discover More
        </CustomTextButton>
      </Box>
      <CustomInputField
        placeholder="Search for articles..."
        size="small"
        variant="outlined"
        autoComplete="off"
        onChange={handleSearch}
        sx={{
          width: "100%",
          "&.MuiFormControl-root.MuiTextField-root .MuiInputBase-root": {
            height: "36px",
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            ...theme.typography.body4,
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {openFAQ ? (
        <Box height="100%" overflow="auto" pb={12} mt={2} className={Classes.scroll_dark}>
          <FAQDetails faqItem={SpecificFAQ} />
        </Box>
      ) : (
        <Box height="100%" overflow="auto" pb={12} mt={1} className={Classes.scroll_dark}>
          {FAQS.map((faq) => (
            <Box key={`faq-${faq?.id}`} onClick={() => handleOpenFAQ(faq?.title)} sx={{ cursor: "pointer" }}>
              <FAQItem faqItem={faq} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
