import { Grid, Typography, useTheme } from "@mui/material";
import CustomButton from "../../../../../../components/CustomButton";
import UserDemographicsMap from "../../../Map/Components/UserDemographics";

export default function UserDemographics() {
  const theme = useTheme();

  return (
    <Grid container mt={5}>
      <Typography variant="h3" color={theme.palette.text.titleLabel} sx={{ fontSize: "22px" }}>
        User Demographics
      </Typography>
      <CustomButton
        sx={{
          ...theme.typography.bigButton,
          background: theme.palette.background.secondaryBgColor,
          color: theme.palette.text.secondaryText,
          ml: "auto",
          width: "122px",
          height: "36px",
          lineHeight: "16px",
        }}
      >
        Open Map
      </CustomButton>
      <Grid mt={"-10px"} container spacing={3} mx="auto" sx={{ mt: 1 }} width="100%">
        <UserDemographicsMap imgSrc="/assets/map.png" showLegend={false} controls={false} containerHeight="400px" />
      </Grid>
    </Grid>
  );
}
