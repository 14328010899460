import { Home } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MessageIcon from "@mui/icons-material/Message";
import SearchIcon from "@mui/icons-material/Search";
import { Box, CardMedia, InputAdornment, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import CustomIconButton from "../../../../components/CustomIconButton";
import CustomInputField from "../../../../components/CustomInputField";
import CustomTab from "../../../../components/CustomTab";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";
import { FAQS } from "../../../../utils/constants";
import Chatboat from "../Chatboat";
import SpecificTopic from "./SpecificTopic";
import Topics from "./Topics";

export default function DiscoreSection() {
  const tabs = [
    {
      id: "topic",
      title: "Topics",
      disabled: false,
      component: <Topics openSpecificTopic={openSpecificTopic} />,
    },
    {
      id: "faq",
      title: "FAQ",
      disabled: false,
      component: <Box>FAQ</Box>,
    },
  ];

  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const [selectedTopic, setSelectedTopic] = useState<number>(0);
  const [articleName, setArticleName] = useState<string>("");
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [specificTopic, setSpecificTab] = useState(false);
  const [isArticle, setArticle] = useState(false);
  const [openChat, setOpenChat] = useState(false);

  function openSpecificTopic(id: number) {
    setSpecificTab((prev) => !prev);
    setSelectedTopic(id);
  }

  const getAllCollections = () => {
    setSpecificTab(false);
    setArticleName("");
    setArticle(false);
  };

  const getProducts = () => {
    setArticleName("");
    setArticle(false);
  };

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {};

  const openSpecificArticle = (title: string) => {
    setArticleName(title);
    setArticle(true);
  };

  const goBack = () => {
    handleRouteClick(routes.home.path);
  };

  const handleOpenChat = () => {
    setOpenChat((prev) => !prev);
  };

  const topicData = FAQS?.find((topic) => topic?.id === selectedTopic);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", p: 0 }}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          boxShadow: `6px 6px 12px 0px ${theme.palette.background.cardBoxShadowColor1}, -6px -6px 15px 0px ${theme.palette.background.cardBoxShadowColor2}`,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0.6em",
            cursor: "pointer",
            left: "1em",
          }}
        >
          <Home onClick={goBack} sx={{ color: theme.palette.text.default, fontSize: "1.5em" }} />
        </Box>
        <Box sx={{ position: "absolute", width: { xs: "70%", xl: "60%" } }}>
          <CardMedia
            component="img"
            image="/assets/lcp_logo.png"
            alt="logo"
            sx={{ width: specificTopic ? "6.5rem" : { xs: "8rem", md: "12.5rem" } }}
          />
          {!specificTopic && (
            <Typography variant="body2" fontSize={{ xs: "1em", md: "1.75rem" }} color={theme.palette.text.captionColor} my={1.2}>
              Advice and answers from the Low Code Team
            </Typography>
          )}
          <Box display="flex" gap={1.5}>
            <CustomInputField
              placeholder=" Search"
              size="small"
              variant="outlined"
              autoComplete="off"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
                "&.MuiFormControl-root.MuiTextField-root .MuiInputBase-root": {
                  height: "2.5rem",
                },
              }}
            />
            <CustomButton
              sx={{
                ...theme.typography.bigButton,
                height: "39px",
                background: theme.palette.text.default,
                color: theme.palette.text.secondaryText,
                "&:hover": {
                  background: theme.palette.text.default,
                  color: theme.palette.text.secondaryText,
                },
                mt: 1,
                px: "2rem",
              }}
            >
              Search
            </CustomButton>
          </Box>
        </Box>
        <CardMedia component="img" image="/assets/discover.png" alt="discover" sx={{ height: specificTopic ? "10.75rem" : "auto" }} />
      </Box>
      <Box
        sx={{
          height: "100%",
          overflow: "hidden",
          pb: 3,
        }}
      >
        {specificTopic && (
          <Box
            sx={{
              width: { xs: "100%", md: "70%" },
              height: "100%",
              margin: "auto",
              mt: 3,
            }}
          >
            <Box display="flex" flexDirection="row" width="100%">
              <Typography variant="subtitle2" onClick={getAllCollections} sx={{ color: theme.palette.text.tableHeader, cursor: "pointer" }}>
                All Collections
              </Typography>
              <ChevronRightIcon sx={{ color: theme.palette.text.tableHeader, mt: "-5px" }} />
              <Typography
                variant="subtitle2"
                sx={{ color: articleName ? theme.palette.text.tableHeader : theme.palette.text.tableHeader, cursor: "pointer" }}
                onClick={getProducts}
              >
                {!articleName ? "Products" : topicData?.title}
              </Typography>
              {articleName && <ChevronRightIcon sx={{ color: theme.palette.text.tableHeader, mt: "-5px" }} />}
              {articleName && (
                <Typography variant="caption1" sx={{ color: theme.palette.text.titleLabel, cursor: "pointer" }}>
                  {articleName}
                </Typography>
              )}
            </Box>
            <Box height="100%" overflow="scroll">
              <SpecificTopic topicData={topicData} openSpecificArticle={openSpecificArticle} isArticle={isArticle} />
            </Box>
          </Box>
        )}

        {!specificTopic && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box display="flex" alignItems="center" flexDirection="column">
              <CustomTab
                tabs={tabs}
                handleActiveTab={handleActiveTab}
                sx={{ display: "flex", my: 3, p: 1, borderRadius: 1, alignItems: "center" }}
                activeTab={activeTab}
              />
              {tabs.find((tab) => tab.id === activeTab)?.component}

              <CustomIconButton sx={{ position: "fixed", bottom: "2em", right: "2em", p: 2.5 }}>
                <MessageIcon sx={{ color: openChat ? theme.palette.text.titleLabel : theme.palette.text.default }} onClick={handleOpenChat} />
              </CustomIconButton>
            </Box>
          </Box>
        )}
      </Box>
      {openChat && (
        <Box
          width={{ xs: "90vw", sm: "48vw", md: "40vw", lg: "30%", xl: "20vw" }}
          sx={{
            background: theme.palette.dashboardContainer.customRadioDropdownBackground,
            border: `1px solid ${theme.palette.additionalColors.lightBorder}`,
            borderRadius: 2,
            p: 2,
            position: "fixed",
            bottom: "5rem",
            right: "1.8rem",
            height: "58%",
            zIndex: 1,
          }}
        >
          <Chatboat handleLiveChat={handleOpenChat} isConnecting={false} discover={true} />
        </Box>
      )}
    </Box>
  );
}
