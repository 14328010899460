import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Grid, Typography, useTheme } from "@mui/material";
import PublicationCard from "../PublicationCard";

export default function RelatedPublication() {
  const theme = useTheme();
  const publicationData = [
    {
      id: 1,
      created_at: "13.04.2023 14:07",
      media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
      source_link: "www.twitter.com",
      description:
        "One time I jokingly posted a photo of myself in a helicopter saying I was going to shoot down the chinese spy balloon and reddit basement dwellers lost their minds saying i was going to get people killed",
      relevance: "97%",
      authenticity: "97%",
      status: "moderate",
      image: [],
      user: {
        profile: "",
        name: "Derek Johnson",
        userid: "@rattletrap1776",
      },
    },
    {
      id: 2,
      title: "Chinese spy balloon was able to transmit information back to Beijing",
      created_at: "13.04.2023 14:07",
      media_image: "https://upload.wikimedia.org/wikipedia/commons/6/66/CNN_International_logo.svg",
      source_link: "www.cnn.com",
      description:
        "The Chinese spy balloon that transited the US earlier this year was able to capture imagery and collect some signals intelligence from US military sites, a source familiar with the matter tells CNN.The balloon was able to trans...",
      relevance: "97%",
      authenticity: "97%",
      status: "moderate",
      image: [
        "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
        "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
        "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
      ],
    },
  ];

  return (
    <Grid container mt={5} mb={5}>
      <Typography variant="h3" color={theme.palette.text.titleLabel} sx={{ fontSize: "22px" }}>
        Related Publications
      </Typography>
      <Typography
        ml="auto"
        variant="link5"
        color={theme.palette.text.default}
        display={"flex"}
        sx={{
          cursor: "pointer",
          fontSize: "16px",
        }}
      >
        View All
        <ChevronRightIcon sx={{ color: theme.palette.text.default, mt: "-6px" }} />
      </Typography>
      <Grid container spacing={2} mt={1}>
        {publicationData.map((data: any, idx) => (
          <Grid key={`image-${idx}`} item xs={12} md={6} display="flex">
            <PublicationCard cardData={data} view={"grid"} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
