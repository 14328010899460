import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import MapSection from "../Components/Map/index";
import Title from "../Components/Title";

export default function Mapwrapper() {
  const theme = useTheme();
  const [pageRefresh, setPageReferesh] = useState(false);

  const handleRefresh = () => {
    setPageReferesh(true);
    setTimeout(removePageRefresh, 2000);
  };

  const removePageRefresh = () => {
    setPageReferesh(false);
  };

  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
      }}
      p={2}
    >
      <Title
        title="Map"
        time="Last updated today at 16:54 (IST)"
        refresh={true}
        results={true}
        resultLabel="4 New Results"
        handleRefresh={handleRefresh}
        isRefresh={pageRefresh}
      />
      <MapSection />
    </Box>
  );
}
