import UploadIcon from "@mui/icons-material/Upload";
import { Box, Grid, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomTab from "../../../components/CustomTab";
import Connectionswrapper from "../Wrappers/Connections";
import Entitieswrapper from "../Wrappers/Entities";
import Feedwrapper from "../Wrappers/Feed";
import Mapwrapper from "../Wrappers/Map";
import Overviewrapper from "../Wrappers/Overview";

const tabs = [
  {
    id: "overview",
    title: "Overview",
    disabled: false,
    component: <Overviewrapper />,
  },
  {
    id: "entities",
    title: "Entities",
    disabled: false,
    component: <Entitieswrapper />,
  },
  {
    id: "feed",
    title: "Feed",
    disabled: false,
    component: <Feedwrapper />,
  },
  {
    id: "connections",
    title: "Connections",
    disabled: false,
    component: <Connectionswrapper />,
  },
  {
    id: "map",
    title: "Map",
    disabled: false,
    component: <Mapwrapper />,
  },
];

export default function SubHeader() {
  const theme = useTheme();
  const [queryParameters] = useSearchParams();

  const subPage = queryParameters.get("page");
  const [searchParams, setSearchParams] = useSearchParams({ tab: "analysis" });

  const [activeTab, setActiveTab] = useState(subPage || tabs[0].id);

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
    setSearchParams((params) => {
      params.set("page", tab);
      return params;
    });
  };

  const queryParam = searchParams.get("page");

  useEffect(() => {
    if (queryParam) {
      setActiveTab(queryParam);
    }
  }, []);

  return (
    <Box height="100%" overflow="hidden">
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <CustomButton sx={{ ...theme.typography.bigButton }}>Query: Chinese Balloon</CustomButton>
        </Grid>
        <Grid item xs={9} md={6} sx={{ textAlign: "center" }}>
          <CustomTab tabs={tabs} handleActiveTab={handleActiveTab} sx={{ p: 1 }} activeTab={activeTab} />
        </Grid>
        <Grid item xs={3} md={3} sx={{ textAlign: "right" }}>
          <CustomButton
            startIcon={<UploadIcon />}
            sx={{
              [theme.breakpoints.down("lg")]: {
                display: "none",
              },
            }}
          >
            Export Report
          </CustomButton>
          <CustomIconButton
            sx={{
              color: theme.palette.text.default,
              width: "36px",
              height: "36px",
              [theme.breakpoints.up("lg")]: {
                display: "none",
              },
            }}
          >
            <UploadIcon />
          </CustomIconButton>
        </Grid>
      </Grid>
      <Box sx={{ height: "100%", overflow: "scroll", mt: 2, pb: 15 }}>{tabs.find((tab) => tab.id === activeTab)?.component}</Box>
    </Box>
  );
}
