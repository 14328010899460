import LinkIcon from "@mui/icons-material/Link";
import { Box, Grid, Link, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import CustomDivider from "../../../../../../../components/CustomDivider";

interface ReferenceLinksProps {
  links: string[];
}
const ReferenceLinks: FC<ReferenceLinksProps> = ({ links }) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: "100%", border: theme.palette.additionalColors.databasecardBorder, borderRadius: 8, p: 2 }}>
      <Typography variant="h4" color={theme.palette.text.titleLabel}>
        References
      </Typography>
      <CustomDivider sx={{ margin: "12px auto" }} />
      <Grid container spacing={3}>
        {links.map((link, idx) => (
          <Grid item xs={6} mt={2} key={`link-${idx}`}>
            <Box display="flex" gap={1}>
              <LinkIcon sx={{ transform: "rotate(-45deg)", color: theme.palette.text.default, fontSize: "16px" }} />
              <Link underline="none" href={link} sx={{ ...theme.typography.body3 }}>
                {link}
              </Link>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ReferenceLinks;
