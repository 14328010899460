import { Box, Grid, Pagination, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import PublicationCard from "../../../Feed/components/Publications/PublicationCard";

export default function OnlineActivity() {
  const theme = useTheme();
  const publicationData = [
    {
      id: 1,
      created_at: "13.04.2023 14:07",
      media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
      source_link: "www.twitter.com",
      description:
        "One time I jokingly posted a photo of myself in a helicopter saying I was going to shoot down the chinese spy balloon and reddit basement dwellers lost their minds saying i was going to get people killed",
      relevance: "97%",
      authenticity: "97%",
      status: "moderate",
      image: [],
      user: {
        profile: "",
        name: "Derek Johnson",
        userid: "@rattletrap1776",
      },
    },
    {
      id: 2,
      title: "Chinese spy balloon was able to transmit information back to Beijing",
      created_at: "13.04.2023 14:07",
      media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
      source_link: "www.cnn.com",
      description:
        "The Chinese spy balloon that transited the US earlier this year was able to capture imagery and collect some signals intelligence from US military sites, a source familiar with the matter tells CNN.The balloon was able to trans...",
      relevance: "97%",
      authenticity: "97%",
      status: "moderate",
      image: [],
    },
    {
      id: 3,
      title: "Chinese spy balloon was able to transmit information back to Beijing",
      created_at: "13.04.2023 14:07",
      media_image: "https://upload.wikimedia.org/wikipedia/commons/6/66/CNN_International_logo.svg",
      source_link: "www.cnn.com",
      description:
        "The Chinese spy balloon that transited the US earlier this year was able to capture imagery and collect some signals intelligence from US military sites, a source familiar with the matter tells CNN.The balloon was able to trans...",
      relevance: "97%",
      authenticity: "97%",
      status: "moderate",
      image: ["https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg"],
    },
    {
      id: 4,
      title: "Chinese spy balloon was able to transmit information back to Beijing",
      created_at: "13.04.2023 14:07",
      media_image: "https://upload.wikimedia.org/wikipedia/commons/6/66/CNN_International_logo.svg",
      source_link: "www.cnn.com",
      description:
        "The Chinese spy balloon that transited the US earlier this year was able to capture imagery and collect some signals intelligence from US military sites, a source familiar with the matter tells CNN.The balloon was able to trans...",
      relevance: "97%",
      authenticity: "97%",
      status: "moderate",
      image: [
        "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
        "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
        "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
      ],
    },
    {
      id: 5,
      title: "Chinese spy balloon was able to transmit information back to Beijing",
      created_at: "13.04.2023 14:07",
      media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
      source_link: "www.cnn.com",
      description:
        "The Chinese spy balloon that transited the US earlier this year was able to capture imagery and collect some signals intelligence from US military sites, a source familiar with the matter tells CNN.The balloon was able to trans...",
      relevance: "97%",
      authenticity: "97%",
      status: "low",
      image: [],
    },
    {
      id: 6,
      created_at: "13.04.2023 14:07",
      media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
      source_link: "www.twitter.com",
      description:
        "One time I jokingly posted a photo of myself in a helicopter saying I was going to shoot down the chinese spy balloon and reddit basement dwellers lost their minds saying i was going to get people killed",
      relevance: "97%",
      authenticity: "97%",
      status: "moderate",
      image: [],
      user: {
        profile: "",
        name: "Derek Johnson",
        userid: "@rattletrap1776",
      },
    },
    {
      id: 7,
      title: "Chinese spy balloon was able to transmit information back to Beijing",
      created_at: "13.04.2023 14:07",
      media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
      source_link: "www.cnn.com",
      description:
        "The Chinese spy balloon that transited the US earlier this year was able to capture imagery and collect some signals intelligence from US military sites, a source familiar with the matter tells CNN.The balloon was able to trans...",
      relevance: "97%",
      authenticity: "97%",
      status: "high",
      image: [],
    },
  ];

  const [page, setPage] = useState(1);

  const pageCount = 6;

  const handlePageChange = (e: any, value: number) => {
    setPage(value);
  };

  return (
    <Box>
      <Typography variant="h3" sx={{ fontSize: "22px", color: theme.palette.text.titleLabel }} pt={3}>
        Online Activity
      </Typography>
      <Grid container spacing={2} mt={0.5}>
        {publicationData.slice((page - 1) * pageCount, page * pageCount).map((data: any, idx) => (
          <Grid key={`image-${idx}`} item xs={12} sm={6} lg={4} display="flex">
            <PublicationCard cardData={data} view={"grid"} />
          </Grid>
        ))}
        <Grid container spacing={2} px={4} py={2}>
          <Box
            sx={{
              mt: 5,
              mx: "auto",
              mr: "0px",
            }}
          >
            {publicationData.length / pageCount >= 1 && (
              <Pagination
                onChange={handlePageChange}
                page={page}
                count={Math.ceil(publicationData.length / pageCount)}
                size="small"
                sx={(theme) => ({
                  ...theme.palette.pagination,
                })}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
