import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import FeedSection from "../Components/Feed";
import Title from "../Components/Title";

export default function Feedwrapper() {
  const theme = useTheme();
  const [isRefresh, setIsRefresh] = useState(false);

  const handleRefresh = () => {
    setIsRefresh(true);
    setTimeout(removePageRefresh, 2000);
  };

  const removePageRefresh = () => {
    setIsRefresh(false);
  };

  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
      }}
      p={{ xs: 1, md: 2 }}
    >
      <Title title="Feed" time="Last updated today at 16:54 (IST)" refresh={true} handleRefresh={handleRefresh} />
      <FeedSection isRefresh={isRefresh} />
    </Box>
  );
}
