import { Box, Grid, Typography } from "@mui/material";
import BorderedCard from "../../../components/BorderedCard";
import CustomButton from "../../../components/CustomButton";
import CustomDivider from "../../../components/CustomDivider";
import SubmitLoadingDeleteButton from "../../../components/SubmitLoadingDeleteButton";

export default function AccountManagement() {
  return (
    <BorderedCard title="Account Management">
      <Grid container spacing={2} my={2}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="subtitle1">Log Out</Typography>
            <Typography variant="caption2">You can safely log out from the current account and log back in later.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
          <Box>
            <CustomButton sx={{ py: 0.5, width: "8rem" }}>Log Out</CustomButton>
          </Box>
        </Grid>
      </Grid>
      <CustomDivider />
      <Grid container spacing={2} my={2}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="subtitle1">Account Deletion</Typography>
            <Typography variant="caption2">
              By deleting your account, you delete all associated data, included all workspaces. You will not be able to reactivate this account
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
          <Box>
            <SubmitLoadingDeleteButton isLoading={false} sx={{ py: 0.5, width: "8rem" }} color="error">
              Delete Account
            </SubmitLoadingDeleteButton>
          </Box>
        </Grid>
      </Grid>
    </BorderedCard>
  );
}
