import CheckIcon from "@mui/icons-material/Check";
import { Box, CardMedia, Typography, useTheme } from "@mui/material";

interface Details {
  title: string;
  thumbnail: string;
  added: boolean;
}

interface CardProps {
  solution: {
    dataSources?: Details[];
    aiModels?: Details[];
  };
}
export default function SolutionIncludes({ solution }: CardProps) {
  const theme = useTheme();

  const isDataSources = solution["dataSources"];
  const title = isDataSources ? "Data Sources" : "AI Models";
  const count = isDataSources ? isDataSources?.length : solution["aiModels"]?.length;
  const solutionIncludes = isDataSources ? isDataSources : solution["aiModels"];

  return (
    <Box mt={3} width="100%">
      <Typography variant="body1" fontWeight={600}>
        {`${title}  ${count}`}
      </Typography>
      <Box display="flex" sx={{ width: "100%", overflowX: "scroll", gap: 2, mt: 3 }}>
        {solutionIncludes?.map((sol: Details, idx) => (
          <Box
            key={`data-${idx}`}
            sx={{
              width: "14rem",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              background: theme.palette.background.cardBgColor,
              border: `1px solid ${theme.palette.additionalColors.lightBorder}`,
              p: 1.5,
              borderRadius: 2,
            }}
          >
            <CardMedia component="img" image={sol?.thumbnail} alt={`${sol?.title}-${idx}`} sx={{ width: "12rem", borderRadius: 2 }} />
            <Box display="flex">
              <Typography variant="h4" color={theme.palette.text.titleLabel}>
                {sol?.title}
              </Typography>
              {sol?.added && <CheckIcon sx={{ color: theme.palette.text.default, ml: "auto", fontSize: "1em" }} />}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
