import { useAppSelector } from "../store/hooks";
import { ThemeVariantsProps } from "../theme";
import Classes from "./SkylarklabsFullCircle.module.css";

export default function SkylarkLabsCircle() {
  const { themeMode } = useAppSelector((state) => state.theme);
  const isDarkTheme: boolean = themeMode === ThemeVariantsProps.dark;

  return <img src={isDarkTheme ? "/skylarklabs_full_circle.png" : "/skylarklabs_full_circle_light.png"} className={Classes.circle} />;
}
