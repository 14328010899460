// import "leaflet.heat";
import { Box, Typography, useTheme } from "@mui/material";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import { getImageSize } from "react-image-size";
import { FeatureGroup, ImageOverlay, MapContainer, Marker, Polyline, Popup, ZoomControl } from "react-leaflet";
import ConnectionSlider, { CustomConnectionSlider } from "../../../../../components/ConnectionSlider";
import CustomImageNode from "../../../../../components/CustomImageNode";
import EntityConnections from "./EntityConnections";
import Legend from "./Legend";
import NodeInfo from "./NodeInfo";
import ScoreLegend from "./ScoreLegend";

interface MapProps {
  imgSrc: string;
}

type TextPalette = {
  extremeStatus: string;
  highStatus: string;
  moderateStatus: string;
  neutralStatus: string;
  lowStatus: string;
};

const Entities: React.FC<MapProps> = ({ imgSrc }) => {
  const theme = useTheme();

  const [width, setWidth] = useState<number>(500);
  const [height, setHeight] = useState<number>(500);
  const [bounds, setBounds] = useState<[number, number][] | null>(null);
  const [isPopped, setIsPopped] = React.useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [value, setValue] = useState<number>(1);

  const popupElRef = React.useRef(null);

  useEffect(() => {
    getImageSize(imgSrc).then(({ width, height }) => {
      setHeight(height);
      setWidth(width);
      setBounds([
        [0, 0],
        [height, width],
      ]);
    });
  }, [imgSrc]);

  if (!bounds) {
    return <></>;
  }

  const marks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
  ];

  function valuetext(value: number) {
    return `${value}`;
  }
  const pos: any = [
    { id: "A", label: "Person 1", position: [260.125, 649], status: "extreme", legend: "person" },
    { id: "B", label: "Topic 1", position: [204, 607.5], status: "high", legend: "topic" }, //to jpn
    { id: "C", label: "Event 1", position: [213.25, 679.25], status: "neutral", legend: "event" },
    { id: "D", label: "Location 1", position: [247, 579.875], status: "moderate", legend: "location" }, //to rus
    { id: "E", label: "Organization 1", position: [233.75, 623.875], status: "low", legend: "organization" },
  ];

  const connectingLines = [
    ["E", "B"],
    ["E", "C"],
    ["E", "D"],
    ["E", "A"],
  ];

  const handleOpenDetails = () => {
    setOpenDetails((prev) => !prev);
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  return (
    <Box mt={2}>
      <Box width="100%" height="55vh" borderRadius={2} overflow="scroll" position="relative">
        <MapContainer
          attributionControl={false}
          center={[height / 2, width / 2]}
          boxZoom
          zoom={1}
          minZoom={-10}
          scrollWheelZoom={false}
          style={{ height: "100%", width: "100%", backgroundColor: "transparent" }}
          crs={L.CRS.Simple}
          zoomControl={false}
        >
          <ImageOverlay bounds={bounds} url={imgSrc} />
          <ZoomControl position="bottomright" />
          <FeatureGroup>
            {pos?.map((mark: any, i: number) => {
              const statusColor: any = `${mark?.status}Status`;
              return (
                <Marker
                  key={i}
                  position={mark?.position}
                  icon={L.divIcon({
                    className: "custom icon",
                    html: ReactDOMServer.renderToString(
                      <CustomImageNode color={theme.palette.text[statusColor as keyof TextPalette]} legend={mark?.legend} label={mark?.label} />
                    ),
                  })}
                  // draggable={true}
                  // eventHandlers={{
                  //   dragend: (e) => {
                  //     console.log("dragend", [e.target._latlng.lat, e.target._latlng.lng]);
                  //   },
                  // }}
                >
                  <Popup
                    closeButton={false}
                    ref={popupElRef}
                    eventHandlers={{
                      add: () => {
                        setIsPopped(true);
                      },
                      remove: () => {
                        setIsPopped(false);
                      },
                    }}
                    className="info-popup"
                  >
                    {isPopped ? (
                      <Box
                        sx={{
                          height: "auto",
                          width: "200px",
                          position: "relative",
                          padding: 0,
                          margin: 0,
                          borderRadius: "10px",
                        }}
                      >
                        <NodeInfo handleOpenDetails={handleOpenDetails} />
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Popup>
                </Marker>
              );
            })}

            {connectingLines.map(([from, to]) => {
              const fromMarker = pos.find((marker: any) => marker.id === from);
              const toMarker = pos.find((marker: any) => marker.id === to);

              return (
                <Polyline
                  key={`${from}-${to}`}
                  positions={[fromMarker.position, toMarker.position]}
                  pathOptions={{ color: theme.palette.text.tableHeader, weight: 1, opacity: 1 }}
                />
              );
            })}
          </FeatureGroup>
        </MapContainer>
        <Box
          width="236px"
          ml={2}
          sx={{ position: "absolute", background: "#1D2330", borderRadius: 2, pt: 2, pb: 0.5, px: 1.5, bottom: "20px", zIndex: 1000 }}
        >
          <Typography variant="body4" color={theme.palette.text.tableHeader}>
            Connection level
          </Typography>
          <br />
          <CustomConnectionSlider
            sx={{
              "&.MuiSlider-root": {
                padding: "12px 0",
              },
              "& .MuiSlider-mark": { display: "none" },
              "& .MuiSlider-markLabel": {
                color: theme.palette.text.titleLabel,
                fontSize: "12px",
                fontWeight: 400,
              },
            }}
            slots={{ thumb: ConnectionSlider }}
            min={0}
            max={5}
            step={1}
            value={value}
            onChange={handleChange}
            marks={marks}
            defaultValue={1}
            getAriaValueText={valuetext}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          mt: 2,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: 1,
          },
        }}
      >
        <Legend />
        <ScoreLegend sx={{ [theme.breakpoints.up("md")]: { ml: "auto" } }} />
      </Box>
      {openDetails && <EntityConnections open={openDetails} openDrawer={handleOpenDetails} />}
    </Box>
  );
};

export default Entities;
