import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import BorderedCard from "../../../components/BorderedCard";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { AVAILABLE_AI_MODELS } from "../../../utils/constants";

export default function AIModelsSection() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Typography variant="h2" color={theme.palette.text.titleLabel} mt={1}>
        AI Models
      </Typography>
      <Grid container spacing={2}>
        {AVAILABLE_AI_MODELS.map((sol, idx) => {
          return (
            <Grid item xs={12} md={6} lg={4} key={`aiModel-${idx}`}>
              <BorderedCard
                sx={{ cursor: "pointer", height: "100%", display: "flex", flexDirection: "column", gap: 1.4, borderRadius: 2 }}
                onClick={() => handleRouteClick(routes.aiModelDetail.path.replace(":aiModelId", String(sol.id)))}
              >
                <img width="100%" alt={String(sol.id)} src={sol.thumbnail} />
                <Typography variant="h4" fontWeight={400}>
                  {sol.title}
                </Typography>
                <Typography variant="body4" color={theme.palette.text.captionColor}>
                  {sol.description}
                </Typography>
                <Box
                  sx={{
                    my: 1,
                    padding: "16px",
                    borderRadius: "8px",
                    background: theme.palette.dashboardContainer.sessionCardActive,
                  }}
                >
                  <Typography variant="body4" color={theme.palette.primary.light}>
                    {sol.solutions.map((s) => s.title).join(", ")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 3 }}>
                  <Typography variant="h2">{sol.price}</Typography>
                  <Button size="small" variant="contained" fullWidth>
                    Add To Cart
                  </Button>
                </Box>
              </BorderedCard>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
