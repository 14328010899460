import CloseIcon from "@mui/icons-material/Close";
import { Box, CardMedia, Dialog, DialogContent, Grid, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CustomIconButton from "../../../../components/CustomIconButton";
import CustomTab from "../../../../components/CustomTab";

interface DialogProps {
  open: boolean;
  handleClose: () => void;
  canvasData?: any;
}

const tabs = [
  {
    id: "download",
    title: "Downloads",
    disable: false,
    component: <Box>Downloads</Box>,
  },
  {
    id: "failed",
    title: "Failings",
    disable: false,
    component: <Box>Failings</Box>,
  },
];

const AddCanvasDialog = ({ open, canvasData, handleClose }: DialogProps) => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(tabs[0]?.id);

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.background.default,
          borderRadius: "10px",
          width: "100%",
          textAlign: "center",
          minHeight: "50vh",
        },
      }}
    >
      <DialogContent sx={{ p: 4, overflow: "hidden" }}>
        <Box display="flex">
          <CustomTab
            tabs={tabs}
            handleActiveTab={handleActiveTab}
            sx={{ border: `1px solid ${theme.palette.primary.tertiary}`, textAlign: "center", p: "1px" }}
            activeTab={activeTab}
          />
          <CustomIconButton variant="contained" onClick={handleClose} sx={{ ml: "auto" }}>
            <CloseIcon sx={{ color: theme.palette.error.main }} />
          </CustomIconButton>
        </Box>
        <Box
          sx={{
            background: theme.palette.background.dark,
            border: theme.palette.additionalColors.databasecardBorder,
            borderRadius: 2,
            my: "auto",
            mt: 3,
            p: 2,
            height: "500px",
            overflow: "scroll",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%", m: "auto" }}>
            {canvasData &&
              Object.keys(canvasData)?.map((file: any, index) => {
                return (
                  <Grid item xs={6} sm={4} md={3} xl={12 / 5} key={`image-${index}`}>
                    <Box p={1}>
                      <CardMedia component="img" image={URL.createObjectURL(canvasData[file])} alt="data" sx={{ height: "84px", borderRadius: 1 }} />
                      <Box display="flex" mt={1}>
                        <Typography
                          variant="body4"
                          sx={{
                            textTransform: "capitalize",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "180px",
                          }}
                        >
                          {canvasData[file].name}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            <Grid></Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddCanvasDialog;
