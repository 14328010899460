import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Grid, Typography, useTheme } from "@mui/material";
import HashtagCard from "./HasgtagCard";
import RedFlaggedCard from "./RedFlaggedCard";
import SentimentAnalysisCard from "./SentimentAnalysisCard";

export default function FeedSummary() {
  const theme = useTheme();

  const sentimentData = [
    { key: "positive", value: 77 },
    { key: "neutral", value: 5 },
    { key: "negative", value: 18 },
  ];

  const hashtagData = [
    { key: "#Tag1", value: 590 },
    { key: "#Tag2", value: 467 },
    { key: "#Tag3", value: 239 },
    { key: "#Tag4", value: 127 },
    { key: "#Tag5", value: 89 },
    { key: "#Tag6", value: 89 },
    { key: "#RussiaisaTerroristState", value: 590 },
    { key: "#NATO", value: 4377 },
    { key: "#SaveUkraine", value: 239 },
    { key: "#WarInUkraine", value: 788 },
    { key: "#SaveAnimals", value: 5990 },
    { key: "#Terrorism", value: 590 },
    { key: "#Weapon", value: 4377 },
    { key: "#Kill", value: 239 },
    { key: "#RussiaisaTerroristState", value: 590 },
    { key: "#NATO", value: 4377 },
    { key: "#SaveUkraine", value: 239 },
    { key: "#WarInUkraine", value: 788 },
    { key: "#SaveAnimals", value: 5990 },
    { key: "#Terrorism", value: 590 },
    { key: "#Weapon", value: 4377 },
    { key: "#Kill", value: 239 },
    { key: "#SaveUkraine", value: 239 },
    { key: "#WarInUkraine", value: 788 },
    { key: "#SaveAnimals", value: 5990 },
    { key: "#Terrorism", value: 590 },
    { key: "#Weapon", value: 4377 },
    { key: "#Kill", value: 239 },
  ];

  const redFlaggedData = [
    { key: "Terrorism", value: 590 },
    { key: "Weapon", value: 467 },
    { key: "Kill", value: 239 },
    { key: "Violence", value: 127 },
    { key: "Hate", value: 89 },
    { key: "Hate1", value: 89 },
  ];

  return (
    <Grid container mt={4}>
      <Typography variant="h3" color={theme.palette.text.titleLabel} sx={{ fontSize: "22px" }}>
        Feed Summary
      </Typography>
      <Typography
        ml="auto"
        variant="link5"
        color={theme.palette.text.default}
        display={"flex"}
        sx={{
          cursor: "pointer",
          fontSize: "16px",
        }}
      >
        View Feed
        <ChevronRightIcon sx={{ color: theme.palette.text.default, mt: "-6px" }} />
      </Typography>

      <Grid mt={1} container spacing={2}>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <SentimentAnalysisCard sentimentData={sentimentData} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <HashtagCard hashtagData={hashtagData} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} display="flex">
          <RedFlaggedCard redFlaggedData={redFlaggedData} />
        </Grid>
      </Grid>
    </Grid>
  );
}
