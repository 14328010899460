import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { routes } from "../../../../../routes";
import { useHandleRouteClick } from "../../../../../routes/hooks";
import EntitiesTable from "./EntitiesTable";
interface detailTypeProps {
  type: string;
}
const tableData = [
  {
    id: 1,
    name: "Entity name",
    type: "Person",
    subtype: "Goverment worker",
    description: "Unusual online activities, Frequent account creation or changes",
    score: "Neutral",
    mentions: 345,
    showDot: true,
  },
  {
    id: 2,
    name: "Entity name",
    type: "Organization",
    subtype: "Goverment worker",
    description: "Unusual online activities, Frequent account creation or changes",
    score: "High",
    mentions: 345,
    showDot: false,
  },
  {
    id: 3,
    name: "Entity name",
    type: "Location",
    subtype: "Goverment worker",
    description: "Unusual online activities, Frequent account creation or changes",
    score: "High",
    mentions: 345,
    showDot: false,
  },
  {
    id: 4,
    name: "Entity name",
    type: "Event",
    subtype: "Goverment worker",
    description: "Unusual online activities, Frequent account creation or changes",
    score: "High",
    mentions: 345,
    showDot: true,
  },
  {
    id: 5,
    name: "Entity name",
    type: "Topic",
    subtype: "Goverment worker",
    description: "Unusual online activities, Frequent account creation or changes",
    score: "High",
    mentions: 345,
    showDot: false,
  },
  {
    id: 6,
    name: "Entity name",
    type: "Person",
    subtype: "Goverment worker",
    description: "Unusual online activities, Frequent account creation or changes",
    score: "Neutral",
    mentions: 345,
    showDot: false,
  },
  {
    id: 7,
    name: "Entity name11",
    type: "Person",
    subtype: "Goverment worker",
    description: "Unusual online activities, Frequent account creation or changes",
    score: "Low",
    mentions: 345,
    showDot: false,
  },
  {
    id: 8,
    name: "Entity name",
    type: "Person",
    subtype: "Goverment worker",
    description: "Unusual online activities, Frequent account creation or changes",
    score: "Extreme",
    mentions: 345,
    showDot: false,
  },
  {
    id: 9,
    name: "Entity name",
    type: "Person",
    subtype: "Goverment worker",
    description: "Unusual online activities, Frequent account creation or changes",
    score: "High",
    mentions: 345,
    showDot: false,
  },
  {
    id: 10,
    name: "Entity name",
    type: "Person",
    subtype: "Goverment worker",
    description: "Unusual online activities, Frequent account creation or changes",
    score: "High",
    mentions: 345,
    showDot: false,
  },
  {
    id: 11,
    name: "Entity name",
    type: "Person",
    subtype: "Goverment worker",
    description: "Unusual online activities, Frequent account creation or changes",
    score: "High",
    mentions: 345,
    showDot: false,
  },
  {
    id: 12,
    name: "Entity name",
    type: "Person",
    subtype: "Goverment worker",
    description: "Unusual online activities, Frequent account creation or changes",
    score: "High",
    mentions: 345,
    showDot: false,
  },
];

const header = [
  { id: "name", label: "Entity Name", searchEnable: false, minWidth: 150, customIcon: true },
  { id: "type", label: "Entity Type", searchEnable: false, minWidth: 100 },
  { id: "subtype", label: "Entity SubType", searchEnable: false, minWidth: 100 },
  { id: "description", label: "Description", searchEnable: false, minWidth: 200 },
  { id: "score", label: "Risk Score", searchEnable: false, type: "button", minWidth: 100 },
  { id: "mentions", label: "Mentions", searchEnable: false, minWidth: 100 },
];

export default function EntitiesDetails({ type }: detailTypeProps) {
  const filterData = tableData.filter((data) => {
    if (type === "all") {
      return data;
    } else {
      return data?.type.toLowerCase() === type;
    }
  });
  const handleRouteClick = useHandleRouteClick();
  const { entities } = useSelector((state: any) => state.magicEdit);
  const { isMagicEdit } = entities;

  const getRowData = (data: any) => {
    handleRouteClick(routes.specificEntity.path.replace(":id", String(data?.id)));
  };

  return (
    <Grid container sx={{ position: "relative" }}>
      <Grid item xs={12} mt={3}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}></Box>
        <EntitiesTable
          header={header}
          tableData={filterData}
          rows={10}
          pagination={true}
          search={false}
          editing={isMagicEdit}
          handleClick={getRowData}
        />
      </Grid>
    </Grid>
  );
}
