import { Box, CardMedia, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import CustomButton from "../../../components/CustomButton";
import CustomDivider from "../../../components/CustomDivider";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { AVAILABLE_SOLUTIONS } from "../../../utils/constants";
import SolutionIncludes from "./SolutionIncludes";

export default function SpecificSolution() {
  const theme = useTheme();
  const params = useParams();
  const handleRouteClick = useHandleRouteClick();

  const handleBack = () => {
    handleRouteClick(routes.marketPlace.path);
  };

  const solutionData = AVAILABLE_SOLUTIONS.find((sol) => sol?.id === Number(params?.id));

  return (
    <Box display="flex" flexDirection="column" height="100%" p={2}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="h1" color={theme.palette.text.titleLabel}>
            {solutionData?.title}
          </Typography>
          <BackButton onClick={handleBack}>Back to Solution</BackButton>
        </Box>
        <Box sx={{ display: "flex", ml: "auto", gap: 2, alignItems: "center" }}>
          <Typography variant="h2" color={theme.palette.text.titleLabel}>
            300$
          </Typography>
          <CustomButton
            sx={{
              ...theme.typography.bigButton,
              background: theme.palette.text.default,
              color: theme.palette.text.secondaryText,
              height: "36px",
              px: "1em",
              "&:hover": {
                background: theme.palette.text.default,
                color: theme.palette.text.secondaryText,
              },
            }}
            onClick={() => {}}
          >
            Add to Cart
          </CustomButton>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          background: theme.palette.background.paper,
          boxShadow: theme.palette.dashboardContainer.customCardInsetBoxShadow,
          borderRadius: 4.5,
          px: { xs: 2, lg: 4, xl: 30 },
          mt: 2,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "98%",
            overflow: "auto",
          }}
        >
          <Box mt={3}>
            <Typography variant="body2" color={theme.palette.primary.light} pb={1}>
              Preferences:
            </Typography>
            <CustomDivider width="100%" />

            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                <Typography variant="body3" color={theme.palette.text.captionColor}>
                  Social media analysis enables organizations, businesses, and individuals to make data-driven decisions, improve marketing
                  strategies, enhance customer engagement, identify opportunities or risks, and gain a deeper understanding of their target audience.
                  Social media analysis enables organizations, businesses, and individuals to make data-driven decisions, improve marketing
                  strategies, enhance customer engagement, identify opportunities or risks, and gain a deeper understanding of their target audience.{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                <Box sx={{ background: `${theme.palette.primary.dark}4D`, p: 2, borderRadius: 2 }}>
                  <Typography variant="subtitle1" color={theme.palette.primary.light}>
                    Estimate the risks and get insights with Risk Factors:
                  </Typography>
                  <ul style={{ ...theme.typography.subtitle1, color: theme.palette.primary.light, paddingLeft: "1.5em" }}>
                    <li>Content Risk Factor</li>
                    <li>Network Risk Factor</li>
                    <li>Privacy Risk Factor</li>
                  </ul>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3}>
            <Typography variant="body2" color={theme.palette.primary.light} pb={1}>
              Solution icludes:
            </Typography>
            <CustomDivider width="100%" />
            {solutionData?.solutions?.map((sol, idx) => (
              <React.Fragment key={`solution-${idx}`}>
                <SolutionIncludes solution={sol} />
              </React.Fragment>
            ))}
          </Box>

          <Box mt={3} pb={5}>
            <Typography variant="body2" color={theme.palette.primary.light} pb={1}>
              Description
            </Typography>
            <CustomDivider width="100%" />
            <Grid container mt={3}>
              <Grid item xs={12} md={4}>
                <CardMedia
                  component="img"
                  image={solutionData?.thumbnail}
                  alt={solutionData?.title}
                  sx={{ width: "100%", borderRadius: 2, height: "15rem" }}
                />
              </Grid>
              <Grid item xs={12} md={8} py={5} sx={{ [theme.breakpoints.up("sm")]: { pl: 4 } }}>
                <Typography variant="h2" color={theme.palette.text.titleLabel}>
                  {solutionData?.title}
                </Typography>
                <Typography variant="body3" color={theme.palette.text.captionColor}>
                  Social media analysis enables organizations, businesses, and individuals to make data-driven decisions, improve marketing
                  strategies, enhance customer engagement, identify opportunities or risks, and gain a deeper understanding of their target audience.
                  Social media analysis enables organizations, businesses, and individuals to make data-driven decisions, improve marketing
                  strategies, enhance customer engagement, identify opportunities or risks, and gain a deeper understanding of their target audience.{" "}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
