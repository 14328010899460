import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { Box, Typography, useTheme } from "@mui/material";
import { TfiExport } from "react-icons/tfi";
import { useLocation } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import CustomButton from "../../../../../components/CustomButton";
import CustomTable from "../../../../../components/CustomTable";
import { routes } from "../../../../../routes";
import { useHandleRouteClick } from "../../../../../routes/hooks";
import { TableData } from "../constant";

export default function TableFullView() {
  const theme = useTheme();
  const { state } = useLocation();
  const handleRouteClick = useHandleRouteClick();

  const handleBackNavigation = () => {
    handleRouteClick(`${routes.analytics.path}?tab=analysis&page=connections`, { activeTab: "table" });
  };

  const header = [
    { id: "name", label: "Entity Name", searchEnable: true, minWidth: 150 },
    { id: "type", label: "Entity Type", searchEnable: true, minWidth: 100 },
    { id: "subtype", label: "Entity SubType", searchEnable: true, minWidth: 300 },
    { id: "region", label: "Region", searchEnable: true, minWidth: 100 },
    { id: "connections", label: "Number of Connection", searchEnable: true, minWidth: 200 },
    { id: "score", label: "Risk Score", searchEnable: true, type: "button", minWidth: 100 },
    { id: "factor1", label: "Factor 1", searchEnable: true, type: "button", minWidth: 100 },
    { id: "factor2", label: "Factor 2", searchEnable: true, type: "button", minWidth: 100 },

    { id: "factor3", label: "Factor 3", searchEnable: true, type: "button", minWidth: 100 },

    { id: "factor4", label: "Factor 4", searchEnable: true, type: "button", minWidth: 100 },

    { id: "factor5", label: "Factor 5", searchEnable: true, type: "button", minWidth: 100 },
    { id: "factor6", label: "Factor 6", searchEnable: true, type: "button", minWidth: 100 },

    { id: "description", label: "Description", searchEnable: true, minWidth: 500 },
  ];

  return (
    <Box
      px={3}
      mt={2}
      width="100%"
      sx={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
      }}
    >
      <Box display="flex" gap={1} justifyContent="space-between">
        <Box display="flex">
          <Typography variant="h1" color={theme.palette.text.titleLabel} textTransform={"capitalize"}>
            Entity Name
          </Typography>
        </Box>
        <Box display="flex" gap={3} px={1} height="40px">
          <CustomButton
            startIcon={<AutoFixHighIcon />}
            sx={{
              ...theme.typography.bigButton,
              background: theme.palette.background.secondaryBgColor,
              color: theme.palette.text.secondaryText,
              width: "123px",
            }}
          >
            Magic Edit
          </CustomButton>
          <CustomButton
            variant="contained"
            sx={{
              ...theme.typography.h6,
              border: `0.5px solid ${theme.palette.additionalColors.border1Light}`,
              display: "flex",
              gap: 1,
              alignItems: "center",
              color: theme.palette.primary.main,
              background: theme.palette.background.tertiaryDarkGradient,
              boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
              "&:hover": {
                background: theme.palette.background.tertiaryGradient,
                boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
              },
            }}
            startIcon={<TfiExport style={{ width: "12px", height: "12px", marginBottom: "5px" }} />}
          >
            Export Report
          </CustomButton>
        </Box>
      </Box>
      <Box>
        <BackButton sx={{ ...theme.typography.bigButton, justifyContent: "flex-start", mt: -1.5, mr: -1 }} onClick={handleBackNavigation} />
      </Box>
      <Box
        sx={{
          width: "100%",
          background: theme.palette.background.dark,
          boxShadow: theme.palette.dashboardContainer.customCardInsetBoxShadow,
          borderRadius: "18px",
          p: 3,
          height: "90%",
          overflow: "scroll",
          mt: 1,
        }}
      >
        <Typography variant="body3" color={theme.palette.text.titleLabel}>
          Description Description Description Description Description
        </Typography>
        <Box sx={{ width: "100%", border: theme.palette.additionalColors.databasecardBorder, p: 2, borderRadius: 2, my: 3 }}>
          <CustomTable header={header} tableData={[state?.rowDetails]} rows={1} pagination={false} search={false} />
        </Box>
        <Typography variant="h4" color={theme.palette.text.titleLabel} mb={2}>
          Connections (319)
        </Typography>
        <CustomTable header={header} tableData={TableData} rows={7} pagination={true} search={true} />
      </Box>
    </Box>
  );
}
