import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { routes } from "../../../routes";

export interface SidebarLogoProps {
  isDarkTheme: boolean;
}

export default function SidebarLogo({ isDarkTheme }: SidebarLogoProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: "transparent",
        color: "white",
        height: "50px",
        width: "50px",
        my: 3,
        textAlign: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "0.5rem",
          height: { sm: "130%", md: "142%" },
          borderLeft: `2px solid ${theme.palette.dashboardContainer.secondThemeColor}`,
          right: "-29px",
          bottom: "-24px",
          borderTopLeftRadius: "10px",
        }}
      ></Box>
      <a href={routes.home.path}>
        <img src={isDarkTheme ? "/skylarklab_logo_black.png" : "/skylarklab_logo_for_light.png"} alt="logo" />
      </a>
    </Box>
  );
}
