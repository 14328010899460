import { Box, Grid, Typography, useTheme } from "@mui/material";
import CustomStatus from "../../../../../../components/CustomStatus";
import { routes } from "../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../routes/hooks";
interface CardProps {
  [x: string]: any;
  view?: string;
}
export default function PublicationCard({ cardData, view }: CardProps) {
  const theme = useTheme();

  const handleRouteClick = useHandleRouteClick();

  const handleNavigation = (id: number) => {
    const route = routes.publication.path.replace(":id", id.toString());
    handleRouteClick(route);
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        p: 2,
        borderRadius: 2,
        flexFlow: "row wrap",
        display: "flex",
        cursor: "pointer",
      }}
      onClick={() => handleNavigation(cardData?.id)}
    >
      <Box width="100%">
        <Box display="flex" gap={2}>
          {cardData?.title ? (
            <Typography variant="bigCaption" mb={2}>
              {cardData?.title}
            </Typography>
          ) : (
            <Box display="flex" gap={1} mb={2}>
              <img src="/assets/profile.png" alt="profile" />
              <Box mt={1}>
                <Typography variant="bigCaption">{cardData?.user?.name}</Typography>
                <Typography variant="subtitle2" color={theme.palette.text.tableHeader}>
                  {cardData?.user?.userid}
                </Typography>
              </Box>
            </Box>
          )}
          <Box ml="auto" display="flex" flexDirection="column">
            <Typography variant="body4" color={theme.palette.text.tableHeader}>
              {cardData?.created_at.split(" ")[0]}
            </Typography>
            <Typography variant="body4" color={theme.palette.text.tableHeader} sx={{ textAlign: "right" }}>
              {cardData?.created_at.split(" ")[1]}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" gap={1}>
          <img src={cardData?.media_image} alt="media" width={"20px"} height={"20px"} style={{ borderRadius: "2px" }} />
          <Typography variant="body4" color={theme.palette.text.tableHeader} display="flex">
            Source:
            <Typography variant="body4" color={theme.palette.text.default}>
              {" "}
              {cardData?.source_link}
            </Typography>
          </Typography>
        </Box>
        <Grid container spacing={2} mt={1.5} display="flex" justifyContent="flex-end">
          <Grid item xs={cardData?.image.length > 0 ? (view === "grid" ? 6 : 9) : 12}>
            <Typography variant="body4" color={theme.palette.text.titleLabel}>
              {cardData?.description}
            </Typography>
          </Grid>
          {cardData?.image.length > 0 && (
            <Grid item xs={view === "grid" ? 6 : 3} sx={{ position: "relative", m: view === "grid" ? "auto" : 0 }}>
              <Box display={"flex"}>
                <img
                  src={cardData?.image[0]}
                  alt="media"
                  width={"164px"}
                  height={"92px"}
                  style={{ borderRadius: "8px", margin: "auto 0 auto auto" }}
                />
                {cardData?.image.length > 1 && (
                  <Typography
                    variant="caption1"
                    sx={{
                      position: "absolute",
                      zIndex: 1,
                      bottom: "8px",
                      right: "8px",
                      background: theme.palette.text.titleLabel,
                      color: theme.palette.background.dark,
                      width: "30px",
                      height: "16px",
                      borderRadius: "4px",
                      textAlign: "center",
                      p: "3px",
                    }}
                  >
                    +{cardData?.image.length}
                  </Typography>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box display="flex" gap={1} mt={5} sx={{ width: "100%", alignSelf: "flex-end" }}>
        <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1}>
          Relevance: {cardData?.relevance}
        </Typography>
        <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1} ml={2}>
          Authenticity: {cardData?.authenticity}
        </Typography>
        <CustomStatus label={cardData?.status} status={cardData?.status} sx={{ width: "88px", height: "28px", marginLeft: "auto" }} />
      </Box>
    </Box>
  );
}
