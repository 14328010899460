import { Box, CardMedia, Checkbox, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { CardData } from "./RecognationOverview";

interface ImageRecognationProps {
  resultsData: CardData;
  handleSelect?(): void;
  select?: boolean;
  checked?: boolean;
  showScore?: boolean;
}
export default function ImageRecognationCard({ resultsData, select, checked, handleSelect, showScore = true }: ImageRecognationProps) {
  const theme = useTheme();
  const [hovered, setHovered] = useState(false);
  const handleHover = () => {
    setHovered((prev) => !prev);
  };

  return (
    <Box sx={{ position: "relative", height: "162px", cursor: "pointer" }} onClick={handleSelect}>
      {select && <Checkbox checked={checked} sx={{ position: "absolute", right: 0, zIndex: 1 }} />}
      <CardMedia component="img" image={resultsData?.image} alt="recognation" sx={{ borderRadius: 1, height: "150px", opacity: checked ? 0.5 : 1 }} />
      <Typography
        variant="body4"
        color={theme.palette.primary.light}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        style={{ cursor: "pointer", textDecoration: hovered ? "underline" : "none" }}
      >
        {resultsData?.entityName || "?????"}
      </Typography>
      {showScore && resultsData?.entityName && (
        <Typography color={theme.palette.primary.light} sx={{ fontSize: "11px", fontWeight: 500 }}>
          Confidence score : {resultsData?.score}
        </Typography>
      )}
    </Box>
  );
}
