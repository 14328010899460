import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import SolutionDialog from "./SolutionDialog";

export interface SolutionCardProps {
  data: { id: string | number; title: string; thumbnail: string; description: string };
}

export default function SolutionCard({ data }: SolutionCardProps) {
  const theme = useTheme();
  const [openSolutionDialog, setOpenSolutionDialog] = React.useState(false);

  const handleToggleSolutionDialog = () => {
    setOpenSolutionDialog((prev) => !prev);
  };

  return (
    <>
      <SolutionDialog handleToggle={handleToggleSolutionDialog} open={openSolutionDialog} solutionData={data} />
      <Box
        onClick={handleToggleSolutionDialog}
        sx={{
          padding: "24px 24px 16px 24px",
          cursor: "pointer",
          backgroundColor: theme.palette.background.paper,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          borderRadius: 1,
          border: `2px solid ${theme.palette.additionalColors.border}`,
          boxShadow: `6px 6px 12px 0px ${theme.palette.background.cardBoxShadowColor1}, -6px -6px 15px 0px ${theme.palette.background.cardBoxShadowColor2}`,
          gap: 2,
        }}
      >
        <img alt="thumbnail" src={data.thumbnail || "/skylarklabs_placeholder.png"} style={{ width: "100%" }} />
        <Typography variant="body1" color={theme.palette.text.default} fontWeight="bold">
          {data.title}
        </Typography>
      </Box>
    </>
  );
}
