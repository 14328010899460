import { Box, Typography, useTheme } from "@mui/material";
import CustomBarChart from "../../../../../components/CustomBarChart";

import { BarChartProps } from "../../../../../components/CustomBarChart";

export default function Precision({ categories, series }: BarChartProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        px: 2,
        pt: 2,
        borderRadius: 2,
        flexFlow: "row wrap",
      }}
    >
      <Box display="flex">
        <Typography variant="h4">Precision</Typography>
      </Box>
      <Box>
        <CustomBarChart categories={categories} series={series} />
      </Box>
    </Box>
  );
}
