import { Box, Typography, useTheme } from "@mui/material";
import { AiOutlinePlus } from "react-icons/ai";
import AddNewCaseDialog from "./AddNewCaseDialog";
import React from "react";

export default function AddNewCaseCard() {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleToggle = () => {
    setOpenDialog((prev) => !prev);
  };

  return (
    <>
      <AddNewCaseDialog handleToggle={handleToggle} open={openDialog} />
      <Box
        sx={{
          p: 2,
          height: "100%",
          backgroundColor: theme.palette.background.dark,
          borderRadius: 1,
        }}
      >
        <Box
          onClick={() => handleToggle()}
          sx={{
            height: "100%",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 1,
            border: `1px dashed ${theme.palette.primary.main}`,
            gap: 2,
          }}
        >
          <AiOutlinePlus size={30} color={theme.palette.text.default} />
          <Typography variant="body1" color={theme.palette.text.default} fontWeight="bold">
            New Custom Case
          </Typography>
        </Box>
      </Box>
    </>
  );
}
