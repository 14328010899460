import { Box } from "@mui/material";
import Header from "./Settings/Header";
import SettingSection from "./Settings/SettingSection";

export default function AISetting() {
  return (
    <Box p={2} height="100%" display="flex" flexDirection="column">
      <Header />
      <SettingSection />
    </Box>
  );
}
