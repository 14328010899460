export const containsText = (text: string, searchText: string) => text.toLowerCase().includes(searchText.toLowerCase());

export const convertSecondsToMMSS = (seconds: number): string => {
  let isNegative = false;
  let secondCopy = seconds;
  if (seconds < 0) {
    secondCopy = Math.abs(seconds);
    isNegative = true;
  }
  var minutes = Math.floor(secondCopy / 60);
  var remainingSeconds = Math.floor(secondCopy % 60);

  var minutesString = minutes < 10 ? "0" + minutes : minutes.toString();
  var secondsString = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds.toString();

  if (isNegative) {
    return `-${minutesString + ":" + secondsString}`;
  }
  return minutesString + ":" + secondsString;
};

export const randomColorHex = (): string => {
  // Storing all letter and digit combinations
  // for html color code
  let letters = "0123456789ABCDEF";

  // HTML color code starts with #
  let color = "#";

  // Generating 6 times as HTML color code
  // consist of 6 letter or digits
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const formatDatetimeToYYYYMMDD = (date: string, range: boolean): string => {
  // // Create a new Date object
  const dateObj = new Date(date);
  if (range) {
    dateObj.setDate(dateObj.getDate() - 1);
  } else {
    dateObj.setDate(dateObj.getDate() + 1);
  }
  const updatedDate = new Date(dateObj);

  // GetUTC the year, month, and day from the Date object
  const year = updatedDate.getFullYear().toString().padStart(4, "0");
  const month = (updatedDate.getMonth() + 1).toString().padStart(2, "0"); // Note: month starts from 0
  const day = updatedDate.getDate().toString().padStart(2, "0");

  // Combine the year, month, and day into the desired format
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const textTruncate = (str: string, length: number): string => {
  return str.length > length ? str.substring(0, length) + "..." : str;
};

export const formatDateTimeIntoBackenStandard = (dateTime = new Date()): string => {
  // This will return the datetime as a string in the format: YYYY-MM-DDThh:mm:ss.mmms
  const year = dateTime.getUTCFullYear();
  const month = String(dateTime.getUTCMonth() + 1).padStart(2, "0");
  const day = String(dateTime.getUTCDate()).padStart(2, "0");
  const hours = String(dateTime.getUTCHours()).padStart(2, "0");
  const minutes = String(dateTime.getUTCMinutes()).padStart(2, "0");
  const seconds = String(dateTime.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(dateTime.getUTCMilliseconds()).padStart(3, "0");

  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  return formattedDateTime; // YYYY-MM-DDThh:mm:ss.mmms
};

export const formatDateTimeIntoCurrentTimeZone = (dateTime: Date) => {
  // This will return datetime as a string in the format: DD/MM/YYYY hh:mm
  const year = dateTime.getFullYear();
  const month = dateTime.getMonth();
  const day = dateTime.getDate();
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();

  return `${day}/${month}/${year} ${hours}:${minutes}`; // DD/MM/YYYY hh:mm
};

export const formatDateTimeIntoDDMMMYYYY = (dateTime: Date) => {
  // This will return datetime as a string in the format: DD Mmm YYYY (1 Apr 2021)
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const day = dateTime.getDate();
  const month = months[dateTime.getMonth()];
  const year = dateTime.getFullYear();

  return `${day} ${month} ${year}`; // DD Mmm YYYY (1 Apr 2021)
};
