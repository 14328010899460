import { Box, useMediaQuery } from "@mui/material";
import ContentUILoader from "../../../../../../components/ContentUILoader";
import CustomNoResults from "../../../../../../components/CustomNoResult";
import ImageCard from "./ImageCard";

const itemData = [
  {
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
];

interface ImageProps {
  drawerOpen: boolean;
  isRefresh: boolean;
}

export default function Images({ drawerOpen, isRefresh }: ImageProps) {
  const matches = useMediaQuery("(min-width:1200px)") ? 2 : 4;

  let mergedColumnIds: number[] = [];

  for (let i = matches; i <= itemData.length; i += 10) {
    mergedColumnIds.push(i);
  }

  for (let i = 5; i <= itemData.length; i += 10) {
    mergedColumnIds.push(i);
  }

  const matchedData = useMediaQuery("(min-width:768px)") ? mergedColumnIds : [];

  return (
    <>
      {isRefresh ? (
        <ContentUILoader type="image" count={5} drawerOpen={drawerOpen} />
      ) : (
        <>
          {itemData.length > 0 ? (
            <Box sx={{ width: 1, mt: 3 }}>
              <Box display="grid" gridTemplateColumns="repeat(12, 4fr)" width="100%" gap={2}>
                {itemData.map((data, idx) => {
                  return (
                    <Box
                      key={`image-${idx}`}
                      gridColumn={{ xs: "span 12", md: drawerOpen ? "span 12" : "span 6", lg: drawerOpen ? "span 6" : "span 4" }}
                      gridRow={matchedData.includes(idx) ? "span 2" : ""}
                    >
                      <ImageCard cardData={data} mergedColumnIds={matchedData} index={idx} />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ) : (
            <CustomNoResults />
          )}
        </>
      )}
    </>
  );
}
