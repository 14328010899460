import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, FormControl, Typography, useTheme } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomInputField from "../../../components/CustomInputField";
import SubmitLoadingButton from "../../../components/SubmitLoadingButton";

export interface FormDataType {
  old_password: string;
  new_password1: string;
  new_password2: string;
}

const initialFormData: FormDataType = {
  old_password: "",
  new_password1: "",
  new_password2: "",
};

export interface ChangePasswordDialogProps {
  open: boolean;
  handleToggle: () => void;
}

const ChangePasswordDialog = ({ open, handleToggle }: ChangePasswordDialogProps) => {
  const theme = useTheme();
  const [formData, setFormData] = React.useState(initialFormData);

  const handleInputChange = ({ fieldName, value }: { fieldName: string; value: any }) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const resetFormState = () => {
    setFormData(initialFormData);
    handleToggle();
  };

  return (
    <Dialog
      onClose={resetFormState}
      open={open}
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          py: 4,
          px: 3,
          minWidth: "50vw",
          minHeight: "50vh",
        },
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h1" m="auto">
          Change Password
        </Typography>
        <CustomIconButton onClick={handleToggle} sx={{ color: theme.palette.error.main }}>
          <CloseIcon />
        </CustomIconButton>
      </Box>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <FormControl fullWidth>
            <Typography variant="caption1">Password</Typography>
            <CustomInputField
              value={formData.old_password}
              required
              name="old_password"
              placeholder="Enter Current Password"
              size="small"
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange({ fieldName: e.target.name, value: e.target.value })}
              autoComplete="off"
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography variant="caption1">New Password</Typography>
            <CustomInputField
              value={formData.new_password1}
              required
              name="new_password1"
              placeholder="Enter New Password"
              size="small"
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange({ fieldName: e.target.name, value: e.target.value })}
              autoComplete="off"
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography variant="caption1">Confirm New Password</Typography>
            <CustomInputField
              value={formData.new_password2}
              required
              name="new_password2"
              placeholder="Enter New Password"
              size="small"
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange({ fieldName: e.target.name, value: e.target.value })}
              autoComplete="off"
            />
          </FormControl>
        </Box>
        <Box my={3} textAlign="center">
          <SubmitLoadingButton isLoading={false}>Change Password</SubmitLoadingButton>
        </Box>
        <Typography variant="subtitle2" mt={2} textAlign="center">
          Have any isues?
          <NavLink
            to="/login"
            style={{
              color: theme.palette.primary.main,
              textDecoration: "none",
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            Contact Us
          </NavLink>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordDialog;
