import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import RadioFilterButton from "../../../../../../components/RadioFilterButton";

interface LanguageFilterProps {
  selectedValue: string;
  isReset: boolean;
  getLanguage(value: string): void;
}
export default function Language({ selectedValue, isReset, getLanguage }: LanguageFilterProps) {
  const theme = useTheme();

  const [value, setValue] = useState<string>("");

  const languageOptions = [
    {
      id: "english",
      text: "English",
    },
    {
      id: "russian",
      text: "Russian",
    },
  ];

  const handleChange = (value: string) => {
    getLanguage(value);
  };

  useEffect(() => {
    if (selectedValue) {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  return (
    <Box mt={2}>
      <Typography variant="h4" color={theme.palette.text.tableHeader} mb={1}>
        Language
      </Typography>
      <RadioFilterButton
        onChange={(value: string) => {
          handleChange(value);
        }}
        options={languageOptions}
        sx={{
          width: "100%",
          padding: 0,
          color: theme.palette.text.titleLabel,
          background: theme.palette.background.gradientLight2,
          boxShadow: theme.palette.dashboardContainer.customFilterOptionShadow,
        }}
        endIcon={<ExpandMoreIcon sx={{ color: theme.palette.primary.main, height: "20px", width: "20px", mt: 1 }} />}
        textColor={theme.palette.text.titleLabel}
        placeholder="Select Language"
        placeholderColor={theme.palette.text.titleLabel}
        isReset={isReset}
        selectionData={value}
      />
    </Box>
  );
}
