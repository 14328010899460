import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CustomDivider from "../../../../components/CustomDivider";
import CustomTextButton from "../../../../components/CustomTextButton";

interface FAQItemProps {
  faqItem: {
    id: number;
    question: string;
    ans: string;
  }[];
}

export default function FAQDetails({ faqItem }: FAQItemProps) {
  const theme = useTheme();
  const [showExpand, setShowExpand] = useState<number>(0);
  const [expanded, setExpanded] = useState<string | false>("panel0");
  const [readMore, setReadMore] = useState(false);

  const handleExpand = (i: number) => {
    setShowExpand(i);
    setReadMore(false);
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleReadMore = () => {
    setReadMore((prev) => !prev);
  };

  const answerStyle = {
    maxWidth: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 4,
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: 1,
  };
  return (
    <>
      {faqItem.map((faq, idx) => {
        const answer = readMore ? faq?.ans : faq?.ans?.slice(0, 50);

        return (
          <Accordion
            key={idx}
            sx={{
              width: "100%",
              background: theme.palette.dashboardContainer.backround,
              border: `1px solid ${theme.palette.additionalColors.lightBorder}`,
              borderRadius: 2,
              mb: 2,
              "&.MuiPaper-root.MuiAccordion-root:before": {
                backgroundColor: "transparent",
              },
              "&.MuiPaper-root.MuiAccordion-root:last-of-type": {
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              },
              "&.MuiPaper-root.MuiAccordion-root:first-of-type": {
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              },
            }}
            expanded={expanded === `panel${idx}`}
            onChange={handleChange(`panel${idx}`)}
          >
            <AccordionSummary
              aria-controls={`panel${idx}-content`}
              id={`panel${idx}-header`}
              onClick={() => handleExpand(idx)}
              sx={{ "& .MuiAccordionSummary-content": { marginBottom: 0 } }}
            >
              <Box display="flex" width="100%">
                <Typography variant="subtitle1" color={expanded === `panel${idx}` ? theme.palette.text.titleLabel : theme.palette.text.tableHeader}>
                  {faq?.question}
                </Typography>
                <Box sx={{ ml: "auto", gap: 4 }}>
                  {showExpand === idx ? (
                    <ExpandLessIcon sx={{ mt: "-10px", color: theme.palette.text.default, fontSize: "1.5em" }} />
                  ) : (
                    <ExpandMoreIcon sx={{ mt: "-10px", color: theme.palette.text.default, fontSize: "1.5em" }} />
                  )}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                mt: -4,
                "&.MuiAccordionDetails-root": {
                  pb: 1,
                },
              }}
            >
              <CustomDivider width="100%" />
              <Typography
                variant="body4"
                color={theme.palette.primary.inactiveIcon}
                sx={
                  readMore
                    ? { mt: "15px", display: "inline-block", marginTop: 1.2 }
                    : {
                        maxWidth: "100%",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: { xs: 3, md: 4 },
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        marginTop: 1,
                      }
                }
              >
                {faq?.ans}
              </Typography>
              <Box>
                <CustomTextButton onClick={handleReadMore}>{readMore ? "Read Less" : "Read More"}</CustomTextButton>
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}
