import { Box, Typography, useTheme } from "@mui/material";
import Chart from "react-apexcharts";
import { useHandleRouteClick } from "../../../../../../routes/hooks";

interface SubData {
  name: string;
  data: number[];
}
type ChartColors = string[];

interface ChartDataOptions {
  options: {
    colors: ChartColors;
    tooltip: {
      enabled: boolean;
      alwaysShow: boolean;
      theme: string;
      marker: {
        show: boolean;
      };
      x: {
        show: boolean;
      };
    };
    plotOptions: {
      bar: {
        columnWidth: string;
        dataLabels: {
          position: string;
        };
        distributed: boolean;
      };
    };
    dataLabels: {
      enabled: boolean;
    };
    xaxis: {
      categories: string[];
      position: string;
      labels: {
        show: boolean;
        offsetY: number;
        style?: {
          colors?: string;
          fontWeight?: number;
          fontSize?: string;
        };
      };
      axisBorder: {
        show: boolean;
      };
      axisTicks: {
        show: boolean;
      };
    };
    yaxis: {
      labels: {
        show: boolean;
      };
    };
    chart: {
      toolbar: {
        show: boolean;
      };
      events: {
        dataPointSelection(event: any, chartContext: any, config: any): void;
      };
    };
    grid: {
      show: boolean;
    };
    states: {
      hover: { filter: { type: string; value: number } };
      active: { filter: { type: string; value: number } };
    };
    legend: {
      show: boolean;
    };
  };
  series: SubData[];
}

export default function RiskScore() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const chartData: ChartDataOptions = {
    options: {
      colors: [
        theme.palette.text.extremeStatus,
        theme.palette.text.highStatus,
        theme.palette.text.moderateStatus,
        theme.palette.text.neutralStatus,
        theme.palette.text.titleLabel,
      ] as ChartColors,
      tooltip: {
        enabled: true,
        alwaysShow: true,
        theme: "dark",
        marker: {
          show: false,
        },
        x: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "70%",
          dataLabels: {
            position: "top",
          },
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["Extreme", "High", "Moderate", "Neutral", "Low"],
        position: "bottom",
        labels: {
          show: true,
          offsetY: 0,
          style: {
            fontWeight: 400,
            fontSize: "14px",
            colors: theme.palette.text.titleLabel,
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            let dataPointIndex = config.w.config.xaxis.categories[config.dataPointIndex];
            handleRouteClick(`/riskscore/${dataPointIndex}`);
          },
        },
      },
      grid: {
        show: false,
      },

      states: {
        hover: { filter: { type: "none", value: 0 } },
        active: { filter: { type: "none", value: 0 } },
      },
      legend: {
        show: false,
      },
    },
    series: [
      {
        name: "entities",
        data: [123, 30, 60, 10, 8],
      },
    ],
  };

  return (
    <Box
      sx={{
        border: theme.palette.additionalColors.databasecardBorder,
        mt: 5,
        p: 2,
        background: theme.palette.background.darkCardBackground,
        borderRadius: 2,
      }}
    >
      <Typography variant="subtitle1">Entities by Risk Score</Typography>
      <Typography variant="h3" mt={1}>
        515
      </Typography>
      <Box textAlign="center">
        <Chart options={chartData.options} series={chartData.series} type="bar" width="100%" height={180} className="apex-charts" />
      </Box>
    </Box>
  );
}
