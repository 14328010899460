import { Box, Typography } from "@mui/material";
import BillingDetails from "./BillingDetails";
import Invoices from "./Invoices";

export default function BillingSection() {
  return (
    <Box>
      <Typography variant="h2" mb={3}>
        Billing
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <BillingDetails />
        <Invoices />
      </Box>
    </Box>
  );
}
