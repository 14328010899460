import { Box, Card, Typography, useTheme } from "@mui/material";
import CustomStatus from "../../../../../../components/CustomStatus";
import CustomVideoPlayer from "../../../../../../components/CustomVideoPlayer";
import { routes } from "../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../routes/hooks";

interface CardProps {
  [x: string]: any;
}

export default function VideoCard({ cardData }: CardProps) {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const openVideoAnalysis = (id: number) => {
    handleRouteClick(`${routes.specificVideo.path.replace(":id", String(id))}`);
  };

  return (
    <Card
      sx={{
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        borderRadius: 2,
        flexFlow: "row wrap",
        display: "flex",
        height: "auto",
      }}
      onClick={() => {
        openVideoAnalysis(cardData?.id);
      }}
    >
      <CustomVideoPlayer hlsStreamUrl="https://www.youtube.com/embed/K0nzQoT6Ymw" thumbnail={cardData?.image} />

      <Box sx={{ p: 1.5, width: "100%", alignSelf: "flex-end", cursor: "pointer" }}>
        <Box display="flex" gap={1}>
          <img src={cardData?.media_image} alt="media" width={"20px"} height={"20px"} style={{ borderRadius: "2px" }} />
          <Typography variant="body4" color={theme.palette.text.tableHeader} display="flex">
            Source:
            <Typography variant="body4" color={theme.palette.text.default}>
              {cardData?.source_link}
            </Typography>
          </Typography>
          <Typography variant="body4" color={theme.palette.text.tableHeader} ml="auto">
            {cardData?.created_at}
          </Typography>
        </Box>
        <Box display="flex" sx={{ width: "100%" }}>
          <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1}>
            Relevance: {cardData?.relevance}
          </Typography>
          <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1} ml={2}>
            Authenticity: {cardData?.authenticity}
          </Typography>
          <CustomStatus label={cardData?.status} status={cardData?.status} sx={{ width: "88px", height: "28px", marginLeft: "auto" }} />
        </Box>
      </Box>
    </Card>
  );
}
