import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, CardMedia, Dialog, DialogContent, Grid, Link, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import CustomCloseIconButton from "../../../../../../components/CustomCloseIconButton";
import CustomIconButton from "../../../../../../components/CustomIconButton";

interface ModalProps {
  open: boolean;
  data?: any;
  index: number;
  handleModalOpen(): void;
}

export default function EntityModal({ open, data, index, handleModalOpen }: ModalProps) {
  const theme = useTheme();

  const [currentIdx, setIndex] = useState(index);

  const arrowUpHandler = () => {
    if (currentIdx > 0) {
      setIndex((prev) => prev - 1);
    }
  };

  const arrowDownHandler = () => {
    if (currentIdx < data?.length - 1) {
      setIndex((prev) => prev + 1);
    }
  };

  useEffect(() => {
    setIndex(index);
  }, [index]);
  const entityDetails = data?.[currentIdx];

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleModalOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.background.default,
          borderRadius: "10px",
          width: "100%",
          textAlign: "center",
        },
      }}
    >
      <DialogContent sx={{ p: 4 }}>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Typography variant="body1">Entity {`${currentIdx + 1}/${data?.length}`}</Typography>
          <CustomCloseIconButton onClick={handleModalOpen} sx={{ marginLeft: { ml: "auto" } }}>
            <CloseIcon sx={{ width: "16px", height: "16px" }} />
          </CustomCloseIconButton>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          sx={{
            width: "100%",
            background: (theme) => theme.palette.background.default,
            borderRadius: "4px",
          }}
        >
          <CustomIconButton
            sx={{
              width: "40px",
              minWidth: "40px",
              height: "40px",
              background: "transparent",
              border: "none",
              boxShadow: "none",
              ":hover": {
                background: "transparent",
                boxShadow: "none",
              },
              ":disabled": {
                background: "transparent",
                boxShadow: "none",
              },
            }}
            disabled={currentIdx === 0}
          >
            <KeyboardArrowLeftIcon
              onClick={arrowUpHandler}
              fontSize="large"
              cursor="pointer"
              sx={{
                margin: "10px 8px 10px 8px",
                color: currentIdx === 0 ? theme.palette.action.disabled : theme.palette.primary.main,
              }}
            />
          </CustomIconButton>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardMedia component={"img"} image={entityDetails?.image} alt="entity" sx={{ borderRadius: 2 }} />
              <Typography variant="body4" display={"flex"} mt={1}>
                Original Source:
                <Link
                  href="/assets/person.png"
                  target="_blank"
                  underline="none"
                  sx={{ ...theme.typography.body4, color: theme.palette.text.default, mt: 0.2, pl: 1 }}
                >
                  54579480{currentIdx}.png
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <CustomIconButton
            sx={{
              width: "40px",
              minWidth: "40px",
              height: "40px",
              background: "transparent",
              border: "none",
              boxShadow: "none",
              ":hover": {
                background: "transparent",
                boxShadow: "none",
              },
              ":disabled": {
                background: "transparent",
                boxShadow: "none",
              },
            }}
            disabled={currentIdx === data?.length - 1}
          >
            <KeyboardArrowRightIcon
              onClick={arrowDownHandler}
              fontSize="large"
              cursor="pointer"
              sx={{
                margin: "10px 8px 10px 8px",
                color: currentIdx === data?.length - 1 ? theme.palette.action.disabled : theme.palette.primary.main,
              }}
            />
          </CustomIconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
