import CustomTable from "../../../../../components/CustomTable";
import { routes } from "../../../../../routes";
import { useHandleRouteClick } from "../../../../../routes/hooks";
import { TableData } from "../constant";

export default function TableView() {
  const handleRouteClick = useHandleRouteClick();

  const header = [
    { id: "name", label: "Entity Name", searchEnable: true, minWidth: 150 },
    { id: "type", label: "Entity Type", searchEnable: true, minWidth: 100 },
    { id: "subtype", label: "Entity SubType", searchEnable: true, minWidth: 300 },
    { id: "region", label: "Region", searchEnable: true, minWidth: 100 },
    { id: "connections", label: "Number of Connection", searchEnable: true, minWidth: 200 },
    { id: "score", label: "Risk Score", searchEnable: true, type: "button", minWidth: 100 },
    { id: "factor1", label: "Factor 1", searchEnable: true, type: "button", minWidth: 100 },
    { id: "factor2", label: "Factor 2", searchEnable: true, type: "button", minWidth: 100 },

    { id: "factor3", label: "Factor 3", searchEnable: true, type: "button", minWidth: 100 },

    { id: "factor4", label: "Factor 4", searchEnable: true, type: "button", minWidth: 100 },

    { id: "factor5", label: "Factor 5", searchEnable: true, type: "button", minWidth: 100 },
    { id: "factor6", label: "Factor 6", searchEnable: true, type: "button", minWidth: 100 },

    { id: "description", label: "Description", searchEnable: true, minWidth: 500 },
  ];

  const getRowData = (data: any) => {
    handleRouteClick(routes.connectionTable.path, { rowDetails: data });
  };

  return <CustomTable header={header} tableData={TableData} rows={10} pagination={true} search={true} handleClick={getRowData} />;
}
