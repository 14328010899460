import { Box, Button, Grid, List, ListItem, Typography, useTheme } from "@mui/material";
import React from "react";
import { BsCheck } from "react-icons/bs";
import BorderedCard from "../../../components/BorderedCard";
import CustomButton from "../../../components/CustomButton";
import CustomTextButton from "../../../components/CustomTextButton";
import PlanButton from "../../../components/PlanButton";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";

export default function CurrentPlanUpgrades() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const handleNavigation = (plan: string) => {
    handleRouteClick(routes.upgradeInfo.path, { plan: plan });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding: "32px",
        borderRadius: "8px",
        background: theme.palette.dashboardContainer.sessionCardActive,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="body3" color={theme.palette.text.main}>
          You’re currently on the Basic plan. Upgrade your plan to upload your own data or customize the AI Models.
        </Typography>
        <CustomTextButton onClick={toggleCollapse}>{isCollapsed ? "Expand" : "Collapse"}</CustomTextButton>
      </Box>
      {!isCollapsed && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <BorderedCard
              sx={{
                background: theme.palette.dashboardContainer.otherPlanCardBackground,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 2,
                minHeight: "22rem",
                height: "100%",
                borderRadius: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <PlanButton type="basic">Basic</PlanButton>
                <Typography variant="body6" color={theme.palette.text.secondary}>
                  Quick start. You can buy any AI Models and Solutions
                </Typography>
                <Typography variant="h2" color={theme.palette.text.default}>
                  Free
                </Typography>
                <Box>
                  <List sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <ListItem sx={{ p: 0 }}>
                      <BsCheck size={24} color={theme.palette.text.default} />
                      <Typography variant="body6" px={0.5}>
                        1 AI Model
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <BsCheck size={24} color={theme.palette.text.default} />
                      <Typography variant="body6" px={0.5}>
                        1 Data Source
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
              </Box>
              <Box>
                <CustomButton sx={{ width: "100%" }} variant="contained" disabled>
                  Current
                </CustomButton>
              </Box>
            </BorderedCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <BorderedCard
              sx={{
                background: theme.palette.dashboardContainer.otherPlanCardBackground,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 2,
                minHeight: "22rem",
                height: "100%",
                borderRadius: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <PlanButton type="pro">Pro</PlanButton>
                <Typography variant="body6" color={theme.palette.text.secondary}>
                  Optimal variant. Analyze everything, including your own data{" "}
                </Typography>
                <Typography variant="h2" color={theme.palette.text.default}>
                  +300$/year
                </Typography>
                <Box>
                  <List sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <ListItem sx={{ p: 0 }}>
                      <BsCheck size={24} color={theme.palette.text.default} />
                      <Typography variant="body6" px={0.5}>
                        All Data sources
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <BsCheck size={24} color={theme.palette.text.default} />
                      <Typography variant="body6" px={0.5}>
                        Own data sourses
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <BsCheck size={24} color={theme.palette.text.default} />
                      <Typography variant="body6" px={0.5}>
                        Workspace
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
              </Box>
              <Box>
                <Button sx={{ width: "100%" }} variant="contained" onClick={() => handleNavigation("pro")}>
                  Upgrade
                </Button>
              </Box>
            </BorderedCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <BorderedCard
              sx={{
                background: theme.palette.dashboardContainer.otherPlanCardBackground,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                justifyContent: "space-between",
                minHeight: "22rem",
                height: "100%",
                borderRadius: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <PlanButton type="enterprise">Enterprise</PlanButton>
                <Typography variant="body6" color={theme.palette.text.secondary}>
                  Customize your models with your own data{" "}
                </Typography>
                <Typography variant="h2" color={theme.palette.text.default}>
                  +700$/year
                </Typography>
                <Box>
                  <List sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <ListItem sx={{ p: 0 }}>
                      <BsCheck size={24} color={theme.palette.text.default} />
                      <Typography variant="body6" px={0.5}>
                        All Data sources
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <BsCheck size={24} color={theme.palette.text.default} />
                      <Typography variant="body6" px={0.5}>
                        Own data sourses
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <BsCheck size={24} color={theme.palette.text.default} />
                      <Typography variant="body6" px={0.5}>
                        Workspace
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <BsCheck size={24} color={theme.palette.text.default} />
                      <Typography variant="body6" px={0.5}>
                        Custom AI Models
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
              </Box>
              <Box>
                <Button sx={{ width: "100%" }} variant="contained" onClick={() => handleNavigation("enterprise")}>
                  Upgrade
                </Button>
              </Box>
            </BorderedCard>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
