import TabLayout from "../../layouts/TabLayout";
import MarketplaceContent from "./component/MarketplaceContent";

const tabs = [
  {
    id: "marketplace",
    title: "Marketplace",
    disabled: false,
    component: <MarketplaceContent />,
  },
];

export default function Marketplace() {
  return <TabLayout tabs={tabs} />;
}
