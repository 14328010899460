import { Box, Card, CardMedia, Typography, useTheme } from "@mui/material";
import CustomStatus from "../../../../../../components/CustomStatus";

interface CardProps {
  [x: string]: any;
  view?: string;
  mergedColumnIds: number[];
  index: number;
}
export default function ImageCard({ cardData, view, mergedColumnIds, index }: CardProps) {
  const theme = useTheme();
  let imgHeight: string = mergedColumnIds.includes(index) ? "548px" : "224px";

  return (
    <Card
      sx={{
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        borderRadius: 2,
        flexFlow: "row wrap",
        display: "flex",
        height: "100%",
      }}
    >
      <CardMedia component="img" image={cardData?.image} alt="data" sx={{ height: imgHeight }} />
      <Box sx={{ p: 1.5, width: "100%", alignSelf: "flex-end" }}>
        <Box display="flex" gap={1}>
          <img src={cardData?.media_image} alt="media" width={"20px"} height={"20px"} style={{ borderRadius: "2px" }} />
          <Typography variant="body4" color={theme.palette.text.tableHeader} display="flex">
            Source:
            <Typography variant="body4" color={theme.palette.text.default}>
              {cardData?.source_link}
            </Typography>
          </Typography>
          <Typography variant="body4" color={theme.palette.text.tableHeader} ml="auto">
            {cardData?.created_at}
          </Typography>
        </Box>
        <Box display="flex" sx={{ width: "100%" }}>
          <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1}>
            Relevance: {cardData?.relevance}
          </Typography>
          <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1} ml={2}>
            Authenticity: {cardData?.authenticity}
          </Typography>
          <CustomStatus label={cardData?.status} status={cardData?.status} sx={{ width: "88px", height: "28px", marginLeft: "auto" }} />
        </Box>
      </Box>
    </Card>
  );
}
