import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import React from "react";

export interface SubmitLoadingDeleteButtonProps extends ButtonProps {
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  isLoading: boolean;
  [x: string]: any;
}

export default function SubmitLoadingDeleteButton({ sx, children, isLoading = false, ...rest }: SubmitLoadingDeleteButtonProps) {
  const theme = useTheme();

  return (
    <Button
      type="submit"
      disabled={isLoading}
      variant="contained"
      sx={{
        fontWeight: "bold",
        border: "none",
        borderRadius: "4px",
        transform: "rotate(0.13deg)",
        ...sx,
      }}
      {...rest}
    >
      {children} {isLoading && <CircularProgress color="inherit" size={16} sx={{ ml: 1 }} />}
    </Button>
  );
}
