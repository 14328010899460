import { Box } from "@mui/material";
import ContentUILoader from "../../../../../../components/ContentUILoader";
import PublicationCard from "./PublicationCard";

interface ListProps {
  [x: string]: any;
}

interface ListViewProps {
  publicationData: ListProps[];
  isRefresh: boolean;
  drawerOpen: boolean;
}
export default function ListView({ publicationData, isRefresh, drawerOpen }: ListViewProps) {
  return (
    <Box>
      {!isRefresh ? (
        <>
          {publicationData.map((data: any, idx) => (
            <Box mb={2} key={`image-${idx}`}>
              <PublicationCard cardData={data} />
            </Box>
          ))}
        </>
      ) : (
        <ContentUILoader type="publication" count={5} drawerOpen={drawerOpen} />
      )}
    </Box>
  );
}
