import { Box, FormControl, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import BorderedCard from "../../../components/BorderedCard";
import CustomDivider from "../../../components/CustomDivider";
import CustomInputField from "../../../components/CustomInputField";
import CustomTextButton from "../../../components/CustomTextButton";
import StaticValueDisplay from "../../../components/StaticValueDisplay";

export default function BillingDetails() {
  const theme = useTheme();
  const [editable, setEditable] = React.useState<boolean>(false);
  const [billingData, setBillingData] = React.useState<Record<string, any>>({});
  const [updatedData, setUpdatedData] = React.useState<Record<string, any>>({});

  const handleEditSave = () => {
    if (editable) {
      //   handleProfileUpdate(updatedData);
    }
    setEditable(!editable);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {};

  return (
    <BorderedCard>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4">Billing Details</Typography>

        {editable ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <CustomTextButton onClick={handleEditSave} color="error">
              Cancel
            </CustomTextButton>
            <CustomTextButton onClick={handleEditSave}>Save</CustomTextButton>
          </Box>
        ) : (
          <CustomTextButton onClick={handleEditSave}>Edit</CustomTextButton>
        )}
      </Box>
      <CustomDivider />
      <>
        <Grid container mt={3} spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            {editable ? (
              <FormControl fullWidth>
                <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                  User Name
                </Typography>
                <CustomInputField
                  defaultValue={billingData.name || "NA"}
                  required
                  name="name"
                  size="small"
                  placeholder="User Name"
                  onChange={handleInputChange}
                  autoComplete="off"
                  disabled={!editable}
                />
              </FormControl>
            ) : (
              <StaticValueDisplay label="User Name" value={billingData["name"]} />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {editable ? (
              <FormControl fullWidth>
                <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                  Country
                </Typography>
                <CustomInputField
                  defaultValue={billingData.country || "NA"}
                  required
                  name="country"
                  size="small"
                  placeholder="Country"
                  onChange={handleInputChange}
                  autoComplete="off"
                  disabled={!editable}
                />
              </FormControl>
            ) : (
              <StaticValueDisplay label="Country" value={billingData["country"]} />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {editable ? (
              <FormControl fullWidth>
                <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                  Address
                </Typography>
                <CustomInputField
                  defaultValue={billingData.address || "NA"}
                  required
                  name="address"
                  size="small"
                  placeholder="Address"
                  onChange={handleInputChange}
                  autoComplete="off"
                  disabled={!editable}
                />
              </FormControl>
            ) : (
              <StaticValueDisplay label="Address" value={billingData["address"]} />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {editable ? (
              <FormControl fullWidth>
                <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                  Email
                </Typography>
                <CustomInputField
                  defaultValue={billingData.email || "NA"}
                  required
                  name="email"
                  size="small"
                  placeholder="Email"
                  onChange={handleInputChange}
                  autoComplete="off"
                  disabled={!editable}
                />
              </FormControl>
            ) : (
              <StaticValueDisplay label="Email" value={billingData["email"]} />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {editable ? (
              <FormControl fullWidth>
                <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                  State
                </Typography>
                <CustomInputField
                  defaultValue={billingData.state || "NA"}
                  required
                  name="state"
                  size="small"
                  placeholder="State"
                  onChange={handleInputChange}
                  autoComplete="off"
                  disabled={!editable}
                />
              </FormControl>
            ) : (
              <StaticValueDisplay label="State" value={billingData["state"]} />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {editable ? (
              <FormControl fullWidth>
                <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                  Zip Code
                </Typography>
                <CustomInputField
                  defaultValue={billingData.zip || "NA"}
                  required
                  name="zip"
                  size="small"
                  placeholder="Zip Code"
                  onChange={handleInputChange}
                  autoComplete="off"
                  disabled={!editable}
                />
              </FormControl>
            ) : (
              <StaticValueDisplay label="Zip Code" value={billingData["zip"]} />
            )}
          </Grid>
        </Grid>
      </>
    </BorderedCard>
  );
}
