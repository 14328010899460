export const ResultsData = [
  {
    model: "Image Recognition",
    identified: 674,
    unidentified: 45,
    data: [
      {
        type: "Person",
        entityName: "Women",
        image: "/assets/person.png",
        score: 78,
        count: 124,
      },
      {
        type: "Car",
        entityName: "Men",
        image: "/assets/person.png",
        score: 78,
        count: 89,
      },
      {
        type: "Bus",
        entityName: "Bus",
        image: "/assets/person.png",
        score: 78,
        count: 24,
      },
      {
        type: "Cat",
        entityName: "Cat",
        image: "/assets/person.png",
        score: 78,
        count: 6,
      },
      {
        type: "Nature",
        entityName: "Entity Name",
        image: "/assets/person.png",
        score: 78,
        count: 93,
      },
      {
        type: "",
        entityName: "",
        image: "/assets/person.png",
        score: 78,
        count: 45,
      },
      {
        type: "",
        entityName: "",
        image: "/assets/person.png",
        score: 78,
        count: 15,
      },
    ],
  },
  {
    model: "Sentiment Analysis",
    data: [
      {
        type: "Positive",
        entityName: "Women",
        image: "/assets/person.png",
        score: 78,
        count: 67,
      },
      {
        type: "Negative",
        entityName: "Men",
        image: "/assets/person.png",
        score: 78,
        count: 93,
      },
      {
        type: "Neutral",
        entityName: "Bus",
        image: "/assets/person.png",
        score: 78,
        count: 265,
      },
    ],
  },
];

export const TextData = [
  {
    model: "Topic Modeling",
    identified: 674,
    unidentified: 45,
    data: [
      {
        type: "Healthy food",
        entityName: "Women",
        description:
          "But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered. But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered.",
        image: "/assets/text.png",
        score: 78,
        count: 124,
      },
      {
        type: "Fashion",
        entityName: "Men",
        description:
          "But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered. But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered.",
        image: "/assets/text.png",
        score: 78,
        count: 89,
      },
      {
        type: "Lifestyle",
        entityName: "Bus",
        description:
          "But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered. But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered.",
        image: "/assets/text.png",
        score: 78,
        count: 24,
      },
      {
        type: "Lifestyle",
        entityName: "Bus",
        description:
          "But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered. But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered.",
        image: "/assets/text.png",
        score: 78,
        count: 24,
      },
      {
        type: "Lifestyle",
        entityName: "Bus",
        description:
          "But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered. But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered.",
        image: "/assets/text.png",
        score: 78,
        count: 24,
      },
      {
        type: "Lifestyle",
        entityName: "Bus",
        description:
          "But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered. But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered.",
        image: "/assets/text.png",
        score: 78,
        count: 24,
      },
      {
        type: "Lifestyle",
        entityName: "Bus",
        description:
          "But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered. But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered.",
        image: "/assets/text.png",
        score: 78,
        count: 24,
      },
      {
        type: "Business",
        entityName: "Cat",
        description:
          "But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered. But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered.",
        image: "/assets/text.png",
        score: 78,
        count: 6,
      },
      {
        type: "",
        entityName: "",
        description:
          "But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered. But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered.",
        image: "/assets/text.png",
        score: 78,
        count: 45,
      },
      {
        type: "",
        entityName: "",
        description:
          "But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered. But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered.",
        image: "/assets/text.png",
        score: 78,
        count: 15,
      },
    ],
  },
  {
    model: "Sentiment Analysis",
    data: [
      {
        type: "Positive",
        entityName: "Women",
        description:
          "But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered. But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered.",
        image: "/assets/text.png",
        score: 78,
        count: 67,
      },
      {
        type: "Negative",
        entityName: "Men",
        description:
          "But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered. But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered.",
        image: "/assets/text.png",
        score: 78,
        count: 93,
      },
      {
        type: "Neutral",
        entityName: "Bus",
        description:
          "But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered. But the NGA document is notable as much for what it doesn’t say, reflecting the government’s possible lack of insight, at least in mid-February, into the balloons’ capabilities. The government recovered debris from the balloon’s crash site in the Atlantic Ocean, but has declined to say how much of the payload it recovered.",
        image: "/assets/text.png",
        score: 78,
        count: 265,
      },
    ],
  },
];
