import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, FormControl, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CustomButton from "../../../../../components/CustomButton";
import CustomCloseIconButton from "../../../../../components/CustomCloseIconButton";
import CustomInputField from "../../../../../components/CustomInputField";

interface ModalProps {
  open: boolean;
  handleModalOpen(): void;
  getAddedLabel(label: string): void;
}

export interface FormDataType {
  name: string;
}

const initialFormData: FormDataType = {
  name: "",
};

export default function AddLabel({ open, handleModalOpen, getAddedLabel }: ModalProps) {
  const theme = useTheme();

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = ({ fieldName, value }: { fieldName: string; value: any }) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const addLabel = () => {
    getAddedLabel(formData?.name);
    setFormData(initialFormData);
    handleModalOpen();
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleModalOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.background.default,
          borderRadius: "10px",
          width: "100%",
          textAlign: "center",
        },
      }}
    >
      <DialogContent sx={{ p: 4 }}>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Typography sx={{ m: "auto" }}>New Label</Typography>
          <CustomCloseIconButton onClick={addLabel} sx={{ marginLeft: { ml: "auto" } }}>
            <CloseIcon sx={{ width: "16px", height: "16px" }} />
          </CustomCloseIconButton>
        </Box>
        <Box mt={5}>
          <FormControl fullWidth>
            <Typography variant="caption2" textAlign="left" color={theme.palette.text.captionColor}>
              Label Name
            </Typography>
            <CustomInputField
              value={formData.name}
              required
              name="name"
              placeholder="Enter label name"
              size="small"
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange({ fieldName: e.target.name, value: e.target.value })}
              autoComplete="off"
            />
          </FormControl>
        </Box>
        <CustomButton
          sx={{
            ...theme.typography.regularButton,
            width: "80%",
            mt: 4,
            background: theme.palette.primary.main,
            color: theme.palette.text.secondaryText,
            "&:hover": {
              background: theme.palette.primary.main,
              color: theme.palette.text.secondaryText,
            },
          }}
          disabled={formData.name.trim() !== "" ? false : true}
          onClick={addLabel}
        >
          Add
        </CustomButton>
      </DialogContent>
    </Dialog>
  );
}
