import CloseIcon from "@mui/icons-material/Close";
import FlagIcon from "@mui/icons-material/Flag";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { Box, Dialog, DialogContent, Grid, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CustomButton from "../../../../../../../components/CustomButton";
import CustomIconButton from "../../../../../../../components/CustomIconButton";

interface FlagData {
  text: string;
  redFlagged: boolean;
}
interface DialogProps {
  open: boolean;
  handleClose: () => void;
  falgData?: any;
}

const flagged = [
  { text: "Hate", redFlagged: true },
  { text: "Violence", redFlagged: true },
  { text: "Kill", redFlagged: true },
  { text: "Weapon", redFlagged: true },
  { text: "Terrorism", redFlagged: true },
];
const RedFlagged = ({ open, handleClose, falgData }: DialogProps) => {
  const theme = useTheme();

  const [edit, setEdit] = useState(false);
  const [flaggedData, setFlaggedData] = useState<FlagData[]>(flagged);
  const [editedData, setEditedData] = useState<FlagData[]>([]);

  const handleClickEdit = () => {
    setEdit((prev) => !prev);
  };

  const handleSaveChanges = () => {
    setEdit(false);
    setFlaggedData(flaggedData);
  };

  const handleRemoveFlag = (flag: FlagData) => {
    const newData = flaggedData.map((data) => {
      if (flag.text === data.text) {
        return {
          text: data?.text,
          redFlagged: !data?.redFlagged,
        };
      } else {
        return data;
      }
    });
    setFlaggedData(newData);
  };

  const redFlagged = flaggedData.filter((flag) => flag.redFlagged);
  const nonRedFlagged = flaggedData.filter((flag) => !flag.redFlagged);

  const renderFlaggedBox = (isFlag: boolean) => {
    let flagData = isFlag ? redFlagged : nonRedFlagged;
    return (
      <Box
        sx={{
          width: "100%",
          background: `linear-gradient(to right, ${theme.palette.action.disabled} 50%, transparent 0%) top repeat-x, linear-gradient(${theme.palette.action.disabled} 50%, transparent 0%) right repeat-y,linear-gradient(to right, ${theme.palette.action.disabled} 50%, transparent 0%) bottom repeat-x, linear-gradient(${theme.palette.action.disabled} 50%, transparent 0%) left repeat-y`,
          backgroundSize: "10px 1px, 1px 10px",
          borderRadius: 2,
          height: "328px",
          p: 2,
          mt: 2,
        }}
      >
        <Grid container display="flex" gap={2} width="100%" flexDirection="row">
          {flagData.length > 0 ? (
            <>
              {flagData.map((flag, idx) => (
                <Box
                  key={`flag-${idx}`}
                  sx={{
                    display: "flex",
                    background: isFlag ? theme.palette.error.text : theme.palette.primary.dark,
                    p: 1,
                    borderRadius: 1,
                    gap: 0.5,
                  }}
                >
                  <Typography color={theme.palette.error.btnText} variant="subtitle1">
                    {flag?.text}
                  </Typography>
                  {edit && (
                    <CloseIcon sx={{ color: theme.palette.error.btnText, fontSize: "16px", mt: "-2px" }} onClick={() => handleRemoveFlag(flag)} />
                  )}
                </Box>
              ))}
            </>
          ) : (
            <Typography variant="body4" color={theme.palette.primary.inactiveIcon}>
              Not Chosen
            </Typography>
          )}
        </Grid>
      </Box>
    );
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.background.default,
          borderRadius: "10px",
          width: "100%",
          textAlign: "center",
          minHeight: "50vh",
        },
      }}
    >
      <DialogContent sx={{ p: 4 }}>
        <Grid container>
          <Box display={"flex"} width="100%" mb={3} gap={2}>
            <Typography variant="h2" color={theme.palette.text.titleLabel} pt={0.6}>
              Red flagged words
            </Typography>
            <Typography variant="h2" color={theme.palette.text.titleLabel} pt={0.6}>
              (35)
            </Typography>
            {!edit && (
              <CustomIconButton sx={{ width: "35px", height: "35px" }} onClick={handleClickEdit}>
                <SaveAsIcon sx={{ color: theme.palette.text.default, fontSize: "18px" }} />
              </CustomIconButton>
            )}
            {edit && <CustomButton onClick={handleSaveChanges}>Save Changes</CustomButton>}
            <CustomIconButton variant="contained" handleClick={() => {}} sx={{ ml: "auto", width: "35px", height: "35px" }}>
              <CloseIcon onClick={handleClose} sx={{ color: theme.palette.error.main }} />
            </CustomIconButton>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box display="flex" gap={2}>
                <FlagIcon sx={{ color: theme.palette.error.text, mt: "-5px" }} />
                <Typography variant="h4" color={theme.palette.error.text}>
                  Define like red flagged
                </Typography>
              </Box>
              {renderFlaggedBox(true)}
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" gap={2}>
                <FlagIcon sx={{ color: theme.palette.text.default, mt: "-5px" }} />
                <Typography variant="h4" color={theme.palette.text.default}>
                  Don't define like red flagged
                </Typography>
              </Box>
              {renderFlaggedBox(false)}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RedFlagged;
