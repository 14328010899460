import { createSlice } from "@reduxjs/toolkit";

export interface UiState {
  isDrawerOpen: boolean;
}

const initialState: UiState = {
  isDrawerOpen: false,
};

const uiSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleDrawer } = uiSlice.actions;
export default uiSlice;
