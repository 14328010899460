import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CropFreeIcon from "@mui/icons-material/CropFree";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, CardMedia, Dialog, DialogContent, Grid, Link, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../../../components/CustomButton";
import CustomCloseIconButton from "../../../../../components/CustomCloseIconButton";
import CustomIconButton from "../../../../../components/CustomIconButton";

interface ModalProps {
  open: boolean;
  data?: any;
  index: number;
  sourceType: string;
  handleModalOpen(): void;
  labels: Record<string, any>;
}

export default function EntityDetailModal({ open, data, index, sourceType, labels, handleModalOpen }: ModalProps) {
  const theme = useTheme();

  const [currentIdx, setIndex] = useState(index);

  const arrowUpHandler = () => {
    if (currentIdx > 0) {
      setIndex((prev) => prev - 1);
    }
  };

  const arrowDownHandler = () => {
    if (currentIdx < data?.data.length - 1) {
      setIndex((prev) => prev + 1);
    }
  };

  useEffect(() => {
    setIndex(index);
  }, [index]);
  const entityDetails = data?.data[currentIdx];

  const maxScore = Object.keys(labels).reduce((a, b) => (labels[a] > labels[b] ? a : b));

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={handleModalOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.background.default,
          borderRadius: "10px",
          width: "100%",
          textAlign: "center",
        },
      }}
    >
      <DialogContent sx={{ p: 4 }}>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Typography variant="body1">Entity {`${currentIdx + 1}/${data?.data?.length}`}</Typography>
          <CustomCloseIconButton onClick={handleModalOpen} sx={{ marginLeft: { ml: "auto" } }}>
            <CloseIcon sx={{ width: "16px", height: "16px" }} />
          </CustomCloseIconButton>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          sx={{
            width: "100%",
            background: (theme) => theme.palette.background.default,
            borderRadius: "4px",
          }}
        >
          <CustomIconButton
            sx={{
              width: "40px",
              minWidth: "40px",
              height: "40px",
              background: "transparent",
              border: "none",
              boxShadow: "none",
              ":hover": {
                background: "transparent",
                boxShadow: "none",
              },
              ":disabled": {
                background: "transparent",
                boxShadow: "none",
              },
            }}
            disabled={currentIdx === 0}
          >
            <KeyboardArrowLeftIcon
              onClick={arrowUpHandler}
              fontSize="large"
              cursor="pointer"
              sx={{
                margin: "10px 8px 10px 8px",
                color: currentIdx === 0 ? theme.palette.action.disabled : theme.palette.primary.main,
              }}
            />
          </CustomIconButton>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} order={{ xs: 2, md: 1 }}>
              {sourceType === "image" ? (
                <CardMedia component={"img"} image={entityDetails?.image} alt="entity" sx={{ borderRadius: 2 }} />
              ) : (
                <Box
                  sx={{
                    borderRadius: 2,
                    px: 2,
                    py: 10,
                    background: theme.palette.dashboardContainer.backround,
                    border: theme.palette.additionalColors.databasecardBorder,
                    textAlign: "left",
                  }}
                >
                  <Typography variant="body4">{entityDetails?.description}</Typography>
                </Box>
              )}

              <Typography variant="body4" display={"flex"} mt={1}>
                Original Source:
                {sourceType === "image" ? (
                  <Link
                    href="/assets/person.png"
                    target="_blank"
                    underline="none"
                    sx={{ ...theme.typography.body4, color: theme.palette.text.default, mt: 0.2, pl: 1 }}
                  >
                    54579480{currentIdx}.png
                  </Link>
                ) : (
                  <Link href="#" underline="none" sx={{ ...theme.typography.body4, color: theme.palette.text.default, mt: 0.2, pl: 1 }}>
                    54579480{currentIdx}.csv
                  </Link>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} order={{ xs: 1, md: 2 }}>
              <Box
                sx={{
                  width: "100%",
                  background: theme.palette.background.darkCardBackground,
                  border: theme.palette.additionalColors.databasecardBorder,
                  borderRadius: 2,
                  p: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box position="relative" display="flex" gap={2}>
                    <CropFreeIcon sx={{ color: theme.palette.primary.light }} />
                    <AddIcon sx={{ position: "absolute", ml: 0.5, mt: 0.5, fontSize: "16px", color: theme.palette.primary.light }} />
                    <Typography variant="body1" pt={0.5}>
                      Pick label
                    </Typography>
                  </Box>
                  <CustomButton
                    variant="text"
                    sx={{ ...theme.typography.bigButton, background: "transparent", border: "none", boxShadow: "none", mt: -0.7, ml: "auto" }}
                  >
                    Add Label
                  </CustomButton>
                </Box>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                  {Object.keys(labels).map((label) => {
                    return (
                      <React.Fragment key={label}>
                        {maxScore === label ? (
                          <Typography
                            variant="body3"
                            sx={{
                              background: theme.palette.text.default,
                              p: 1.5,
                              mt: 2,
                              border: theme.palette.additionalColors.databasecardBorder,
                              borderRadius: 2,
                              color: theme.palette.text.secondaryText,
                              textTransform: "capitalize",
                            }}
                          >
                            {`${label} (${labels[label]})%`}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body3"
                            sx={{
                              p: 1.5,
                              border: theme.palette.additionalColors.databasecardBorder,
                              borderRadius: 2,
                              mt: 2,
                              textTransform: "capitalize",
                              color: theme.palette.primary.light,
                            }}
                          >
                            {label}
                          </Typography>
                        )}
                      </React.Fragment>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <CustomIconButton
            sx={{
              width: "40px",
              minWidth: "40px",
              height: "40px",
              background: "transparent",
              border: "none",
              boxShadow: "none",
              ":hover": {
                background: "transparent",
                boxShadow: "none",
              },
              ":disabled": {
                background: "transparent",
                boxShadow: "none",
              },
            }}
            disabled={currentIdx === data?.data.length - 1}
          >
            <KeyboardArrowRightIcon
              onClick={arrowDownHandler}
              fontSize="large"
              cursor="pointer"
              sx={{
                margin: "10px 8px 10px 8px",
                color: currentIdx === data?.data.length - 1 ? theme.palette.action.disabled : theme.palette.primary.main,
              }}
            />
          </CustomIconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
