import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Checkbox, Grid, Popover, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import CustomCloseIconButton from "../../../components/CustomCloseIconButton";
import CustomInputField from "../../../components/CustomInputField";
import Classes from "../../../components/Scrollbar.module.css";

export interface LinkData {
  values: string[];
  checked: boolean;
}

interface RelevanceDialogProps {
  anchor: HTMLElement | null;
  id: string;
  open: boolean;
  getData?: (data: LinkData) => void;
  handleClose: () => void;
}

const RelevanceDialog = ({ anchor, id, open, getData, handleClose }: RelevanceDialogProps) => {
  const theme = useTheme();
  const [checked, setChecked] = useState<boolean>(true);

  const [values, setValues] = useState<string[]>([""]);

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleInputChange = (value: string, index: number) => {
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);
  };

  const handleAddLink = () => {
    setValues([...values, ""]);
  };

  const saveHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (getData) {
      getData({ values: values, checked: checked });
    }
    handleClose();
    setValues([""]);
  };

  const closeHandler = () => {
    setValues([""]);
    handleClose();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        marginTop: "20px",
      }}
      slotProps={{
        paper: {
          sx: {
            background: theme.palette.background.gradientLight1,
            boxShadow: theme.palette.dashboardContainer.editTagsDialogBoxShadow,
            border: theme.palette.dashboardContainer.editTagsDialogBorder,
            borderRadius: "10px",
            width: "30%",
            p: 2,
            textAlign: "center",
          },
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
            Add source for analysis:
          </Typography>
          <CustomCloseIconButton onClick={closeHandler} sx={{ marginLeft: { xs: "0", md: "80px" } }}>
            <CloseIcon sx={{ width: "16px", height: "16px" }} />
          </CustomCloseIconButton>
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="column">
          <Typography variant="caption1" sx={{ lineHeight: "120%", mb: "2px", color: theme.palette.text.light }} textAlign="left">
            Add a link to a relevant source
          </Typography>
          <Box width="100%" className={Classes.scroll_dark} sx={{ maxHeight: "100px", pr: "14px", overflowY: "scroll" }}>
            {values.map((value, index) => (
              <CustomInputField
                key={index}
                value={value}
                required
                name={`url-${index}`}
                placeholder="Paste the link"
                size="small"
                variant="outlined"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.value, index)}
                autoComplete="off"
                sx={{ width: "100%" }}
              />
            ))}
          </Box>
          <CustomButton
            onClick={handleAddLink}
            startIcon={<AddIcon sx={{ mt: "-3px", mr: -1 }} />}
            varient="text"
            sx={{
              ...theme.typography.bigButton,
              lineHeight: "110%",
              cursor: "pointer",
              marginTop: "5px",
              display: "flex",
              justifyContent: "left",
              background: "transparent",
              boxShadow: "none",
              border: "none",
              "&:hover": {
                background: "transparent",
              },
              pl: 0,
              alignSelf: "flex-end",
            }}
          >
            Add More Links
          </CustomButton>
        </Grid>
        <Grid item xs={12} display="flex" alignItems="center">
          <Checkbox
            checked={checked}
            onChange={handleChecked}
            inputProps={{ "aria-label": "controlled" }}
            sx={{
              p: 0,
              fontSize: "14px",
              fontWeight: "400",
              "& .MuiSvgIcon-root": {
                fill: checked ? theme.palette.checkbox.default : theme.palette.checkbox.darkBorder,
              },
              "& .MuiButtonBase-root.MuiCheckbox-root": {
                pl: "5px",
              },
              "&:hover .MuiSvgIcon-root": {
                fill: checked ? theme.palette.checkbox.default : "linear-gradient(135deg, rgba(38, 46, 63, 0.70) 0%, rgba(29, 35, 48, 0.70) 100%)",
                stroke: checked ? "none" : theme.palette.checkbox.darkBorder,
              },
              "&:active .MuiSvgIcon-root": {
                fill: checked ? "#245785" : theme.palette.checkbox.darkBorder,
              },
            }}
          />
          <Typography variant="caption" ml={1} color={theme.palette.text.titleLabel}>
            Apply new tags configuration to similar cases
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" gap={1.5} mt={1} justifyContent="flex-end">
          <CustomButton
            variant="contained"
            sx={{
              ...theme.typography.h6,
              border: `0.5px solid ${theme.palette.additionalColors.border1Light}`,
              color: theme.palette.primary.main,
              background: theme.palette.background.tertiaryDarkGradient,
              boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
              "&:hover": {
                background: theme.palette.background.tertiaryGradient,
                boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
              },
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              setValues([""]);
              handleClose();
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            sx={{
              ...theme.typography.bigButton,
              background: theme.palette.background.secondaryBgColor,
              color: theme.palette.text.secondaryText,
              width: "123px",
            }}
            onClick={saveHandler}
          >
            Save
          </CustomButton>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default RelevanceDialog;
